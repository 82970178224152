import { FC } from 'react';

import { AccordionBody as ReactstrapAccordionBody } from 'reactstrap';

import { IAccordionBodyProps } from './types';

/**
 * A simple wrapper class for the `reactstrap: <AccordionBody />` component.
 *
 * @interface IAccordionBodyProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const AccordionBody: FC<IAccordionBodyProps> = props => (
  <ReactstrapAccordionBody {...props} />
);
