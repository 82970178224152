import { format, fromUnixTime } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { getCurrentLocaleObj } from '@web/utils/@date-fns/locale';

import { translations } from '@shippypro/translations';

import type { OrderTimeline } from '@web/types/order';

import { TimelineEvent } from '@shippypro/design-system-web/types';
import { IconHelper } from '@shippypro/design-system-web/iconhelper';
import { Color } from '@shippypro/design-system-web/enums';

/**
 * This hook takes a list of events and returns a configured timeline events list
 * for the <Timeline /> Component.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 *
 * @param events The order events taken from the API
 *
 * @returns {TimelineEvent[]} The parsed events to display in the timeline
 */
const useGetOrderTimelineConfig = (
  events: OrderTimeline[],
): TimelineEvent[] => {
  const { t } = useTranslation(),
    eventTrans = translations.order.events;

  const locale = getCurrentLocaleObj();

  return (
    events
      // this 'sort' orders events from the most recent to the less recent one, based on created_at data
      .sort((a, b) => b.created_at - a.created_at)
      .map(event => {
        let tooltip = t(eventTrans.categories[event.category]);

        // augment event tooltip info with a formatted date (<weekday>,<month> <day>, <year> at <time>)
        if (event.created_at) {
          // Yes 'PPPPpp' is an ACTUAL format 🤣
          const eventHumanDate = format(
            fromUnixTime(event.created_at),
            'PPPPpp',
          );

          tooltip += ' -  ' + eventHumanDate;
        }

        const config = {
          icon: toEventIcon(event.code),
          color: toEventColor(event.code, event.category),
          tooltip,
        };

        return {
          ...config,

          title: t(eventTrans[event.code]),
          content: event.description,

          time: {
            day: format(fromUnixTime(event.created_at), 'dd/MM/yyyy', {
              locale,
            }),
            hour: format(fromUnixTime(event.created_at), 'HH:mm', {
              locale,
            }),
          },
        };
      })
  );
};

export const toEventColor = (code: string, category: string): Color => {
  if (code === 'delivered') {
    return Color.success;
  } else if (code === 'outForDelivery') {
    return Color.lightblue;
  } else if (code === 'inTransit') {
    return Color.blue;
  } else if (code === 'exception') {
    return Color.danger;
  } else if (code === 'failedAttempt') {
    return Color.warning;
  } else if (code === 'infoReceived') {
    return Color.purple;
  } else if (category === 'internal') {
    return Color.primary;
  } else if (category === 'tracking') {
    return Color.warning;
  } else {
    return Color.secondary;
  }
};

export const toEventIcon = (code: string): JSX.Element => {
  switch (code.trim()) {
    case 'imported':
      return <IconHelper icon="IconLogin" size={14} />;
    case 'updated':
      return <IconHelper icon="IconRefresh" size={14} />;
    case 'shipped':
      return <IconHelper icon="IconLogout" size={14} />;
    case 'printed':
      return <IconHelper icon="IconPrinter" size={14} />;
    case 'infoReceived':
      return <IconHelper icon="IconFileText" size={14} />;
    case 'inTransit':
      return <IconHelper icon="IconTruck" size={14} />;
    case 'outForDelivery':
      return <IconHelper icon="IconPackage" size={14} />;
    case 'failedAttempt':
      return <IconHelper icon="IconSquareX" size={14} />;
    case 'exception':
      return <IconHelper icon="IconAlertTriangle" size={14} />;
    case 'delivered':
      return <IconHelper icon="IconCircleCheck" size={14} />;
    default:
      return <IconHelper icon="IconCircleDot" size={14} />;
  }
};

export default useGetOrderTimelineConfig;
