// ########################## [IMPORTANT LIBRARIES]
import React, { useEffect } from 'react';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [TYPES]
import { Order } from '@web/types/order';

// ########################## [DESIGN SYSTEM]
import { Button, CardLoading } from '@shippypro/design-system-web';
import { IconHelper } from '@shippypro/design-system-web/iconhelper';
import { Color, StyleType } from '@shippypro/design-system-web/enums';

// ########################## [PAGE COMPONENTS]
import CardView from '@web/components/common-ui/wrappers/card/CardView';
import TrackingCardHeader from './TrackingCardHeader';
import OrderTimeline from '@web/components/common-features/order/OrderTimeline';
import { FlatCard } from '@web/components/common-ui/wrappers/flat-card/flat-card';

// ########################## [HOOKS]
import { getCarrier } from '@web/hooks/carriers/useGetCarrier';
import { useGetCarriers } from '@web/features/_global/hooks/api/useGetCarriers';
import { useGetOrderTimeline } from '@web/features/order/hooks/api/useGetOrderTimeline';
import useGetOrderTimelineConfig from '@web/hooks/orders/useGetOrderTimelineConfig';

interface ITrackingCard {
  order: Order;
  className: string;
}

/**
 * The TrackingCard component gets the order from outside
 * and renders every tracking detail.
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 *
 * @version 1.0.0
 * @interface ITrackingCard
 * @returns {JSX.Element}
 */
const TrackingCard: React.FC<ITrackingCard> = ({ order, className }) => {
  const { t } = useTranslation(),
    transTrackingCard = translations.order.info.trackingDialog.trackingCard;

  const { carriers } = useGetCarriers();

  const carrier = getCarrier(carriers!, order.carrier_id, order.carrier_name);

  const carrierTrackingUrl = order.shipment?.carrier_tracking_url;
  const carrierReturnTrackingUrl = order.shipment?.carrier_return_tracking_url;

  // Timeline data -- start
  const orderTimeline = useGetOrderTimeline();
  const { getTimeline, data: events, isLoading, isError } = orderTimeline;

  const trackingEvents =
    events?.filter(event => event.category === 'tracking') ?? [];

  const timeline = useGetOrderTimelineConfig(trackingEvents ?? []);

  // Fetching the Order's timeline
  useEffect(() => {
    if (order) getTimeline(order);
  }, [order, getTimeline]);

  // Timeline data -- end

  return (
    <CardView
      header={<TrackingCardHeader order={order} carrier={carrier} />}
      headerMuted={false}
      flex={false}
      hideActions
      headerCSS="border-bottom-light tracking-card-header"
      bodyCSS="overflow-y-auto w-full h-full tracking-card-body"
      cardCSS="align-self-start"
      className={className}
    >
      <FlatCard title={t(transTrackingCard.trackingCode)} className={'px-0'}>
        <div className="flex gap-2 items-center">
          <span className="font-medium text-2xl">
            {order.shipment?.tracking_code}
          </span>
          {carrierTrackingUrl && (
            <Button
              className="flex items-center gap-1"
              color={Color.primary}
              styleType={StyleType.flat}
              icon={<IconHelper icon="IconMapPin" size={14} />}
              iconOnHover={<IconHelper icon="IconExternalLink" size={14} />}
              onClick={() =>
                carrierTrackingUrl ? window.open(carrierTrackingUrl) : {}
              }
            >
              {t(transTrackingCard.trackOrder)}
            </Button>
          )}
        </div>
      </FlatCard>

      {order.shipment?.return_tracking_code && (
        <FlatCard
          title={t(transTrackingCard.returnTrackingCode)}
          className={'px-0'}
        >
          <div className="flex gap-2 items-center">
            <span className="font-medium text-2xl">
              {order.shipment?.return_tracking_code}
            </span>
            {carrierReturnTrackingUrl && (
              <Button
                className="flex items-center gap-1"
                color={Color.primary}
                styleType={StyleType.flat}
                icon={<IconHelper icon="IconMapPin" size={14} />}
                iconOnHover={<IconHelper icon="IconExternalLink" size={14} />}
                onClick={() =>
                  carrierReturnTrackingUrl
                    ? window.open(carrierReturnTrackingUrl)
                    : {}
                }
              >
                {t(transTrackingCard.trackOrder)}
              </Button>
            )}
          </div>
        </FlatCard>
      )}

      <FlatCard title={t(transTrackingCard.timeline)} className={'px-0 flex-1'}>
        <OrderTimeline
          isLoading={isLoading}
          isError={isError}
          timeline={timeline}
          loadingComponent={<CardLoading />}
        />
      </FlatCard>
    </CardView>
  );
};

export default TrackingCard;
