import styled from 'styled-components';

import { Card } from '@ds-web/components/atoms/ui/card/Card';

/**
 * This Styles Object formats the `Statistics` Card accordingly to give it
 * a responsive form. More comments inside of the specs.
 *
 * @interface ICardMetricProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const StyledStatisticsCard = styled(Card)`
  // -------- [HEADING SECTION]
  .stats-card-body {
    // Styling the various top texts+icon to give them a responsive set of rules
    .stats-card-title {
      margin-bottom: 7px;
      font-size: 21px;
      font-weight: bold;
      letter-spacing: -0.13px;
      color: var(--shp-color-genericui-primary);
    }

    .stats-card-description {
      margin-top: 7px;
      font-size: 14px;
      line-height: 1.5;
      color: var(--shp-color-genericui-purpleish-grey);
    }

    .stats-card-statcontainer {
      margin-top: 25px;

      display: flex;
      gap: 1rem;

      .stats-card-statavatar {
        width: 48px;
        height: 48px;
        padding: 12px;

        border-radius: 26px;
        cursor: default;

        .avatar-content {
          width: 24px;
          height: 24px;
        }
      }

      // Aligning the icon to be centered with the stat value + desc
      .stats-card-stattextcontainer {
        display: flex;
        flex-direction: column;

        .stats-card-stattitle {
          margin: 2px 32px 1px 0;
          font-size: 16px;
          font-weight: bold;
          color: var(--shp-color-genericui-primary);
        }

        .stats-card-statdescription {
          font-size: 14px;
          line-height: 1.5;
          color: var(--shp-color-genericui-purpleish-grey);
        }
      }
    }
  }

  // -------- [CHART SECTION]
  .stats-card-graphcontainer {
    // Expanding the chart over the card to give it a background feel
    height: 150px;
    overflow: hidden;

    .recharts-responsive-container {
      .recharts-cartesian-axis {
        display: none;
      }
    }
  }

  // -------- [LINK SECTION]
  .stats-card-footer {
    // Aligning the footer absolutely on the card to always be at the bottom-right of the content
    position: absolute;
    bottom: 1rem;
    right: 2rem;

    // This is the actual container of the text + navigation icon of the link
    .stats-card-footer-navlink {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.25px;
      cursor: pointer;

      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    // This badge appears when the link is not present
    .stats-card-footer-badge {
      font-size: 12px;
      font-weight: 500;
      line-height: 1.5;
      letter-spacing: 0.5px;
      text-align: center;

      text-transform: uppercase;
    }
  }
`;
