import classnames from 'classnames';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import { Badge } from '@shippypro/design-system-web';

interface IOrderCODBadgeProps {
  className?: string;
}

/**
 * Summary badge that shows if the order has Cash On Delivery as a payment method.
 *
 * @interface IOrderCODBadgeProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const OrderCODBadge: React.FC<IOrderCODBadgeProps> = ({ className }) => {
  const { t } = useTranslation(),
    transShipInfo = translations.order.shipmentInfo;

  return (
    <Badge
      pill
      className={classnames(
        'cursor-default !bg-[--shp-color-bg-info-light] !text-[--shp-color-text-info-label] leading-4',
        className,
      )}
    >
      {t(transShipInfo.cod)}
    </Badge>
  );
};

export default OrderCODBadge;
