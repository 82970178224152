export type AdditionalInfo = {
  id: number;
  marketplace_id: number;
  marketplace_platform: string;
  transaction_id: string;
  additional_info_name: string;
  additional_info_value: string;
};

export enum AdditionalInfoName {
  Tags = 'tags',
  AddressType = 'AddressType',
  OrderType = 'OrderType',
}

export enum AdditionalInfoAddressType {
  Residential = 'Residential',
  Commercial = 'Commercial',
}

export enum AdditionalInfoOrderType {
  consumer = 'Consumer',
  business = 'Business',
  prime = 'Prime',
  premium = 'Premium',
}
