import { Spinner } from 'reactstrap';

import { CardWrapper } from '@shippypro/design-system-web';

const Preload: React.FC = () => {
  return (
    <CardWrapper
      cardClassName="full-size"
      wrapperClassName="flex items-center h-[95vh]"
    >
      <Spinner color="grey" size="xl" />
    </CardWrapper>
  );
};

export default Preload;
