import { useCallback, useEffect, useMemo, useState } from 'react';
import { Note } from '@web/types/order';

export type UseEditOrderNoteProps = {
  notes?: Note | null;
  onSubmitShippyProNote: (note: string) => void;
  canEditShippyProNote: boolean;
};
/**
 * [HOOK] This hook exposes logics for editing notes
 * @author Fabio Fognani <fabio.fognani@shippypro.com>
 */
export const useEditOrderNote = ({
  notes,
  onSubmitShippyProNote,
  canEditShippyProNote,
}: UseEditOrderNoteProps) => {
  const shippyProNote = notes?.shippypro ?? '';

  // this local state is used to enter/exit edit mode
  const [isEditingShippyProNote, setEditingShippyProNote] = useState(false);
  // this local state is used to handle onChange events before submitting the edited value
  const [localShippyProNote, setLocalShippyProNote] = useState(shippyProNote);
  // this local state is used to handle latest saved value to allow discards
  const [savedShippyProNote, setSavedShippyProNote] = useState(shippyProNote);

  // handles enter/exit edit mode
  const onSetShippyProNoteEditing = useCallback(
    (isEditing: boolean, save: boolean) => {
      // enter/exit edit mode
      setEditingShippyProNote(isEditing);
      // if it's not in editing mode
      if (!isEditing) {
        // if save argument is true (only in onEditShippyNote)
        if (save) {
          // save the local shippy pro note
          onSubmitShippyProNote(localShippyProNote);
          // set the last saved note in local state
          setSavedShippyProNote(localShippyProNote);
          // if save argument is false (only from outside here)
        } else {
          // set the local value for note to the last saved value
          setLocalShippyProNote(savedShippyProNote);
        }
      }
    },
    [
      setLocalShippyProNote,
      setSavedShippyProNote,
      setEditingShippyProNote,
      onSubmitShippyProNote,
      localShippyProNote,
      savedShippyProNote,
    ],
  );

  useEffect(() => {
    setLocalShippyProNote(shippyProNote);
  }, [shippyProNote, setLocalShippyProNote]);

  // Checking if notes are empty
  const areNotesEmpty = useMemo(() => {
    return !notes || Object.values(notes).every(note => !note);
  }, [notes]);

  // Checking if a note can be added:
  // 1. the canEditShippyProNote prop is set to true
  // 2. the local state for shippy pro is valued
  // 3. the local state for isEditing is set to false
  const canAddNote =
    canEditShippyProNote && !localShippyProNote && !isEditingShippyProNote;

  // Callback to save shippy pro note on editing
  const onEditShippyNote = useCallback(() => {
    onSetShippyProNoteEditing(true, true);
  }, [onSetShippyProNoteEditing]);

  return useMemo(
    () => ({
      canAddNote,
      areNotesEmpty,
      onEditShippyNote,
      localShippyProNote,
      setLocalShippyProNote,
      isEditingShippyProNote,
      setEditingShippyProNote,
      onSetShippyProNoteEditing,
    }),
    [
      areNotesEmpty,
      canAddNote,
      isEditingShippyProNote,
      localShippyProNote,
      onEditShippyNote,
      onSetShippyProNoteEditing,
    ],
  );
};
