// ########################## [IMPORTANT LIBRARIES]
import { FC, memo } from 'react';
import { Col } from 'reactstrap';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [DESIGN SYSTEM]
import { Dot } from '@shippypro/design-system-web';
import { Color } from '@shippypro/design-system-web/enums';

// ########################## [STATE MANAGEMENT]
import { useDispatch } from 'react-redux';
import { changelogModalActions } from '@web/features/changelog-modal/slice';

// ########################## [FORMS]

// ########################## [DESIGN SYSTEM]
import { DropdownItem } from '@shippypro/design-system-web';
import { IconHelper } from '@shippypro/design-system-web/iconhelper';

// ########################## [TYPES]

// ########################## [PAGE COMPONENTS]

// ########################## [UTILS]

// ########################## [CONTEXTS]

// ########################## [HOOKS]
import { useCheckForChangelogUpdates } from '@web/features/changelog-modal/hooks/useCheckForChangelogUpdates';

/**
 * This is the header menu item that displays the Changelog when clicked.
 * It shows an accent dot when new articles are published.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const NavButton: FC = memo(() => {
  const { t } = useTranslation(),
    trans = translations.changelog;

  // Checking if there are new updates. IF yes, display an accent dot next to the menu link
  const newUpdatesAvailable = useCheckForChangelogUpdates();

  const dispatch = useDispatch();

  return (
    <DropdownItem
      data-test="open-changelog-modal-button"
      tag="div"
      onClick={() => dispatch(changelogModalActions.toggleChangelogModal(true))}
    >
      <Col className="fonticon-container flex justify-start items-center">
        <div className="fonticon-wrap">
          <IconHelper icon="IconBolt" size={13} />
        </div>
        <div className="inline-flex items-center justify-start">
          <label className="fonticon-classname">{t(trans.whatsNewMenu)}</label>
          {newUpdatesAvailable && <Dot color={Color.accent} />}
        </div>
      </Col>
    </DropdownItem>
  );
});

export default NavButton;
