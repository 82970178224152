import classnames from 'classnames';

import { Badge } from '@ds-web/components';
import { Color } from '@ds-web/tokens/enums';

import { INumberBadgeProps } from './types';

/**
 * A simple badge with a number inside of it. It disappears if the number is not higher than 1.
 * Its colors can also be primary or inverted.
 *
 * @interface INumberBadgeProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const NumberBadge: React.FC<INumberBadgeProps> = ({
  value = 0,
  inverted = false,
}) => {
  return (value ?? 0) > 1 ? (
    <Badge
      className={classnames('leading-4', {
        'text-primary': inverted,
        'text-white': !inverted,
      })}
      color={inverted ? Color.light : Color.dark}
    >
      {value}
    </Badge>
  ) : null;
};
