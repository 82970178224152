import { i18n, translationsJson } from './instance';

import {
  convertLanguageJsonToObject,
  translations_restricted,
} from './translations';

// Create the 'translations' object to provide full intellisense support for the static json files.
convertLanguageJsonToObject(
  translationsJson.en.translation,
  translations_restricted,
);

export { i18n, translationsJson };
