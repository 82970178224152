import { ReactNode } from 'react';

import { formatLocale } from '@ds-web/utils/functions';

export const handleSortIcons = (
  id: string | ((row: any, rowIndex: number) => ReactNode) | undefined,
) => {
  Array.from(
    document.body.querySelectorAll(
      '.react-dataTable [id^=column-].dt-row-selected-for-sorting',
    ),
  ).forEach(el => el.classList.remove('dt-row-selected-for-sorting'));

  document
    .getElementById('column-' + id)
    ?.classList.add('dt-row-selected-for-sorting');
};

export const sortFunctionFactory =
  (customSort?: any) =>
  (
    rows: any[],
    field: (row: any) => any,
    sortDirection: 'asc' | 'desc',
  ): any[] => {
    const sortedRows = !field
      ? rows
      : typeof customSort == 'undefined'
      ? defaultTableSorting(rows, field, sortDirection)
      : customSort(rows, field, sortDirection);

    return sortDirection === 'asc' ? sortedRows : sortedRows.reverse();
  };

export const defaultTableSorting = (rows, selector, direction) =>
  [...rows].sort((a, b) => {
    const aField = selector(a).toString().toLowerCase();
    const bField = selector(b).toString().toLowerCase();

    return direction ? (aField > bField ? 1 : -1) : aField < bField ? 1 : -1;
  });

export const timestampTableSorting = (rows, selector, sortDirection) =>
  [...rows].sort((a, b) => {
    const aField = selector(a).toString().toLowerCase();
    const bField = selector(b).toString().toLowerCase();

    const parseM = d => formatLocale(d.replace('- ', ''), 'MM-DD-YYYY');
    const parseD = d => new Date(d).valueOf();
    const parsedA = parseM(aField);
    const parsedB = parseM(bField);

    return parsedA === 'Invalid date'
      ? -1
      : parsedB === 'Invalid date'
      ? 1
      : sortDirection
      ? Math.sign(parseD(parsedB) - parseD(parsedA))
      : Math.sign(parseD(parsedA) - parseD(parsedB));
  });
