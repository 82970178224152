import { useState, useCallback, useMemo, useEffect, useRef } from 'react';

import { IManageOrderModalExtContextProps } from '@web/features/manage-order-modal/contexts/ManageOrderModalExtContext';

import { Order } from '@web/types/order';
import {
  ManageOrderModalInfo,
  ManageOrderModalView,
} from '../types/collection';

import {
  closeModalWhileSubmittingBlock,
  confirmCloseModalByView,
} from '@web/utils/@sweetalerts';

import useLDFlags from '@web/hooks/useLDFlags';

const VIEWS_WITHOUT_DEFAULT_CLOSINGMODAL: [keyof typeof ManageOrderModalView] =
  ['EditOrdersToShip'];

/**
 * `[HOOK]` This hook instances every common info prop related to the "Manage Order" drawer, from its initial state to its various shared props.
 * It is also used as a localised state that can get updated by an external source (I.e. A dedicated context or a smaller component further down the line).
 *
 * This hook+context manages the outside/external state of the modal, AKA if it's open, how many orders does it have and its typing.
 *
 * N.B. **ALREADY INSTANTIATED AT THE NEAR-TOP OF THE CODE TREE, NO NEED TO USE THIS ANYWHERE ELSE**
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 *
 * @returns {IManageOrderModalExtContextProps} All of the info needed for the modal to function
 */
const useInstanceManageOrderModalExtState =
  (): IManageOrderModalExtContextProps => {
    // ------ [ STATE SETUP ] ------ //

    // Bootstrapping the modal's contents (& visibility)
    const [info, setInfo] = useState<ManageOrderModalInfo | undefined>(
      undefined,
    );

    // Setting the view type of the modal (could be changed indipendently in the future, maybe with mobile switches?)
    const [view, setView] = useState<keyof typeof ManageOrderModalView>(
      ManageOrderModalView.ShipOrders,
    );

    const onOrdersRemoveFromModal = useRef((_ids: number[]) => {});

    // Preparing the state to lock the modal's possible bits&bobs when submitting or doing any "final" operation
    const [modalLockdown, setModalLockdown] = useState<boolean>(false);

    const { deliverCheckAddress: hasCheckAddressFF } = useLDFlags();

    // ------ [ EFFECTS ] ------ //

    // Clearing the modal's contents automatically ONLY when the list of orders is empty
    useEffect(() => {
      if (info?.loadedOrdersList.length === 0) {
        setInfo(undefined);
      }
    }, [info?.loadedOrdersList.length]);

    // ------ [ UTIL FUNCTIONS ] ------ //

    // Opening the modal and filling it with its info
    const openManageOrderModal = useCallback(
      <T extends keyof typeof ManageOrderModalView>(
        view: T,
        info: ManageOrderModalInfo,
        ordersRemovalCallback: (ids: number[]) => void = () => {},
      ) => {
        setView(view);
        setInfo(info);

        onOrdersRemoveFromModal.current = ordersRemovalCallback;
      },
      [],
    );

    // Closing the modal and destroying the info
    const closeManageOrderModal = useCallback(
      (forceClose: boolean = false) => {
        if (forceClose) {
          setInfo(undefined);
          return;
        }

        // IF the modal is in lockdown (submitting, etc.), we need to block the ability to close it
        // and perform some custom actions depending on the view we're in.
        if (modalLockdown && info?.submissionBlockTitle) {
          closeModalWhileSubmittingBlock(
            info?.submissionBlockTitle!,
            info?.submissionBlockDescription!,
            info?.submissionBlockConfirm!,
          );
        } else if (!VIEWS_WITHOUT_DEFAULT_CLOSINGMODAL.includes(view)) {
          confirmCloseModalByView(
            `${view}-modal-close`,
            view,
            () => setInfo(undefined),
            undefined,
            view === 'FixOrders' && hasCheckAddressFF,
          );
        } else {
          setInfo(undefined);
        }
      },
      [
        modalLockdown,
        info?.submissionBlockTitle,
        info?.submissionBlockDescription,
        info?.submissionBlockConfirm,
        view,
        hasCheckAddressFF,
      ],
    );

    // Removing an order both from the "Manage Order" modal AND the "Ship Orders" Table,
    // based on the category of the page we're on.
    const clearOrderFromModal = useCallback(
      (order: Order) => {
        if (info) {
          setInfo(prev => ({
            ...prev,
            loadedOrdersList: (prev ?? info).loadedOrdersList.filter(
              o => o.id !== order.id || o.external_id !== order.external_id,
            ),
          }));

          onOrdersRemoveFromModal.current &&
            onOrdersRemoveFromModal.current([order.id]);
        }
      },
      [info],
    );

    // ------ [ RETURN STATEMENT ] ------ //
    return useMemo(
      () => ({
        isOpen: Boolean(info),
        view,
        info,
        modalLockdown,
        openManageOrderModal,
        closeManageOrderModal,
        clearOrderFromModal,
        setModalLockdown,
      }),
      [
        view,
        info,
        modalLockdown,
        closeManageOrderModal,
        openManageOrderModal,
        clearOrderFromModal,
        setModalLockdown,
      ],
    );
  };

export default useInstanceManageOrderModalExtState;
