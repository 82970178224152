import { FC, memo } from 'react';

import {
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  BarChart,
  Bar,
  Tooltip,
} from 'recharts';

import { toLocalizedDecimal } from '@ds-web/utils/functions/numbers/localised-decimal/localised-decimal';

import { IStackedBarChartMetricsBodyProps } from './types';

/**
 * This Component renders a `Stacked Bar Chart` view of a metric, with two data columns, one above the other.
 *
 * @interface IStackedBarChartMetricsBodyProps
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
export const StackedBarChartMetricsBody: FC<IStackedBarChartMetricsBodyProps> =
  memo(({ data, locale = 'en-US', XAxisFormatter, tooltipComponent }) => {
    const maxYValue = Math.max(
      ...data.map(d => d.yValueFirst + d.yValueSecond),
    );
    const yAxisDomain = [0, maxYValue + maxYValue * 0.25]; // Add 25% padding to the top of the chart

    return (
      <section
        id="metricsstackedbarchart-container"
        className="w-full flex flex-col justify-between items-start"
      >
        <div
          id="metricsstackedbarchart-graphcontainer"
          className="recharts-wrapper !mt-5"
        >
          <ResponsiveContainer width="98%" height="100%">
            <BarChart
              data={data}
              margin={{ right: 0, left: 0, top: 10, bottom: 0 }}
            >
              <CartesianGrid vertical={false} />
              <XAxis dataKey="xName" tick={XAxisFormatter} interval={0} />
              <YAxis
                dataKey="yValue"
                width={35}
                domain={yAxisDomain}
                tickFormatter={value => toLocalizedDecimal(value, 0, 0)}
              />
              <Tooltip
                cursor={{ fill: 'transparent' }}
                content={tooltipComponent}
              />
              <Bar
                type="monotone"
                dataKey="yValueFirst"
                fill="#7349dd"
                fillOpacity={1}
                stackId="a"
                isAnimationActive={false}
              ></Bar>
              <Bar
                type="monotone"
                dataKey="yValueSecond"
                fill="#b6a0ed"
                fillOpacity={1}
                radius={[3, 3, 0, 0]}
                stackId="a"
                isAnimationActive={false}
              ></Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </section>
    );
  });
