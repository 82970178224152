import { FC, useState } from 'react';
import { ButtonDropdown as ReactstrapButtonDropdown } from 'reactstrap';

import { IButtonDropdownProps } from './types';

/**
 * A simple wrapper class for the `reactstrap: <ButtonDropdown />` component.
 *
 * @interface IButtonDropdownProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const ButtonDropdown: FC<IButtonDropdownProps> = props => {
  let [isOpen, setIsOpen] = useState(false);

  return (
    <ReactstrapButtonDropdown
      isOpen={isOpen}
      toggle={() => setIsOpen(!isOpen)}
      {...props}
    />
  );
};
