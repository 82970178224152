import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import { Button, TooltipWrapperByID } from '@shippypro/design-system-web';
import { IconHelper } from '@shippypro/design-system-web/iconhelper';
import { scrollIntoView } from '@shippypro/design-system-web/functions';

/**
 * This is a simple "Scroll to Top" button to help the user navigate to the top of the
 * page when they're scrolled to far down, appearing only after a while.
 * It also displays a tooltip to help the user understand what it does.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const ScrollToTop: React.FC = () => {
  const { t } = useTranslation(),
    backTrans = translations.common.backToTop;

  // This is the true element that gets the `Scroll` event, NOT <body />
  const scrollContainer = document.getElementById('main-container');
  const targetID = 'scroll-to-top';

  const [visible, setVisible] = useState(false);
  const handleBtnVisibility = useCallback(
    () => setVisible((scrollContainer?.scrollTop ?? 0) > 300),
    [scrollContainer],
  );

  useEffect(() => {
    scrollContainer?.addEventListener('scroll', handleBtnVisibility);

    return () =>
      scrollContainer?.removeEventListener('scroll', handleBtnVisibility);
  });

  return (
    <StyledScrollToTopContainer
      style={{
        opacity: visible ? 1 : 0,
        visibility: visible ? 'visible' : 'hidden',
      }}
      id="scroll-to-top-container"
    >
      <TooltipWrapperByID target={targetID} text={t(backTrans)}>
        <Button
          id={targetID}
          className="btn-icon"
          onClick={() => scrollIntoView('.site-anchor')}
        >
          <IconHelper icon="IconChevronUp" size={15} />
        </Button>
      </TooltipWrapperByID>
    </StyledScrollToTopContainer>
  );
};

const StyledScrollToTopContainer = styled.div`
  z-index: 999;

  position: fixed;

  bottom: 15px;
  right: 15px;

  cursor: pointer;

  transition:
    visibility 0s,
    opacity 0.2s linear;

  #scroll-to-top {
    background-color: white !important;
    border-color: white !important;
    color: var(--shp-color-genericui-primary) !important;

    box-shadow: 0px 10px 25px -2px rgba(0, 0, 0, 0.3);

    &:hover {
      background-color: var(--shp-color-bg-highlight) !important;
      border-color: var(--shp-color-bg-highlight) !important;

      box-shadow: 0px 5px 10px -2px rgba(0, 0, 0, 0.3);
    }
  }
`;

export default ScrollToTop;
