import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { LiveChatObject, WindowWithLiveChat } from '@web/features/help/types';

/**
 *  **[HOOK]** This hook manages the Live Chat's state and its events.
 * It is used to open and close the chat, and to keep track of the current conversation and unread messages.
 *
 * @returns {LiveChatObject} An object containing all of the info needed to manage the Live Chat
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const useManageSupportChat = (): LiveChatObject => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [activeConversation, setActiveConversation] = useState(false);

  const eventRef = useRef<((payload) => void) | null>(null);

  /*================== SERVICE SET-UP =================*/
  useEffect(() => {
    /*======= SETTINGS =======*/
    if ((window as WindowWithLiveChat).hsConversationsSettings) {
      (window as WindowWithLiveChat).hsConversationsSettings = {
        inlineEmbedSelector: '#shippypro-livechat',
        loadImmediately: true,
      };
    }

    /*======= LIVE CHAT =======*/
    if ((window as WindowWithLiveChat).HubSpotConversations) {
      (window as WindowWithLiveChat).HubSpotConversations.widget.load({
        widgetOpen: false,
      });

      setHasLoaded(true);
    }
  }, []);

  /*================== CHAT EVENTS SUBSCRIPTION =================*/
  /* istanbul ignore next */
  useEffect(() => {
    eventRef.current = payload => {
      if (payload.conversation)
        setActiveConversation(payload.conversation.conversationId);

      /**
       * Okay, this will sound absurd but bear with me:
       * The hubspot integration is as old as time and doesn't bode well with DOM reactivity.
       * Using a reactive `useState` call here would cause the notification sound to mute and the
       * "Unread messages" pulsars and flashing title to NEVER disappear.
       *
       * So, since this is insanely stupid, I'm using a DOM query to get the pulsars and show/hide them
       * at will, since I cannot be bothered by a hacky, old and surprisingly badly-coded integration.
       */
      const userMenuDot = document.getElementById('unreadchat-usermenu');
      const supportSectionDot = document.getElementById(
        'unreadchat-supportsection',
      );

      if (payload.unreadCount) {
        if (userMenuDot) userMenuDot.classList.remove('hidden');
        if (supportSectionDot) supportSectionDot.classList.remove('hidden');
      } else {
        if (userMenuDot) userMenuDot.classList.add('hidden');
        if (supportSectionDot) supportSectionDot.classList.add('hidden');
      }
    };

    if (hasLoaded) {
      (window as WindowWithLiveChat).HubSpotConversations.on(
        'conversationStarted',
        eventRef.current,
      );

      (window as WindowWithLiveChat).HubSpotConversations.on(
        'unreadConversationCountChanged',
        eventRef.current,
      );
    }

    return () => {
      (window as WindowWithLiveChat).HubSpotConversations.off(
        'conversationStarted',
        eventRef.current,
      );

      (window as WindowWithLiveChat).HubSpotConversations.off(
        'unreadConversationCountChanged',
        eventRef.current,
      );
    };
  }, [hasLoaded]);

  /*================== CHAT HANDLERS =================*/
  const openHandler = useCallback(() => {
    if (hasLoaded) {
      const status = (
        window as WindowWithLiveChat
      ).HubSpotConversations.widget.status();

      if (!status.loaded && status.pending) {
        (window as WindowWithLiveChat).HubSpotConversations.widget.open();
      } else if (status.loaded && !status.pending) {
        (
          window as WindowWithLiveChat
        ).HubSpotConversations.resetAndReloadWidget();
      }
    }
  }, [hasLoaded]);

  const closeHandler = useCallback(() => {
    if (hasLoaded) {
      (window as WindowWithLiveChat).HubSpotConversations.widget.close();
    }
  }, [hasLoaded]);

  return useMemo(
    () => ({
      hasLoaded,
      activeConversation,
      openHandler,
      closeHandler,
    }),
    [activeConversation, closeHandler, hasLoaded, openHandler],
  );
};

export default useManageSupportChat;
