import {
  UncontrolledAlert as ReactstrapUncontrolledAlert,
  Alert as ReactstrapAlert,
} from 'reactstrap';
import classnames from 'classnames';

import { IAlertProps } from './types';

import { IconHelper } from '@ds-web-iconhelper';

/**
 * A simple alert component with the ability to configure its appearance.
 * It can be dismissable, have a custom separated header and host a pre-text icon.
 *
 * @interface IAlertProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const Alert = ({
  heading,
  headingClassName,
  bodyClassName,
  dismissable,
  icon,
  iconClassName,
  children,
  ...rest
}: IAlertProps) => {
  const AlertComponent = dismissable
    ? ReactstrapUncontrolledAlert
    : ReactstrapAlert;

  return (
    <AlertComponent {...rest}>
      {heading ? (
        <div
          className={classnames(
            'alert-heading flex items-center',
            headingClassName,
          )}
        >
          <IconHelper
            icon={icon}
            size={14}
            className={classnames('mr-3', iconClassName, { hidden: !icon })}
          />{' '}
          {heading}
        </div>
      ) : null}
      <div
        className={classnames('alert-body', bodyClassName, {
          hidden: !children,
        })}
      >
        {children}
      </div>
    </AlertComponent>
  );
};
