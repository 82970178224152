import { FC } from 'react';

import { InputGroup as ReactstrapInputGroup } from 'reactstrap';

import { IInputGroupProps } from './types';

import { Size } from '@ds-web/tokens/enums';

/**
 * A simple wrapper class for the `reactstrap: <InputGroup />` component.
 *
 * @interface IInputGroupProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const InputGroup: FC<IInputGroupProps> = ({
  size = Size.md,
  ...rest
}) => <ReactstrapInputGroup {...rest} />;
