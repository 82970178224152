// ########################## [IMPORTANT LIBRARIES]
import { FC } from 'react';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [FORMS]
import { UseFormReturn } from 'react-hook-form';
import { ILabelFormatSettingsForm } from '../types';

// ########################## [COMMON COMPONENTS]
import { ControlledCheckbox } from '@web/utils/@react-hook-form';

export type ILabelFormatSettingsFormProps = {
  form: UseFormReturn<ILabelFormatSettingsForm>;
  ids?: number[];
};

/**
 * This form allows the user to configure the label format settings.
 * The currently available features are:
 * * `saveAsDefault`: if checked, the current format will be saved as the default one for the user.
                      N.B.: TWO default formats can be saved,  for the ONE LABEL config or the MULTIPLE LABELS config.
 *
 * @interface ILabelFormatSettingsFormProps The `react-hook-form` instance.
 *
 * @author [Emanuele Moricci] <emanuele.moricci@shippypro.com>
 */
export const LabelFormatSettingsForm: FC<ILabelFormatSettingsFormProps> = ({
  form: { control },
  ids,
}) => {
  const { t } = useTranslation(),
    transInputs = translations.ship.buttons.print.glsItaly.modal.setDefault;

  return (
    <form>
      <ControlledCheckbox
        name="saveAsDefault"
        label={t(transInputs, { count: ids?.length })}
        control={control}
        labelClassName="font-medium text-grey md:text-purpleish"
        data-test="glsitaly-formatpicker-modal__saveasdefault"
      />
    </form>
  );
};
