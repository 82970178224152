import { useMemo } from 'react';

import { CustomRTKQueryOptions } from '@web/utils/@reduxjs/rtk-query';

import { useGetHotJarFieldsQuery } from '@web/features/_global/api';

/**
 * Returns RTK Query hook with renamed data field.
 * This Hook fetches every configured HotJar field from the User's account and
 * uses it to initialise & identify the proper HotJar session here on the App
 *
 * @param {CustomRTKQueryOptions} options subset of RTK Query hook options
 */
export const useGetHotJarFields = (options?: CustomRTKQueryOptions) => {
  const { data: getHotJarFields, ...rest } = useGetHotJarFieldsQuery(
    undefined,
    options,
  );

  return useMemo(
    () => ({
      fields: getHotJarFields,
      ...rest,
    }),
    [getHotJarFields, rest],
  );
};
