// ########################## [IMPORTANT LIBRARIES]
import { FC, memo, useContext } from 'react';

// ########################## [CONTEXTS]
import TrackingDialogContext from './contexts/TrackingDialogContext';

// ########################## [TYPES]

// ########################## [PAGE COMPONENTS]
import OrderTrackingDialog from './pages';

// ########################## [HOOKS]
import { useGetOrder } from '@web/features/order/hooks/api/useGetOrder';

/**
 * **FEATURE: Order Tracking Dialog**
 *
 * This code is responsible for showing a modal with every tracking detail in it.
 *
 * Said modal will contain the transaction id, the extra eu detail, the documentation detail, every recipient info,
 * every items info, the carrier name and img, the tracking code and the complete timeline.
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
export const TrackingDialog: FC = memo(() => {
  const { orderId, ...rest } = useContext(TrackingDialogContext)!;

  const { order } = useGetOrder({
    id: orderId ? String(orderId) : '',
    category: 'shipped',
  });

  return order ? (
    <OrderTrackingDialog
      show={rest.isOpen}
      order={order}
      onCancel={() => rest.closeTrackingDialog()}
    />
  ) : null;
});
