import { FC } from 'react';
import { NavLink as ReactstrapNavLink } from 'reactstrap';

import { INavLinkProps } from './types';

/**
 * A simple wrapper class for the `reactstrap: <NavLink />` component.
 *
 * @interface INavLinkProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const NavLink: FC<INavLinkProps> = props => (
  <ReactstrapNavLink {...props} />
);
