import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import {
  showErrorToastr,
  showWarningToastr,
  showInfoToastr,
} from '@shippypro/design-system-web/functions';

/**
 * Hook that fetches any Url GET Parameter, checks if any of them are talking
 * about a possible error, groups them and shows a unique toastr to notify the user.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 *
 * @returns {void}
 */
const useShowParamsMessages = (): void => {
  const { t } = useTranslation(),
    transCommon = translations.common,
    transErr = transCommon.errors;

  // Gets every parameter from the url
  const urlParams = new URLSearchParams(window.location.search);

  // Iterates through'em and checks for possible errors
  const hasErrors = parseMessagesToToastr(
    urlParams,
    'error',
    showErrorToastr,
    t,
    transErr.errorCount,
    transErr.error,
  );

  // Iterates through'em and checks for possible warnings
  const hasWarnings = parseMessagesToToastr(
    urlParams,
    'warning',
    showWarningToastr,
    t,
    transCommon.warningCount,
    transCommon.warning,
  );

  // Iterates through'em and checks for possible statuses
  const hasStatuses = parseMessagesToToastr(
    urlParams,
    'status',
    showInfoToastr,
    t,
    transCommon.messagesCount,
    transCommon.messages,
  );

  // Wipes the url search bar if any were found
  if (hasErrors || hasWarnings || hasStatuses)
    window.history.replaceState({}, document.title, window.location.pathname);
};

/**
 * This function creates a volatile iterator for the current URL params,
 * Searches for a precise param and turns it into a Toastr message.
 *
 */
const parseMessagesToToastr = (
  params: URLSearchParams,
  msgKey: 'error' | 'warning' | 'status',
  toastrCallback: Function,
  t: TFunction,
  transTitle: string,
  transBody: string,
): boolean => {
  // Creating the Iterator for the url params
  const entries = params.entries();

  // Iterates through'em and checks for possible messages
  let messages: string[] = [];
  for (const [key, value] of entries) {
    if (key.includes(msgKey)) messages.push(value);
  }

  // If there are, generates a JSX Component that groups them all
  if (messages.length) {
    const MessageComponent = (
      <>
        <b>{t(transTitle, { count: messages.length })}:</b>
        {messages.map((msg, i) => (
          <div key={i} className="!my-2">
            {msg}
          </div>
        ))}
      </>
    );

    // Fires a toastr to notify the user
    toastrCallback(t(transBody), MessageComponent, {
      toastId: `${msgKey}ParamsBoundary`,
    });

    return true;
  }

  return false;
};

export default useShowParamsMessages;
