import styled from 'styled-components';

import { Modal } from '@shippypro/design-system-web';

export const StyledModal = styled(Modal)`
  max-width: 95% !important;
  height: 95% !important;

  .modal-content {
    height: 100% !important;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
`;
