import { FC, useCallback, useMemo } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import { Button, TooltipWrapperByID } from '@shippypro/design-system-web';
import { IconHelper } from '@shippypro/design-system-web/iconhelper';
import { Color } from '@shippypro/design-system-web/enums';
import {
  showErrorToastr,
  showSecondaryToastr,
} from '@shippypro/design-system-web/functions';

import { downloadFile } from '@web/utils/functions';
import { Documentation } from '@web/types/order';

/**
 * Documentation block props
 * @interface IDocumentationProps
 * @property {Documentation} documentation
 */
interface IDocumentationProps {
  documentation: Documentation;
  downloadLabel?: string;
}

/**
 * The DocumentationBlock is a component that displays the clickable document and its download icon inline
 *
 * @author Fortunato De Angelis <fortunato.deangelis@shippypro.com>
 *
 * @version 1.0.0
 * @param {IDocumentationProps} props
 * @returns {JSX.Element}
 */
const DocumentationBlock: FC<IDocumentationProps> = ({
  documentation,
  downloadLabel,
}): JSX.Element => {
  const { t } = useTranslation();
  const transDocumentType = translations.common.documentType;

  /**
   * Format date
   * @type {string}
   * @returns {string}
   */
  const formattedDate: string = useMemo(() => {
    if (!documentation.date) return '-';
    const date = new Date(documentation.date * 1000);
    const formattedDate = date.toLocaleDateString(window.navigator.language, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    });
    return formattedDate;
  }, [documentation]);

  /**
   * Handle download file
   * @param e
   * @returns {void}
   */
  const handleDownloadFile = useCallback(
    async (e: React.MouseEvent): Promise<void> => {
      const fileUrl = e.currentTarget.getAttribute('data-file-url');
      if (!fileUrl) return;
      await downloadFile(fileUrl)
        .then(() => {
          showSecondaryToastr();
        })
        .catch(() => {
          showErrorToastr(
            t(translations.common.errors.error),
            t(translations.common.fileDownloadError),
          );
        });
    },
    [t],
  );

  const isLabelEplOrZpl =
    documentation.type === 'label' &&
    ['.zpl', '.epl'].includes(documentation.file_url.slice(-4));

  // If file_url is not available, disable download button
  const isFileUrlUnavailable = !documentation.file_url;

  return (
    <StyledDocumentationBlock className="document-item d-flex justify-content-between border-b-1">
      <div className="d-flex flex-col">
        <p
          onClick={() =>
            isLabelEplOrZpl
              ? downloadFile(documentation.file_url)
              : window.open(documentation.file_url)
          }
          className={classnames(
            'font-bold m-0 hover:underline cursor-pointer',
            {
              'text-[color:--shp-color-link]': !isFileUrlUnavailable,
              'text-muted': isFileUrlUnavailable,
            },
          )}
        >
          {t(transDocumentType[documentation.type] || transDocumentType.other)}
        </p>
        <p className="text-muted m-0">{formattedDate}</p>
      </div>
      <div className="icon d-flex flex-col">
        <TooltipWrapperByID
          target="downloadFile"
          text={t(translations.common.downloadFile)}
        >
          <Button
            data-file-url={documentation.file_url}
            color={Color.none}
            disabled={isFileUrlUnavailable}
            className="flex items-center gap-1 btn-icon cursor-pointer hover:bg-[color:--shp-color-bg-highlight] font-semibold"
            onClick={handleDownloadFile}
          >
            <IconHelper icon="IconDownload" size={17} />
            {downloadLabel}
          </Button>
        </TooltipWrapperByID>
      </div>
    </StyledDocumentationBlock>
  );
};

const StyledDocumentationBlock = styled.div`
  p {
    max-width: 400px;
    white-space: break-spaces;
  }
`;

export default DocumentationBlock;
