import { MenuConfig, MenuItem } from '../types';

/**
 * Takes a list of menu items and unravels them with their children
 * into a single unified list.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 *
 * @param config {MenuConfig} The list of menu items
 * @returns {MenuItem[]} The unraveled list of items + their children
 */
const useGetAllMenuItems = (config: MenuConfig): MenuItem[] => {
  const allChildren = config.reduce<MenuItem[]>(
    (prev, curr) => [...prev, curr, ...(curr.children ?? [])],
    [],
  );

  return allChildren;
};

export default useGetAllMenuItems;
