import classnames from 'classnames';

import { Color } from '@ds-web/tokens/enums';

import { IDotProps } from './types';

/**
 * A simple colored pulsing dot, attachable to icons and other components.
 *
 * @returns {JSX.Element}
 */
export const Dot: React.FC<IDotProps> = ({
  isPulsing = false,
  color = Color.primary,
  dotClassName,
  pulsingClassName,
  ...rest
}) => {
  const bgColor = color in Color ? `bg-${color}` : '';
  const styleColor = typeof color === 'string' && !bgColor ? `${color}` : '';

  return isPulsing ? (
    <span
      className={classnames(
        'flex h-4 w-4 absolute top-[-2px] right-0',
        dotClassName,
      )}
      {...rest}
    >
      <span
        className={classnames(
          'animate-ping absolute inline-flex h-3 w-3 rounded-full',
          { [bgColor]: !!color && bgColor },
          pulsingClassName,
        )}
        style={{ backgroundColor: `${styleColor}` }}
      ></span>
      <span
        className={classnames(
          'relative inline-flex rounded-full h-3 w-3',
          { [bgColor]: !!color && bgColor },
          pulsingClassName,
        )}
        style={{ backgroundColor: `${styleColor}` }}
      ></span>
    </span>
  ) : (
    <span
      data-test="new-updates"
      className={classnames(
        'flex h-3 w-3 absolute top-[-2px] right-[3px] rounded-full mb-3 ml-[2px]',
        { [bgColor]: !!color && bgColor },
        dotClassName,
      )}
      style={{ backgroundColor: `${styleColor}` }}
      {...rest}
    ></span>
  );
};
