import { FC, useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import { useFzf, FzfHighlight } from 'react-fzf';

import { IInputWithCreatableOptionsProps } from './types';

import {
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
} from '@ds-web/components/atoms/ui/dropdowns/dropdown';
import { Input } from '@ds-web/components/atoms/form/inputs/input/Input';

export const InputWithCreatableOptions: FC<IInputWithCreatableOptionsProps> = ({
  inputProps,
  dataTest,
  optionsList,
  onOptClick,
  getOptionValue,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useClickAway(ref, () => setIsOpen(false));

  const [selectedValue, setSelectedValue] = useState<string>('');

  const { results, getFzfHighlightProps } = useFzf({
    items: optionsList,
    query: getOptionValue
      ? (inputProps?.value as object)['value']?.toString() ?? ''
      : inputProps?.value?.toString() ?? selectedValue ?? '',
    sort: true,
  });

  const mergedList = [...new Set([...results, ...optionsList])];

  return (
    <div
      className="w-full relative"
      data-test={`${dataTest ?? 'generic'}__input-with-options-container`}
      ref={ref}
    >
      <ButtonDropdown
        isOpen={isOpen}
        toggle={() => setIsOpen(true)}
        className="w-full !cursor-default"
        direction="start"
      >
        <DropdownToggle
          className="p-0 !cursor-default !border-none !shadow-none !bg-transparent focus:!bg-transparent active:!bg-transparent"
          tabIndex={-1}
        >
          <Input
            {...inputProps}
            value={
              getOptionValue
                ? getOptionValue(inputProps?.value as object)
                : inputProps?.value ?? selectedValue
            }
            onChange={e =>
              inputProps?.onChange
                ? inputProps.onChange(e)
                : setSelectedValue(e.target.value)
            }
            data-test={`${dataTest ?? 'generic'}__input-with-options-input`}
          />
        </DropdownToggle>
        {mergedList.length > 0 && (
          <DropdownMenu className="left-0 w-full !translate-y-16 h-fit max-h-[200px] overflow-auto">
            {mergedList.map((opt, index) => (
              <div key={index} className="w-full flex-flex-col">
                <button
                  data-test={`${
                    dataTest ?? 'generic'
                  }__input-with-options-match`}
                  className="w-full p-[8px_12px] text-left hover:bg-gray-200 focus:bg-primary focus:text-white"
                  onClick={() => {
                    setIsOpen(false);
                    return onOptClick ? onOptClick(opt) : setSelectedValue(opt);
                  }}
                >
                  {results.includes(opt) ? (
                    <FzfHighlight
                      {...getFzfHighlightProps({
                        item: opt,
                        index,
                        className:
                          'fuzzy-highlighted-letter font-bold text-primary',
                      })}
                    />
                  ) : (
                    opt
                  )}
                </button>
              </div>
            ))}
          </DropdownMenu>
        )}
      </ButtonDropdown>
    </div>
  );
};
