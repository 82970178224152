import { FC, memo } from 'react';
import { TriggerEvent, useContextMenu } from 'react-contexify';

import { Menu, Item } from '@ds-web/components/atoms/ui/dropdowns/context';

import { IconHelper } from '@ds-web-iconhelper';

import { ListViewAction, IListActionProps } from './types';

const ListAction: FC<IListActionProps> = memo(
  ({ config, hide = false, ...rest }) => {
    const actions: ListViewAction[] | undefined = config.actions?.context,
      singleAction = actions && actions.length === 1,
      menuId = `menu_id_${rest.row ? Object.values(rest.row)[0] : 'header'}`,
      { show } = useContextMenu({
        id: menuId,
      }),
      handleContextMenu = (event: TriggerEvent) => {
        event.preventDefault();
        show(event);
      };

    return actions ? (
      <div className={`list-view-${rest.row ? 'row' : 'header'}-action`}>
        {!hide && (
          <>
            <span
              onClick={
                singleAction ? () => actions[0].fn(rest.row) : handleContextMenu
              }
            >
              {singleAction ? (
                <IconHelper icon={actions[0].icon} className="mr-1" size={20} />
              ) : (
                <IconHelper icon="IconDots" className="cursor" size="20" />
              )}
            </span>
            <Menu id={menuId} animation="fade">
              {actions.map((action: ListViewAction, i: number) => (
                <Item
                  key={i}
                  disabled={false}
                  onClick={() => action.fn(rest.row)}
                >
                  <IconHelper icon={action.icon} className="mr-1" size={20} />
                  {action.name}
                </Item>
              ))}
            </Menu>
          </>
        )}
      </div>
    ) : null;
  },
);

export default ListAction;
