// ########################## [IMPORTANT LIBRARIES]
import React from 'react';

// ########################## [TYPES]
import { Order } from '@web/types/order';

// ########################## [PAGE COMPONENTS]
import ExtraEUCountry from './ExtraEUCountry';
import AddedDocuments from './AddedDocuments';
import StatusBadge from '@web/features/order/components/badges/StatusBadge';

interface IDetailsCardHeader {
  order: Order;
  isDutiable: boolean;
}

/**
 * The DetailsCardHeader component gets the order from outside
 * and renders the tracking code, the status badge, the extra eu detail and the documentation count.
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 *
 * @version 1.0.0
 * @interface IDetailsCardHeader
 * @returns {JSX.Element}
 */
const DetailsCardHeader: React.FC<IDetailsCardHeader> = ({
  order,
  isDutiable,
}) => {
  return (
    <>
      <div className="flex items-center gap-1 h-10">
        <span className="font-medium text-lg">{order.external_id}</span>
        <StatusBadge
          order={order}
          target={`${order.external_id}-tracking-dialog-status`}
          hasHover={false}
        />
      </div>
      <div className="flex justify-end gap-1">
        {isDutiable && (
          <ExtraEUCountry
            id="tracking-countries"
            country={order.address.country}
          />
        )}
        <AddedDocuments
          id="tracking-documents"
          documentation={order.documentation}
        />
      </div>
    </>
  );
};

export default DetailsCardHeader;
