import { FC } from 'react';
import { UncontrolledCollapse } from 'reactstrap';

import NavmenuDropdownItem from './NavmenuDropdownItem';
import useGetActiveTabData from './hooks/useGetActiveTabData';

import type { INavMenuItemGenericProps } from './types';

const NavmenuCollectorItem: FC<INavMenuItemGenericProps> = ({
  item,
  activeTab,
  tabChangeHandler,
}) => {
  const { activeTab: activeChild } = useGetActiveTabData(item, activeTab);

  return (
    <>
      <NavmenuDropdownItem
        item={item}
        activeTab={activeChild ? item.id : activeTab}
        tabChangeHandler={tabChangeHandler}
        clickHandler={item.clickHandler}
        caret={item.children && item.children.length > 0}
      />
      {item.children && (
        <UncontrolledCollapse toggler={`#nav-item-${item.id}`}>
          {item.children.map(item => (
            <NavmenuDropdownItem
              item={item}
              activeTab={activeTab}
              tabChangeHandler={tabChangeHandler}
              clickHandler={item.clickHandler}
              key={item.id}
            />
          ))}
        </UncontrolledCollapse>
      )}
    </>
  );
};

export default NavmenuCollectorItem;
