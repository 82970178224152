import { flexRender } from '@tanstack/react-table';
import { IReactTableProps } from '../../types';

/**
 * This component is the UI representation of a `react-table` footer.
 * It displays the `columns > footer` list into an actual table footer row layout.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 *
 * @returns {FC} The `React Table` footer, fully rendered & managed.
 */
function Footer<T extends object>(props: IReactTableProps<T>) {
  const { getFooterGroups, customComponents } = props;

  return customComponents?.hasFooter ? (
    <tfoot>
      {getFooterGroups().map(group => (
        <tr key={group.id} className="react-table-footer">
          {group.headers.map(header => (
            <td
              key={header.id}
              className="react-table-footer-column"
              {...{
                style: {
                  width: header.column.getSize(),
                },
              }}
            >
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.footer,
                    header.getContext(),
                  )}
            </td>
          ))}
        </tr>
      ))}
    </tfoot>
  ) : null;
}

export default Footer;
