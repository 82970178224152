import styled from 'styled-components';

export const StyledInteractablePill = styled.div`
  border-width: 1px !important;

  &.has-label {
    border: none !important;

    &.is-selected {
      padding-right: 0.5rem;
    }
    &.has-more {
      border-color: var(--shp-color-bg-dark-highlight) !important;
      background-color: var(--shp-color-bg-dark-highlight) !important;
      .interactable-pill-label {
        color: var(--shp-color-bg-dark-highlight) !important;
      }
    }
  }

  &.has-white-bg {
    background-color: white !important;
    border-color: var(--hp-color-genericborder-light);
  }

  &.is-not-selected:not(.is-highlighted) {
    width: fit-content !important;
    &:hover {
      background-color: var(--shp-color-bg-highlight) !important;
    }
  }

  &.is-selected {
    border: 1px solid var(--shp-color-genericborder-input) !important;
    background: var(--shp-color-bg);

    .interactable-pill-label {
      color: var(--shp-color-bg-dark-highlight) !important;
    }

    &:hover {
      background-color: var(--shp-color-bg-highlight) !important;
    }
  }

  &.is-highlighted {
    background-color: var(--shp-color-bg-highlight) !important;
    border: 1px solid var(--shp-color-genericborder-input) !important;

    &:hover {
      background-color: var(--shp-color-bg-highlight) !important;
    }
  }
`;
