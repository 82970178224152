import {
  OrderToManage,
  OrderShipOrEditMutationPayload,
} from '@web/features/manage-order-modal/types/collection';

/**
 * A simple type that represents a tuple of an OrderToManage and its corresponding OrderShipOrEditMutationPayload to send to the APIs.
 */
export type OrderAndPayloadTuple = [
  OrderToManage,
  OrderShipOrEditMutationPayload,
];
