import { ITableProps } from '../types';
import { ICustomHeaderProps } from '../headers/types';

export const toHeaderProps = (
  configProps: ITableProps,
  tableProps: any,
): ICustomHeaderProps => ({
  filters: tableProps.filterValue,
  filterNumber: tableProps.filterNumber,
  handleFilter: tableProps.filterFunctionFactory,
  paginationPerPage: tableProps.paginationPerPage,
  handlePagination: (rowNumber: number) =>
    tableProps.setPaginationPerPage(rowNumber),
  transFunc: tableProps.t,
  selectedRows: tableProps.selected,
  composition: configProps.composition,
  pagination: configProps.pagination,
  selection: configProps.selection,
  filtering: configProps.filtering,
  action: configProps.action,
  utility: configProps.utility,
});
