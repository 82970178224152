import { memo, lazy, Suspense } from 'react';
import * as Sentry from '@sentry/react';

export interface SuspenseOpts {
  fallback: React.ReactNode;
  avoidReload?: boolean;
}
type Unpromisify<T> = T extends Promise<infer P> ? P : never;

const oldChunksCheck = 'old-chunks-cleanup';
const oldChunksMaxReloads = 3;

/**
 * This function is able to take a Route component and lazy-load it to give the
 * user a better experience, with less lag and more immediate responsivity.
 *
 * @param importFunc Function that holds the import url of the component
 * @param selectorFunc Selector that is able to get the component code by its name
 * @param opts Options for the Suspense API
 *
 * @returns {(props: React.ComponentProps<U>) => JSX.Element}
 */
export const lazyLoad = <
  T extends Promise<any>,
  U extends React.ComponentType<any>,
>(
  importFunc: () => T,
  selectorFunc?: (s: Unpromisify<T>) => U,
  opts: SuspenseOpts = { fallback: null, avoidReload: false },
): ((props: React.ComponentProps<U>) => JSX.Element) => {
  let lazyFactory: () => Promise<{ default: U }> = importFunc;

  if (selectorFunc) {
    lazyFactory = async () =>
      new Promise((resolve, reject) => {
        // check if the window has already been refreshed
        const refreshTimes = parseInt(
          JSON.parse(sessionStorage.getItem(oldChunksCheck) || '0'),
        );

        // try to import the component
        importFunc()
          .then(module => {
            // reset the refresh and return the imported component
            sessionStorage.setItem(oldChunksCheck, '0');

            resolve({ default: selectorFunc(module) });
          })
          .catch(error => {
            console.error('Lazy-loading failure: ', error);
            Sentry.captureException(error);

            /* istanbul ignore if */
            if (refreshTimes <= oldChunksMaxReloads) {
              // switch the session var and refresh the page
              sessionStorage.setItem(oldChunksCheck, `${refreshTimes + 1}`);

              return opts.avoidReload ? undefined : window.location.reload();
            }

            reject(error);
          });
      });
  }

  const LazyComponent = memo(lazy(lazyFactory));

  return (props: React.ComponentProps<U>): JSX.Element => (
    <Suspense fallback={opts.fallback!}>
      <LazyComponent {...props} />
    </Suspense>
  );
};
