import { FC } from 'react';

import { Input as ReactstrapInput } from 'reactstrap';
import classnames from 'classnames';

import { IInputProps } from './types';

/**
 * A simple wrapper class for the `reactstrap: <Input />` component.
 *
 * @interface IInputProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const Input: FC<IInputProps> = ({ borderType, className, ...rest }) => (
  <ReactstrapInput
    className={classnames(className, { [`${borderType}`]: borderType })}
    {...rest}
  />
);
