import i18n from 'i18next';

/**
 * Formats a decimal number into a localised string (`.`, `,` on thousands and decimals depending on the language)
 * that can have a set amount of decimal digits.
 *
 * @param {number} value The numeric value to format
 * @param {?number} maxDecimals The maximum amount of decimal digits after the localised divider symbol
 * @param {?number} minDecimals The minimum amount of decimal digits after the localised divider symbol
 * @returns {string} The localised string value
 */
export function toLocalizedDecimal(
  value?: number,
  maxDecimals: number = 2,
  minDecimals: number = 0,
): string {
  try {
    return new Intl.NumberFormat(i18n.language, {
      notation: 'compact',
      compactDisplay: 'short',
      minimumFractionDigits: minDecimals,
      maximumFractionDigits: maxDecimals,
    }).format(value ?? 0);
  } catch {
    // Added the old version to allow users with old computers to not crash
    return (value ?? 0).toFixed(maxDecimals);
  }
}
