import { useGetDeviceSize } from '@ds-web/utils/hooks';
import { FC, memo } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { Skeleton } from '@ds-web/components';

/**
 * A skeleton loader for the `Bar Chart` Metrics Card.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const BarsMetricsSkeleton: FC = memo(() => {
  const { isTablet, isXLScreen } = useGetDeviceSize();

  let viewBoxHeight = 120;
  /* istanbul ignore if */
  if (isTablet) viewBoxHeight = 250;
  /* istanbul ignore if */
  if (isXLScreen) viewBoxHeight = 82;

  return (
    <Card className="overflow-hidden">
      <CardHeader className="h-[4.5rem] border-b-[1px] border-b-[color:--shp-color-divider]">
        <Skeleton className="w-full">
          <circle cx="12" cy="12" r="12" />
          <rect x="30" y="5" width="134" height="14" rx="2" />
        </Skeleton>
      </CardHeader>
      <CardBody className="p-2">
        <Skeleton
          className="w-full"
          viewBox={`0 0 400 ${viewBoxHeight}`}
          preserveAspectRatio="xMidYMax meet"
        >
          {/* AREA CHART */}
          <rect x="0" y="0" width="100%" height={`${isXLScreen ? 70 : 80}%`} />
          {/* FOOTER */}
          <rect
            x="85%"
            y={`${isXLScreen ? 85 : 88}%`}
            width="40"
            height="5"
            rx="2"
          />
          <rect
            x="96%"
            y={`${isXLScreen ? 81 : isTablet ? 87 : 85}%`}
            width="10"
            height="10"
            rx="10"
          />
        </Skeleton>
      </CardBody>
    </Card>
  );
});
