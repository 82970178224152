import { FC, memo } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { Skeleton } from '@ds-web/components';

interface IKpiCardSkeleton {
  title?: string;
}

/**
 * A skeleton loader for the `Kpi` Metrics Card.
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
export const KpiCardSkeleton: FC<IKpiCardSkeleton> = memo(({ title }) => {
  return (
    <Card className="overflow-hidden">
      <CardHeader className="h-[2.5rem]">
        {title ? (
          <span
            className="title overflow-x-hidden text-ellipsis !text-[#303030]
              !text-[14px] not-italic font-normal leading-normal"
          >
            {title}
          </span>
        ) : (
          <Skeleton className="w-full">
            <rect x="0" y="5" width="134" height="10" rx="2" />
          </Skeleton>
        )}
      </CardHeader>
      <CardBody className="p-1">
        <Skeleton
          className="w-full"
          viewBox={`0 0 400 20`}
          preserveAspectRatio="xMidYMax meet"
        >
          {/* KPI VALUE */}
          <rect x="0" y="0" width="100" height="20" rx="2" />
          {/* KPI TREND */}
          <rect x="90%" y="5" width="10" height="10" rx="10" />
          <rect x="95%" y="5" width="30" height="10" rx="2" />
        </Skeleton>
      </CardBody>
    </Card>
  );
});
