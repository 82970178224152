import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import { OrderSearchEntity } from '@web/features/global-search/types/entities/orders';

type ReturnType = {
  label: string;
  icon: string;
  category: 'toship' | 'shipped';
};

/**
 * **[HOOK]** Parses the order status to a more readable format and adds complementary information to it.
 *
 * @returns A function to parse the order status
 */
const useParseOrderEntityStatus = (): ((
  orderStatus: OrderSearchEntity['order_status'],
) => ReturnType) => {
  const { t } = useTranslation(),
    trans = translations.ship.tabs;

  return useCallback(
    (orderStatus: OrderSearchEntity['order_status']) => {
      switch (orderStatus) {
        case 'to_ship':
          return {
            label: t(trans['ready-to-ship'].title),
            icon: 'IconSend',
            category: 'toship',
          };
        case 'shipped':
          return {
            label: t(trans.shipped.title),
            icon: 'IconTruck',
            category: 'shipped',
          };
        case 'archived':
          return {
            label: t(trans.archived.title),
            icon: 'IconArchive',
            category: 'shipped',
          };
        case 'deleted':
          return {
            label: t(trans.deleted.title),
            icon: 'IconTrash',
            category: 'shipped',
          };
        default:
          return {
            label: 'Unknown',
            icon: 'IconCircle',
            category: 'shipped',
          };
      }
    },
    [t, trans],
  );
};

export default useParseOrderEntityStatus;
