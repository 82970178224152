import { OrderCategory } from '@web/types/order';

/* --- STATE --- */
export interface OrderState {
  mktpOrdersUpdating: number[];
  sendingMail: boolean;
  labelMailIds: number[] | null;
}

// The gateway API calls related to the `Order` feature
export enum OrderAPIActions {
  OrderSaveNote = 'OrderSaveNote',
  OrderGetTimeline = 'OrderGetTimeline',
  OrderMarketplaceUpdate = 'OrderMarketplaceUpdate',
  OrderDelete = 'OrderDelete',
  OrderMergeOrders = 'OrderMergeOrders',
  OrderArchive = 'OrderArchive',
  OrderUnarchive = 'OrderUnarchive',
  OrderSendEmail = 'OrderSendEmail',
  OrderSendLabelsMail = 'OrderSendLabelsMail',
  OrderCheckUniqueExternalId = 'OrderCheckUniqueExternalId',
  OrderCancelAndMoveToReadyToShip = 'OrderCancelAndMoveToReadyToShip',
  // [EXPORT NEW ACTIONS ABOVE] < Needed for generating API Hooks seamlessly
}

export type FeatureState = OrderState;

/* --- QUERY TYPES --- */

export type GetOrderQueryPayload = {
  category: OrderCategory | string;
  filters: {
    get_specific_order: {
      toship_order_id?: string | number;
      shippypro_id?: string | number;

      external_id?: string | null;
      marketplace_id?: string | number | null;
      marketplace_platform?: string | null;
    };
  };
};

export type GetOrderTimelineMutationPayload = {
  order_id: number | null;
  transaction_id: string;
  marketplace_id: number | null;
  marketplace_platform: string;
};

export type OrderDeletionMutationResult = {
  ids_deleted: number[];
  ids_error: number[];
};

export type OrderMergeMutationPayload = {
  ids: number[];
};

export type OrderArchiviationMutationPayload = {
  ids: (number | string)[];
  archive_all?: boolean;
};

export type BaseOrdersMutationPayload = {
  category: string;
  ids: (number | string)[];
};

export type OrderSaveNoteMutationPayload = {
  id: number | string;
  category: string;
  note: string;
};

export type OrderUpdateMarketplaceMutationPayload = {
  ids: number[];
  markeplacesCount: number;
};

export type SendOrderMailBaseMutationPayload = {
  ids: number[];
};

export type OrderSendLabelsMailOptions = {
  email_recipient: string;
  email_subject: string;
  email_body: string;
  server_type: string;
  server_id: number;
  include_manifests: boolean;
  receive_copy: boolean;
};

export type OrderSendLabelsMailPayload = {
  ids: number[];
} & OrderSendLabelsMailOptions;

export type SendUniqueTransactionIDMutationPayload = {
  external_id: string;
};

export type CancelOrderAndMoveItToReadyToShipMutationPayload = {
  orderId: number;
};

export type CancelOrderAndMoveItToReadyToShipMutationResult = {
  result: boolean;
  error: string | null;
};
