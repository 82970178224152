import { FC, cloneElement } from 'react';
import classnames from 'classnames';

import { Card as ReactstrapCard, UncontrolledPopover } from 'reactstrap';

import { IconHelper } from '@ds-web-iconhelper';
import { UncontrolledTooltip } from '@ds-web/components/atoms/ui/tooltip/UncontrolledTooltip';

import { ICardMetricProps } from './types';
import { StyledCardMetrics } from './style';

/**
 * This Component renders a Metrics Card, that holds a dynamic cta,
 * body and trend, based on the given configuration.
 *
 * @interface ICardMetricProps
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
export const CardMetrics: FC<ICardMetricProps> = ({
  code,
  headerHighlight = false,
  icon,
  title,
  description,
  rightSideOptWidget,
  rightSideBodyWidget,
  tooltip,
  children,
  bodyHint,
  message,
  cta,
  className,
  bodyClassName,
  headerClassName,
  withHeaderBorderBottom = true,
  titleTooltip,
  rightSideHeaderWidget,
  ...rest
}) => {
  const target = `${code}-tooltip`;
  const popoverTitleTarget = `${code.replace(/ /g, '').toLocaleLowerCase()}-title-popover`;

  return (
    <ReactstrapCard {...rest} className={classnames(className, 'h-full')}>
      <StyledCardMetrics className="flex flex-column justify-start flex-grow">
        <section
          id="metricscard-header"
          className={classnames(
            'flex justify-between items-center p-2 overflow-x-hidden text-ellipsis whitespace-nowrap',
            {
              'border-b-[1px]': withHeaderBorderBottom,
              'pb-0': !withHeaderBorderBottom,
              'bg-[color:--shp-color-bg-accent-highlight] rounded-t-[0.428rem] border-b-white':
                headerHighlight,
              'border-b-[color:--shp-color-divider]':
                withHeaderBorderBottom && !headerHighlight,
            },
            headerClassName,
          )}
        >
          <div
            id="metricscard-title"
            className="flex !gap-2 items-start justify-start"
          >
            {icon && (
              <IconHelper icon={icon} className="font-bold text-primary" />
            )}
            <div id="metricscard-info" className="w-full flex flex-col mt-0.5">
              {titleTooltip ? (
                <>
                  <span
                    id={popoverTitleTarget}
                    className="title overflow-x-hidden text-ellipsis font-bold text-primary hover:underline hover:decoration-dotted"
                  >
                    {title}
                  </span>
                  <UncontrolledPopover
                    target={popoverTitleTarget}
                    trigger="hover"
                    placement="top"
                  >
                    {titleTooltip}
                  </UncontrolledPopover>
                </>
              ) : (
                <span className="title overflow-x-hidden text-ellipsis font-bold text-primary">
                  {title}
                </span>
              )}
              {description && (
                <span className="description overflow-x-hidden text-ellipsis text-sm text-subdued">
                  {description}
                </span>
              )}
            </div>
            {rightSideHeaderWidget && (
              <div className="flex justify-end items-center">
                {rightSideHeaderWidget}
              </div>
            )}
          </div>
          {(rightSideOptWidget || tooltip) && (
            <div className="metricscard-rightside flex justify-center items-center !gap-6">
              {rightSideOptWidget}
              {tooltip && (
                <div id="metricscard-tooltip">
                  <IconHelper icon="IconHelpCircle" id={target} />
                  <UncontrolledTooltip target={target}>
                    {tooltip}
                  </UncontrolledTooltip>
                </div>
              )}
            </div>
          )}
        </section>
        <section
          id="metricscard-body"
          className={classnames(
            'flex flex-col p-2 pb-2 items-center',
            bodyClassName,
          )}
        >
          {rightSideBodyWidget && (
            <div className="w-full flex justify-end">{rightSideBodyWidget}</div>
          )}
          <div className="w-full flex justify-center h-full">
            {cloneElement(children, { code, rightSideBodyWidget })}
          </div>
        </section>
        {bodyHint && (
          <section
            id="metricscard-body-hint"
            className="flex flex-wrap justify-start items-center px-6 my-1 !gap-1"
          >
            <span className="text-[color:--shp-color-text-subdued]">
              <IconHelper
                icon={bodyHint.icon ?? 'IconLightbulb'}
                className="!inline-block !align-middle"
              />{' '}
              <span>{bodyHint.hintCta}</span>
              <span
                className="!ml-1 text-black underline cursor-pointer"
                onClick={bodyHint.onClick}
              >
                {bodyHint.linkCta}
              </span>
            </span>
          </section>
        )}
        {(message || cta) && (
          <section
            id="metricscard-footer"
            className="w-full flex justify-between items-end px-2 pb-2 flex-grow"
          >
            {message && (
              <div
                id="metricscard-message"
                className={classnames(
                  'w-full flex justify-start items-end !gap-2 text-sm',
                  {
                    'text-danger': message.dangerous,
                    'text-[color:--shp-color-text-subdued]': !message.dangerous,
                  },
                )}
              >
                {message.icon && <IconHelper icon={message.icon} />}
                <span>{message.text}</span>
              </div>
            )}
            {cta && (
              <div
                id="metricscard-cta"
                className="w-full flex justify-end items-center text-[color:--shp-color-link] font-semibold"
              >
                <div
                  className="flex justify-start items-center !gap-2 cursor-pointer hover:underline"
                  onClick={cta.onClick}
                >
                  <span>{cta.text}</span>
                  <IconHelper icon="IconCircleArrowRight" />
                </div>
              </div>
            )}
          </section>
        )}
      </StyledCardMetrics>
    </ReactstrapCard>
  );
};
