import { FC, memo } from 'react';
import classnames from 'classnames';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations_restricted';

import { InteractablePill, TooltipWrapperByID } from '@ds-web/components';
import { useGetDeviceSize } from '@ds-web/utils/hooks';

import { IFiltersSummaryBtnProps } from './types';

/**
 * The button shown in the top-right corner of the page layout, below the tabs.
 * It shows how many filters are currently applied and has a quick way to "get rid of'em".
 * Normally it opens the FiltersForm drawer when clicked.
 *
 * @interface IFiltersSummaryBtnProps
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const FiltersSummaryBtn: FC<IFiltersSummaryBtnProps> = memo(
  ({
    tooltipContent,
    isTooltipActive,
    disabled,
    submittedFiltersCount,
    onClear,
    toggleDrawer,
    isFilterFormClearable,
  }) => {
    const { t } = useTranslation(),
      trans = translations.common;

    // Changing the amount and size of the pills if the display is smaller than 768px
    const { isTabletHoriz } = useGetDeviceSize();

    return (
      <div onClick={() => !disabled && toggleDrawer?.()}>
        <TooltipWrapperByID
          target="filters-draweropen-button"
          text={tooltipContent}
          active={isTooltipActive}
        >
          <InteractablePill
            id="filters-summary-pill"
            data-test="filters-summary-button"
            label={
              <span className="h-full flex items-center !px-2">
                {isTabletHoriz
                  ? submittedFiltersCount
                  : t(trans.countFilters, { count: submittedFiltersCount })}
              </span>
            }
            checkOverflow={false}
            isSelected={isFilterFormClearable ?? submittedFiltersCount > 0}
            onRemoveSelectionBtnClick={e => {
              e.preventDefault();
              e.stopPropagation();
              (isFilterFormClearable ?? submittedFiltersCount > 0) &&
                !disabled &&
                onClear();
            }}
            isInteractible
            className={classnames({
              'text-muted': disabled,
              '!cursor-default': toggleDrawer === undefined,
            })}
          />
        </TooltipWrapperByID>
      </div>
    );
  },
);
