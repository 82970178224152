import classnames from 'classnames';

import { TooltipWrapperByID } from '@ds-web/components/molecules/ui/tooltips/tooltip-wrapper-byid/TooltipWrapperByID';
import { IconHelper } from '@ds-web-iconhelper';

import { ICustomActionProps } from './types';
import { Spinner } from 'reactstrap';

/**
 * This component is a fully customizabile action: it can have an icon
 * and be styled as a button. It can have a tooltip and of course an action on click.
 *
 * @interface ICustomActionProps
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
export const CustomAction: React.FC<ICustomActionProps> = ({
  name,
  disabled,
  customActionTooltip,
  actionCSS,
  actionFn,
  actionIcon,
  iconSize,
  iconColor,
  actionLabel,
  isLoading = false,
}) => {
  return (
    <TooltipWrapperByID
      tooltipProps={{
        placement: 'bottom',
      }}
      target={`${name}-custom-action`}
      text={customActionTooltip}
      active={!!customActionTooltip && !disabled}
    >
      <span
        className={classnames(
          'info-card-custom-action gap-[0.25rem] flex items-center cursor-pointer',
          actionCSS,
          {
            'info-card-custom-action-disabled': disabled,
          },
        )}
        onClick={() => !disabled && actionFn()}
      >
        {isLoading && <Spinner size="sm" />}
        {actionIcon && !isLoading ? (
          <IconHelper
            icon={actionIcon}
            size={iconSize}
            className={classnames({
              [`stroke-${iconColor}`]: !!iconColor,
            })}
          />
        ) : null}
        {actionLabel && !isLoading ? (
          <span className="leading-[20px] break-keep">{actionLabel}</span>
        ) : null}
      </span>
    </TooltipWrapperByID>
  );
};
