import { useMemo } from 'react';

import { useLazyGetQzTrayAuthDataQuery } from '@web/features/ship/api';

/**
 * Returns RTK Query hook with renamed data field.
 * This hook fetches the necessary data to sign the QZTray Trust Certificate.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const useGetQzTrayAuthData = () => {
  const [trigger, { data, ...rest }] = useLazyGetQzTrayAuthDataQuery();

  return useMemo(
    () => ({
      getQzTrayAuthData: trigger,
      data,
      ...rest,
    }),
    [data, rest, trigger],
  );
};
