import styled from 'styled-components';

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;

  .status-alert-wrapper {
    display: flex;
    background-color: #e67e22;
    color: #ffffff;
    margin: 0;
    padding: 0;
    width: 320px;
    border-radius: 3px;
    border: none;
    box-shadow: 0 20px 32px -8px rgba(9, 20, 66, 0.25);
    z-index: -1;
    transition:
      left 1s ease,
      bottom 1s ease,
      right 1s ease;

    &.floating {
      z-index: -1;
      position: fixed;
      left: -9999px;
      right: auto;
      bottom: 60px;
    }

    &.visible {
      left: 60px;
      z-index: 999999;
    }

    &.maintenance {
      background-color: #3498db;
    }
  }

  .status-alert-icon {
    padding: 10px 5px 5px;
  }

  .status-alert-close {
    text-align: right;
    min-width: 11px;
    font-size: 11px;
    flex: 0.7;
    padding: 14px 14px 0 0;
  }

  .status-alert-content {
    flex: 6;
    padding: 10px;
    display: flex;
    flex-direction: column;
  }

  .status-alert-title {
    word-wrap: break-word;
    margin: 0;
    max-height: 50px;
    min-height: 20px;
    overflow: hidden;
  }

  .status-alert-title p {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    color: #ffffff;
  }

  .status-alert-context {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 12px;
    flex: 1;
  }

  .status-alert-item a {
    color: #ffffff;
    text-decoration: underline;
  }

  .status-alert-svg-button {
    border: none;
    background: none;
    cursor: pointer;
    padding: 1px 0 2px 0;
  }
`;

export default StyledContainer;
