import { FC } from 'react';
import { Input, Label } from 'reactstrap';
import classnames from 'classnames';

import { Color } from '@ds-web/tokens/enums';

import { ISwitchProps } from './types';

export const Switch: FC<ISwitchProps> = ({
  label,
  color = Color.primary,
  iconTrue = null,
  iconFalse = null,
  onChange = () => {},
  ...rest
}) => (
  <div
    className={classnames(
      `form-check form-switch ${rest.className} form-check-${color}`,
    )}
  >
    <Input
      type="switch"
      value={rest.value}
      disabled={rest.disabled}
      name={rest.name}
      id={rest.name}
      onClick={rest.onClick}
      onChange={onChange}
      ref={rest.ref}
      checked={rest.checked}
    />
    {iconTrue && iconFalse && (
      <Label className="form-check-label" htmlFor={rest.name}>
        <span className="switch-icon-left">{iconTrue}</span>
        <span className="switch-icon-right">{iconFalse}</span>
      </Label>
    )}
    <Label for={rest.name} className="form-check-label">
      {label}
    </Label>
  </div>
);
