import { FC } from 'react';
import { Menu as ContexifyMenu } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';

import { IMenuProps } from './types';

/**
 * A simple wrapper class for the `contexify: <Menu />` component.
 *
 * @interface IMenuProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const Menu: FC<IMenuProps> = props => <ContexifyMenu {...props} />;
