import { FC, memo, ReactNode } from 'react';
import classnames from 'classnames';

interface IStatusBadgeProps {
  children: ReactNode;
  className?: string | undefined;
  status?: boolean | null | undefined;
  showStatus?: boolean | undefined;
}

/**
// TODO: move to design system + add documentation
 * Wrapper to add status badge updated/not updated to a component
 *
 * @interface IStatusBadgeProps
 * @author Federico Mauri <federico.mauri@shippypro.com>
 */
export const StatusBadge: FC<IStatusBadgeProps> = memo(
  ({ children, status, showStatus, className = '' }) => {
    return (
      <div className={classnames('relative', className)}>
        {children}
        {showStatus && status !== null && (
          <div
            data-test="status-badge"
            className={classnames(
              'absolute inline-flex items-center justify-center w-[10px] !h-[10px] !right-[-2px] !bottom-[-2px] rounded-full',
              {
                'bg-success': status,
                'bg-warning': !status,
              },
            )}
          />
        )}
      </div>
    );
  },
);
