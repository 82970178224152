import { useMemo } from 'react';
import { useGetCarriersQuery } from '@web/features/_global/api';
import { CustomRTKQueryOptions } from '@web/utils/@reduxjs/rtk-query';

/**
 * Returns RTK Query hook with renamed data field.
 * Returns user connected carriers.
 *
 * @author Fabio Fognani <fabio.fognani@shippypro.com>
 *
 * @param {CustomRTKQueryOptions} options subset of RTK Query hook options
 */
export const useGetCarriers = (options?: CustomRTKQueryOptions) => {
  const { data: carriers, ...rest } = useGetCarriersQuery(undefined, options);

  return useMemo(
    () => ({
      carriers,
      ...rest,
    }),
    [carriers, rest],
  );
};
