import { ReactElement } from 'react';
import * as jwt from 'jsonwebtoken';
import { useLocation } from 'react-router-dom';

import { CacheItem } from '@web/utils/functions/session';

import Preload from './layout/Preload/Preload';

// These paths live in the App (see authenticated.tsx)
const authCodeRequestPath: string = '/request-authcode';
const authCodeExchangePath: string = '/exchange-authcode';
// These paths live in Drupal
const authCodeRequestTarget: string = '/issue-authcode';
const authCodeExchangeTarget: string = '/issue-accesstoken';
// The scopes required by the app
const requiredScopes: string[] = [
  'ui-app',
  'privacy:config-get',
  'privacy:config-set',
  'privacy:pii-archives-get',
  'shipment-documents:read',
  'shipment-documents:create',
  'shipment-documents:dispatch',
  'shipment-documents:delete',
];

const binaryToHex = (byteArray: Uint8Array) =>
  Array.from(byteArray)
    .map(byte => byte.toString(16).padStart(2, '0'))
    .join('');

const hashSha256 = (string: string): Promise<ArrayBuffer> => {
  const utf8 = new TextEncoder().encode(string);
  // From https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
  return crypto.subtle.digest('SHA-256', utf8).then(hashBuffer => hashBuffer);
};

const getAppHostname = (): string => {
  const protocol = window.location.protocol;
  if (window.location.port !== '') {
    return `${protocol}//${window.location.hostname}:${window.location.port}`;
  } else {
    return `${protocol}//${window.location.hostname}`;
  }
};

const checkOAuthClientVariables = (): boolean => {
  // If they aren't set, generate a clientID and clientState, then save them in the
  // localStorage and prepare a query string to redirect us to Step #3
  if (localStorage.getItem('clientId') === null) {
    const clientId =
      'ui-app' + binaryToHex(crypto.getRandomValues(new Uint8Array(8)));
    localStorage.setItem('clientId', clientId);
  }
  if (localStorage.getItem('clientState') === null) {
    const clientState = binaryToHex(crypto.getRandomValues(new Uint8Array(8)));
    localStorage.setItem('clientState', clientState);
  }
  return true;
};

/**
 * Requests an OAuth2 AuthCode with the PKCE + AuthCode grant
 * See https://datatracker.ietf.org/doc/html/rfc7636 for further details
 */
const requestAuthCode = () => {
  // Fetching any "GET" URL Parameter in the request
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop as string),
  });

  // Saving the destination module for later redirection
  let dest_module = params['destination_module'];
  if (params['fragment']) dest_module += `#${params['fragment']}`;
  if (dest_module) sessionStorage.setItem('destination_module', dest_module);

  // Saving the CSRF token from the same Drupal Website Session
  const csrf_token = params['csrf_token'];
  if (csrf_token) sessionStorage.setItem('csrf_token', csrf_token);

  // Remove the previously stored token on env refresh
  localStorage.removeItem('portal-token');

  // Generate the Code Verifier and store it in the local storage
  // Please note we are not verifying if we are in a secure context - we could've used
  // crypto.getRandomUUID and removed the hyphens as an alternative, but that
  // would have made local testing a bit more demanding (HTTPS would've become
  // mandatory even for this proof-of-concept code)
  const codeVerifierOctets = 32;
  const codeVerifier = binaryToHex(
    crypto.getRandomValues(new Uint8Array(codeVerifierOctets)),
  );
  // Set the Code Verifier in the LocalStorage
  localStorage.setItem('codeVerifier', codeVerifier);
  // Calculate the SHA-256 hash
  hashSha256(codeVerifier).then(data => {
    // The SHA-256 hash -has- to be Base64URL encoded!
    // See https://datatracker.ietf.org/doc/html/rfc7636#section-4.2 for further
    // details
    // Figuring this out was literally nothing short of an exorcism
    const codeChallenge = btoa(
      String.fromCharCode(...Array.from(new Uint8Array(data))),
    )
      .replace(/[=]/g, '')
      .replace(/[+]/g, '-')
      .replace(/[/]/g, '_');
    // Set the Code Challenge in the LocalStorage
    localStorage.setItem('codeChallenge', codeChallenge);
    const redirectSearchParamValues = {
      response_type: 'code',
      redirect_uri: `${getAppHostname()}${authCodeExchangePath}`,
      client_id: localStorage.getItem('clientId'),
      client_state: localStorage.getItem('clientState'),
      code_challenge: codeChallenge,
      code_challenge_method: 'S256',
      scope: requiredScopes.join(' '),
    };
    // While in common JS we don't have to be so strict in the URLSearchParams
    // construction, TypeScript has its nuances
    const redirectSearchParams = new URLSearchParams();
    for (const key in redirectSearchParamValues) {
      if (
        Object.prototype.hasOwnProperty.call(redirectSearchParamValues, key)
      ) {
        redirectSearchParams.append(key, redirectSearchParamValues[key]);
      }
    }
    // console.log(
    //   `Redirecting to ${process.env.NX_LEGACY_URL}${authCodeRequestTarget}?` +
    //     redirectSearchParams.toString(),
    // );
    window.location.href =
      `${process.env.NX_LEGACY_URL}${authCodeRequestTarget}?` +
      redirectSearchParams.toString();
  });
};

/**
 * Exchanges an OAuth2 AuthCode for an AccessToken and a RefreshToken, while issuing
 * the Code Verifier that incapsulates the AuthCode grant in a PKCE
 */
const exchangeAuthCode = () => {
  // We are now ready to exchange the received AuthCode for an Access Token -
  // but to let our server be certain the code wasn't exfiltrated to another client,
  // we have to send the Code Verifier as well
  const authCode = new URL(window.location.href).searchParams.get('code');
  if (authCode === null) {
    const noAuthCodeError = new Error(
      `Could not fetch AuthCode from the current Location! (${window.location.toString()})`,
    );
    throw noAuthCodeError;
  }
  const requestTarget = new URL(
    authCodeExchangeTarget,
    process.env.NX_LEGACY_URL,
  );
  const redirectTarget = new URL(
    window.location.pathname,
    `${getAppHostname()}${window.location.pathname}`,
  );
  const requestData = {
    grant_type: 'authorization_code',
    client_id: localStorage.getItem('clientId'),
    client_secret: localStorage.getItem('clientSecret'),
    redirect_uri: redirectTarget.toString(),
    scope: ['ui-app'],
    code: authCode,
    code_verifier: localStorage.getItem('codeVerifier'),
  };
  fetch(requestTarget.toString(), {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    body: JSON.stringify(requestData),
  })
    .then(data => data.json())
    .then(data => {
      if (data.hasOwnProperty('error')) {
        throw Error(`${data.error_description} | ${data.hint}`);
      }
      if (data.token_type !== 'Bearer') {
        const error = new Error(`Unhandled token type ${data.token_type}`);
        throw error;
      }
      // Store the issued AccessToken and RefreshToken
      localStorage.setItem('accessToken', data.access_token);
      // Cache the JWT-encoded Access Token
      CacheItem('portal-token', 'jwt-token', data.access_token);
      localStorage.setItem('refreshToken', data.refresh_token);
      // TODO: Actually fetch the Public Key from a reputable source and verify the
      //       JWT
      const jwtData = jwt.decode(data.access_token);
      // console.log(jwtData);
      // Set the decoded JWT data in the app cache
      CacheItem('portal-token', 'jwt', jwtData);
      window.location.href = '/';
    })
    .catch(error => {
      console.error(error);
    });
};

/**
 * Handles the AuthCode request and exchange routes in the app
 * @param url The URL component, which will offer us (through the 'location'
 *            property) the ability to discern what operation has been requested
 * @returns ReactElement The empty Fragment that will incapsulate this as a stateless
 *                       ReactElement
 */
const AuthCodeHandler = (url): ReactElement => {
  const { pathname } = useLocation();

  // Prepare the Client to issue the requests
  checkOAuthClientVariables();
  // Handle a request for an AuthCode
  if (pathname === authCodeRequestPath) {
    requestAuthCode();
  } else if (pathname === authCodeExchangePath) {
    exchangeAuthCode();
  }
  return <Preload />;
};

export {
  AuthCodeHandler,
  authCodeRequestPath,
  authCodeExchangePath,
  requiredScopes,
};
