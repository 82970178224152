import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { LDFlagSet } from 'launchdarkly-js-sdk-common';

/**
 * [HOOK] useLDFlags > Hook that returns ONLY the true definitions of a flag without incurring
 * into a problem while the user is thought "anonymous" while the App is loading LaunchDarkly.
 *
 * @returns {object} the fool-proof LD Flags object
 *
 * @author Emanuele Moricci <https://github.com/emanuele-moricci-shippypro>
 */
const useLDFlag = (): LDFlagSet => {
  let client = useLDClient(),
    user = client?.getContext();

  const flags = useFlags();

  return user?.key === '0' ? {} : flags;
};

export default useLDFlag;
