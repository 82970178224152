import { combineReducers } from '@reduxjs/toolkit';

// API reducer (RTK Query)
import { rootApi } from './rtk-query';

// App Reducers
import { reducer as shipReducer } from '@web/features/ship/slice';
import { reducer as orderReducer } from '@web/features/order/slice';
import { reducer as changelogModalReducer } from '@web/features/changelog-modal/slice';
import { reducer as homeReducer } from '@web/features/home/slice';
import { reducer as analysisReducer } from '@web/features/analysis/slice';
import { reducer as analyticsDashboardsReducer } from '@web/features/analytics-dashboards/slice';
import { reducer as onboardingReducer } from '@web/features/onboarding/slice';
import { reducer as manageOrderModalReducer } from '@web/features/manage-order-modal/slice';
import { reducer as documentsReducer } from '@web/features/fulfillment/documents/slice';
import { reducer as dataVaultReducer } from '@web/features/data-vault/slice';
// [IMPORT NEW FEATURE RED. ABOVE] < Needed for generating features seamlessly

export default combineReducers({
  [rootApi.reducerPath]: rootApi.reducer,
  ship: shipReducer,
  order: orderReducer,
  changelogModal: changelogModalReducer,
  home: homeReducer,
  analysis: analysisReducer,
  analyticsDashboards: analyticsDashboardsReducer,
  onboarding: onboardingReducer,
  manageOrderModal: manageOrderModalReducer,
  documents: documentsReducer,
  dataVault: dataVaultReducer,
  // [APPEND NEW REDUCERS ABOVE] < Needed for generating features seamlessly
});
