import { translations } from '@shippypro/translations';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { usePrevious } from 'react-use';

import {
  showErrorToastr,
  showSuccessToastr,
} from '@shippypro/design-system-web/functions';

import { useGetHealthCheckQuery } from '@web/features/_global/api';

/**
 * Returns RTK Query hook with renamed data field.
 * Also handles polling and toastr messages.
 * @author Fabio Fognani <fabio.fognani@shippypro.com>
 *
 * @param {CustomRTKQueryOptions} options subset of RTK Query hook options
 */
export const useGetHealthCheck = () => {
  const { data, isLoading, isError, refetch, ...rest } =
    useGetHealthCheckQuery();

  const isPortalHealthy = useMemo(() => {
    if (isLoading) return undefined;
    return !isError && data === 'ok';
  }, [data, isLoading, isError]);

  const { t } = useTranslation(),
    trans = translations.common;

  const previousIsPortalHealthy = usePrevious(isPortalHealthy);

  // Perform a HealthCheck on the Portal API Gateway
  useEffect(() => {
    const healthCheckInterval = 10 * 1000;
    const healthCheck = setInterval(() => {
      refetch();
    }, healthCheckInterval);
    return () => clearInterval(healthCheck);
  }, [refetch]);

  useEffect(() => {
    const isStatusNotReady =
      previousIsPortalHealthy === undefined || isPortalHealthy === undefined;
    const isStatusUnchanged = previousIsPortalHealthy === isPortalHealthy;
    if (isStatusNotReady || isLoading || isStatusUnchanged) return;
    if (isPortalHealthy) {
      showSuccessToastr(t(trans.success_alt), t(trans.connectionBackUp), {
        toastId: 'status',
      });
    } else {
      showErrorToastr(t(trans.errors.error), t(trans.errors.connectionLost), {
        toastId: 'status',
      });
    }
  }, [t, trans, previousIsPortalHealthy, isLoading, isPortalHealthy]);

  return useMemo(
    () => ({
      isLoading,
      isError,
      refetch,
      isPortalHealthy,
      ...rest,
    }),
    [isLoading, refetch, isError, rest, isPortalHealthy],
  );
};
