import { useState, useCallback, useMemo } from 'react';

import { IGLSItalyPrintFormatPickerContextProps } from './context';
import {
  GLSFormatCode,
  GLSFormatConfiguration,
  GLSFormatValue,
  IGLSItalyPrintFormatPickerInfo,
} from './types';

/**
 * `[HOOK]` This function instances the `GLS Italy - Format Picker` dialog modal.
 * It is also used as a localised state that can get updated by an external source (I.e. A dedicated context).
 *
 * N.B. **ALREADY INSTANTIATED AT THE NEAR-TOP OF THE `Ship` FEATURE TREE, NO NEED TO USE THIS ANYWHERE ELSE**
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 *
 * @returns {IGLSItalyPrintFormatPickerContextProps} All of the info needed for the dialog modal to function
 */
export const useInstanceGLSItalyPrintFormatPickerModal =
  (): IGLSItalyPrintFormatPickerContextProps => {
    // Bootstrapping the modal's contents (& visibility)
    const [info, setInfo] = useState<
      IGLSItalyPrintFormatPickerInfo | undefined
    >(undefined);

    // Opening the modal
    const onDialogOpen = useCallback((info: IGLSItalyPrintFormatPickerInfo) => {
      setInfo(info);
    }, []);

    // Closing the modal and destroying the info
    const onDialogCLose = useCallback(() => {
      setInfo(undefined);
    }, []);

    return {
      isOpen: Boolean(info),
      info,
      openDialog: onDialogOpen,
      closeDialog: onDialogCLose,
    };
  };

/**
 * `[HOOK]` This function returns the current configuration of the GLS Italy logics (single label / multiple labels),
 * based on the number of selected orders.
 *
 * @param {number} selectOrdersNum The number of selected orders
 * @returns {GLSFormatConfiguration} The current configuration of the GLS Italy logics (single label / multiple labels)
 */
export const useGetCurrentGLSItalyConfig = (
  selectOrdersNum: number,
): GLSFormatConfiguration =>
  useMemo(
    () =>
      selectOrdersNum > 1
        ? GLSFormatConfiguration.MultipleLabels
        : GLSFormatConfiguration.SingleLabel,
    [selectOrdersNum],
  );

/**
 * `[HOOK]` This function returns the GLS Italy format value from the format code.
 *
 * @param {?keyof GLSFormatCode} format The format code
 * @returns {GLSFormatValue} The GLS Italy format value
 */
export const useGetGLSItalyFormatValueFromCode = (
  format?: keyof typeof GLSFormatCode | null,
): GLSFormatValue =>
  useMemo(() => (format ? GLSFormatCode[format] : -1), [format]);
