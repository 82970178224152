import { FC } from 'react';
import classnames from 'classnames';

import { Size } from '@ds-web/tokens/enums';

import { IBulletTextProps } from './types';

/**
 * A simple text with a colored bullet before it.
 *
 * @interface IBulletTextProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const BulletText: FC<IBulletTextProps> = ({
  text,
  color,
  size = Size.sm,
}) => {
  return (
    <div className="flex items-center">
      <span
        className={classnames(`bullet bullet-${color} bullet-${size} !mr-2`)}
      />
      <p>{text}</p>
    </div>
  );
};
