import { createContext } from 'react';

import { IGLSItalyPrintFormatPickerInfo } from './types';

export interface IGLSItalyPrintFormatPickerContextProps {
  isOpen: boolean;
  info?: IGLSItalyPrintFormatPickerInfo;
  openDialog: (info: IGLSItalyPrintFormatPickerInfo) => void;
  closeDialog: () => void;
}

/**
 * This is the Context that is instantiated at the near-top of the `ship` feature component tree.
 * This allows any component in said tree to call the `GLS Italy - "Print Format" picker` dialog modal.
 *
 * @example
 * const { openDialog } = useContext(GLSItalyPrintFormatPickerContext)!;
 * ...
 * <button onClick={() => openDialog()}>Click Me!</button>
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const GLSItalyPrintFormatPickerContext =
  createContext<IGLSItalyPrintFormatPickerContextProps | null>(null);
