import { FC, memo } from 'react';
import classnames from 'classnames';
import { Card, CardBody, CardHeader, Skeleton } from '@ds-web/components';
import { useGetDeviceSize } from '@ds-web/utils/hooks';
import { ISkeletonProps } from './types';

/**
 * A skeleton loader for the `Area Chart` Metrics Card.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const ChartMetricsSkeleton: FC<ISkeletonProps> = memo(
  ({ className }) => {
    const { isTablet, isXLScreen } = useGetDeviceSize();

    let viewBoxHeight = 210;
    /* istanbul ignore if */
    if (isTablet) viewBoxHeight = 340;
    /* istanbul ignore if */
    if (isXLScreen) viewBoxHeight = 102;

    return (
      <Card className={classnames('overflow-hidden', className)}>
        <CardHeader className="h-[4.5rem]">
          <Skeleton className="w-full">
            <circle cx="12" cy="12" r="12" />
            <rect x="30" y="5" width="134" height="14" rx="2" />
          </Skeleton>
        </CardHeader>
        <CardBody className="p-2">
          <Skeleton
            className="w-full"
            viewBox={`0 0 400 ${viewBoxHeight}`}
            preserveAspectRatio="xMidYMax meet"
          >
            {/* NUMBER + TREND */}
            <rect x="0" y="0" width="100" height="20" rx="13" />
            {/* AREA CHART */}
            <rect
              x="20"
              y="30"
              width="90%"
              height={`${isXLScreen ? 40 : 70}%`}
            />
            {/* FOOTER */}
            <rect
              x="75%"
              y={`${isXLScreen ? 80 : 90}%`}
              width="77"
              height="14"
              rx="2"
            />
            <rect
              x="95%"
              y={`${isXLScreen ? 79.3 : 89.3}%`}
              width="18"
              height="18"
              rx="18"
            />
          </Skeleton>
        </CardBody>
      </Card>
    );
  },
);
