import styled from 'styled-components';

export const StyledCardMetrics = styled.div`
  font-family: Roboto;
  border: 1px solid #dcdfe1;
  background: #fff;
  box-shadow: 0 3px 3px 0 rgba(26, 26, 26, 0.07);
  border-radius: 0.9rem;

  #metricscard-header {
    border-bottom-width: 0;
    .title {
      color: #303030 !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  #metricscard-body {
    padding-top: 0.75rem !important;
  }
  #chart-summary {
    font-weight: 600;
    .subtitle {
      color: var(--shp-color-genericui-purpleish-grey);
      font-size: 14px;
      font-weight: 400;
    }
  }
`;
