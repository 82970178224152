import { FC, memo } from 'react';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import { Card } from '@shippypro/design-system-web';
import { IconHelper } from '@shippypro/design-system-web/iconhelper';

import {
  GLSFormatCode,
  GLSFormatValue,
} from '@web/features/ship/components/dialogs/glsitaly-printformat-picker/types';

interface IFormatCardProps {
  glsFormatValue: GLSFormatValue;
  glsFormatCode: GLSFormatCode;
  selected: boolean;
  onClick: (code: GLSFormatValue) => void;
}

/**
 * This is the Presentational component responsible for showing a UI Card that
 * holds every info needed to identify a specific GLS Italy Label Format.
 *
 * @interface IFormatCardProps
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const FormatCard: FC<IFormatCardProps> = memo(
  ({ glsFormatValue, glsFormatCode, selected, onClick }) => {
    const { t } = useTranslation(),
      trans = translations.ship.buttons.print.glsItaly.modal,
      transFormats = trans.formats;

    return (
      <Card
        className="format-card flex flex-row !gap-1"
        data-selected={selected}
        data-test={`format-card-${glsFormatCode}`}
        onClick={() => onClick(glsFormatValue)}
      >
        <img
          src={`https://cdn.shippypro.com/assets/images/labels/glsitaly-${glsFormatCode}.png`}
          alt={glsFormatCode.toString()}
          className="format-card-img"
          width={75}
          height={100}
        />
        <span className="format-card-info flex flex-col grow !gap-1 !ml-3 w-full">
          <span className="format-card-info-title text-lg md:text-sm text-[color:--shp-color-text-section-title] font-medium tracking-wide uppercase">
            {t(transFormats[glsFormatCode].title)}
          </span>
          <span className="format-card-info-subtitle text-xl md:text-base text-purpleish font-bold">
            {t(transFormats[glsFormatCode].subtitle)}
          </span>
        </span>
        {selected && (
          <span className="absolute bottom-3 right-3">
            <div className="format-card-selected-check w-8 h-8 flex justify-center items-center bg-accent rounded-full">
              <IconHelper icon="IconCheck" size={18} color="white" />
            </div>
          </span>
        )}
      </Card>
    );
  },
);

export default FormatCard;
