import { CSSProperties, FC } from 'react';

import { Button } from '@ds-web/components/atoms/ui/buttons/button/Button';

import { IconHelper } from '@ds-web-iconhelper';
import { Color, StyleType } from '@ds-web/tokens/enums';

interface IExpansionProps {
  toggleExpandedHandler: () => void;
  isExpanded: boolean;
  isExpandAll?: boolean;
  style?: CSSProperties;
}

/**
 * This component is the UI representation of a `react-table` expansion indicator.
 * It shows if the selected row/s has/have expanded or not.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 *
 * @returns {FC} The `React Table` header/row expansion icons, fully rendered & managed.
 */
const Expansion: FC<IExpansionProps> = ({
  toggleExpandedHandler,
  isExpanded,
  isExpandAll,
  style,
}) => {
  const expandIcon = isExpanded ? 'IconCircleMinus' : 'IconCirclePlus';

  return (
    <span
      onClick={toggleExpandedHandler}
      className={`react-table-${
        isExpandAll ? 'header-expandall' : 'body-row-expandicon'
      } !pl-1`}
      style={style}
    >
      <Button
        styleType={StyleType.flat}
        color={Color.secondary}
        className="rounded-full flex justify-center items-center !p-1 !w-8 !h-8"
      >
        <IconHelper icon={expandIcon} size={20} />
      </Button>
    </span>
  );
};

export default Expansion;
