import { Order } from '@web/types/order';

/**
 * **[HOOK]** This hook returns a boolean depending if
 * the passed order has Cash On Delivery or not
 *
 * @param {Order} order The order to check the COD from
 * @returns {boolean} Whether the order has COD or not
 */
const useGetOrderHasCOD = (order: Order | null | undefined) => {
  return getOrderHasCOD(order);
};

export const getOrderHasCOD = (order: Order | null | undefined) => {
  const paymentMethod = order?.payment.method?.toLowerCase();

  return (
    (order?.payment.cashondelivery ?? 0) > 0 ||
    paymentMethod === 'cod' ||
    paymentMethod?.includes('(cod)') ||
    paymentMethod?.includes('(cash)') ||
    paymentMethod?.includes('consegna') ||
    paymentMethod?.includes('contrassegno') ||
    paymentMethod?.includes('contant') ||
    paymentMethod?.includes('cash on delivery')
  );
};

export default useGetOrderHasCOD;
