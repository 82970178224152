/* istanbul ignore file */

// TODO: Component not used at the moment, remove ignore when included in some feature

// ########################## [IMPORTANT LIBRARIES]
import { FC, memo } from 'react';

// ########################## [DESIGN SYSTEM]
import { ModalHeader, ModalBody } from '@shippypro/design-system-web';
import { useGetDeviceSize } from '@shippypro/design-system-web/hooks';

// ########################## [CONTEXTS]
import { IPaywallContextProps } from '@web/features/paywall/contexts/PaywallContext';

// ########################## [TYPES]
import { TwoRowsOneVideoLayoutPaywallInfo } from '@web/features/paywall/types';

// ########################## [PAGE COMPONENTS]
import StyledPaywallModal from '@web/features/paywall/pages/two-rows-single-video/styles';
import TopInfo from '@web/features/paywall/components/top-info';
import FeatureDetails from '@web/features/paywall/components/feature-details';
import CtaSection from '@web/features/paywall/components/cta-section';

interface ITwoRowsSingleVideoPaywallProps
  extends Omit<
    IPaywallContextProps<TwoRowsOneVideoLayoutPaywallInfo>,
    'design'
  > {}

/**
 * **DESIGN: Two Rows - One Video**
 *
 * This Design of the Paywall displays two separated rows. One has the name, desc and details of the feature (+ bottom cta)
 * and the other on the right has a `Feature Preview` video displayed on it.
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
export const TwoRowsSingleVideoPaywall: FC<ITwoRowsSingleVideoPaywallProps> =
  memo(({ isOpen, info, closePaywall }) => {
    const { isMobile } = useGetDeviceSize();

    return (
      <StyledPaywallModal
        isOpen={isOpen}
        toggle={closePaywall}
        $gradientColor={info?.gradientColor}
        data-test="paywall-modal"
      >
        <ModalHeader toggle={closePaywall} />
        <ModalBody className="flex flex-col-reverse lg:flex-row justify-around items-start">
          <section
            id="paywall-info"
            className="w-full lg:w-[40%] 2xl:min-h-[700px] rounded-br-[6px] lg:rounded-br-none"
          >
            <TopInfo />
            <FeatureDetails />
            <CtaSection />
          </section>
          <section
            id="paywall-video"
            className="w-full !hidden lg:!flex lg:w-[60%] justify-center items-center h-full"
          >
            {info?.videoUrl && (
              <iframe
                title="paywall video"
                height="470"
                width="700"
                className="w-[-webkit-fill-available]]"
                allow="autoplay"
                src={[info?.videoUrl, `autoplay=${+!isMobile}`].join(
                  info?.videoUrl.includes('?') ? '&' : '?',
                )}
              ></iframe>
            )}
          </section>
        </ModalBody>
      </StyledPaywallModal>
    );
  });
