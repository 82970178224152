import { FC, memo } from 'react';
import classnames from 'classnames';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations_restricted';

import { INoteSavingOverlayProps } from './types';

/**
 * This overlay will completely block a note while it's being saved at the DB.
 *
 * @interface INoteSavingOverlayProps
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
export const NoteSavingOverlay: FC<INoteSavingOverlayProps> = memo(
  ({ visible }) => {
    const { t } = useTranslation(),
      transCommon = translations.common;

    return (
      <div
        className={classnames(
          'cursor-wait note-textoverlay w-full h-full absolute top-0 bottom-full left-0 right-full rounded flex justify-center items-center',
          {
            hidden: !visible,
          },
        )}
        style={{
          // Unfortunately has to be this way because CSS can't convert from HEX to RGBA on its own
          // and we can't use simple opacity as it will trickle down to the container children :/
          backgroundColor: 'rgba(1, 22, 39, 0.6)',
        }}
      >
        <span className="text-white font-medium opacity-100">
          {t(transCommon.savingNote)}
        </span>
      </div>
    );
  },
);
