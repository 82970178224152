import { FC, memo, useEffect } from 'react';

import { PaginationBox } from './PaginationBox';
import { PaginationItem } from './PaginationItem';
import { PaginationLink } from './PaginationLink';
import { IconHelper } from '@ds-web-iconhelper';

import { IPaginationProps } from './types';

/**
 * The complete `<Pagination />` component, that uses the various sub-components to
 * complete the paginating logics.
 *
 * @interface IPaginationProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const Pagination: FC<IPaginationProps> = memo(
  ({
    rowsPerPage,
    currentPage,
    rowCount,
    pageCount,
    onChangeRowsPerPage,
    onChangePage,
    maxElements = 7,
    getPaginationString,
    hasPaginationString = true,
    ...rest
  }) => {
    rest.size = rest.size || 'md';
    let paginationLinks: any = [];

    const pages = pageCount ?? Math.ceil(rowCount / rowsPerPage),
      startPage = Math.max(1, ~~(currentPage - maxElements / 2) + 1),
      maxPage = Math.min(pages, ~~(currentPage + maxElements / 2)),
      separated = rest.arrows === 'separated',
      hide = rest.arrows === 'hide',
      double = rest.icon === 'double';

    for (let i = startPage; i <= maxPage; i++) {
      paginationLinks.push(
        <PaginationItem active={currentPage === i} key={i}>
          <PaginationLink href="" onClick={() => onChangePage(i, rowCount)}>
            {i}
          </PaginationLink>
        </PaginationItem>,
      );
    }

    const getArrowItem = (
      className: string,
      onChangePage: any,
      Icon: string,
      onlyOne: boolean,
    ) => {
      return (
        <PaginationItem href="" className={className} disabled={onlyOne}>
          <PaginationLink href="" last onClick={onChangePage}>
            <IconHelper icon={Icon} />
          </PaginationLink>
        </PaginationItem>
      );
    };

    let paginationPrev: any = (double: boolean) =>
        getArrowItem(
          separated ? 'prev-item' : '',
          () =>
            onChangePage(
              double ? 1 : currentPage !== 1 ? currentPage - 1 : currentPage,
              rowCount,
            ),
          double ? 'IconChevronsLeft' : 'IconChevronLeft',
          pages === 1,
        ),
      paginationNext: any = (double: boolean) =>
        getArrowItem(
          separated ? 'next-item' : '',
          () =>
            onChangePage(
              double
                ? pages
                : currentPage !== pages
                  ? currentPage + 1
                  : currentPage,
              rowCount,
            ),
          double ? 'IconChevronsRight' : 'IconChevronRight',
          pages === 1,
        );

    (rest.icon === 'both' &&
      (paginationPrev = (
        <>
          {paginationPrev(true)}
          {paginationPrev(false)}
        </>
      )) &&
      (paginationNext = (
        <>
          {paginationNext(false)}
          {paginationNext(true)}
        </>
      ))) ||
      ((paginationPrev = paginationPrev(double)) &&
        (paginationNext = paginationNext(double)));

    useEffect(
      () =>
        onChangeRowsPerPage && onChangeRowsPerPage(rowsPerPage, currentPage),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );

    // REMOVING UNUSED PROPS
    const {
      paginationRowsPerPageOptions,
      paginationIconLastPage,
      paginationIconFirstPage,
      paginationIconNext,
      paginationIconPrevious,
      paginationComponentOptions,
      ...parsedRest
    } = rest;
    return (
      <div className="mt-2 w-full flex justify-between">
        <div className="pagination-string ml-1 mb-1 whitespace-pre-wrap">
          {hasPaginationString && getPaginationString
            ? getPaginationString(currentPage, pages, rowCount)
            : ''}
        </div>
        <PaginationBox {...parsedRest}>
          {hide ? '' : paginationPrev}
          {paginationLinks}
          {hide ? '' : paginationNext}
        </PaginationBox>
      </div>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.rowsPerPage === nextProps.rowsPerPage &&
      prevProps.currentPage === nextProps.currentPage &&
      prevProps.rowCount === nextProps.rowCount
    );
  },
);
