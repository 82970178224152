import { Address } from '@web/types/common/address';
import { sanitizeString } from '@shippypro/design-system-web/functions';
import { translations } from '@shippypro/translations';

/**
 * Remove from address fields characters that can break backend XSS filter
 * NB Currently ONLY street1 and street2 (if present) are sanitized
 * using {@link sanitizeString} logic
 * @param input The address to be sanitized
 * @return Address the sanitized address
 */
export const sanitizeAddress = (input: Address): Address => {
  return {
    ...input,
    street1: sanitizeString(input?.street1 ?? ''),
    street2: input?.street2 ? sanitizeString(input?.street2) : input?.street2,
    phone: input?.phone ? input.phone.replace(/ /g, '') : input?.phone,
  };
};

/**
 * Remove from address fields characters that can break backend XSS filter
 * using {@link sanitizeAddress} logic if a NOT null and NOT undefined Address is
 * specified as input
 * @param input The address to be sanitized
 * @return The sanitized address if present, undefined otherwise
 */
export const sanitizeOptAddress = (
  input: Address | null | undefined,
): Address | undefined => {
  if (!input) return undefined;
  return sanitizeAddress(input);
};

/**
 * Mapping between Address form fields keys and Friendly name to be displayed to users
 * Currently used by {@link notifyAPIError} to format XSS filter errors
 */
export const addressFormFieldMapping = new Map([
  ['content_description', translations.common.form.description],
  ['city', translations.common.form.city],
  ['street1', translations.common.form.address1],
  ['street2', translations.common.form.address2],
  ['state', translations.common.form.state],
  ['name', translations.common.form.name],
  ['company', translations.common.form.company],
]);
