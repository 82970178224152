import { FC } from 'react';

import { Checkbox } from '@ds-web/components/atoms/form/checkbox/Checkbox';

interface ISelectorProps {
  isSelectAll?: boolean;

  checked: boolean;
  indeterminate: boolean;
  onChange: (event: unknown) => void;

  name?: string;
}

/**
 * This component is the UI representation of a `react-table` checkbox selector.
 * It shows a checkbox that can be used to select the given row.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 *
 * @returns {FC} The `React Table` checkbox selector block, fully rendered & managed.
 */
const Selection: FC<ISelectorProps> = ({
  isSelectAll = false,
  checked,
  indeterminate,
  onChange,
  name,
}) => {
  return (
    <div
      className={`react-table-${
        isSelectAll ? 'header-selectall' : 'body-row-selector'
      } !pl-2`}
      onClick={e => e.stopPropagation()}
    >
      <Checkbox
        name={name}
        checked={checked}
        indeterminate={indeterminate}
        onChange={onChange}
      />
    </div>
  );
};

export default Selection;
