import { ReactNode } from 'react';

import {
  InvoicingInfo,
  UserEmailServer,
  Plugin,
  Carrier,
  Marketplace,
} from '@web/types/global';
import { APIGatewayBulkResponse } from '@web/types/global/api-gateway';
import { AddressType } from '@web/types/enums';
import { ConnectionEntityField } from '@web/types/connection';

/* --- STATE --- */
export interface GlobalState {
  loaded: boolean;
  isMobile: boolean;
}

export type FeatureState = GlobalState;

// The gateway API calls related to the `Global` feature
export enum GlobalAPIActions {
  HealthCheck = 'SystemHeartbeat',
  BulkMultipleAction = 'BulkMultipleAction',
  GetUserInfo = 'UserGetInfo',
  GetPlanInfo = 'UserGetPlanInfo',
  GetCustomerSenderInfo = 'CustomerGetSenderInfo',
  GetMarketplaces = 'UserGetMarketplaces',
  GetCarriers = 'UserGetCarriers',
  GetCountries = 'PortalGetCountries',
  GetInvoicingInfo = 'CustomerGetInvoicingInfo',
  UserGetPlugins = 'UserGetPlugins',
  PortalGetChangelog = 'PortalGetChangelog',
  UserGetEmailServers = 'UserGetEmailServers',
  CustomerGetPreferences = 'CustomerGetPreferences',
  CustomerGetAddressBook = 'CustomerGetAddressBook',
  UserGetParcelsBook = 'UserGetParcelsBook',
  UserGetHotJarFields = 'UserGetHotJarFields',
  UserGetRefinerFields = 'UserGetRefinerFields',
  UserGetFeatureFlaggingCustomParameters = 'UserGetFeatureFlaggingCustomParameters',
  UserSwitchPlatformToggle = 'UserSwitchPlatformToggle',
  GetSetup = 'UserGetSetupProgress',
  GetOnboarding = 'UserGetOnboardingProgress',
  ListCarriers = 'CarrierListCarriers',
  ListMarketplaces = 'MarketplaceListMarketplaces',
  CheckAvailableFeature = 'UserCheckAvailableFeature',
  HasActivePlan = 'UserHasActivePlan',
  // [EXPORT NEW ACTIONS ABOVE] < Needed for generating API Hooks seamlessly
}

/* --- QUERY TYPES --- */

export type GetChangelogMutationPayload = { page: number };
export type GetAddressBookQueryPayload = { address_type: AddressType };

export type SwitchPlatformToggleResult = {
  result: boolean;
  error: string;
};
export type SwitchPlatformTogglePayload = {
  toggle_status: number;
};

// ------------------- | CACHED DATA TYPES

export type ShippyProJWT = {
  jti: number;
  iat: number;
  nbf: number;
  exp: number;
  sub: string;
  scopes: string[];
};

export type ShippyProJWTQueryParsing = {
  jwt: ShippyProJWT;
};

// ------------------- | MENU LINK TYPES
// NOTE:
// if isExternal is false, isCustom will be false
// if isExternal is true, isCustom can be true if link is link outside of deliver or drupal
export type MenuLinks = MenuLink[];

export type MenuLink = {
  category?: string;
  data: FirstLevelLink[] | LastLevelLink[];
};

export interface FirstLevelLink extends LinkSharedProps {
  icon: string;
  isExternal: boolean;
  subMenu?: Array<MiddleLevelLink> | Array<LastLevelLink>;
}

export interface MiddleLevelLink extends LinkSharedProps {
  isHidden?: boolean;
  isExternal: boolean;
  isCustom: boolean;
  subMenu: Array<MiddleLevelLink> | Array<LastLevelLink>;
}

export interface LastLevelLink extends LinkSharedProps {
  isHidden?: boolean;
  isExternal: boolean;
  isCustom: boolean;
}

interface LinkSharedProps {
  label: string;
  link: string | null; // NOTE: link can be null if an action needs to be called on click
  hasDot?: boolean;
  dotProps?: {
    isPulsing: boolean;
  };
  isVisible: boolean;
  lockComponent?: ReactNode;
  onClick?: () => void;
}

// --------------- [SECONDARY DATA BLOCK - START] ---------------

export const GlobalSecondaryDataQueryId = 'GLOBAL_SecondaryData';

export type GlobalSecondaryDataResult = {
  [GlobalAPIActions.GetInvoicingInfo]: APIGatewayBulkResponse<InvoicingInfo>;
  [GlobalAPIActions.UserGetEmailServers]: APIGatewayBulkResponse<
    UserEmailServer[]
  >;
  [GlobalAPIActions.UserGetPlugins]: APIGatewayBulkResponse<Plugin>;
};

export type GlobalSecondaryDataPayload = {
  [GlobalAPIActions.UserGetEmailServers]: {
    action: GlobalAPIActions.UserGetEmailServers;
    payload: {};
  };
  [GlobalAPIActions.GetInvoicingInfo]: {
    action: GlobalAPIActions.GetInvoicingInfo;
    payload: {};
  };
  [GlobalAPIActions.UserGetPlugins]: {
    action: GlobalAPIActions.UserGetPlugins;
    payload: {};
  };
};

// --------------- [SECONDARY DATA BLOCK - END] ---------------

export type FetchedOnboarding = {
  businessPhone: string;
  companyAddress: string;
  companyCity: string;
  companyCountry: string;
  companyName: string;
  companyStreetNumber: string;
  companyWebsite: string;
  companyZip: string;

  monthlyShipments: ShipmentsNumberChoices;

  carriers: string;
  marketplaces: string;

  onboardingStep: 0 | 1 | 2 | 3 | 4;
  onboardingCompleted: boolean;
};

export type FetchedSetup = {
  uid: number;
  markets: Marketplace[];
  carriers: Carrier[];
  progress: SetupProgress;
};

export type SetupProgress = {
  storeCompleted: boolean;
  carrierCompleted: boolean;
  shipCompleted: boolean;
  setupCompleted: boolean;
};

// @deprecated
export enum PrintType {
  zpl = 'zpl',
  pdf = 'pdf',
  any = 'none',
}

export enum ShipmentsNumberChoices {
  '1-250' = '1 - 250',
  '250-500' = '250 - 500',
  '500-1000' = '500 - 1000',
  '1000-2000' = '1000 - 2000',
  '2000-4000' = '2000 - 4000',
  '>4000' = '> 4000',
  'noShipments' = 'no shipments',
  'unknown' = 'Unknown',
}

export type CarrierConnection = {
  CarrierName: string;
  DisplayName: string;
  CarrierLogo: string;
  IsOAuth: boolean;
  Fields: ConnectionEntityField[];
};

export type MarketplaceConnection = {
  MarketplaceName: string;
  DisplayName: string;
  MarketplaceLogo: string;
  IsOAuth: boolean;
  Fields: ConnectionEntityField[];
};

// The gateway API calls related to the `Setup` feature
/* --- QUERY TYPES --- */

export type ListConnectionEntityQueryPayload = {
  only_with_fields?: boolean;
  search?: string;
  country?: string;
};

export type ListMarketplacesQueryPayload = ListConnectionEntityQueryPayload & {
  marketplace_names?: string[];
};

export type ListCarriersQueryPayload = ListConnectionEntityQueryPayload & {
  carrier_names?: string[];
};

export type CheckAvailableFeatureQueryPayload = {
  feature: string;
};

export type HasActivePlanQueryPayload = {};

export type ListMarketplacesQueryResult = {
  marketplaces: MarketplaceConnection[];
};

export type ListCarriersQueryResult = {
  carriers: CarrierConnection[];
};

export type CheckAvailableFeatureQueryResult = {
  is_feature_available: boolean;
};

export type HasActivePlanQueryResult = {
  hasActivePlan: boolean;
  redirectPath?: string | null;
};

export type AddConnectionEntityMutationPayload = {
  credentials: {
    [key: string]: string | boolean | number | null | undefined;
  };
};

export type AddMarketplaceMutationPayload =
  AddConnectionEntityMutationPayload & {
    marketplace_name?: string;
  };

export type AddCarrierMutationPayload = AddConnectionEntityMutationPayload & {
  carrier_name?: string;
};

export type SavePrintTypeMutationPayload = {
  print_type: PrintType;
};

// The feature - code association
// The values are codes inside plans_features table
// NOTE that this is a wip enum, to be filled as it is useful
export enum Features {
  EasyReturn = 'RETURNMANAGEMENT',
  Api = 'APIMANAGEMENT',
  ShipAndCollect = 'LIVECHECKOUT',
  Optimizer = 'OPTIMIZER',
}
