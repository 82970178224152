import { FC } from 'react';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [DESIGN SYSTEM]
import { Color } from '@shippypro/design-system-web/enums';

// ########################## [PAGE COMPONENTS]
import BadgeWithCustomHover from './BadgeWithCustomHover';

interface IIsReturnBadgeProps {
  compact?: boolean;
}

/**
 * Displays a simple return badge with a tooltip
 *
 * @author Fabio Mezzina <fabio.mezzina@shippypro.com>
 */
export const IsReturnBadge: FC<IIsReturnBadgeProps> = ({ compact = false }) => {
  const { t } = useTranslation(),
    transShipmentInfo = translations.order.shipmentInfo;

  return (
    <BadgeWithCustomHover
      color={Color.secondary}
      className="w-fit cursor-default flex items-center shrink-0"
      icon="IconArrowBack"
      hasHover={compact}
      label={compact ? '' : t(transShipmentInfo.return)}
      target="order-isreturn"
    >
      {t(transShipmentInfo.return)}
    </BadgeWithCustomHover>
  );
};
