import { FC, memo, useContext } from 'react';
import classnames from 'classnames';

import HelpSectionContext from '@web/features/help/contexts/HelpSectionContext';

import { HelpSubSection } from '@web/features/help/types';

import ContactUs from './contact-us';
import HelpCenter from './help-center';

interface ISupportSectionProps {}

/**
 * This is the main home of the Help section.
 * It displays a set of help articles and links to contact our support team.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const SupportSection: FC<ISupportSectionProps> = memo(() => {
  const { subSection } = useContext(HelpSectionContext)!;

  return (
    <section
      data-test="support-section"
      className={classnames('w-full h-1/2 flex flex-col !gap-2', {
        hidden: subSection !== HelpSubSection.home,
      })}
    >
      <HelpCenter />
      <ContactUs />
    </section>
  );
});

export default SupportSection;
