// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@web/utils/@reduxjs/toolkit';

import { FeatureState } from './types';

// The initial state of the Analysis feature
export const initialState: FeatureState = {
  drawers: {
    download: {
      open: false,
    },
  },
};

const analysisSlice = createSlice({
  name: 'analysis',
  initialState,
  reducers: {
    openDrawer(state) {
      state.drawers.download.open = true;
    },
    closeDrawer(state) {
      state.drawers.download.open = false;
    },
  },
});

export const {
  actions: analysisActions,
  reducer,
  name: sliceKey,
} = analysisSlice;
