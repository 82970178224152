import { FC, memo, useCallback } from 'react';
import { useLocalStorage } from 'react-use';

import { STATUSPAGE_LOCALSTORAGE_KEY } from '@web/features/shared/types/statuspage';

import AlertCard from './alert-card';

import useGetPollingTimeWithIdlenessCheck from '@web/hooks/useGetAlertingPollingTime';
import { useGetStatusPageAlerts } from '@web/features/shared/hooks/api/useGetStatusPageAlerts';

interface IStatusPageAlertsContainerProps {}

/**
 * **[COMPONENT]** The status-page-alerts-container component.
 * The container block for any kind of StatusPage Alert to display to the user.
 *
 * @interface IStatusPageAlertsContainerProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const StatusPageAlertsContainer: FC<IStatusPageAlertsContainerProps> = memo(
  () => {
    // Check which StatusPage Alert has been dismissed by the user
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [dismissedAlerts, setDismissedAlerts] = useLocalStorage<string[]>(
      STATUSPAGE_LOCALSTORAGE_KEY,
      [],
    );

    // Checking if the StatusPage APIs are returning any kind of alert
    const pollingObj = useGetPollingTimeWithIdlenessCheck(120000);
    const {
      statuspagealerts: { status_alerts = [], is_whitelabel_user = false } = {},
    } = useGetStatusPageAlerts({}, pollingObj);
    const latestAlert = status_alerts.filter(
      a => !dismissedAlerts?.includes(a.id),
    )[0];

    const dismissAlert = useCallback(
      (id: string) => {
        setDismissedAlerts([...(dismissedAlerts ?? []), id]);
      },
      [dismissedAlerts, setDismissedAlerts],
    );

    return (
      <AlertCard
        alert={latestAlert}
        hideLatestUpdateCta={is_whitelabel_user}
        dismissAlert={dismissAlert}
      />
    );
  },
);

export default StatusPageAlertsContainer;
