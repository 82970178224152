import { FC } from 'react';
import { IListItemCellProps } from './types';

const ListItemCell: FC<IListItemCellProps> = ({ width, ...rest }) => {
  rest.className = (
    (rest.className || '') +
    ` list-view-item-cell flex ${
      rest.bold ? 'font-semibold' : 'font-extralight'
    }`
  ).trim();
  return (
    <span
      className={rest.className}
      style={width ? { width: `${width}%` } : {}}
    >
      {rest.children}
    </span>
  );
};

export default ListItemCell;
