import { rootApi, getGatewayQuery } from '@web/utils/@reduxjs/rtk-query';

import { toLowerFirst } from '@shippypro/design-system-web/functions';

import { ShipAPIActions } from '@web/features/ship/types';
import {
  BaseOrdersMutationPayload,
  GetOrderQueryPayload,
  GetOrderTimelineMutationPayload,
  OrderAPIActions,
  OrderMergeMutationPayload,
  OrderArchiviationMutationPayload,
  OrderSaveNoteMutationPayload,
  OrderUpdateMarketplaceMutationPayload,
  SendOrderMailBaseMutationPayload,
  OrderSendLabelsMailPayload,
  OrderDeletionMutationResult,
  SendUniqueTransactionIDMutationPayload,
  CancelOrderAndMoveItToReadyToShipMutationPayload,
  CancelOrderAndMoveItToReadyToShipMutationResult,
} from './types';
import { Order, OrderCategory, OrderTimeline } from '@web/types/order';
import { BaseAPIResult } from '@web/types/common/APIResults';

// Define a service using a base URL and expected endpoints
export const orderApi = rootApi.injectEndpoints({
  endpoints: build => ({
    /**
     * API: getOrder
     *
     * @provides { type: 'Order', id: <The order's ID>}
     */
    getOrder: build.query<{ data: Order[] }, GetOrderQueryPayload>({
      query: args =>
        getGatewayQuery<ShipAPIActions, GetOrderQueryPayload>(
          ShipAPIActions.OrderGetList,
          args,
        ),
      providesTags: order => [
        { type: 'Order', id: order?.data[0] ? order?.data[0].id : -1 },
      ],
    }),
    /**
     * API: getTimeline
     *
     */
    getOrderTimeline: build.mutation<
      OrderTimeline[],
      GetOrderTimelineMutationPayload
    >({
      query: args =>
        getGatewayQuery<OrderAPIActions, GetOrderTimelineMutationPayload>(
          OrderAPIActions.OrderGetTimeline,
          args,
        ),
    }),
    /**
     * API: deleteOrders
     *
     * @invalidates [{ type: 'Ship', id: <The passed Category>/'deleted'}, { type: 'OrdersCount', id: <The passed Category>}, { type: 'TableCarriers', id: <The passed Category>/'deleted'}]
     */
    deleteOrders: build.mutation<
      OrderDeletionMutationResult,
      BaseOrdersMutationPayload
    >({
      query: args =>
        getGatewayQuery<OrderAPIActions, BaseOrdersMutationPayload>(
          OrderAPIActions.OrderDelete,
          args,
        ),
      invalidatesTags: (result, _, args) => [
        ...(result?.ids_deleted.map(id => ({ type: 'Order', id })) ?? []),
        { type: 'Ship', id: toLowerFirst(args.category) },
        { type: 'Ship', id: OrderCategory.deleted },
        { type: 'TableCarriers', id: toLowerFirst(args.category) },
        { type: 'TableCarriers', id: OrderCategory.deleted },
        { type: 'OrdersCount', id: toLowerFirst(args.category) },
        { type: 'OrdersCountCarrier', id: toLowerFirst(args.category) },
      ],
    }),
    /**
     * API: mergeOrders
     *
     * @invalidates [{ type: 'Ship', id: <The passed Category>/'merged'}, { type: 'OrdersCount', id: <The passed Category>}, { type: 'TableCarriers', id: <The passed Category>/'merged'}]
     */
    mergeOrders: build.mutation<
      BaseAPIResult<boolean>,
      OrderMergeMutationPayload
    >({
      query: args =>
        getGatewayQuery<OrderAPIActions, OrderMergeMutationPayload>(
          OrderAPIActions.OrderMergeOrders,
          args,
        ),
      invalidatesTags: (_, __, arg) => [
        ...(arg.ids.map(id => ({ type: 'Order', id })) ?? []),
        { type: 'Ship', id: OrderCategory.toShip },
        { type: 'TableCarriers', id: OrderCategory.toShip },
      ],
    }),
    /**
     * API: archiveOrders
     *
     * @invalidates [{ type: 'Order', id: <The order IDs>}, { type: 'Ship', id: 'shipped'}, { type: 'Ship', id: 'archived'}, { type: 'Ship', id: 'error'}, { type: 'TableCarriers', id: 'shipped'}, { type: 'TableCarriers', id: 'archived'}, { type: 'TableCarriers', id: 'error'}]
     */
    archiveOrders: build.mutation<
      BaseAPIResult<boolean>,
      OrderArchiviationMutationPayload
    >({
      query: args =>
        getGatewayQuery<OrderAPIActions, OrderArchiviationMutationPayload>(
          OrderAPIActions.OrderArchive,
          args,
        ),
      invalidatesTags: (_, __, arg) => [
        ...(arg.ids.map(id => ({ type: 'Order', id })) ?? []),
        { type: 'Ship', id: OrderCategory.shipped },
        { type: 'Ship', id: OrderCategory.archived },
        { type: 'Ship', id: OrderCategory.error },
        { type: 'TableCarriers', id: OrderCategory.shipped },
        { type: 'TableCarriers', id: OrderCategory.archived },
        { type: 'TableCarriers', id: OrderCategory.error },
      ],
    }),
    /**
     * API: unarchiveOrders
     *
     * @invalidates [{ type: 'Order', id: <The order IDs>}, { type: 'Ship', id: 'shipped'}, { type: 'Ship', id: 'archived'}, { type: 'Ship', id: 'error'}, { type: 'TableCarriers', id: 'shipped'}, { type: 'TableCarriers', id: 'archived'}, { type: 'TableCarriers', id: 'error'}]
     */
    unarchiveOrders: build.mutation<
      BaseAPIResult<boolean>,
      OrderArchiviationMutationPayload
    >({
      query: args =>
        getGatewayQuery<OrderAPIActions, OrderArchiviationMutationPayload>(
          OrderAPIActions.OrderUnarchive,
          args,
        ),
      invalidatesTags: (_, __, arg) => [
        ...(arg.ids.map(id => ({ type: 'Order', id })) ?? []),
        { type: 'Ship', id: OrderCategory.shipped },
        { type: 'Ship', id: OrderCategory.archived },
        { type: 'Ship', id: OrderCategory.error },
        { type: 'TableCarriers', id: OrderCategory.shipped },
        { type: 'TableCarriers', id: OrderCategory.archived },
        { type: 'TableCarriers', id: OrderCategory.error },
      ],
    }),
    /**
     * API: saveOrderNote
     *
     * @invalidates { type: 'Order', id: <The order IDs>}
     */
    saveOrderNote: build.mutation<
      BaseAPIResult<boolean>,
      OrderSaveNoteMutationPayload
    >({
      query: args => {
        const fixedArgs = {
          /*
            API won't support other order categories than toShip and shipped.
            We know we could want to ship an order in error, and in this
            specific case we can assume an order in error is always a shipped order.

            FIXME: find a better way to represent the category of an order ( = the "bucket" we
            are putting the order into) and the table to which the order is stored into.
          */
          ...args,
          category:
            args.category === OrderCategory.error
              ? OrderCategory.shipped
              : args.category,
        };
        return getGatewayQuery<OrderAPIActions, OrderSaveNoteMutationPayload>(
          OrderAPIActions.OrderSaveNote,
          fixedArgs,
        );
      },
      invalidatesTags: (_, __, arg) => [
        // This cause a refetch of the whole table data. We can improve this in a later epic
        { type: 'Order', id: arg.id },
        { type: 'Ship', id: arg.category },
      ],
    }),
    /**
     * API: updateOrdersMarketplace
     *
     * @invalidates [{ type: 'Order', id: <The order IDs>}]
     */
    updateOrdersMarketplace: build.mutation<
      BaseAPIResult<boolean>,
      OrderUpdateMarketplaceMutationPayload
    >({
      query: args =>
        getGatewayQuery<OrderAPIActions, OrderUpdateMarketplaceMutationPayload>(
          OrderAPIActions.OrderMarketplaceUpdate,
          args,
        ),
      invalidatesTags: (_, __, arg) => [
        { type: 'Ship', id: OrderCategory.shipped },
        ...(arg.ids.map(id => ({ type: 'Order', id })) ?? []),
      ],
    }),
    /**
     * API: sendOrderMail
     *
     */
    sendOrdersMail: build.mutation<
      BaseAPIResult<boolean>,
      SendOrderMailBaseMutationPayload
    >({
      query: args =>
        getGatewayQuery<OrderAPIActions, SendOrderMailBaseMutationPayload>(
          OrderAPIActions.OrderSendEmail,
          args,
        ),
      invalidatesTags: (_, __, arg) =>
        arg.ids.map(id => ({ type: 'Order', id })) ?? [],
    }),
    /**
     * API: sendOrderMailWithLabel
     *
     */
    sendOrdersMailWithLabel: build.mutation<
      BaseAPIResult<boolean>,
      OrderSendLabelsMailPayload
    >({
      query: args =>
        getGatewayQuery<OrderAPIActions, SendOrderMailBaseMutationPayload>(
          OrderAPIActions.OrderSendLabelsMail,
          args,
        ),
      invalidatesTags: (_, __, arg) =>
        arg.ids.map(id => ({ type: 'Order', id })) ?? [],
    }),
    /**
     * API: checkUniqueTransactionId
     *
     */
    checkUniqueTransactionId: build.mutation<
      BaseAPIResult<boolean>,
      SendUniqueTransactionIDMutationPayload
    >({
      query: args =>
        getGatewayQuery<
          OrderAPIActions,
          SendUniqueTransactionIDMutationPayload
        >(OrderAPIActions.OrderCheckUniqueExternalId, args),
    }),
    /**
     * API: cancelOrderAndMoveItToReadyToShip
     *
     * @invalidates [{ type: 'Ship', id: OrderCategory.shipped },  { type: 'Ship', id: OrderCategory.toShip }, { type: 'OrdersCount', id: OrderCategory.toShip }, { type: 'Order', id: <The order IDs>}]
     */
    cancelOrderAndMoveItToReadyToShip: build.mutation<
      CancelOrderAndMoveItToReadyToShipMutationResult,
      CancelOrderAndMoveItToReadyToShipMutationPayload
    >({
      query: args =>
        getGatewayQuery<
          OrderAPIActions,
          CancelOrderAndMoveItToReadyToShipMutationPayload
        >(OrderAPIActions.OrderCancelAndMoveToReadyToShip, args),
      // TODO: Ideally, the server should response an error http status so that we don't need to perform this check
      // But as of now, even if there's an error we return a success response which is not caught by the error handler
      transformResponse: (
        response: CancelOrderAndMoveItToReadyToShipMutationResult,
      ) => {
        if (!response.result && response.error) {
          throw new Error(response.error);
        }
        return response;
      },
      invalidatesTags: (_, __, arg) => [
        { type: 'Ship', id: OrderCategory.shipped },
        {
          type: 'Ship',
          id: `${OrderCategory.shipped}_${OrderCategory.shipped}`,
        },
        // Since we move from shipped to ready to ship, the tags for the "to ship" category needs to be invalidated
        {
          type: 'Ship',
          id: `${OrderCategory.shipped}_${OrderCategory.toShip}`,
        },
        { type: 'Ship', id: OrderCategory.toShip },
        { type: 'OrdersCount', id: OrderCategory.toShip },
        { type: 'Order', id: arg.orderId },
      ],
    }),
    // [APPEND NEW APIS ABOVE] < Needed for generating API Hooks seamlessly
  }),
});

export const {
  useGetOrderQuery,
  useGetOrderTimelineMutation,
  useDeleteOrdersMutation,
  useMergeOrdersMutation,
  useArchiveOrdersMutation,
  useUnarchiveOrdersMutation,
  useSaveOrderNoteMutation,
  useUpdateOrdersMarketplaceMutation,
  useSendOrdersMailMutation,
  useSendOrdersMailWithLabelMutation,
  useCheckUniqueTransactionIdMutation,
  useCancelOrderAndMoveItToReadyToShipMutation,
  // [EXPORT NEW APIS ABOVE] < Needed for generating API Hooks seamlessly
} = orderApi;
