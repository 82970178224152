import { memo } from 'react';
import { format } from 'date-fns';
import DOMPurify from 'dompurify';

import { Badge } from '@shippypro/design-system-web';
import { Color } from '@shippypro/design-system-web/enums';

import { ChangelogPost } from '@web/types/global';

interface IPostProps {
  post: ChangelogPost;
}

/**
 * **COMPONENT: Post**
 *
 * This is the single post component that will be displayed in the modal alongside its peers.
 * it starts with some labels and the publication date at the top, then it displays the post's
 * title, and then its body.
 *
 * Don't be afraid of the `dangerouslySetInnerHTML` tag, it's used to parse and apply the response
 * from a totally safe API endpoint (Changelogfy) and its cleaned further using the `dompurify` library.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 * @interface IPostProps
 */
const Post: React.FC<IPostProps> = memo(({ post }) => {
  return (
    <article className={`post-${post.id}`}>
      <div className="post-metadata flex items-center justify-between !mb-5">
        <div className="post-labels flex gap-1">
          {post.labels.map(label => (
            <Badge
              key={label.id}
              color={Color.none}
              style={{
                color: label.primary_color,
                backgroundColor: label.secondary_color,
              }}
            >
              {label.name}
            </Badge>
          ))}
        </div>
        <div className="post-publish-date text-muted">
          {format(new Date(post.published_at), 'MMMM d, y')}
        </div>
      </div>
      <p className="text-lg font-bold !mb-5">{post.post_content.title}</p>
      <section
        className="post-body"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(post.post_content.body),
        }}
      />
    </article>
  );
});

export default Post;
