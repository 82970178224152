/**
 * This functions takes an array of objects and moves an element
 * from an index to another
 *
 * @param {Array<T>} array The array of object to map
 * @param {number} fromIndex The initial index
 * @param {number} toIndex The desired index
 * @returns {T[]} The resulting prop value array
 */
export function moveElementInArray<T>(
  array: Array<T>,
  fromIndex: number,
  toIndex: number,
): T[] {
  [array[fromIndex], array[toIndex]] = [array[toIndex], array[fromIndex]];
  return array;
}
