import { FC, Fragment } from 'react';
import classnames from 'classnames';
import { useDropzone } from 'react-dropzone';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations_restricted';

import { IconHelper } from '@ds-web-iconhelper';
import { getHumanReadableFileSize } from '@ds-web/utils/functions/files';

import { IFileUploadDropzoneProps } from './types';

const DEFAULT_SUPPORTED_TYPES = {
  'text/plain': ['.txt'],
  'text/csv': ['.csv'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xlsx',
  ],
};

const DEFAULT_LABELS = {
  dropHere: 'Drop a file here or',
  browse: 'browse',
  yourFiles: 'your files',
  supportedExtensions: 'Supported extensions:',
  suggestedFileSize: 'Suggested file size:',
  lessThan: 'less than 2 MB:',
};

/**
 * A wrapper class for the `react-dropzone: <Dropzone />` component.
 *
 * @interface IFileUploadProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const FileUploadDropzone: FC<IFileUploadDropzoneProps> = ({
  value,
  onChange,
  onError,
  formatFileSize = getHumanReadableFileSize,
  labels = DEFAULT_LABELS,
  supportedTypes = DEFAULT_SUPPORTED_TYPES,
  supportedTypesAdditionalLabels = null,
  showFileSize = true,
  multiple = false,
  removeIcon = 'IconCircleX',
}) => {
  const { t } = useTranslation();

  const allExtensions = Object.keys(supportedTypes).flatMap(key => {
    const types = supportedTypes[key];
    if (types.length) return types;
    return [key];
  });

  const allExtensionsLabels = Object.values(supportedTypes).flatMap(types =>
    types.map(type => supportedTypesAdditionalLabels?.[type] || type),
  );

  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    multiple,
    accept: supportedTypes,
    onDrop: result => {
      if (result.length) {
        const compliantFiles: File[] = [];
        result.forEach(file => {
          const isSupportedType = allExtensions.some(fType =>
            file.name.endsWith(fType),
          );
          if (!isSupportedType) {
            onChange([]);
            if (onError) {
              onError(new Error('unsupported type'));
            }
          } else {
            compliantFiles.push(file);
          }
        });

        onChange(compliantFiles);
      }
    },
  });

  const inputProps = getInputProps();

  return (
    <div
      {...getRootProps({ className: 'dropzone' })}
      // prevent file selection if a file was already selected
      // (you must clear the existing value first)
      {...(value.length ? { onClick: e => e.preventDefault() } : undefined)}
    >
      <input {...inputProps} />
      {!value.length ? (
        <div
          className={classnames(
            'flex items-center flex-col justify-center py-1 min-h-[135px] cursor-pointer border-[1px]',
            '!border-[color:--shp-color-genericborder-input] !border-dashed rounded-2xl hover:!border-[color:--shp-color-genericui-primary]',
            {
              'bg-[color:--shp-color-bg-hover]': isDragAccept,
            },
          )}
        >
          {isDragAccept ? (
            <div className="font-bold text-[color:--shp-color-text-title]">
              {t(translations.common.dropFilesHere)}
            </div>
          ) : (
            <>
              <IconHelper
                icon="IconCloudUpload"
                className="pb-1 opacity-50"
                size={45}
              />
              <p className="text-center text-[color:--shp-color-text-title] pb-[0.5rem]">
                {labels.dropHere && (
                  <span className="font-bold">{labels.dropHere}</span>
                )}
                {labels.browse && labels.yourFiles && (
                  <span className="font-bold hover:underline">
                    {labels.browse} {labels.yourFiles}
                  </span>
                )}
              </p>

              <p className="text-sm text-center text-[color:--shp-color-text-label] px-2">
                {labels.supportedExtensions && (
                  <>
                    <span>{labels.supportedExtensions} </span>
                    <strong>{allExtensionsLabels.join(', ')}</strong>
                  </>
                )}
                {labels.suggestedFileSize && labels.lessThan && (
                  <>
                    {' - '}
                    <span>{labels.suggestedFileSize} </span>
                    <strong>{labels.lessThan}</strong>
                  </>
                )}
              </p>
            </>
          )}
        </div>
      ) : (
        <div className="flex items-center justify-center flex-col relative">
          {value.map((file, i) => (
            <Fragment key={i}>
              <span className="rounded-lg cursor-pointer ml-auto right-0 top-0">
                <IconHelper
                  icon={removeIcon}
                  onClick={e => {
                    e.stopPropagation();
                    onChange(value.filter(f => f.name !== file.name));
                  }}
                />
              </span>
              <IconHelper icon="IconFile" size={64} className="pb-1" />
              <p className="text-lg text-center pb-1">{file.name}</p>
              {showFileSize && (
                <p className="text-center">({formatFileSize(file.size)})</p>
              )}
              {value.length > 1 && <hr className="!my-3" />}
            </Fragment>
          ))}
        </div>
      )}
    </div>
  );
};
