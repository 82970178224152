/**
 * Returns a human readable string representing the size of a file
 *
 * @param {number} bytes the file size in bytes
 * @param {number} decimals the number of decimals
 * @returns a human readable string (i.e. 10 KB)
 *
 * @author Fabio Fognani <fabio.fognani@shippypro.com>
 */
export function getHumanReadableFileSize(bytes, decimals = 2) {
  // supported unit measures, we could add more of them, like PB
  // (...but we will never need them!)
  const unitMeasures = ['B', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return [0, unitMeasures[0]].join(' ');

  // Bytes and successive unit measures are powers of 1024
  const base = 1024;
  // calculate the exponent needed to express bytes in exponential notation
  const exp = Math.floor(Math.log(bytes) / Math.log(base));
  // since we don't technically support ALL powers, we want to know what is
  // the last unit measure we can use. For example, if we decided to truncate
  // unitMeasures = ['B', 'KB'], then 1 MB would be expressed as 1024 KB
  const maxExp = Math.min(exp, unitMeasures.length - 1);

  return [
    parseFloat((bytes / Math.pow(base, maxExp)).toFixed(decimals)),
    unitMeasures[maxExp],
  ].join(' ');
}
