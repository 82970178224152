import { FC } from 'react';

import { AccordionItem as ReactstrapAccordionItem } from 'reactstrap';

import { IAccordionItemProps } from './types';

/**
 * A simple wrapper class for the `reactstrap: <AccordionItem />` component.
 *
 * @interface IAccordionItemProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const AccordionItem: FC<IAccordionItemProps> = props => (
  <ReactstrapAccordionItem {...props} />
);
