import { ReactNode } from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import classnames from 'classnames';

import {
  FormGroup,
  FormGroupProps,
  FormText,
  FormTextProps,
  FormFeedback,
  FormFeedbackProps,
  Label,
} from 'reactstrap';

import { HelperText, Input } from '@shippypro/design-system-web';
import { IInputProps } from '@shippypro/design-system-web/types';

export interface ControlledCheckboxProps<FormType extends FieldValues>
  extends Pick<
    FormGroupProps,
    | 'row'
    | 'check'
    | 'inline'
    | 'floating'
    | 'disabled'
    | 'tag'
    | 'cssModule'
    | 'className'
  > {
  name: Path<FormType>;
  control: Control<FormType>;
  label?: ReactNode;
  required?: boolean;
  formGroupCheckLabelClassName?: string;
  formGroupCheckContainerClassName?: string;
  labelClassName?: string;
  checkboxProps?: Omit<IInputProps, 'checked' | 'value'>;
  color?: IInputProps['color'];
  errorTextProps?: FormFeedbackProps;
  errorText?: ReactNode;
  successTextProps?: FormTextProps;
  successText?: ReactNode;
  helperTextProps?: FormTextProps;
  helperText?: ReactNode;
  disabled?: boolean;
  endIcon?: ReactNode;
  indeterminate?: boolean;
}

export function ControlledCheckbox<FormType extends FieldValues>({
  name,
  formGroupCheckLabelClassName,
  formGroupCheckContainerClassName,
  label,
  labelClassName,
  control,
  checkboxProps,
  color,
  helperTextProps,
  errorTextProps,
  errorText,
  successTextProps,
  successText,
  helperText,
  className,
  disabled,
  required,
  endIcon,
  indeterminate,
  ...rest
}: ControlledCheckboxProps<FormType>) {
  return (
    <Controller
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormGroup {...rest} className={classnames('!mb-0', className)}>
          <Label
            className={classnames(
              'form-check-label',
              formGroupCheckLabelClassName,
            )}
          >
            <div className="flex items-center space-x-1">
              <div
                className={classnames(
                  'flex items-center form-check',
                  formGroupCheckContainerClassName,
                  {
                    'form-check-indeterminate': indeterminate,
                  },
                )}
              >
                <Input
                  type="checkbox"
                  {...checkboxProps}
                  color={color ?? checkboxProps?.color}
                  name={name}
                  onChange={e => {
                    checkboxProps?.onChange && checkboxProps?.onChange(e);

                    onChange(e);
                  }}
                  onClick={onChange}
                  checked={value}
                  required={required ?? checkboxProps?.required}
                  disabled={disabled ?? checkboxProps?.isDisabled}
                  valid={Boolean(successText)}
                  invalid={Boolean(error || errorText)}
                />
                <span className={classnames('pl-2', labelClassName)}>
                  {label}
                </span>
              </div>
              {endIcon}
            </div>
          </Label>
          <div>
            {(error || errorText) && (
              <FormFeedback {...errorTextProps}>
                {errorText ?? error?.message}
              </FormFeedback>
            )}
            {successText && (
              <FormText color="success" {...successTextProps}>
                {successText}
              </FormText>
            )}
            {helperText && (
              <HelperText {...helperTextProps}>{helperText}</HelperText>
            )}
          </div>
        </FormGroup>
      )}
      name={name}
    />
  );
}
