import { ICustomHeaderProps } from '../types';

import { Button } from '@ds-web/components/atoms/ui/buttons/button/Button';
import DataTableSearch from '../_components/DataTableSearch';

import { StyleType, Color } from '@ds-web/tokens/enums';

interface ISelectedForCustomActionHeader extends ICustomHeaderProps {}

const SelectedForCustomActionHeader = (
  props: ISelectedForCustomActionHeader,
) => {
  const { pagination, selection, filtering, action, utility, ...rest } = props;

  return (
    <div className="d-flex flex-wrap justify-content-between">
      <div
        className={`position-relative has-icon-left mb-1 ml-1 ${
          utility.isMobile ? 'w50' : ''
        }`}
      >
        <DataTableSearch {...rest} filtering={filtering} />
      </div>
      {selection.selActionBtnText ? (
        <div className="dropleft dropleft-form mr-1 mb-1 d-inline-block filter">
          <Button
            styleType={selection.btnStyle || StyleType.flat}
            color={selection.btnColor || Color.primary}
            className="btn-icon !flex"
            icon={selection.btnIcon}
            onClick={() =>
              selection.onSelCustomActionClick &&
              selection.onSelCustomActionClick(rest.selectedRows)
            }
          >
            {utility.isMobile
              ? rest.selectedRows.length
              : utility.t(selection.selActionBtnText, {
                  count: rest.selectedRows.length,
                })}
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default SelectedForCustomActionHeader;
