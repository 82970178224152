export const STATUSPAGE_LOCALSTORAGE_KEY = 'status_alerts_dismissed';

export type StatusPageAlert = {
  id: string;
  name: string;
  status: 'in_progress' | 'investigating' | 'closed' | 'resolved';
  component: string;
  update: string;
  created_at: string;
  updated_at: string;
  scheduled_for?: string;
  scheduled_until?: string;
};
