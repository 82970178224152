import { memo } from 'react';
import { Col } from 'reactstrap';

import { Card, CardBody, CardHeader } from '@ds-web/components/atoms/ui/card';
import { Checkbox } from '@ds-web/components/atoms/form/checkbox/Checkbox';

import { formatLocale } from '@ds-web/utils/functions';

import ListAction from './ListAction';
import ListIcon from './ListIcon';

import {
  ListViewReservedFields,
  IListItemProps as IGridItemProps,
} from './types';

const GridItem = memo(({ config, row, columns, ...rest }: IGridItemProps) => {
  const values = Object.values(row),
    selected = rest.selected.includes(values[0]),
    { selectable } = config,
    handleSelected = () => {
      if (selectable)
        rest.handleSelect(
          selected
            ? rest.selected.filter(s => s !== values[0])
            : [...rest.selected, values[0]],
        );
    },
    highlight = config.rowHighlight ? config.rowHighlight(row) : false;

  return (
    <Col xl={3} md={4} sm={6} className="list-view-grid-item flex-wrap flex">
      <Card
        className={`border action-card ${
          selected && 'selected border-primary'
        } ${highlight && 'list-view-row-highlighted'}`}
      >
        <CardHeader>
          {selectable ? (
            <Checkbox
              className="mr-1"
              checked={selected}
              onChange={() => handleSelected()}
            />
          ) : (
            <span></span>
          )}

          <ListAction config={config} row={row} />
        </CardHeader>
        <CardBody onClick={() => handleSelected()}>
          {columns
            .filter(
              c =>
                c.name.toUpperCase() !== 'ID' &&
                !c.hidden &&
                c.name !== ListViewReservedFields.icon,
            )
            .map((column, i) => (
              <p
                key={i}
                className={`${i ? '' : 'font-semibold'} flex justify-start`}
              >
                <ListIcon columns={columns} row={row} cell={column.data} />
                {column.data === ListViewReservedFields.date &&
                row[column.data]['start'] &&
                row[column.data]['end']
                  ? `${formatLocale(
                      row[column.data]['start'],
                      config.dateFormat,
                      config.language,
                    )} - ${formatLocale(
                      row[column.data]['end'],
                      config.dateFormat,
                      config.language,
                    )}`
                  : row[column.data]}
              </p>
            ))}
        </CardBody>
      </Card>
    </Col>
  );
});

export default GridItem;
