import { refiner } from './refiner';
import { mixpanel } from './mixpanel';
import { isProductionEnv } from '../functions';

refiner('setProject', process.env.NX_REFINER_PROJECT_KEY);

mixpanel.init(process.env.NX_MIXPANEL_PROJECT_KEY, {
  debug: !isProductionEnv(),
  track_pageview: true,
  persistence: 'localStorage',
});
