import { useMemo } from 'react';
import { localeLinks } from '@web/utils/@links/links';
import { browserLanguage } from '@web/utils/@translations/browser-language';

/**
 * This hook fetch the preferred language of the client and returns
 * the right slug based on the link key passed as a prop.
 * If the user's language is not one of the list, it defaults to the english version
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 * @param {string} linkKey
 * @returns {string}
 */

const useGetLocaleLink = (linkKey: string) => {
  const slugs = localeLinks[linkKey];
  const userLanguage = browserLanguage;

  const slug = useMemo(() => {
    return userLanguage in slugs ? slugs[userLanguage] : slugs.en;
  }, [slugs, userLanguage]);

  return slug;
};

export default useGetLocaleLink;
