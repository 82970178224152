import { useMemo } from 'react';

import { useGetPlanInfoQuery } from '@web/features/_global/api';
import {
  CustomRTKQueryOptions,
  parseAPIError,
} from '@web/utils/@reduxjs/rtk-query';

/**
 * Returns RTK Query hook with renamed data field.
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 *
 * @param {CustomRTKQueryOptions} options subset of RTK Query hook options
 */
export const useGetPlan = (options?: CustomRTKQueryOptions) => {
  const {
    data: plan,
    error,
    ...rest
  } = useGetPlanInfoQuery(undefined, options);

  return useMemo(
    () => ({
      plan,
      error: parseAPIError(error),
      ...rest,
    }),
    [error, plan, rest],
  );
};
