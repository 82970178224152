// ########################## [IMPORTANT LIBRARIES]
import { useMemo } from 'react';
import ReactCountryFlag from 'react-country-flag';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [DESIGN SYSTEM]
import { TooltipWrapperByID } from '@shippypro/design-system-web';

// ########################## [TYPES]
import { Order } from '@web/types/order';
import { Country } from '@web/types/global';

const columnHelper = createColumnHelper<Order>();

/**
 * Displays the ISO-2 code + UI Flag and the actual country name on hover with a tooltip
 * @author Fabio Fognani <fabio.fognani@shippypro.com>
 */
export const useGetCountryColumn = (
  countries: Country[] | undefined,
): ColumnDef<Order, string> => {
  const { t } = useTranslation();

  return useMemo(
    () =>
      columnHelper.accessor('address.country.alpha_2_code', {
        header: t(translations.common.form.country),
        minSize: 100,
        size: 150,
        maxSize: 200,
        enableSorting: true,
        id: 'country',
        cell: ({ row: { original } }) =>
          countryColumnRendererFn(countries, original),
      }),
    [t, countries],
  );
};

const countryColumnRendererFn = (
  countries: Country[] | undefined,
  { address, id }: Order,
) => {
  const target = `country-${address.country}-${id}`;
  const country = countries?.find(c => c.alpha_2_code === address.country);

  return (
    <TooltipWrapperByID target={target} text={country?.name}>
      <div
        className="flex justify-center items-center !gap-2"
        data-test="order-country-block"
      >
        <ReactCountryFlag
          className="!w-5 !h-5"
          countryCode={address.country as string}
          title={address.country as string}
          svg
        />
        <span>{address.country as string}</span>
      </div>
    </TooltipWrapperByID>
  );
};
