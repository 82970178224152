import { FC, PropsWithChildren, memo } from 'react';

/**
 * This component wraps notes (shippypro, marketplace, carrier, invoice)
 * into div with classes and data test.
 *
 * @interface INotesListWrapperProps
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
export const NotesListWrapper: FC<PropsWithChildren> = memo(({ children }) => {
  return (
    <div
      className="flex flex-col w-full items-start"
      data-test="notes-list-wrapper"
    >
      {children}
    </div>
  );
});
