import classnames from 'classnames';
import { Checkbox, HoverableAccordionHeader } from '@ds-web/components';
import { IToggleAccordionProps } from './types';
import { StyledAccordionItem } from './styles';

/**
 * This is a custom Accordion field to be used in forms
 * It does not open an accordion body. Instead it triggers the form state updating from outside thanks to the setValue function it receives
 * The clearFilter function sets the form field value to its initial one.
 *
 * @author Fabio Mezzina <fabio.mezzina@shippypro.com>
 */
export const ToggleAccordion: React.FC<IToggleAccordionProps> = ({
  name,
  label,
  value,
  toggleValue,
  className,
}) => {
  return (
    <StyledAccordionItem
      className="accordion-without-arrow"
      data-test={`accordion-filter-${name}`}
    >
      <HoverableAccordionHeader onClick={toggleValue} targetId="0">
        <div
          className={classnames(
            'w-full flex gap-1 justify-between items-center font-normal',
            className,
            {
              'filter-active text-primary !font-bold': value,
            },
          )}
        >
          <p>{label}</p>
          <Checkbox checked={value} onChange={toggleValue} />
        </div>
      </HoverableAccordionHeader>
    </StyledAccordionItem>
  );
};
