// ########################## [IMPORTANT LIBRARIES]
import { FC, memo, useContext } from 'react';
import classnames from 'classnames';

// ########################## [CONTEXTS]
import HelpSectionContext from './contexts/HelpSectionContext';
import { Offcanvas } from '@shippypro/design-system-web';

// ########################## [TYPES]
import { HelpSubSection } from './types';

// ########################## [COMPONENTS]
import Header from './components/header';
import SupportSection from './components/support-section';
import Ticketing from './components/embedded/ticketing';
import LiveChat from './components/embedded/live-chat';

/**
 * **FEATURE: Help Section**
 *
 * *"It's to dangerous to go alone! Take this 🗡️✨"*
 * This code is responsible for showing a Help section to aid the user in their journey.
 *
 * Said section will contain a searchable list of articles and two buttons to open the ticketing system
 * and the live support chat.
 *
 * @link T.B.D.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const HelpSection: FC = memo(() => {
  const {
    isOpen,
    subSection,
    closeHelpSection: closeHelp,
  } = useContext(HelpSectionContext)!;

  return (
    <Offcanvas
      show={true}
      backdrop={false}
      loading={false}
      handleToggle={closeHelp}
      showButtons={false}
      header={<Header />}
      headerClassName="title-xl"
      offcanvasClassname={classnames(
        '!border-l !border-[color:--shp-color-bg-highlight]',
        {
          'invisible !translate-x-full': !isOpen,
          'visible !transform-none': isOpen,
        },
      )}
      bodyClassName={classnames({
        '!bg-[color:--shp-color-bg-main]': subSection !== HelpSubSection.home,
        '!p-0': subSection === HelpSubSection.home,
        '!p-0 overflow-hidden': subSection === HelpSubSection.livechat,
      })}
      id="help-section"
    >
      {/* 
        Listing the various sections, they will hide/show depending on the selected subsection,
        allowing for a seamless transition between the various sections without losing any data (chat and ticketing form).
      */}
      <SupportSection />
      <Ticketing />
      <LiveChat />
    </Offcanvas>
  );
});
