import { isWithinInterval } from 'date-fns';

import { toDate } from '../to-date/to-date';

/**
 * Checks if a date is between a range of two dates, from start to end (included).
 *
 * @param {string | Date} date The date to check
 * @param {string | Date} start The start of the range
 * @param {string | Date} end The end of the range
 * @returns {boolean} True if the date is between the range, false otherwise
 */
export const isDateBetweenRange = (
  date: string | Date,
  start: string | Date,
  end: string | Date,
): boolean =>
  isWithinInterval(toDate(date), { start: toDate(start), end: toDate(end) });
