import type { Order } from '@web/types/order';
import type { Marketplace } from '@web/types/global';
import { Address } from '@web/types/common/address';

export interface IOrderInfoBlock {
  order?: Order | null;
  marketplace?: Marketplace;
  address?: Address | null;

  collapsible?: boolean;
  initialOpened?: boolean;
}

export const NO_IMAGE = 'NoImage';

export type ItemDescImage = {
  description?: string | null;
  url: string;
};
