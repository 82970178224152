/* istanbul ignore file */

import { FC, memo } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { ICountryWithFlag } from './types';

/**
 * This component renders the standard country name with the flag
 * @param {ICountryWithFlag} the area code of the country and the country name
 * @returns {JSX.Element} The CountryWithFlag component
 *
 * @author Federico Mauri <federico.mauri@shippypro.com>
 */
export const CountryWithFlag: FC<ICountryWithFlag> = memo(
  ({ countryCode, name }) => {
    return (
      <div>
        {countryCode && (
          <ReactCountryFlag
            className="!w-5 !h-5 mr-2"
            countryCode={countryCode}
            title={name}
            svg
          />
        )}
        {name}
      </div>
    );
  },
);
