/**
 * MUST be the same as the one into drupal project
 * 'carrier_error' should be all the error that we recognize them as a temporary carrier problem
 * 'invalid_info' something is wrong/missing on the order info fields
 * 'unknown' for some reason the error_message order field is not available
 */
export enum ErrorCategory {
  carrierError = 'carrier_error',
  invalidInfo = 'invalid_info',
  unknown = 'unknown',
}

export type OrderErrorTypeItem = {
  value: ErrorCategory;
  label: string;
};
