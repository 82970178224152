import { ReactNode } from 'react';

export interface IListViewProps {
  config: ListViewConfig;
  columns: ListViewColumn[];
  data: any;
}

export interface IListItemCellProps {
  children?: ReactNode;
  bold?: boolean;
  className?: string;
  width?: number;
}

export interface IListHeaderProps {
  mode: ListViewMode;

  config: ListViewConfig;
  columns: ListViewColumn[];
  data: { [key: string]: string }[];

  order: ListViewOrder;
  toggleOrder: (o: ListViewOrder) => void;
  selected: string[];
  setSelected: (selection: string[]) => void;
}

export interface IListHeaderCellProps {
  config: ListViewConfig;
  first?: boolean;
  width?: number;

  children?: ReactNode;
  className?: string;
  actions?: boolean;
}

export interface IListHeaderActionsProps {
  config: ListViewConfig;
  data: { [key: string]: string }[];

  order: 'desc' | 'asc';
  toggleOrder: (o: ListViewOrder) => void;
  selected: string[];
  setSelected: (selection: string[]) => void;
}

export interface IListItemProps {
  config: ListViewConfig;
  row: { [key: string]: any };
  columns: ListViewColumn[];

  selected: string[];
  handleSelect: (selection: string[]) => void;
}

export interface IListIconProps {
  columns: ListViewColumn[];
  cell: string;
  row: { [key: string]: any };
}

export interface IListFiltersProps {
  config: ListViewConfig;

  className?: string;
  selected: string[];
  search: (k: string) => void;
  dates: Date[];
  setDates: (k: Date[]) => void;
  mode: ListViewMode;
  toggleMode: (m: ListViewMode) => void;
}

export interface IListActionProps {
  config: ListViewConfig;

  row?: object;
  hide?: boolean;
}

export interface ListViewConfig {
  selectable: boolean;
  mobile: boolean;
  actions?: {
    download?: (sel: string[]) => void;
    delete?: (sel: string[]) => void;
    context: ListViewAction[];
  };
  language: string;
  translations: {
    actions: string;
    search: string;
    datepicker: string;
    selected: string;
  };
  dateFormat: string;
  emptyText: string;
  noMatchText: string;
  rowsPerPage?: number;
  order?: ListViewOrder;
  rowHighlight?: (row: any) => boolean;
}

export interface ListViewAction {
  icon: string;
  name: string;
  fn: (row?: object) => void;
}

export interface ListViewColumn {
  name: string;
  data: string;
  icon?: boolean;
  width?: number;
  hidden?: boolean;
}

export interface ListViewIcon {
  code: string;
  tooltip?: JSX.Element;
}

export enum ListViewMode {
  list = 'list',
  grid = 'grid',
}

export enum ListViewOrder {
  asc = 'asc',
  desc = 'desc',
}

export enum ListViewReservedFields {
  date = 'date',
  icon = 'icon',
}
