import { useGetSetupData } from '@web/features/_global/hooks/api/useGetSetupData';

/**
 * [HOOK] Returns the number of setup steps the user has completed
 * @returns {number} stepsCompleted
 *
 * @author Fabio Mezzina <fabio.mezzina@shippypro.com>
 */
export const useGetSetupStepsCompleted = () => {
  const {
    data: [, setup],
  } = useGetSetupData();

  let stepsCompleted = 0;

  for (const step in setup?.progress) {
    if (step !== 'setupCompleted' && setup.progress[step]) stepsCompleted++;
  }

  return stepsCompleted;
};
