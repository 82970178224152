import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import {
  toCurrencySymbol,
  toLocalizedPrice,
} from '@shippypro/design-system-web/functions';

import CardView from '@web/components/common-ui/wrappers/card/CardView';

import { IOrderInfoBlock } from './types';

interface IPaymentInfoProps extends IOrderInfoBlock {}

const PaymentInfo: React.FC<IPaymentInfoProps> = ({ order }) => {
  const { t } = useTranslation(),
    transOrder = translations.order.info;

  const { payment } = order ?? {};

  return (
    <CardView
      col
      header={t(transOrder.paymentInfo).toUpperCase()}
      name="payment-info"
    >
      <div data-test="order-detail-payment">
        <div className="flex justify-between">
          <p>{t(transOrder.shipmentAmount)}</p>
          <p>
            {toLocalizedPrice(
              toCurrencySymbol(payment?.shipment_amountpaid_currency),
              payment?.shipment_amountpaid ?? 0,
            )}
          </p>
        </div>
        <div className="flex justify-between">
          <p>{t(transOrder.shipmentInsurance)}</p>
          <p>
            {toLocalizedPrice(
              toCurrencySymbol(payment?.insurance_currency),
              payment?.insurance ?? 0,
            )}
          </p>
        </div>
        <div className="flex justify-between">
          <p>{t(transOrder.shipmentSales)}</p>
          <p>
            {toLocalizedPrice(
              toCurrencySymbol(payment?.totalsales_curr),
              payment?.totalsales ?? 0,
            )}
          </p>
        </div>
        <hr />
        <div className="flex justify-between">
          <p>{t(transOrder.totalAmount)}</p>
          <p>
            {toLocalizedPrice(
              toCurrencySymbol(order?.currency),
              !!order?.total_amount ? +order?.total_amount : 0,
            )}
          </p>
        </div>
      </div>
    </CardView>
  );
};

export default PaymentInfo;
