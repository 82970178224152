import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import { Pagination, NumberBadge } from '@shippypro/design-system-web';

import CardView from '@web/components/common-ui/wrappers/card/CardView';
import SingleItem from './UI/SingleItem';

import { IOrderInfoBlock } from './types';

interface IProductDetailsProps extends IOrderInfoBlock {
  allowMagnifyItemImages?: boolean;
  header?: JSX.Element;
  collapsedSummary?: JSX.Element;
  cardCSS?: string;
}

const ProductDetails: React.FC<IProductDetailsProps> = ({
  order,
  allowMagnifyItemImages,
  header,
  cardCSS,
  collapsedSummary,
  collapsible = true,
  initialOpened = true,
}) => {
  const { t } = useTranslation(),
    transOrder = translations.order.info;

  const { items, items_count } = order ?? {};

  // CLIENT-SIDE PAGINATION
  const [page, setPage] = useState(1);
  const [perPage] = useState(5);
  const pageItems =
    items && items.length
      ? items.slice((page - 1) * perPage, page * perPage)
      : [];

  return (
    <CardView
      name="product-details"
      col
      collapsible={collapsible}
      initialOpened={initialOpened}
      cardCSS={cardCSS}
      collapsedSummary={collapsedSummary}
      header={
        header ?? (
          <div
            className="text-muted flex items-center"
            data-test="order-detail-products-head"
          >
            <p className="mr-1">{t(transOrder.productDetails).toUpperCase()}</p>
            <NumberBadge value={items_count} />
          </div>
        )
      }
    >
      {items && items.length ? (
        <>
          <div
            data-test="order-detail-products-list"
            className="order-detail-products-list"
          >
            {pageItems.map((item, i) => (
              <SingleItem
                item={item}
                currency={order?.currency ?? 'EUR'}
                divider={i !== pageItems.length - 1}
                key={item.id}
                allowMagnify={allowMagnifyItemImages}
              />
            ))}
          </div>
          {items.length > perPage && (
            <div
              className="flex justify-center"
              data-test="order-detail-products-pagination"
            >
              <Pagination
                rowsPerPage={perPage}
                rowCount={items.length}
                onChangePage={setPage}
                currentPage={page}
                hasPaginationString={false}
              />
            </div>
          )}
        </>
      ) : (
        <div className="flex w-full justify-center">
          <p className="text-muted">{t(transOrder.noProducts)}</p>
        </div>
      )}
    </CardView>
  );
};

export default ProductDetails;
