import { createContext } from 'react';

import {
  PaywallDesign,
  PaywallInfo,
  TwoRowsOneImageLayoutPaywallInfo,
  TwoRowsOneVideoLayoutPaywallInfo,
} from '@web/features/paywall/types';

export interface IPaywallContextProps<TPaywall = PaywallInfo> {
  isOpen: boolean;
  design: keyof typeof PaywallDesign;
  openPaywall: <T extends keyof typeof PaywallDesign = PaywallDesign.PureText>(
    design: T,
    info: T extends PaywallDesign.TwoRowsSingleImage
      ? TwoRowsOneImageLayoutPaywallInfo
      : T extends PaywallDesign.TwoRowsSingleVideo
        ? TwoRowsOneVideoLayoutPaywallInfo
        : PaywallInfo,
  ) => void;
  closePaywall: () => void;
  info?: TPaywall;
}

/**
 * This is the Paywall Context that is instantiated at the near-top of the component tree.
 * This allows any feature to open the modal and pay-lock a user from accessing specific features.
 * The context holds every info possible that the paywall modal needs to function correctly:
 * * IF the paywall modal is open or not;
 * * The current UX/UI Design of the modal;
 * * A Handy handler function to OPEN the modal;
 * * A Handy handler function to CLOSE the modal;
 * The info that the modal actually needs (title, cta, image...)
 *
 * @example
 * const { openPaywall } = useContext(PaywallContext)!;
 * ...
 * <button onClick={() => openPaywall<PaywallDesign.<DESIGN>>(<DESIGN>, <PAYWALL DATA>)}>Click Me!</button>
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const PaywallContext = createContext<IPaywallContextProps | null>(null);

export default PaywallContext;
