import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@web/utils/@reduxjs';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.changelogModal || initialState;

export const selectChangelogModal = createSelector(
  [selectDomain],
  globalState => globalState,
);
