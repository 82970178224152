import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import {
  showSecondaryToastr,
  showWarningToastr,
} from '@shippypro/design-system-web/functions';

import { shipActions } from '@web/features/ship/slice';
import {
  OrdersActionBaseMutationPayload,
  PrintOrdersMutationResult,
} from '@web/features/ship/types';

import { usePrintBrtLabelsMutation } from '@web/features/ship/api';

import { handleAPIError } from '@web/utils/@reduxjs/rtk-query';
import { downloadBase64File } from '@web/utils/functions';

/**
 * Returns a `usePrintBrtLabelsMutation` RTK Query hook with its action field renamed.
 * This hook creates a list of N .pdf files for every selected order in the 'Shipped' table
 * that's using BRT as a shipping carrier. Depending on the result of the API call,
 * the user will be notified and, when everything has completed successfully, will be able to download the file.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const usePrintBrtLabels = () => {
  const { t } = useTranslation(),
    trans = translations.common,
    transLabels = translations.ship.buttons.print;

  const [trigger, { ...results }] = usePrintBrtLabelsMutation();

  const dispatch = useDispatch();

  const printBrtLabels = useCallback(
    async (payload: OrdersActionBaseMutationPayload) => {
      dispatch(shipActions.OrderPrinting());

      const result = await trigger(payload);
      const data = await handleAPIError<PrintOrdersMutationResult>(result);

      dispatch(shipActions.OrderPrinted());
      if (!data) return false;

      if (data.base64File) {
        downloadBase64File(data.base64File);
        showSecondaryToastr(
          t(transLabels.printedSuccessfully, { count: payload.ids.length }),
        );

        return true;
      } else {
        showWarningToastr(t(trans.errors.error), t(trans.errors.noContent));

        return false;
      }
    },
    [
      dispatch,
      t,
      trans.errors.error,
      trans.errors.noContent,
      transLabels.printedSuccessfully,
      trigger,
    ],
  );

  return useMemo(
    () => ({
      printBrtLabels,
      ...results,
    }),
    [printBrtLabels, results],
  );
};
