import { FC, memo } from 'react';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations_restricted';

import {
  Badge,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
} from '@ds-web/components';

import { ITabBadgeProps } from '@ds-web/components/types';
import { Color } from '@ds-web/tokens/enums';

/**
 * This component show a badge next to a tab, with a number and a color (and possible popover explanation).
 *
 * @interface ITabBadgeProps
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const TabBadge: FC<ITabBadgeProps> = memo(
  ({ badgeID, badgeNumber = 0, badgeColor = Color.none, explainPopover }) => {
    const { t } = useTranslation(),
      transReadMore = translations.common.readMore;

    return (
      badgeNumber > 0 && (
        <>
          <UncontrolledPopover
            placement="bottom"
            target={badgeID}
            trigger="hover"
            active={Boolean(explainPopover)}
            disabled={!Boolean(explainPopover)}
          >
            <PopoverHeader className="flex justify-between">
              <span>{explainPopover?.title}</span>
              {explainPopover?.readMore && (
                <a
                  href={explainPopover?.readMore}
                  target="_blank"
                  rel="noreferrer"
                  className="font-bold"
                >
                  {t(transReadMore)}
                </a>
              )}
            </PopoverHeader>
            <PopoverBody className="max-w-80">
              {explainPopover?.body}
            </PopoverBody>
          </UncontrolledPopover>
          <Badge
            id={badgeID}
            color={badgeColor}
            className="min-w-[22px] text-[12px] font-medium flex flex-col justify-center"
          >
            {/* caps the badge number to '999+ */}
            {badgeNumber > 999 ? '999+' : badgeNumber}
          </Badge>
        </>
      )
    );
  },
);

export default TabBadge;
