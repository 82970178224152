import { FC } from 'react';

import classnames from 'classnames';
import { Badge as ReactstrapBadge } from 'reactstrap';

import { IBadgeProps } from './types';

/**
 * This component displays a simple badge with a colored background and a component
 * contained in the `children` prop.
 *
 * @interface IBadgeProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const Badge: FC<IBadgeProps> = ({
  glow,
  light,
  square,
  round,
  block,
  up,
  className,
  color,
  ...rest
}) => (
  <ReactstrapBadge
    className={classnames(className, {
      'badge-glow': glow,
      'badge-square': square,
      'badge-round': round,
      block: block,
      'badge-up': up,
    })}
    color={light ? `light-${color}` : color}
    // Spreading the rest of the props to the library component
    {...rest}
  />
);
