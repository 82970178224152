import { useEffect, useState } from 'react';
import classnames from 'classnames';

import { Header } from '@tanstack/react-table';

import { IReactTableProps } from '../../types';

import TableHeaderCell from './cell';

/**
 * This component is the UI representation of a `react-table` header.
 * It displays the `columns` list into an actual table headers layout and manages:
 * * Full Selection (w/ checkbox);
 * * Full Expansion (w/ icon);
 * * Cell Sorting (w/ icon);
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 *
 * @returns {FC} The `React Table` header, fully rendered & managed.
 */
function TableHeader<T extends object>(props: IReactTableProps<T>) {
  const {
    getHeaderGroups,
    options,
    getState,
    customComponents: {
      bulkActions,
      selectedOrdersTotal = 0,
      translations: { transFunction = () => '', selectionText = '' } = {},
    } = {},
  } = props;

  const canResize = options.enableColumnResizing ?? false;
  const isResizing = getState().columnSizingInfo.isResizingColumn;
  const hasSelectedRows = selectedOrdersTotal > 0;
  const showBulkActions = !!bulkActions && hasSelectedRows;
  const [draggingCol, setDraggingCol] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!isResizing) setDraggingCol(undefined);
  }, [isResizing]);

  return (
    <thead className="react-table-header rdt_TableHead">
      {getHeaderGroups().map(headerGroup => (
        <tr key={headerGroup.id} className="relative !overflow-visible">
          {!!bulkActions && hasSelectedRows && (
            <div
              data-test="bulk-actions"
              className="bulk-actions w-[-webkit-fill-available] absolute top-0 left-0 bg-[color:--shp-color-genericui-lighterbluegray] flex !gap-2"
            >
              <TableHeaderCell
                header={headerGroup.headers[0]}
                index={0}
                canResize={canResize}
                isResizing={isResizing}
                draggingCol={draggingCol}
                setDraggingCol={setDraggingCol}
                className="max-w-fit"
                {...props}
              />
              <th className="rdt_TableCol react-table-header-column max-w-fit flex items-center !font-medium text-[color:--shp-color-text-table-header] !h-[3.25rem] hover:!bg-[inherit] mr-3">
                {transFunction(selectionText, {
                  selected: selectedOrdersTotal,
                })}
              </th>
              <th className="max-w-fit !overflow-visible !px-3 flex items-center flex-nowrap overflow-x-scroll gap-6 !h-[3.25rem] hover:!bg-inherit">
                {bulkActions.map((action, i) => action)}
              </th>
            </div>
          )}
          {headerGroup.headers.map((header: Header<T, unknown>, i) => (
            <TableHeaderCell
              key={header.id}
              header={header}
              index={i}
              canResize={canResize}
              isResizing={isResizing}
              draggingCol={draggingCol}
              setDraggingCol={setDraggingCol}
              className={classnames({
                '!invisible !opacity-0': showBulkActions,
              })}
              {...props}
            />
          ))}
        </tr>
      ))}
    </thead>
  );
}

export default TableHeader;
