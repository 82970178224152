import { useCallback, useMemo } from 'react';

import { useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import {
  showSecondaryToastr,
  showErrorToastr,
} from '@shippypro/design-system-web/functions';

import { useUpdateOrdersMarketplaceMutation } from '@web/features/order/api';
import { orderActions } from '@web/features/order/slice';

import { handleAPIError } from '@web/utils/@reduxjs/rtk-query';

import { BaseAPIResult } from '@web/types/common/APIResults';

/**
 * Returns a `useUpdateOrdersMarketplaceMutation` RTK Query hook with its action field renamed.
 * This hook uses a series of passed order IDs to update their respective marketplaces counterparts.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const useUpdateOrdersMarketplace = () => {
  const { t } = useTranslation(),
    trans = translations.common,
    transUpdateMarketplace = translations.order.updateMarketplace,
    transErr = trans.errors.error;

  const dispatch = useDispatch();

  const [fetchMore, { data, error, ...results }] =
    useUpdateOrdersMarketplaceMutation();
  const updateOrdersMarketplace = useCallback(
    async (ids: number[], markeplacesCount: number) => {
      const apiPayload = {
        markeplacesCount,
        ids,
      };

      dispatch(orderActions.OrderUpdateMarketplaceUpdating({ ids }));
      const result = await fetchMore(apiPayload);
      dispatch(orderActions.OrderUpdateMarketplaceUpdated());

      const data = await handleAPIError<BaseAPIResult<boolean>>(result);

      if (!data) return;
      else if (!data?.error && !error) {
        showSecondaryToastr(
          t(transUpdateMarketplace.success, {
            count: 1,
          }),
        );
      } else if (data?.error) showErrorToastr(t(transErr), data?.error);

      return data;
    },
    [dispatch, error, fetchMore, t, transErr, transUpdateMarketplace.success],
  );

  return useMemo(
    () => ({
      updateOrdersMarketplace,
      ...results,
    }),
    [results, updateOrdersMarketplace],
  );
};
