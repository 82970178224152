import { Dispatch, SetStateAction, createContext } from 'react';

import { Order } from '@web/types/order';
import {
  EditOrdersToShipModalInfo,
  EditOrdersShippedModalInfo,
  ManageOrderModalInfo,
  ManageOrderModalView,
  ManualShipmentModalInfo,
  ShipOrdersModalInfo,
  FixOrdersModalInfo,
} from '../types/collection';

export interface IManageOrderModalExtContextProps<
  TModalInfo = ManageOrderModalInfo,
> {
  isOpen: boolean;

  info?: TModalInfo;
  view: keyof typeof ManageOrderModalView;
  modalLockdown: boolean;

  openManageOrderModal: <
    T extends
      keyof typeof ManageOrderModalView = ManageOrderModalView.ShipOrders,
  >(
    view: T,
    info: T extends ManageOrderModalView.ShipOrders
      ? ShipOrdersModalInfo
      : T extends ManageOrderModalView.ManualShipment
        ? ManualShipmentModalInfo
        : T extends ManageOrderModalView.EditOrdersToShip
          ? EditOrdersToShipModalInfo
          : T extends ManageOrderModalView.EditOrdersShipped
            ? EditOrdersShippedModalInfo
            : T extends ManageOrderModalView.FixOrders
              ? FixOrdersModalInfo
              : ManageOrderModalInfo,
    ordersRemovalCallback?: (ids: number[]) => void,
  ) => void;
  closeManageOrderModal: (forceClose?: boolean) => void;
  clearOrderFromModal: (order: Order) => void;
  setModalLockdown: Dispatch<SetStateAction<boolean>>;
}

/**
 * This is the "Manage Order" Modal Context that is instantiated at the near-top of the component tree.
 * This allows any feature to open the modal and manage a list of various orders in several different ways.
 * The context holds every info possible that the modal needs to function correctly:
 * * IF the modal is open or not;
 * * The info that the modal actually needs;
 * * The current flow the modal must follow;
 * * A Handy handler function to OPEN the modal;
 * * A Handy handler function to CLOSE the modal;
 *
 * @example
 * // `SHIP ORDERS` mode (you can check the other available "views" in the feature's `types/views.ts` file)
 * const openManageOrderModal = useOpenShipOrdersModal();
 * ...
 * <button onClick={() => openManageOrderModal(orders)}>Ship your orders!</button>
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const ManageOrderModalExtContext =
  createContext<IManageOrderModalExtContextProps | null>(null);

export default ManageOrderModalExtContext;
