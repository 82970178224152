import { FC } from 'react';
import classnames from 'classnames';

import { StyleType } from '@shippypro/design-system-web/enums';

import { IconHelper } from '@shippypro/design-system-web/iconhelper';
import { Button } from '@shippypro/design-system-web';

interface ISetupStepButtonProps {
  step: string;
  title: string;
  icon: string;
  isCompleted: boolean;
  onClick: () => void;
}

/**
 * The generic setup step button
 * It changes its state whether the step is completed or not
 *
 * @interface ISetupStepButtonProps
 * @author Fabio Mezzina <fabio.mezzina@shippypro.com>ƒ
 */
export const SetupStepButton: FC<ISetupStepButtonProps> = ({
  step,
  title,
  icon,
  isCompleted,
  onClick,
}) => {
  return (
    <Button
      onClick={onClick}
      styleType={StyleType.outline}
      className={classnames('flex items-center gap-[0.571rem] !opacity-100', {
        '!bg-transparent !text-white !border-none': isCompleted,
        '!bg-[color:--shp-color-bg-highlight] !text-[color:--shp-color-genericui-primary]':
          !isCompleted,
      })}
      data-test={`${step}-setup-step-btn`}
    >
      <IconHelper icon={isCompleted ? 'IconCheck' : icon} size={17} />
      {title}
    </Button>
  );
};
