import { FC, PropsWithChildren, useRef } from 'react';
import classnames from 'classnames';

import { UncontrolledTooltip } from '@ds-web/components/atoms/ui/tooltip/UncontrolledTooltip';

import { ITooltipWrapperByREFProps } from './types';

/**
 * Uncontrolled Tooltip shown without the need to pass an id (uses ref)
 *
 * @interface ITooltipWrapperByREFProps
 * @author Fabio Fognani <fabio.fognani@shippypro.com>
 */
export const TooltipWrapperByREF: FC<
  PropsWithChildren<ITooltipWrapperByREFProps>
> = ({
  children,
  disabled,
  content,
  fullWidth = true,
  containerProps,
  ...rest
}) => {
  const target = useRef<HTMLDivElement>(null);

  return (
    <>
      <div
        ref={target}
        {...containerProps}
        className={classnames(
          fullWidth ? 'w-full' : '!w-fit',
          containerProps?.className,
        )}
      >
        {children}
      </div>
      {!disabled && (
        <UncontrolledTooltip {...rest} target={target}>
          {content}
        </UncontrolledTooltip>
      )}
    </>
  );
};
