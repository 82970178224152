import { createSlice } from '@web/utils/@reduxjs/toolkit';

import { FeatureState } from './types';

// The initial state of the Documents feature
export const initialState: FeatureState = {};

const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {},
});

export const {
  actions: documentsActions,
  reducer,
  name: sliceKey,
} = documentsSlice;
