import { ButtonGroup as ReactstrapButtonGroup } from 'reactstrap';

import { IButtonGroupProps } from './types';

/**
 * A simple wrapper class for the `reactstrap: <ButtonGroup />` component.
 * It also adds the ability to change the separator type between symbols, arrows, slashes, etc...
 *
 * @interface IButtonGroupProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const ButtonGroup = ({ ...rest }: IButtonGroupProps) => (
  <ReactstrapButtonGroup {...rest} />
);
