import { useMemo } from 'react';

import { CustomRTKQueryOptions } from '@web/utils/@reduxjs/rtk-query';

import { useGetRefinerFieldsQuery } from '@web/features/_global/api';

/**
 * Returns RTK Query hook with renamed data field.
 * This Hook fetches every configured Refiner field from the User's account and
 * uses it to initialise & identify the proper Refiner session here on the App
 *
 * @param {CustomRTKQueryOptions} options subset of RTK Query hook options
 */
export const useGetRefinerFields = (options?: CustomRTKQueryOptions) => {
  const { data: getRefinerFields, ...rest } = useGetRefinerFieldsQuery(
    undefined,
    options,
  );

  return useMemo(
    () => ({
      fields: getRefinerFields,
      ...rest,
    }),
    [getRefinerFields, rest],
  );
};
