import axios from 'axios';
import * as jwt from 'jsonwebtoken';

import { requiredScopes } from '@web/features/_global/oauth';

export const isProductionEnv = (): boolean =>
  process.env.NODE_ENV === 'production';

export const isLocalEnv = (): boolean => process.env.NODE_ENV !== 'production';

export const isTestingEnv = (): boolean =>
  process.env.NODE_ENV !== 'production' && process.env.NX_ENV === 'testing';

export const isDevelopEnv = (): boolean =>
  process.env.NODE_ENV !== 'production' && process.env.NX_ENV === 'develop';

/* istanbul ignore next */
export function goToLegacy(
  route: string | null = null,
  isExternal: boolean = false,
) {
  const landingRoute = route ?? `index.html`;

  const target = isExternal ? '_blank' : '_self';

  window.open(`${process.env.NX_LEGACY_URL}/panel/${landingRoute}`, target);
}

// used a dedicated axios client to send the refresh token calls
// just to be sure it doesn't mess with the main client (i.e. 401 interceptor loop)
const refreshTokenClient = axios.create();

/* istanbul ignore next */
async function refreshAccessTokenLogic() {
  const refreshTokenUrl = [
    process.env.NX_LEGACY_URL!,
    process.env.NX_NEW_TOKEN_PAGE!,
  ].join('/');

  const refreshToken = localStorage.getItem('refreshToken');

  try {
    if (!refreshToken) {
      throw new Error('missing refreshToken');
    }

    const requestData = {
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
      client_id: localStorage.getItem('clientId'),
      client_secret: localStorage.getItem('clientSecret'),
      scope: requiredScopes,
    };

    const { data } = await refreshTokenClient.post(
      refreshTokenUrl,
      requestData,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );

    if (data.hasOwnProperty('error')) {
      throw Error(`${data.error_description} | ${data.hint}`);
    }
    if (data.token_type !== 'Bearer') {
      throw new Error(`Unhandled token type ${data.token_type}`);
    }
    if (!data.access_token) {
      throw new Error('Missing token in refreshToken response');
    }
    localStorage.setItem('accessToken', data.access_token);

    // Cache the JWT-encoded Access Token
    CacheItem('portal-token', 'jwt-token', data.access_token);
    localStorage.setItem('refreshToken', data.refresh_token);

    const jwtData = jwt.decode(data.access_token);
    // Set the decoded JWT data in the app cache
    CacheItem('portal-token', 'jwt', jwtData);

    // notifies App that the token has been refreshed
    // (since it's read from localStorage, it's not "reactive")
    document.dispatchEvent(new CustomEvent('refreshed-token'));

    return Promise.resolve(data.access_token);
  } catch (e) {
    console.error(e);
    const isNetworkError = /Network Error/.test((e as Error).toString());
    if (!isNetworkError && !isTestingEnv()) {
      goToLegacy();
    }
  }
}

let refreshTokenPromise: null | Promise<string>;
export function getOrCreateRefreshTokenPromise() {
  if (!refreshTokenPromise) {
    refreshTokenPromise = refreshAccessTokenLogic().then(newToken => {
      refreshTokenPromise = null;
      return newToken;
    });
  }
  return refreshTokenPromise;
}

/**
 * Fetches data from the JWT token, cached in the LocalStorage
 *
 * @param param The property within the JWT token
 * @returns When found, the property within JWT token
 */
const getCachedTokenData = (param: string) => {
  let token = CacheItem('portal-token'),
    shouldRefresh = false;

  // If a value has been stored in the cache, parse it
  if (token) {
    token = JSON.parse(token).jwt;
    // console.log(`JWT property decoded by getCachedTokenData:`);
    // console.log(token);
    // console.log(`Trying to access ${param}. Token value: ${token[param]}`);
    // Verify the existence of the specific token key, but first check the token
    // expiration
    if (token && token[param] && token.exp) {
      // ~~ is equivalent (**only** for positive numbers) to Math.floor, but a bit
      // faster
      const currentTime = ~~(Date.now() / 1000);
      // console.log(currentTime);
      // console.log(token.exp);
      if (currentTime < token.exp) {
        // console.log('TOKEN VALID - Called for param ' + param);
        // console.trace();
        return token[param];
      } else {
        console.log(
          'Token expired at ' + new Date(token.exp * 1000).toDateString(),
          // + ', redirecting user to login',
        );
        // Clean the cache before redirecting the User
        localStorage.removeItem('portal-token');
        shouldRefresh = true;
      }
    } else return '';
  }
  // If the token should be refreshed, call the proper function
  if (shouldRefresh) {
    getOrCreateRefreshTokenPromise();
  }
};

export const GetToken = () => {
  return getCachedTokenData('jti');
};

// The JWT encoding is actually a transformation of the entirety of the token data
export const GetJWTToken = () => {
  return CacheItem('portal-token', 'jwt-token');
};

export const GetUserId = () => {
  return getCachedTokenData('sub');
};

export const GetTokenExpiry = () => {
  return getCachedTokenData('exp');
};

export const CacheItem = (page: string, key?: string | null, value?: any) => {
  const hasValue = typeof value !== 'undefined';

  if (key === null || typeof key == 'undefined') {
    if (hasValue) {
      return localStorage.setItem(page, JSON.stringify(value));
    } else {
      return localStorage.getItem(page);
    }
  }

  const cacheString: string | null = localStorage.getItem(page);
  let cacheObj = JSON.parse(cacheString || '{}');
  if (!hasValue) return cacheObj[key] || null;

  cacheObj[key] = value;
  localStorage.setItem(page, JSON.stringify(cacheObj));
};

/* istanbul ignore next */
export const logout = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('portal-token');
  window.location.href = `${process.env.NX_LEGACY_URL}/login.html`;
};
