// ########################## [IMPORTANT LIBRARIES]
import { useMemo } from 'react';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [DESIGN SYSTEM]
import { TooltipWrapperByID } from '@shippypro/design-system-web';
import {
  toCurrencySymbol,
  toLocalizedPrice,
} from '@shippypro/design-system-web/functions';

// ########################## [COMMON COMPONENTS]
import OrderCODBadge from '@web/components/common-features/order/OrderCODBadge';

// ########################## [TYPES]
import { Order } from '@web/types/order';
import { RowDensity } from '@web/features/ship/types';

// ########################## [UTILS]
import { getOrderHasCOD } from '@web/hooks/orders/useGetOrderHasCOD';

// ########################## [HOOKS]
import useGetTabColumnSize from '@web/features/ship/hooks/tabs/useGetTabColumnSize';

const columnHelper = createColumnHelper<Order>();

export const AmountColumnCode = 'amount';

export const useGetAmountColumn = (
  rowDensity: RowDensity | null,
): ColumnDef<Order, string> => {
  const { t } = useTranslation(),
    transToShipTable = translations.ship.table,
    transToShip = transToShipTable.columns;

  const size = useGetTabColumnSize(AmountColumnCode, 100);

  return useMemo(
    () =>
      columnHelper.accessor('total_amount', {
        header: t(transToShip.amount),
        id: 'total_amount',
        minSize: 100,
        size,
        maxSize: 300,
        enableSorting: true,
        cell: ({ row: { original, getIsExpanded } }) =>
          amountColumnRendererFn(
            getIsExpanded() ? RowDensity.S : rowDensity,
            original,
          ),
      }),
    [t, transToShip, rowDensity, size],
  );
};

const amountColumnRendererFn = (
  rowDensity: RowDensity | null,
  order: Order,
) => {
  const { id, total_amount, currency, payment } = order;

  const target = `order-amount-${id}`;

  const hasCOD = getOrderHasCOD(order);

  return (
    <div className="text-left max-w-full">
      <p className="text-[--shp-color-text-tab-title] font-bold flex items-center !gap-2">
        {toLocalizedPrice(
          toCurrencySymbol(currency),
          !!total_amount ? +total_amount : 0,
        )}
        {hasCOD && <OrderCODBadge />}
      </p>
      {rowDensity !== RowDensity.S && (
        <TooltipWrapperByID
          tooltipProps={{
            placement: 'bottom',
          }}
          target={`${target}-paymentmethod`}
          text={payment.method ?? ''}
          checkOverflow
        >
          <p className="payment-method font-normal text-[color:--shp-color-text-table-header] truncate">
            {payment.method}
          </p>
        </TooltipWrapperByID>
      )}
    </div>
  );
};
