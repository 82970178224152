import { ColumnDef, Row, createColumnHelper } from '@tanstack/react-table';

import Selection from '../components/handlers/Selection';

export interface IGetSelectColumnConfigOptions<T extends {}> {
  id?: string;
  wantSelectAll?: boolean;
  ignoreRowClick?: boolean;
  checkboxName?: (row: Row<T>) => string;
  onToggleSelectSingleRow?: (e: MouseEvent, row: Row<T>) => void;
  onToggleSelectAllRows?: (e: MouseEvent) => void;
  selectedRowIds?: number[];
  rowIdName?: string;
  areAllChecked?: boolean;
  indeterminate?: boolean;
}

/**
 * This is a common column used to show a `Select (+All)` icon with its own logics.
 *
 * @param {?string} id The ID of the column you want to create.
 * @returns {Column<T>} A `Select` column with the correct typing of your dataset.
 */
export const getSelectColumnConfig = <T extends {}>({
  id = 'select-column',
  wantSelectAll = true,
  ignoreRowClick,
  checkboxName,
  onToggleSelectAllRows,
  onToggleSelectSingleRow,
  selectedRowIds,
  rowIdName = 'id',
  areAllChecked,
  indeterminate,
}: IGetSelectColumnConfigOptions<T> = {}): ColumnDef<T> => {
  const columnHelper = createColumnHelper<T>();
  return columnHelper.display({
    id,
    meta: {
      ignoreRowClick,
    },
    enableResizing: false,
    // The header can use the table's getToggleAllRowsSelectedProps method
    // to render a checkbox
    header: ({ table }) =>
      wantSelectAll ? (
        <Selection
          isSelectAll
          {...{
            checked: areAllChecked ?? table.getIsAllRowsSelected(),
            indeterminate: indeterminate ?? table.getIsSomeRowsSelected(),
            onChange: e => {
              table.getToggleAllRowsSelectedHandler()(e);

              if (onToggleSelectAllRows) onToggleSelectAllRows(e as MouseEvent);
            },
          }}
        />
      ) : null,
    // The cell can use the individual row's getToggleRowSelectedProps method
    // to the render a checkbox
    cell: ({ row }) => (
      <Selection
        name={checkboxName ? checkboxName(row) : undefined}
        {...{
          checked: selectedRowIds
            ? selectedRowIds.includes(row.original[rowIdName])
            : row.getIsSelected(),
          disabled: !row.getCanSelect(),
          indeterminate: row.getIsSomeSelected(),
          onChange: e => {
            row.getToggleSelectedHandler()(e);

            if (onToggleSelectSingleRow)
              onToggleSelectSingleRow(e as MouseEvent, row);
          },
        }}
      />
    ),
    size: 20,
  });
};
