import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import {
  DropdownItem,
  TooltipWrapperByREF,
} from '@shippypro/design-system-web';
import { IDropdownItemProps } from '@shippypro/design-system-web/types';
import { IconHelper } from '@shippypro/design-system-web/iconhelper';

import './CtxActionWithIcon.css';

interface ICtxActionWithIconProps {
  onClick: () => void;
  icon: string;
  label: string;
  danger?: boolean;
  code: string;
  itemProps?: Partial<Omit<IDropdownItemProps, 'onClick' | 'children'>>;
  translate?: boolean;
  tooltip?: string;
}

// TODO: move to design system + add documentation
const CtxActionWithIcon: FC<ICtxActionWithIconProps> = memo(
  ({
    onClick,
    icon,
    label,
    danger,
    code,
    itemProps,
    translate = true,
    tooltip,
  }) => {
    const { t } = useTranslation();

    return (
      <TooltipWrapperByREF disabled={!tooltip} content={tooltip}>
        <DropdownItem
          onClick={onClick}
          toggle={false}
          disabled={itemProps?.disabled}
        >
          <div className="flex justify-between space-x-4 w-full items-center">
            <span
              className={classnames('flex text-light-purpleish items-center', {
                'text-light-purpleish': !danger,
                'text-danger': danger,
              })}
              data-test={`${code}-action`}
            >
              <IconHelper icon={icon} size={16} className="mr-3" />
              {translate ? t(label) : label}
            </span>
          </div>
        </DropdownItem>
      </TooltipWrapperByREF>
    );
  },
);

export default CtxActionWithIcon;
