import { FC, memo, useContext } from 'react';

// ########################## [CONTEXT]
import ManageOrderModalExtContext from './contexts/ManageOrderModalExtContext';

// ########################## [TYPES]
import {
  EditOrdersShippedModalInfo,
  EditOrdersToShipModalInfo,
  ManageOrderModalView,
  ManualShipmentModalInfo,
  ShipOrdersModalInfo,
  FixOrdersModalInfo,
  ReturnShippedOrdersModalInfo,
  DuplicateShippedOrderModalInfo,
} from './types/collection';

// ########################## [PAGE COMPONENTS]
import LazyLoadingModal from './components/modal-blocks/LazyLoadingModal';

// ########################## [HOOKS]
import useGetLazyLoad, {
  useGetBasicLazyComponentOpts,
} from '@web/hooks/useGetLazyLoad';

const ROOT_PAGES_FOLDER = 'manage-order-modal/views';

/**
 * **FEATURE: "Manage Order" Modal**
 *
 * *"So you wanna ship yo'Orders, huh? You've come to tha right place 🗺️"*
 *
 * This feature is responsable from taking a number of orders (1 or more / none if we're doing a "Manual Shipment") and,
 * based on the view type in which it was summoned, it can manage said orders to ship, edit, review, fix, return, etc.
 *
 * Said feature will open-up a modal that will contain the list of the selected orders and
 * every tool needed to do whatever the user wanted to do with'em.
 *
 * N.B. It also has a `Page Differentiation capability`, meaning that you can pass different
 * designs to create different Paywall UI experiences!
 *
 * @link TODO: T.B.D. Tettra Doc link
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 * @author Fabio Mezzina <fabio.mezzina@shippypro.com>
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
export const ManageOrderModal: FC = memo(() => {
  const { view, info, ...rest } = useContext(ManageOrderModalExtContext)!;

  // Preparing a fallback in case the lazy routing takes too much time
  const lazyOpts = useGetBasicLazyComponentOpts(LazyLoadingModal);

  // ----- [LAZY-LOADED VIEWS]
  const ShipOrdersView = useGetLazyLoad(
    `${ROOT_PAGES_FOLDER}/ship-orders`,
    'ShipOrdersModal',
    lazyOpts,
  );

  const ManualShipmentView = useGetLazyLoad(
    `${ROOT_PAGES_FOLDER}/manual-shipment`,
    'ManualShipmentModal',
    lazyOpts,
  );

  const EditOrdersToShipView = useGetLazyLoad(
    `${ROOT_PAGES_FOLDER}/edit-orders-toship`,
    'EditOrdersToShipModal',
    lazyOpts,
  );

  const EditOrdersShippedView = useGetLazyLoad(
    `${ROOT_PAGES_FOLDER}/edit-orders-shipped`,
    'EditOrdersShippedModal',
    lazyOpts,
  );

  const FixOrdersView = useGetLazyLoad(
    `${ROOT_PAGES_FOLDER}/fix-orders`,
    'FixOrdersModal',
    lazyOpts,
  );

  const ReturnShippedOrdersView = useGetLazyLoad(
    `${ROOT_PAGES_FOLDER}/return-shipped-orders`,
    'ReturnShippedOrdersModal',
    lazyOpts,
  );

  const DuplicateShippedOrderView = useGetLazyLoad(
    `${ROOT_PAGES_FOLDER}/duplicate-shipped-order`,
    'DuplicateShippedOrderModal',
    lazyOpts,
  );

  // ----- [CALCULATING WHICH VIEW TO SHOW]
  if (!info?.loadedOrdersList) return <div />;
  let page = <div />;
  switch (view) {
    case ManageOrderModalView.ShipOrders:
      page = <ShipOrdersView info={info as ShipOrdersModalInfo} {...rest} />;
      break;
    case ManageOrderModalView.ManualShipment:
      page = (
        <ManualShipmentView info={info as ManualShipmentModalInfo} {...rest} />
      );
      break;

    case ManageOrderModalView.EditOrdersToShip:
      page = (
        <EditOrdersToShipView
          info={info as EditOrdersToShipModalInfo}
          {...rest}
        />
      );
      break;
    case ManageOrderModalView.EditOrdersShipped:
      page = (
        <EditOrdersShippedView
          info={info as EditOrdersShippedModalInfo}
          {...rest}
        />
      );
      break;
    case ManageOrderModalView.FixOrders:
      page = <FixOrdersView info={info as FixOrdersModalInfo} {...rest} />;
      break;
    case ManageOrderModalView.ReturnShippedOrders:
      page = (
        <ReturnShippedOrdersView
          info={info as ReturnShippedOrdersModalInfo}
          {...rest}
        />
      );
      break;
    case ManageOrderModalView.DuplicateShippedOrder:
      page = (
        <DuplicateShippedOrderView
          info={info as DuplicateShippedOrderModalInfo}
          {...rest}
        />
      );
      break;
    /* istanbul ignore next */
    default:
      // No view found! That's weird... 🤔
      break;
  }

  return page;
});

ManageOrderModal.displayName = 'ManageOrderModal_memoized';
