import { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import {
  showSecondaryToastr,
  showWarningToastr,
} from '@shippypro/design-system-web/functions';

import { useDispatch } from 'react-redux';
import { shipActions } from '@web/features/ship/slice';
import { usePrintOrdersMutation } from '@web/features/ship/api';
import {
  PrintOrdersMutationResult,
  PrintOrdersBaseMutationPayload,
} from '@web/features/ship/types';

import { handleAPIError } from '@web/utils/@reduxjs/rtk-query';
import { downloadBase64File } from '@web/utils/functions';

/**
 * Returns a `usePrintOrdersMutation` RTK Query hook with its action field renamed.
 * This hook creates a list of N .pdf files for every selected order in the 'Shipped' table.
 * Depending on the result of the API call, the user will be notified and, when everything
 * has completed successfully, will be able to download the file.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const usePrintOrders = () => {
  const { t } = useTranslation(),
    trans = translations.common,
    transLabels = translations.ship.buttons.print;

  const [fetchMore, { ...results }] = usePrintOrdersMutation();

  const dispatch = useDispatch();

  const printOrders = useCallback(
    async (payload: PrintOrdersBaseMutationPayload) => {
      dispatch(shipActions.OrderPrinting());
      const result = await fetchMore({
        // Defaulting the format to -1 IF not passed to avoid PHP ISSET errors
        glsformat: -1,
        ...payload,
      });
      const data = await handleAPIError<PrintOrdersMutationResult>(result);

      dispatch(shipActions.OrderPrinted());
      if (!data) return false;

      if (data.base64File) {
        downloadBase64File(data.base64File);
        showSecondaryToastr(
          t(transLabels.printedSuccessfully, { count: payload.ids.length }),
        );

        return true;
      } else {
        showWarningToastr(t(trans.errors.error), t(trans.errors.noContent));

        return false;
      }
    },
    [
      dispatch,
      fetchMore,
      t,
      trans.errors.error,
      trans.errors.noContent,
      transLabels.printedSuccessfully,
    ],
  );

  return useMemo(
    () => ({
      printOrders,
      ...results,
    }),
    [printOrders, results],
  );
};
