import { useState } from 'react';
import { Col } from 'reactstrap';

import { translations } from '@shippypro/translations';
import { useTranslation } from 'react-i18next';

import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
  TooltipWrapperByID,
} from '@shippypro/design-system-web';
import { IconHelper } from '@shippypro/design-system-web/iconhelper';
import { StyleType } from '@shippypro/design-system-web/enums';
import {
  NestedDropdownItem,
  NestedDropdown,
} from '@shippypro/design-system-web';

import ChangelogNavButton from '@web/features/changelog-modal/components/NavButton';

import { INavbarElementsProps } from './types';

export interface INavbarHelpProps extends INavbarElementsProps {
  email: string;
  iconSize: number;
}

const NavbarHelp: React.FC<INavbarHelpProps> = ({ iconSize, email }) => {
  const { t } = useTranslation();
  const [ticketHelpMenuIsOpen, setTicketHelpMenuIsOpen] = useState(false);

  const ticketsSubMenuRoutes = [
    {
      to: `https://support.shippypro.com/tickets-view/new?email=${email}`,
      title: t(translations.nav.help.newTicket),
      icon: 'PlusSquare',
    },
    {
      to: 'https://support.shippypro.com/tickets-view?offset=0',
      title: t(translations.nav.help.ticketsList),
      icon: 'List',
    },
  ];

  return (
    <UncontrolledButtonDropdown
      tag="li"
      className="nav-item dropdown ml-1"
      // BLOCKED THE OLD DROPDOWN AND JUST REDIRECTED TO THE NEW TICKET PAGE
      isOpen={false}
      onClick={
        /* istanbul ignore next */
        e => {
          e.preventDefault();
          e.stopPropagation();

          window.open(
            `https://support.shippypro.com/tickets-view/new?email=${encodeURIComponent(
              email,
            )}`,
            '_blank',
            'noopener noreferrer',
          );
        }
      }
    >
      <TooltipWrapperByID
        target="nav-get-help"
        text={t(translations.common.tooltips.getHelp)}
      >
        <DropdownToggle
          tag="a"
          styleType={StyleType.filled}
          data-toggle="dropdown"
          id="header-help-button"
          className="nav-link dropdown-help-link cinitial m-auto"
        >
          <div className="flex">
            <div className="fonticon-wrap inline-flex !gap-1 flex-nowrap justify-center items-center">
              <IconHelper icon="IconHelpCircle" size={iconSize} />
              <span className="leading-normal">
                {t(translations.nav.help.help)}
              </span>
            </div>
          </div>
        </DropdownToggle>
      </TooltipWrapperByID>
      <DropdownMenu end>
        {/* Help center */}
        <DropdownItem
          tag="a"
          href="https://help.shippypro.com/en/collections/1895594-get-started-with-shippypro"
          target="_blank"
        >
          <Col className="fonticon-container flex items-center">
            <div className="fonticon-wrap">
              <IconHelper icon="IconBook" size={13} />
            </div>
            <label className="fonticon-classname">
              {t(translations.nav.help.helpCenter)}
            </label>
          </Col>
        </DropdownItem>
        {/* Support */}
        <div data-test="menu-item-support">
          <NestedDropdown
            isOpen={ticketHelpMenuIsOpen}
            mouseLeave={
              /* istanbul ignore next */
              () => setTicketHelpMenuIsOpen(false)
            }
            xDirection="right"
            click={
              /* istanbul ignore next */
              () => setTicketHelpMenuIsOpen(prev => !prev)
            }
            mouseEnter={
              /* istanbul ignore next */
              () => setTicketHelpMenuIsOpen(true)
            }
            title={t(translations.nav.help.tickets)}
            icon="IconLifebuoy"
          >
            {ticketsSubMenuRoutes.map((route, key) => (
              <NestedDropdownItem
                key={key}
                to={route.to}
                title={route.title}
                icon={route.icon}
              />
            ))}
          </NestedDropdown>
        </div>
        <DropdownItem divider />
        {/* What's new */}
        <ChangelogNavButton />
        {/* Suggest a feature */}
        <DropdownItem
          tag="a"
          href="https://suggest.shippypro.com"
          target="_blank"
        >
          <Col className="fonticon-container flex items-center">
            <div className="fonticon-wrap">
              <IconHelper icon="IconMessageCircle" size={13} />
            </div>
            <label className="fonticon-classname">
              {t(translations.nav.help.suggestFeature)}
            </label>
          </Col>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};

export default NavbarHelp;
