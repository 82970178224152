import { useMemo } from 'react';
import { useGetCountriesQuery } from '@web/features/_global/api';
import { CustomRTKQueryOptions } from '@web/utils/@reduxjs/rtk-query';

/**
 * Returns RTK Query hook with renamed data field.
 * @author Fabio Fognani <fabio.fognani@shippypro.com>
 *
 * @param {CustomRTKQueryOptions} options subset of RTK Query hook options
 */
export const useGetCountries = (options?: CustomRTKQueryOptions) => {
  const { data: countries, ...rest } = useGetCountriesQuery(undefined, options);

  return useMemo(
    () => ({
      countries,
      ...rest,
    }),
    [countries, rest],
  );
};
