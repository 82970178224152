import { FC, memo, useContext } from 'react';

import { Pace } from '@shippypro/design-system-web';

import TopProgressBarContext from './contexts/TopProgressBarContext';

/**
 * The top progress bar is a component that shows a progress bar on the top of the page,
 * that grows in width as the page loads, using a "fake loading" function.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const TopProgressBar: FC = memo(props => {
  const { toggle, complete } = useContext(TopProgressBarContext)!;

  return <Pace toggle={toggle} complete={complete} />;
});
