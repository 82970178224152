import { CSSProperties, useState } from 'react';
import classnames from 'classnames';

import { Row } from '@tanstack/react-table';

import { TooltipWrapperByID } from '@ds-web/components/molecules/ui/tooltips/tooltip-wrapper-byid/TooltipWrapperByID';

import { IReactTableProps } from '@ds-web/components/organisms/tables/react-table/types';

export type TableRowProps<T extends object> = {
  row: Row<T>;
  tableProps: IReactTableProps<T>;
  rowStyle: CSSProperties;
};

/**
 * This component is the UI representation of a `react-table` marks container.
 * It displays a small block at the start of a row that has different "sections" (or marks) that can be hovered to show a tooltip.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 *
 * @returns {FC} The `React Table` row mark, fully rendered & managed.
 */
function TableMarks<T extends object>({ row, tableProps }: TableRowProps<T>) {
  const { customComponents } = tableProps;

  const [activeMark, setActiveMark] = useState<string | undefined>(undefined);

  return (
    (customComponents
      ?.getCustomMarks?.(row)
      .filter(m => m.customMarkProps?.active).length ?? 0) > 0 && (
      <div
        className={classnames(
          'react-table-body-row_mark-container',
          'w-[10px] h-full absolute top-0 left-0',
          'flex flex-col',
        )}
      >
        {customComponents
          ?.getCustomMarks?.(row)
          .filter(mark => mark.customMarkProps?.active)
          .map((mark, index) => (
            <TooltipWrapperByID
              key={index}
              active={
                Boolean(mark.customMarkProps?.id) &&
                Boolean(mark.customMarkProps?.tooltip)
              }
              target={mark.customMarkProps?.id ?? ''}
              text={mark.customMarkProps?.tooltip}
              tooltipProps={{
                placement: 'right',
                className: '[&>.bs-tooltip-auto]:!ml-[5px]',
              }}
            >
              <div
                className={classnames(
                  'transition-all ease-out duration-100',
                  'react-table-body-row_mark',
                  'w-[10px] h-full',
                  {
                    grow: activeMark === mark.customMarkProps?.id,
                    'shrink !h-2':
                      activeMark !== undefined &&
                      activeMark !== mark.customMarkProps?.id,
                  },
                  mark?.customMarkClassName,
                )}
                onMouseOver={() => setActiveMark(mark.customMarkProps?.id)}
                onMouseLeave={() => setActiveMark(undefined)}
                {...(customComponents?.getCustomTrProps &&
                  mark.customMarkProps)}
              ></div>
            </TooltipWrapperByID>
          ))}
      </div>
    )
  );
}

export default TableMarks;
