// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@web/utils/@reduxjs/toolkit';
import { FeatureState } from './types';

// The initial state of the AnalyticsDashboards feature
export const initialState: FeatureState = {};

const analyticsDashboardsSlice = createSlice({
  name: 'analyticsDashboards',
  initialState,
  reducers: {},
});

export const {
  actions: analyticsDashboardsActions,
  reducer,
  name: sliceKey,
} = analyticsDashboardsSlice;
