import { FC } from 'react';
import { ModalFooter as ReactstrapModalFooter } from 'reactstrap';

import { IModalFooterProps } from './types';

/**
 * A simple wrapper class for the `reactstrap: <ModalFooter />` component.
 *
 * @interface IModalFooterProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const ModalFooter: FC<IModalFooterProps> = props => (
  <ReactstrapModalFooter {...props} />
);
