// ########################## [IMPORTANT LIB]
import { FC, memo } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

// ########################## [DESIGN SYSTEM]
import {
  Badge,
  CopyButton,
  GenericHoverableItem,
  TooltipWrapperByID,
} from '@shippypro/design-system-web';
import { Color } from '@shippypro/design-system-web/enums';

interface ILinkWithBadgeProps {
  text: string;
  className?: string;
  url: string;
  badge?: string | number | null;
  badgeTooltip?: string;
  badgeTarget?: string;
  badgeClassName?: string;
  textTooltip?: string;
  textTarget?: string;
  color?: Color;
  invertTextColor?: boolean;
  allowEvents?: boolean;
}

// TODO: move to design system + add documentation
const LinkWithBadge: FC<ILinkWithBadgeProps> = memo(
  ({
    text,
    className,
    url,
    badge,
    badgeTooltip,
    badgeTarget = '',
    badgeClassName = '',
    textTarget = '',
    textTooltip = '',
    color = Color.dark,
    invertTextColor = false,
    allowEvents = false,
  }) => {
    return (
      <div className="flex items-center link-with-badge truncate">
        <GenericHoverableItem reveals={<CopyButton value={text} />}>
          <TooltipWrapperByID
            target={textTarget}
            text={textTooltip}
            checkOverflow
          >
            <Link
              to={url}
              target="_blank"
              className={classnames(
                'text-truncate max-w-full min-w-[40px] text-[1.125rem] font-bold !font-order_id mb-0 cursor-pointer hover:underline',
                {
                  'text-[--shp-color-text-tab-title]': !invertTextColor,
                  'text-secondary': invertTextColor,
                },
                className,
              )}
            >
              {text}
            </Link>
          </TooltipWrapperByID>
        </GenericHoverableItem>
        {badge && (
          <TooltipWrapperByID
            tooltipProps={{
              popperClassName: 'min-w-[300px]',
            }}
            target={badgeTarget}
            text={badgeTooltip}
            active={!!badgeTooltip}
          >
            <Badge
              className={classnames(
                'ml-2 font-medium text-[12px] w-[20px] h-[20px] flex justify-center items-center cursor-default',
                badgeClassName,
              )}
              color={color}
              data-tag={allowEvents && 'allowRowEvents'}
              id={badgeTarget}
              round
            >
              {badge}
            </Badge>
          </TooltipWrapperByID>
        )}
      </div>
    );
  },
);

export default LinkWithBadge;
