import { FC, Fragment } from 'react';
import { Navbar } from 'reactstrap';

import { UncontrolledTooltip } from '@ds-web/components/atoms/ui/tooltip/UncontrolledTooltip';

import NavmenuDropdown from './NavmenuDropdown';
import NavmenuItem from './NavmenuItem';

import useArrangeMenu from './hooks/useArrangeMenu';

import '@ds-web/assets/scss/base/components/navmenu.scss';

import { INavmenuProps } from './types';

/**
 * This Component renders a Controlled Horizontal Navigation Menu, that
 * holds a dynamic list of menu items and sub-items, based on the given
 * menu configuration.
 *
 * @link https://app.tettra.co/teams/shippypro/pages/navmenu-navbar
 *
 * @interface INavmenuProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const Navmenu: FC<INavmenuProps> = ({
  config,
  activeTab,
  tabChangeHandler,
  arrangeMenuOptions,
}) => {
  const hiddenItems = useArrangeMenu(config, activeTab, arrangeMenuOptions);

  return (
    <div className="horizontal-menu-wrapper">
      <Navbar
        tag="div"
        expand="sm"
        className="navmenu header-navbar navbar-horizontal navbar-expand-sm"
      >
        <div className="navbar-container main-menu-content">
          <ul className="nav navbar-nav navmenu-nav">
            {config.map(item => {
              const Item =
                item.collector || item.children?.length
                  ? NavmenuDropdown
                  : NavmenuItem;

              return (
                <Fragment key={item.id}>
                  <Item
                    item={item}
                    activeTab={activeTab}
                    tabChangeHandler={tabChangeHandler}
                    clickHandler={item.children ? undefined : item.clickHandler}
                    hiddenItems={hiddenItems}
                  />
                  {item.tooltipTxt && (
                    <UncontrolledTooltip target={`nav-item-${item.id}`}>
                      {item.tooltipTxt}
                    </UncontrolledTooltip>
                  )}
                </Fragment>
              );
            })}
          </ul>
        </div>
      </Navbar>
    </div>
  );
};
