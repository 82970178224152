import styled from 'styled-components';

import { Modal } from '@shippypro/design-system-web';

/**
 * **COMPONENT: Modal Container**
 *
 * Just stylin'&pimpin'.
 * What where you expecting?
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const StyledChangelogModal = styled(Modal)`
  img {
    margin-bottom: 1.25rem;
  }
  p {
    margin-bottom: 1rem;
  }
  a {
    text-decoration: underline;
  }

  .infinite-scroll-component {
    overflow-y: hidden !important;
  }

  .post-body {
    margin-bottom: 1.25rem;
  }

  .modal-footer {
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);

    .footer-link {
      text-decoration: none;
    }
  }
`;
