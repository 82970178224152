import { format } from 'date-fns';

/**
 * **[UTILITY]** Formats an "ISO Date" string to the following format: `dd/MM/yyyy, hh:mm:ss`.
 * If no date is passed, the current date is used.
 *
 * @param {string | Date} [date=new Date()] The date to format.
 * @returns {string} The formatted date.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const formatAlertDate = (date: string | Date = new Date()) =>
  format(new Date(date ?? new Date()), 'dd/MM/yyyy, hh:mm:ss');
