export const SetTabUrl = (
  hasActivePlan: boolean,
  tabList: string[],
  tab?: number,
  givenHash?: string,
): number => {
  const hash = givenHash ?? window.location.hash;
  const hashTab = tabList.indexOf(hash.substring(1));
  const activeTab = hasActivePlan ? tab ?? (hashTab > -1 ? hashTab : 0) : 1;

  window.history.replaceState(
    { activeTab: tabList[activeTab] },
    tabList[activeTab],
    window.location.pathname + '#' + tabList[activeTab],
  );

  window.dispatchEvent(new Event('locationchange'));

  return activeTab;
};
