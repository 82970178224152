// ########################## [IMPORTANT LIBRARIES]
import { useCallback, useContext } from 'react';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [DESIGN SYSTEM]
import {
  TooltipWrapperByREF,
  CopyButton,
  GenericHoverableItem,
} from '@shippypro/design-system-web';
import { IconHelper } from '@shippypro/design-system-web/iconhelper';

// ########################## [TYPES]
import { Order } from '@web/types/order';

// ########################## [CONTEXTS]
import TrackingDialogContext from '@web/features/tracking-dialog/contexts/TrackingDialogContext';

interface ITrackingCodeText {
  order: Order;
  trackingCode?: string | null;
  trackingLabel?: string | null;
  trackingStatus?: string | null;
  showTrackingStatus?: boolean;
}

/**
 * This badge:
 * - shows the tracking code when it is available
 * - shows 'pending' when the tracking code isn't available yet
 * If the tracking code is available, it will open the Tracking modal on click
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 *
 * @interface ITrackingCodeText
 */
const TrackingCodeText: React.FC<ITrackingCodeText> = ({
  order,
  trackingCode,
  trackingLabel,
  trackingStatus,
  showTrackingStatus = true,
}) => {
  const { t } = useTranslation(),
    transOrderShipmentInfo = translations.order.shipmentInfo;

  const trackingCodeLengthLimit = 14;
  const cutTrackingLenghtLimit = useCallback((tracking: string) => {
    if (tracking && tracking.length > trackingCodeLengthLimit) {
      return tracking.slice(0, trackingCodeLengthLimit) + '...';
    }
    return tracking;
  }, []);

  // Bootstrapping the `TrackingDialog` utility to show every tracking detail
  const { openTrackingDialog } = useContext(TrackingDialogContext)!;

  return trackingCode ? (
    <span className="flex flex-col text-truncate">
      <GenericHoverableItem reveals={<CopyButton value={trackingCode} />}>
        <TooltipWrapperByREF
          content={trackingCode}
          fullWidth={false}
          disabled={trackingCode.length <= 50}
          containerProps={{
            className: 'text-truncate',
          }}
        >
          <span
            className="font-bold hover:underline hover:cursor-pointer tracking-code text-[color:--shp-color-genericui-grey]"
            onClick={e => {
              e.stopPropagation();
              openTrackingDialog(order.id);
            }}
          >
            {cutTrackingLenghtLimit(trackingCode)}
          </span>
        </TooltipWrapperByREF>
      </GenericHoverableItem>
      {showTrackingStatus && trackingStatus ? (
        <TooltipWrapperByREF
          content={trackingStatus}
          fullWidth={false}
          disabled={trackingStatus.length <= trackingCodeLengthLimit}
        >
          <span className="flex items-center pl-2 tracking-status cursor-default">
            {trackingLabel ? (
              <IconHelper icon={trackingLabel} size={16} />
            ) : null}
            <span className="ml-2">
              {cutTrackingLenghtLimit(trackingStatus)}
            </span>
          </span>
        </TooltipWrapperByREF>
      ) : (
        ''
      )}
    </span>
  ) : (
    <TooltipWrapperByREF
      fullWidth={false}
      content={t(transOrderShipmentInfo.trackingCodePendingTooltip)}
    >
      <span className="text-subdued !px-2 tracking-code">
        {order.carrier_name !== 'Generic'
          ? t(transOrderShipmentInfo.trackingCodeNotAvailableYet)
          : t(transOrderShipmentInfo.trackingCodeNotAvailable)}
      </span>
    </TooltipWrapperByREF>
  );
};

export default TrackingCodeText;
