import { TFunction } from 'i18next';
import { translations } from '@shippypro/translations';
import { useTranslation } from 'react-i18next';

/**
 * [HOOK] function that gets a carrier_closeout code (0, 1) and returns
 * a label with the correct translation.
 *
 * @author Fabio Fognani <fabio.fognani@shippypro.com>
 *
 * @param carrier_closeout {string} the code gotten from the API
 *
 * @returns {string} The correct label for the code
 */
const useGetCarrierCloseoutLabel = (code?: string | null): string => {
  const { t } = useTranslation();
  return getCarrierCloseoutLabel(t, code);
};

export const getCarrierCloseoutLabel = (
  t: TFunction,
  code?: string | null,
): string => {
  const transCloseout = translations.order.carrierCloseout;

  switch (code) {
    case '1':
      return t(transCloseout.transmitted);
    default:
      return t(transCloseout.notTransmitted);
  }
};

export default useGetCarrierCloseoutLabel;
