// ########################## [IMPORTANT LIBRARIES]
import { FC, cloneElement, memo } from 'react';

// ########################## [DESIGN SYSTEM]
import { TabContent, TabPane } from '@ds-web/components';

// ########################## [TYPES]
import { IPageWithTabs } from '@ds-web/components/templates/with-tabs/types';

interface IBodyContentProps
  extends Omit<IPageWithTabs, 'toggle' | 'headingTitle'> {}

/**
 * [LAYOUT CARD WITH TOP TABS - BODY CONTENT] This component is used to render the tabs actual content inside of the layout card.
 * N.B. The content shown is based on the currently active tab and NO OTHER page is pre-rendered and hidden in the parsed HTML code.
 *
 * @interface IBodyContentProps
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const BodyContent: FC<IBodyContentProps> = memo(
  ({ activeTabIndex, tabs, loadInvisibleTabs }) => {
    return (
      <section className="wrapper-tab-body-content h-full">
        {/* TAB CONTENT MANAGEMENT */}
        {/* only load the tab content that is actually visible */}
        <TabContent
          activeTab={activeTabIndex}
          className="card-top-tabs-body-contents h-full"
        >
          {tabs.map(({ mainContent: { render, additionalProps } }, i) => {
            return loadInvisibleTabs || activeTabIndex === i ? (
              <TabPane tabId={i} key={i} className="h-[inherit]">
                {cloneElement(render, { ...additionalProps })}
              </TabPane>
            ) : null;
          })}
        </TabContent>
      </section>
    );
  },
);

BodyContent.displayName = 'BodyContent_memoized';

export default BodyContent;
