import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import { TooltipWrapperByID } from '@shippypro/design-system-web';
import { IconHelper } from '@shippypro/design-system-web/iconhelper';
import { copyToClipboard } from '@shippypro/design-system-web/functions';

import CardView from '@web/components/common-ui/wrappers/card/CardView';

import { IOrderInfoBlock } from './types';

interface ICustomer extends IOrderInfoBlock {}

const Customer: React.FC<ICustomer> = ({ address }) => {
  const { t } = useTranslation(),
    transCommon = translations.common,
    transOrder = translations.order.info,
    transShipTooltips = translations.ship.tooltips;

  const isAddressEmpty = useMemo(
    () => !address || Object.values(address).every(value => !value),
    [address],
  );

  const fullAddressStreet = [address?.street1, address?.street2]
    .filter(Boolean)
    .join(' ');

  return (
    <CardView
      flex={false}
      header={t(transCommon.customer).toUpperCase()}
      name="customer"
    >
      {isAddressEmpty ? (
        <p className="text-muted text-center">
          {t(transOrder.customerInfoMissing)}
        </p>
      ) : (
        <>
          <div
            className="flex justify-between"
            data-test="order-detail-customer-info"
          >
            <p className="font-bold text-primary address-name">
              {address?.name || (
                <span className="text-muted">{t(transOrder.nameMissing)}</span>
              )}
            </p>
            <span className="text-right">
              <p className="address-email">
                {address?.email || (
                  <span className="text-muted">
                    {t(transOrder.emailMissing)}
                  </span>
                )}
              </p>
              <p className="address-phone">
                {address?.phone || (
                  <span className="text-muted">
                    {t(transOrder.phoneMissing)}
                  </span>
                )}
              </p>
            </span>
          </div>
          <hr />
          <div data-test="order-detail-customer-address">
            {!address?.street1 && !address?.city ? (
              <p className="text-muted">
                {t(transOrder.customerAddressMissing)}
              </p>
            ) : (
              <>
                <span className="flex justify-between text-right !mb-5">
                  <p className="font-bold">{t(transOrder.shippingAddress)}</p>
                  <TooltipWrapperByID
                    tooltipProps={{
                      placement: 'bottom',
                    }}
                    target="address-copy"
                    text={t(transShipTooltips.copyToClipboard)}
                  >
                    <IconHelper
                      icon="IconCopy"
                      size={17}
                      className="cursor-pointer"
                      onClick={() => copyToClipboard(address?.street1 ?? '')}
                    />
                  </TooltipWrapperByID>
                </span>
                <p className="address-street-city">
                  {fullAddressStreet || (
                    <span className="text-muted">
                      {t(transOrder.streetMissing)}
                    </span>
                  )}{' '}
                  {address?.city || (
                    <span className="text-muted">
                      {t(transOrder.cityMissing)}
                    </span>
                  )}
                </p>
              </>
            )}

            <p className="address-zip-state">
              {address?.zip}{' '}
              {address?.state || (
                <span className="text-muted">{t(transOrder.stateMissing)}</span>
              )}
            </p>
            <p className="address-country">
              {typeof address?.country === 'string' ? (
                address?.country
              ) : (
                <span className="text-muted">
                  {t(transOrder.countryMissing)}
                </span>
              )}
            </p>
          </div>
        </>
      )}
    </CardView>
  );
};

export default Customer;
