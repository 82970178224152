// ########################## [IMPORTANT LIB]
import { Fragment, memo } from 'react';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [DESIGN SYSTEM]
import { Modal, ModalBody, ModalHeader } from '@shippypro/design-system-web';
import { FullSize } from '@shippypro/design-system-web/enums';

// ########################## [PAGE COMPONENTS]
import { OrderItemListItem } from './OrderItemListItem';

// ########################## [HOOKS]
import { Item } from '@web/types/order';

interface IProductsModalProps {
  isOpen: boolean;
  onClose: () => void;
  items: Item[] | null;
  currency: string;
}

/**
 * The modal to show a list of an order's products
 *
 * @interface IProductsModalProps
 * @author [Fabio Mezzina] <fabio.mezzina@shippypro.com>
 */
export const ProductsModal: React.FC<IProductsModalProps> = memo(
  ({ isOpen, onClose, items, currency }) => {
    const { t } = useTranslation(),
      transCommon = translations.common;

    return (
      <Modal
        className="modal-dialog-centered modal-wrapper"
        size={FullSize.lg}
        isOpen={isOpen}
        data-test="products-modal"
        backdrop={true}
        toggle={onClose}
      >
        <ModalHeader toggle={() => onClose()}>
          <span className="text-purpleish text-[16px] font-semibold">
            {t(transCommon.productsCount, { count: items?.length })}
          </span>
        </ModalHeader>
        <ModalBody className="flex flex-col overflow-auto max-h-[70vh]">
          {/* Items list */}
          {items?.map((item, i) => (
            <Fragment key={i}>
              <OrderItemListItem
                item={item}
                key={item.id}
                currency={currency}
              />
              {i < items.length - 1 && <hr className="mt-0" />}
            </Fragment>
          ))}
        </ModalBody>
      </Modal>
    );
  },
);
