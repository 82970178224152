// ########################## [IMPORTANT LIBRARIES]
import { useMemo } from 'react';
import { useLocalStorage } from 'react-use';

// ########################## [STATE MANAGEMENT]
import { parseAPIError } from '@web/utils/@reduxjs/rtk-query';

// ########################## [HOOKS]
import { useLazyGetSearchResultsQuery } from '@web/features/global-search/api';
import useSubmitTrackingEvent from '@web/hooks/useSubmitTrackingEvent';

/**
 * Returns RTK Query hook with renamed data field.
 * Returns the user' requested global search results.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 *
 * @param {CustomRTKQueryOptions} options subset of RTK Query hook options
 */
export const useGetGlobalSearch = () => {
  const [fetch, { data: globalSearch, error, ...rest }] =
    useLazyGetSearchResultsQuery();

  const trackEvent = useSubmitTrackingEvent(['survey']);

  const [searchPerformedTimes, setSarchPerformedTimes] =
    useLocalStorage<number>('global-search-performed-times', 0);

  return useMemo(
    () => ({
      onFetch: (query: string) => {
        fetch({ query }, true);
        setSarchPerformedTimes(() => {
          const curr = (searchPerformedTimes ?? 0) + 1;
          if (curr === 2) trackEvent('global_search_performed_twice');

          return curr;
        });
      },
      globalSearch,
      error: parseAPIError(error),
      ...rest,
    }),
    [
      globalSearch,
      error,
      rest,
      fetch,
      setSarchPerformedTimes,
      searchPerformedTimes,
      trackEvent,
    ],
  );
};
