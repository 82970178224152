import { FC } from 'react';
import styled from 'styled-components';

/**
 * This component displays a blurry div.
 *
 * @interface IBlurredElementProps
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
export const BlurredElement: FC = () => <StyledBlurredElement />;

export const StyledBlurredElement = styled.div`
  width: 100% !important;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 500px;
  height: 35px;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    #fff 50%
  );
  border-radius: 0px 0px var(--bs-card-border-radius)
    var(--bs-card-border-radius);
`;
