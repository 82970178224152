import styled from 'styled-components';

import { Modal } from '@shippypro/design-system-web';

import ModalBaseStyles from '../styles';

/**
 * This stylising wrapper customises the Paywall modal with some handy specifics
 * based on the Zeplin UI mocks: https://zpl.io/jZX0WAp
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const StyledPaywallModal = styled(Modal)<{
  $gradientColor?: string;
}>`
  ${ModalBaseStyles}

  // Displaying the paywall image at the center of a gray gradient
  #paywall-img {
    background: radial-gradient(
      50% 50% at 50% 50%,
      ${props => props.$gradientColor} 0%,
      #011627 100%
    );

    height: 100%;
    min-height: 200px;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: auto;
    margin-bottom: auto;

    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;

    img {
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
    }
  }
`;

export default StyledPaywallModal;
