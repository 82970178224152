import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@web/utils/@reduxjs/toolkit';

import {
  FeatureState,
  InvoicePackingSlipType,
  PickingListType,
  IPickedOrder,
  PickingListItem,
} from './types';
import { Order, OrderCategory } from '@web/types/order';
import { DeliverView } from '@web/types/user/preferences/deliver-views';

// The initial state of the Ship feature
export const initialState: FeatureState = {
  actions: {
    exportPerformingAction: false,
    printing: false,
    newDocPerformingAction: false,
  },
  tablesSettings: {
    drawerOpen: false,
    showLargeRowDensityOption: false,
    toShip: {
      actionsShown: false,
      rowDensity: null,
      ordersNumber: null,
    },
    shipped: {
      actionsShown: false,
      rowDensity: null,
      ordersNumber: null,
    },
    error: {
      actionsShown: false,
      rowDensity: null,
      ordersNumber: null,
    },
    archived: {
      actionsShown: false,
      rowDensity: null,
      ordersNumber: null,
    },
    deleted: {
      actionsShown: false,
      rowDensity: null,
      ordersNumber: null,
    },
  },
  carriersErrorsMessages: null,
  editViewsDrawer: {
    open: false,
    tabs: [],
  },
  infoDrawer: {
    open: false,
    order: null,
  },
  pickingListDrawer: {
    category: null,
    currentlyOpenDrawerType: null,
    pickedOrders: {},
  },
  invoicePackingSlipDrawer: {
    open: false,
    type: InvoicePackingSlipType.Invoice,
  },
  editOrderNotesDialog: null,
  saveCustomViewDialog: null,
  orderSummaryDrawer: {
    open: false,
    orders: [],
  },
};

const shipSlice = createSlice({
  name: 'ship',
  initialState,
  reducers: {
    // Showing/Hiding the actions row (bulk/smart)
    showActionRow(state, { payload }: PayloadAction<OrderCategory>) {
      state.tablesSettings[payload].actionsShown = true;
    },
    hideActionRow(state, { payload }: PayloadAction<OrderCategory>) {
      state.tablesSettings[payload].actionsShown = false;
    },

    // Opening/Closing the Table Settings drawer
    openTableSettings(state, { payload }: PayloadAction<boolean>) {
      state.tablesSettings.drawerOpen = true;
      state.tablesSettings.showLargeRowDensityOption = payload;
    },
    closeTableSettings(state) {
      state.tablesSettings.drawerOpen = false;
    },

    // Opening/Closing the Edit Views drawer
    openEditViewsDrawer(state, { payload }: PayloadAction<DeliverView[]>) {
      state.editViewsDrawer.open = true;
      state.editViewsDrawer.tabs = payload;
    },
    closeEditViewsDrawer(state) {
      state.editViewsDrawer.open = false;
      state.editViewsDrawer.tabs = [];
    },

    // Opening/Closing the Order Summary drawer
    OpenOrderSummaryDrawer(
      state,
      {
        payload,
      }: PayloadAction<{
        orders: Order[];
      }>,
    ) {
      state.orderSummaryDrawer.open = true;
      state.orderSummaryDrawer.orders = payload.orders;
    },
    CloseOrderSummaryDrawer(state) {
      state.orderSummaryDrawer.open = false;
    },

    // Opening/Closing the Order Detail info drawer
    OrderDetailOpen(state, { payload }: PayloadAction<Order>) {
      state.infoDrawer.open = true;
      state.infoDrawer.order = payload;
    },
    OrderDetailClose(state, { payload }: PayloadAction<boolean>) {
      state.infoDrawer.open = false;
      if (payload) state.infoDrawer.order = null;
    },

    // Performing the 'Export' menu actions
    ExportPerformingAction(state) {
      state.actions.exportPerformingAction = true;
    },
    ExportActionPerformed(state) {
      state.actions.exportPerformingAction = false;
    },

    // Printing the selected 'Shipped' Order Documents
    OrderPrinting(state) {
      state.actions.printing = true;
    },
    OrderPrinted(state) {
      state.actions.printing = false;
    },

    // Performing the 'New Document' menu actions
    NewDocPerformingAction(state) {
      state.actions.newDocPerformingAction = true;
    },
    NewDocActionPerformed(state) {
      state.actions.newDocPerformingAction = false;
    },

    // Actions to open/close the InvoicingPackingSlipDrawer
    InvoicingDrawerOpen(
      state,
      {
        payload,
      }: PayloadAction<{
        category: OrderCategory;
        type: InvoicePackingSlipType;
      }>,
    ) {
      state.invoicePackingSlipDrawer.open = true;
      state.invoicePackingSlipDrawer.type = payload.type;
    },
    InvoicingDrawerClose(state) {
      state.invoicePackingSlipDrawer.open = false;
    },

    // Actions to open/close the PickingListDrawer
    OpenPickingListDrawer(
      state,
      {
        payload,
      }: PayloadAction<{
        type: PickingListType;
        category: OrderCategory;
      }>,
    ) {
      state.pickingListDrawer.currentlyOpenDrawerType = payload.type;
      state.pickingListDrawer.category = payload.category;
      state.pickingListDrawer.pickedOrders = {};
    },

    ClosePickingListDrawer(state) {
      state.pickingListDrawer.category = null;
      state.pickingListDrawer.currentlyOpenDrawerType = null;
      state.pickingListDrawer.pickedOrders = {};
    },

    PickingListDrawerRemoveOrders(state, { payload }: PayloadAction<number[]>) {
      const newState = { ...state.pickingListDrawer.pickedOrders };
      payload.forEach(id => {
        delete newState[id];
      });
      state.pickingListDrawer.pickedOrders = newState;
    },

    PickingListDrawerRemoveOrderItem(
      state,
      { payload }: PayloadAction<{ orderId: number; itemIndex: number }>,
    ) {
      state.pickingListDrawer.pickedOrders[payload.orderId].items =
        state.pickingListDrawer.pickedOrders[payload.orderId].items.filter(
          (_, index) => index !== payload.itemIndex,
        );
    },

    PickingListDrawerSetOrderItem(
      state,
      {
        payload,
      }: PayloadAction<{
        orderId: number;
        itemIndex: number;
        item: PickingListItem;
      }>,
    ) {
      const newItems =
        state.pickingListDrawer.pickedOrders[payload.orderId].items;
      newItems[payload.itemIndex] = payload.item;
      state.pickingListDrawer.pickedOrders[payload.orderId].items = newItems;
    },

    PickingListDrawerSetOrderShippyProNote(
      state,
      {
        payload,
      }: PayloadAction<{
        orderId: number;
        notes: string;
      }>,
    ) {
      state.pickingListDrawer.pickedOrders[payload.orderId].shippyProNote =
        payload.notes;
    },

    PickingListDrawerAddOrderItem(
      state,
      {
        payload,
      }: PayloadAction<{
        orderId: number;
        item: PickingListItem;
      }>,
    ) {
      const newItems = state.pickingListDrawer.pickedOrders[
        payload.orderId
      ].items.concat([payload.item]);
      state.pickingListDrawer.pickedOrders[payload.orderId].items = newItems;
    },

    PickingListDrawerSetOrder(
      state,
      { payload }: PayloadAction<{ id: number; order: IPickedOrder }>,
    ) {
      state.pickingListDrawer.pickedOrders[payload.id] = payload.order;
    },

    // Actions for editing notes via the context menu dialog
    OpenEditOrderNotesDialog(
      state,
      {
        payload,
      }: PayloadAction<{
        orders: Order[];
        category: OrderCategory;
      }>,
    ) {
      state.editOrderNotesDialog = {
        orders: payload.orders,
        category: payload.category,
      };
    },
    CloseEditOrderNotesDialog(state) {
      state.editOrderNotesDialog = null;
    },

    // Actions for editing carriers errors messages
    SetCarriersErrorsMessages(
      state,
      {
        payload,
      }: PayloadAction<{
        errorMessages: any;
        suggestions: any;
      }>,
    ) {
      state.carriersErrorsMessages = payload;
    },
  },
});

export const { actions: shipActions, reducer, name: sliceKey } = shipSlice;
