import { useCallback, useEffect, useMemo, useState } from 'react';
import { uniqBy, debounce } from 'lodash';
import { ChangelogPost } from '@web/types/global';
import { useLazyGetChangelogQuery } from '@web/features/_global/api';

/**
 * Returns RTK Query hook with renamed data field.
 * Also merges successive fetches into a single post array
 * for supporting infinite scroll.
 * @author Fabio Fognani <fabio.fognani@shippypro.com>
 *
 * @param {CustomRTKQueryOptions} options subset of RTK Query hook options
 */
export const useGetChangelog = () => {
  const [fetchMore, { data: changelog, isLoading, ...rest }] =
    useLazyGetChangelogQuery();

  const [posts, setPosts] = useState<ChangelogPost[]>(changelog?.posts ?? []);
  const [currPage, setCurrPage] = useState(0);

  const loadMore = useCallback(async () => {
    if (isLoading) return;

    const newChangelog = await fetchMore({
      page: currPage + 1,
    });

    if ('data' in newChangelog) {
      const newPosts = uniqBy(
        [...posts, ...(newChangelog.data?.posts ?? [])],
        'id',
      );
      setPosts(newPosts);
      setCurrPage(currPage + 1);
    }
  }, [currPage, isLoading, setPosts, setCurrPage, fetchMore, posts]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedLoadMore = useCallback(debounce(loadMore, 500), [loadMore]);

  // load initial page
  useEffect(() => {
    debouncedLoadMore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(
    () => ({
      changelog,
      isLoading,
      loadMore: debouncedLoadMore,
      allPosts: posts,
      publishedPosts: posts.filter(post => post.published),
      currPage,
      ...rest,
    }),
    [changelog, isLoading, debouncedLoadMore, currPage, posts, rest],
  );
};
