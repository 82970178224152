import { useMemo } from 'react';

import { CustomRTKQueryOptions } from '@web/utils/@reduxjs/rtk-query';

import { useGetSenderInfoQuery } from '@web/features/_global/api';

/**
 * Returns RTK Query hook with renamed data field.
 * @author Fabio Fognani <fabio.fognani@shippypro.com>
 *
 * @param {CustomRTKQueryOptions} options subset of RTK Query hook options
 */
export const useGetSenderInfo = (options?: CustomRTKQueryOptions) => {
  const { data: senderInfo, ...rest } = useGetSenderInfoQuery(
    undefined,
    options,
  );

  return useMemo(
    () => ({
      senderInfo,
      ...rest,
    }),
    [senderInfo, rest],
  );
};
