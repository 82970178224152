import { isNullOrEmpty } from '@shippypro/design-system-web/functions';

/**
 * Convert an object or array data to an encoded url query string
 *
 * @param {object} obj
 * @param {?string} prefix
 * @returns {string}
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export function objectToQueryString(obj: object, prefix?: string) {
  let str: string[] = [];

  for (let prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      let key = prefix ? prefix + '[' + prop + ']' : prop,
        value = obj[prop];

      !isNullOrEmpty(value) &&
        str.push(
          typeof value === 'object'
            ? objectToQueryString(value, key)
            : encodeURIComponent(key) + '=' + encodeURIComponent(value),
        );
    }
  }
  return str.join('&');
}
