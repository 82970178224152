// ########################## [IMPORTANT LIBRARIES]
import { useMemo } from 'react';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [DESIGN SYSTEM]
import { TooltipWrapperByREF } from '@shippypro/design-system-web';

// ########################## [TYPES]
import { Order } from '@web/types/order';
import { Marketplace } from '@web/types/global';

// ########################## [HOOKS]
import { useGetMarketplaces } from '@web/features/_global/hooks/api/useGetMarketplaces';
import { getMarketplace } from '@web/hooks/marketplaces/useGetMarketplace';

const columnHelper = createColumnHelper<Order>();

export const useGetSalesChannelColumn = (): ColumnDef<Order, string> => {
  const { t } = useTranslation(),
    transToShipTable = translations.ship.table,
    transToShip = transToShipTable.columns;

  const { allMarketplaces: marketplaces } = useGetMarketplaces();

  return useMemo(
    () =>
      columnHelper.accessor('marketplace_platform', {
        header: () => (
          <span className="w-full text-center">
            {t(transToShip.salesChannel)}
          </span>
        ),
        minSize: 200,
        size: 350,
        maxSize: 350,
        enableSorting: false,
        id: 'salesChannel',
        cell: ({ row: { original } }) =>
          salesChannelColumnRendererFn(marketplaces, original),
      }),
    [marketplaces, t, transToShip],
  );
};

const salesChannelColumnRendererFn = (
  marketplaces: Marketplace[] | undefined,
  { marketplace_id, marketplace_platform }: Order,
) => {
  const marketplace = getMarketplace(
    marketplaces!,
    marketplace_id,
    marketplace_platform,
  );

  if (!marketplace) return <p>...</p>;
  return (
    <TooltipWrapperByREF
      content={`${marketplace.displayName} - ${marketplace_id}`}
    >
      <div className="flex justify-center w-full">
        <img
          className="w-10 h-10"
          loading="lazy"
          alt={marketplace.displayName}
          src={marketplace.image_url}
        />
      </div>
    </TooltipWrapperByREF>
  );
};
