import { FC } from 'react';
import { ModalBody as ReactstrapModalBody } from 'reactstrap';

import { IModalBodyProps } from './types';

/**
 * A simple wrapper class for the `reactstrap: <ModalBody />` component.
 *
 * @interface IModalBodyProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const ModalBody: FC<IModalBodyProps> = props => (
  <ReactstrapModalBody {...props} />
);
