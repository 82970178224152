import styled, { css } from 'styled-components';

const MediaQueries = css`
  @media (max-width: 3840px) {
    #shippypro-livechat {
      height: 97.8dvh !important;
    }
  }

  @media (max-width: 2560px) {
    #shippypro-livechat {
      height: 96.5dvh !important;
    }
  }

  @media (max-width: 1920px) {
    #shippypro-livechat {
      height: 95dvh !important;
    }
  }

  @media (max-width: 1728px) {
    #shippypro-livechat {
      height: 94dvh !important;
    }
  }

  @media (max-width: 1280px) {
    #shippypro-livechat {
      height: 93dvh !important;
    }
  }
`;

const StyledLiveChatContainer = styled.section`
  ${MediaQueries}

  #shippypro-livechat {
    width: 100%;
    height: 95dvh;

    #hubspot-conversations-inline-parent {
      width: 100%;
      height: 100%;

      #hubspot-conversations-inline-iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
`;

export default StyledLiveChatContainer;
