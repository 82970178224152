import { FC } from 'react';
import { FormText } from 'reactstrap';
import { IHelperText } from './types';

/**
 * A simple helper text for form fields
 *
 * @interface IHelperText
 * @author Fabio Mezzina <fabio.mezzina@shippypro.com>
 */
export const HelperText: FC<IHelperText> = ({ children, props }) => {
  return (
    <FormText className="!text-[color:--shp-color-text-helper]" {...props}>
      {children}
    </FormText>
  );
};
