import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations_restricted';

import { Button, TooltipWrapperByREF } from '@ds-web/components';
import { IconHelper } from '@ds-web-iconhelper';
import { StyleType } from '@ds-web/tokens/enums';

import { ICopyButtonProps } from './types';

import { useTextCopiedWithTimeout } from '@ds-web/utils/hooks/dom/text-copied-with-timeout/text-copied-with-timeout';

/**
 * A small icon button that can copy a given text to the user's clipboard
 *
 * @interface ICopyButtonProps
 * @author Fabio Fognani <fabio.fognani@shippypro.com>
 */
export const CopyButton: FC<ICopyButtonProps> = ({
  value,
  tooltip,
  iconProps,
  tooltipProps,
  ...btnProps
}) => {
  const { t } = useTranslation();
  const transCommon = translations.common;

  const { isCopied, onCopyClick } = useTextCopiedWithTimeout(value ?? '');

  return (
    <TooltipWrapperByREF
      content={
        tooltip ?? isCopied
          ? t(transCommon.copied)
          : t(transCommon.copyToClipboard)
      }
      innerClassName="min-w-[100px]"
      {...tooltipProps}
    >
      <Button
        className="p-[4px] copy-button"
        styleType={StyleType.flat}
        onClick={onCopyClick}
        disabled={!value}
        {...btnProps}
      >
        <IconHelper
          icon="IconCopy"
          size={17}
          className="cursor-pointer"
          {...iconProps}
        />
      </Button>
    </TooltipWrapperByREF>
  );
};
