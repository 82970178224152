import { useState, useCallback } from 'react';

import { PaywallInfo, PaywallDesign } from '@web/features/paywall/types';

import { IPaywallContextProps } from '@web/features/paywall/contexts/PaywallContext';

/**
 * `[HOOK]` This paywall instances every info prop related to the Paywall, from its state to its contents.
 * It is also used as a localised state that can get updated by an external source (I.e. A dedicated context).
 *
 * N.B. **ALREADY INSTANTIATED AT THE NEAR-TOP OF THE CODE TREE, NO NEED TO USE THIS ANYWHERE ELSE**
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 *
 * @returns {IPaywallContextProps} All of the info needed for the paywall to function
 */
const useInstancePaywall = (): IPaywallContextProps => {
  // Bootstrapping the modal's contents (& visibility)
  const [info, setInfo] = useState<PaywallInfo | undefined>(undefined);

  // Setting the design of the modal (could be changed indipendently in the future, maybe with mobile switches?)
  const [design, setDesign] = useState<keyof typeof PaywallDesign>(
    PaywallDesign.PureText,
  );

  // Opening the modal and filling it with its info
  const onPaywallOpen = useCallback(
    <T extends keyof typeof PaywallDesign>(design: T, info: PaywallInfo) => {
      setDesign(design);
      setInfo(info);
    },
    [],
  );

  // Closing the modal and destroying the info
  const onPaywallClose = useCallback(() => {
    setInfo(undefined);
  }, []);

  return {
    isOpen: Boolean(info),
    design: design,
    info,
    openPaywall: onPaywallOpen,
    closePaywall: onPaywallClose,
  };
};

export default useInstancePaywall;
