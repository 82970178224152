import { FC, ReactNode, useMemo, useState } from 'react';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [TYPES]
import { Note } from '@web/types/order';

// ########################## [DESIGN SYSTEM]
import { NotesListWrapper } from '@shippypro/design-system-web';
import { Color } from '@shippypro/design-system-web/enums';

// ########################## [COMPONENTS]
import CardView, {
  ICardViewProps,
} from '@web/components/common-ui/wrappers/card/CardView';

// ########################## [HOOKS]
import { useEditOrderNote } from '@web/features/order/hooks/useEditOrderNote';
import NotesList from './NotesList';

interface INotesSectionProps {
  notes?: Note | null;
  header?: null | ReactNode;
  cardProps?: Partial<ICardViewProps>;
  isSavingShippyProNote: boolean;
  onSubmitShippyProNote: (note: string) => void;
  isShippyProNoteEditable: boolean;
}

/**
 * This component shows notes inside a card and handles edit mode for ShippyPro notes
 * @author Fabio Fognani <fabio.fognani@shippypro.com>
 */
const NotesSection: FC<INotesSectionProps> = ({
  notes,
  header,
  cardProps,
  isSavingShippyProNote,
  onSubmitShippyProNote,
  isShippyProNoteEditable,
}) => {
  const { t } = useTranslation(),
    transCommon = translations.common,
    transOrderTooltips = translations.order.tooltips;

  // Check empty notes
  const areNotesEmptyCheck: boolean = useMemo(() => {
    return !notes || Object.values(notes).every(note => !note);
  }, [notes]);
  const [areNotesEmpty, setAreNotesEmpty] = useState(areNotesEmptyCheck);

  // Handle edit and update of a note
  const {
    onEditShippyNote,
    canAddNote,
    localShippyProNote,
    setLocalShippyProNote,
    isEditingShippyProNote,
    onSetShippyProNoteEditing,
  } = useEditOrderNote({
    notes,
    onSubmitShippyProNote: newNote => {
      setAreNotesEmpty(!newNote && areNotesEmptyCheck);
      onSubmitShippyProNote(newNote);
    },
    canEditShippyProNote: isShippyProNoteEditable,
  });

  return (
    <CardView
      muted={areNotesEmpty}
      headerMuted={!header}
      header={header ?? t(transCommon.notes).toUpperCase()}
      actionFn={onEditShippyNote}
      customAction={canAddNote}
      customActionTooltip={t(transOrderTooltips.createNote)}
      actionIcon="IconCirclePlus"
      iconColor={Color.primary}
      iconSize={20}
      name="notes"
      {...cardProps}
    >
      {!areNotesEmpty || isEditingShippyProNote ? (
        <NotesListWrapper>
          <NotesList
            notes={notes}
            editable={isShippyProNoteEditable}
            isEditing={isEditingShippyProNote}
            setEditing={onSetShippyProNoteEditing}
            onChange={setLocalShippyProNote}
            onSubmit={onSubmitShippyProNote}
            disabled={isSavingShippyProNote}
            localShippyProNote={localShippyProNote}
          />
        </NotesListWrapper>
      ) : (
        <p>{t(transCommon.noNotes)}</p>
      )}
    </CardView>
  );
};

export default NotesSection;
