import styled from 'styled-components';

/**
 * This Layout is an addon to the Tanstack table component hook that makes sure
 * that every row is correctly centered and uses the `flex` display properties.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const ExpandableFlexLayout = styled.div`
  .react-table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;

    .react-table-header {
      display: table;
      width: 100%;
      position: sticky;
      top: -1px !important;

      tr {
        display: flex;
        flex: 1 0 auto;
        min-width: 0px;

        .react-table-header-column {
          box-sizing: border-box;
          flex: 150 0 auto;
          min-width: 0px;
          width: 150px;

          &[class*='select-column'],
          &[class*='contextual_menu'],
          &[class*='expand-column'] {
            max-width: 100px !important;
          }
        }
      }
    }

    .react-table-body {
      display: table;
      width: 100%;

      .react-table-body-row {
        display: flex;
        width: 100%;

        .react-table-body-row-cell {
          display: flex;
          align-items: center;
          width: 100%;

          box-sizing: border-box;
          flex: 150 0 auto;
          min-width: 0px;

          &[class*='select-column'],
          &[class*='contextual_menu'],
          &[class*='expand-column'] {
            max-width: 100px !important;
          }
        }
      }
    }
  }
`;

export default ExpandableFlexLayout;
