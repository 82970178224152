import { toLocalizedDecimal } from '../localised-decimal/localised-decimal';

/**
 * Formats a decimal number into a localised string (`.`, `,` on thousands and decimals depending on the language)
 * that can have a set amount of decimal digits.
 * This function is a shortcut for `toLocalizedDecimal` for price values with a fixed amount of decimals (always 2)
 *
 * @param {string} currency The currency preferred by the user
 * @param {number} value The numeric value to format
 * @returns {string} The localised string value
 * @see toLocalizedDecimal
 *
 **/
export function toLocalizedPrice(currency: string, value?: number): string {
  return `${currency}${toLocalizedDecimal(value, 2, 2)}`;
}
