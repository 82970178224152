import styled, { css } from 'styled-components';

/**
 * This Layout is an addon to the `@tanstack/react-virtual` plugin that makes
 * sure that the height and width of the table are respected to make the virtualisation work.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const getMedia = () => css`
  @media (max-width: 1024px) {
    .react-table-pagination {
      bottom: 50px !important;
    }
  }
`;

/**
 * Styled layout used in Table instance CustomLayout
 *
 * @param {boolean} compact sets the row height value for standard content
 * @param {boolean} areActionsShown sets the action visualization
 * @param {boolean} isTestingEnv sets the environment is production or testing
 * @param {boolean} haveHighlightedRow sets param in case table could have a
 * highlighted row to prevent background color mismatching
 */
export const VirtualLayoutSmallFactor = styled.div<{
  compact?: boolean;
  areActionsShown?: boolean;
  isTestingEnv?: boolean;
  haveHighlightedRow?: boolean;
}>`
  ${_p => getMedia()}

  height:  100% !important;

  position: relative;
  overflow: hidden;

  .react-table-virtualisation-container {
    display: block !important;

    overflow-y: scroll;
  }

  .react-table {
    max-height: unset !important;
    min-width: unset !important;

    display: block;
    overflow-x: auto;
    white-space: nowrap;

    flex: 1;
    flex-shrink: 0;
    min-height: 95%;

    margin-bottom: 0 !important;
    padding-bottom: ${p => (p.areActionsShown ? 21 : 16)}rem !important;

    .react-table-header {
      display: table;
      width: 100%;
      position: sticky;
      top: 0 !important;
      z-index: 30 !important;

      tr {
        display: flex;
        flex: 1 0 auto;
        min-width: 0px;

        .react-table-header-column {
          box-sizing: border-box;
          flex: 150 0 auto;
          min-width: 0px;
          width: 150px;

          &.header-column--select-column,
          &.header-column--expand-column {
            max-width: 30px !important;
          }

          // sticky last header column
          &:last-child {
            position: sticky;
            right: -1px;
            z-index: 10;
            background-color: var(--shp-color-bg-table-header);
          }
        }
      }
    }

    .react-table-body {
      display: table;
      width: 100%;

      margin-bottom: 0 !important;

      overflow-y: scroll;

      .react-table-body-row {
        display: flex;

        width: 100%;
        height: ${p => (p.compact ? '4rem' : '6rem')} !important;

        position: relative;
        transition: 150ms ease;

        & > td:last-child {
          display: flex;
          justify-content: flex-end;
          opacity: ${p => (p.isTestingEnv ? 1 : 0)};
          transition: 150ms ease;

          & .context-menu {
            opacity: ${p => (p.isTestingEnv ? 1 : 0)};
            transition-property: opacity;
            transition-duration: 150ms;
            padding: 0.72rem 0 0.72rem 2.5rem !important;
          }
        }

        &:hover {
          position: relative !important;
          background-color: var(--shp-color-bg-highlight);

          & > td:last-child {
            overflow: visible;
            opacity: 1;
            background-color: var(--shp-color-bg-highlight);
            & > * {
              visibility: ${p => (p.haveHighlightedRow ? 'visible' : 'unset)')};
            }

            & .context-menu {
              width: fit-content !important;
              opacity: 1;
              background: linear-gradient(
                to right,
                transparent,
                var(--shp-color-bg-highlight) 2rem,
                var(--shp-color-bg-highlight)
              ) !important;
            }
          }
        }

        .react-table-body-row-cell {
          display: flex;
          align-items: center;
          width: 100%;

          box-sizing: border-box;
          flex: 150 0 auto;
          min-width: 0px;

          height: ${p => (p.compact ? '4rem' : '5.75rem')};
        }

        .react-table-body-row-cell {
          &[class*='select-column'],
          &[class*='expand-column'] {
            max-width: 30px !important;
          }
        }

        // sticky last column in table body
        .react-table-body-row-cell {
          &:last-child {
            position: sticky;
            right: -1px;
            z-index: 10;

            height: ${p => (p.compact ? '-1rem' : '6rem')};
            background-color: ${p =>
              p.haveHighlightedRow
                ? 'inherit !important'
                : 'var(--shp-color-bg)'};
            opacity: ${p => (p.haveHighlightedRow ? 1 : 'unset)')};

            & > * {
              visibility: ${p => (p.haveHighlightedRow ? 'hidden' : 'unset)')};
            }
          }
        }
      }

      .react-table-body-row-container {
        height: 560px !important;

        background-color: var(--shp-color-bg-highlight);

        .react-table-body-row-subcomponent {
          position: sticky;
          left: 0;

          height: 560px !important;
          width: 100dvw;
          @media (min-width: 768px) {
            width: calc(100dvw - 95px);
          }
          overflow: auto;

          display: flex;
          flex-wrap: wrap;
          background-color: var(--shp-color-bg-highlight);

          .react-table-expanded-row {
            border-bottom: 4px solid
              var(--shp-color-genericborder-primary-light) !important;
            display: flex;
            width: 100%;
            flex-direction: column;

            & > div:first-child {
              max-width: 98%;
            }
          }
        }
      }

      // this is to prevent cell contents from overlapping with transparent background
      .react-table-body-row-cell {
        background-color: inherit;
      }

      // highlighted and selected rows have a special background color
      .react-table-body-row-selected .react-table-body-row-cell:last-child,
      .react-table-body-row-highlighted .react-table-body-row-cell:last-child {
        background-color: var(--shp-color-bg-table-selected);
      }
    }
  }

  .react-table-pagination {
    position: fixed;
    bottom: 0;

    width: 100dvw;
    z-index: 10;

    margin: 0 !important;
    padding: 0.5rem 7rem 0.5rem 2rem;

    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
    background-color: white;

    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;

      margin-top: 0 !important;

      .pagination-string {
        margin-bottom: 0 !important;
      }
    }
  }
`;
