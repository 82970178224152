import { Row } from '@tanstack/react-table';

import { IReactTableProps } from '../../types';

export type SubComponentContainerProps<T extends object> = {
  row: Row<T>;
  tableProps: IReactTableProps<T>;
};

/**
 * This custom component gets rendered when its corresponding row is expanded.
 * It gets the entire row as a prop and can render whatever UI it prefers.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 *
 * @returns {FC} The custom sub-component, fully rendered & managed from the outside.
 */
function SubComponentContainer<T extends object>({
  row,
  tableProps,
}: SubComponentContainerProps<T>) {
  const { customComponents, getAllColumns } = tableProps;

  // Render the sub-component only if it exists and the row has been expanded
  return row.getIsExpanded() && customComponents?.renderRowSubComponent ? (
    <tr className="react-table-body-row-container">
      <td
        colSpan={getAllColumns().length}
        className="react-table-body-row-subcomponent"
      >
        {customComponents?.renderRowSubComponent(row)}
      </td>
    </tr>
  ) : null;
}

export default SubComponentContainer;
