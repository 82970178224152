import { FC } from 'react';
import 'flatpickr/dist/themes/light.css';

import '@ds-web/assets/scss/react/libs/flatpickr/flatpickr.scss';

import Flatpickr from 'react-flatpickr';

import { IDatetimePickerProps } from './types';

/**
 * A simple wrapper class for the `flatpickr: <DatetimePicker />` component.
 *
 * @interface IDatetimePickerProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const DatetimePicker: FC<IDatetimePickerProps> = props => (
  <Flatpickr {...props} />
);
