import featureBlockIcon from '@web/assets/img/icons/feature_block_icon.svg';

/** TODO: move to design system
 * A simple badge that symbolizes that the user needs an higher plan to access a given feature.
 *
 * @author Fabio Mezzina <fabio.mezzina@shippypro.com>
 */
const CommonBadgeLock = () => {
  return (
    <div className="locked-common rounded-xl !pt-1 !pb-[0.10rem] ml-auto">
      <img
        src={featureBlockIcon}
        alt="FeatureBlock"
        width={14}
        height={14}
        className="feature-block-icon"
      />
    </div>
  );
};

export default CommonBadgeLock;
