import React, { FC, useState, useEffect, memo, ReactNode } from 'react';
import DataTableComponent from 'react-data-table-component';
import classnames from 'classnames';

import { ITableProps } from './types';

import { IconHelper } from '@ds-web-iconhelper';

import { Checkbox } from '@ds-web/components/atoms/form/checkbox/Checkbox';
import SelectedForCustomActionHeader from './headers/SelectedForCustomAction/SelectedForCustomActionHeader';
import TablePagination from './pagination/Pagination';

import { handleSortIcons, sortFunctionFactory } from './functions/Sorting';
import { selectionChangeFactory } from './functions/Selection';
import { filterFunctionFactory } from './functions/Filtering';
import { toHeaderProps } from './functions/Headers';

/**
 * The main `react-data-table-component` library wrapper.
 *
 * ⚠️ UNDER DEPRECATION ⚠️
 *
 * @deprecated Use the `<ReactTable />` component!
 *
 * @interface ITableProps
 */
export const DataTable: FC<ITableProps> = memo(
  props => {
    // --------------------------- [EXTRACTING THE MAIN PROPERTIES]
    const {
      composition,
      headers,
      pagination,
      sorting,
      selection,
      filtering,
      action,
      styling = {},
      utility,
      ...otherProps
    } = props;
    const { columns, data, keyField = 'id', secondaryField } = composition;
    // eslint-disable-next-line no-empty-pattern
    const {} = headers;
    const { emptyText, paginationText, remote } = pagination;
    const { customSort, remoteSort } = sorting;
    const {
      clicked,
      onRowClicked,
      selectableRows,
      selectionHeader,
      selectionHighlight = true,
      selectableRowsHighlight = false,
      selectableRowSelected,
      resetSelection = false,
      selectionText,
      selected: selectedRows = [],
      onSelectionChange,
    } = selection;
    const { filterState, handleFilter } = filtering;
    const { conditionalRowStyles = [], ...otherStyles } = styling;
    // eslint-disable-next-line no-empty-pattern
    const {} = action;
    const { role, adminFn, t } = utility;

    // --------------------------- [PREPARING THE STATE]
    const [filteredData, setFilteredData] = useState<any[]>([]),
      [filterValue, setValue] = useState(filterState),
      [filterNumber, setFilterNumber] = useState(0),
      [paginationPerPage, setPaginationPerPage] = useState(
        remote ? remote.paginationPerPage ?? 10 : 10,
      ),
      [page, setPage] = useState(1),
      [resetPage, setResetPage] = useState(false),
      [selected, setSelected] = useState(selectedRows);

    // --------------------------- [SETTING-UP THE HEADERS]
    const PrimaryHeader =
      selected?.length === 0
        ? headers.PrimaryHeader
        : selectionHeader
        ? SelectedForCustomActionHeader
        : null;
    const SecondaryHeader = headers.SecondaryHeader;
    const headerProps = toHeaderProps(props, {
      filterValue,
      filterNumber,
      filterFunctionFactory: filterFunctionFactory(
        handleFilter,
        setValue,
        setFilteredData,
        setFilterNumber,
      ),
      paginationPerPage,
      setPaginationPerPage,
      t,
      selected,
      composition,
      pagination,
      selection,
      filtering,
      action,
      utility,
    });

    // --------------------------- [REFRESHING THE TABLE ON PAGE CHANGE]
    useEffect(() => {
      setResetPage(!resetPage);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    useEffect(() => {
      setPaginationPerPage(remote ? remote.paginationPerPage ?? 10 : 10);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [remote?.paginationPerPage]);

    // --------------------------- [RENDERING THE DATATABLE]
    return (
      <div className={classnames('react-dataTable', otherStyles.className)}>
        <DataTableComponent
          className="react-dataTable"
          // --------------------------- [COMPOSITION]
          data={
            Object.values(filterValue ?? {}).some(x => x !== null && x !== '')
              ? filteredData
              : data
          }
          columns={columns}
          keyField={keyField}
          // --------------------------- [HEADERS]
          noHeader
          subHeader={PrimaryHeader ? true : false}
          subHeaderComponent={
            <div className="w-100 mx-0 mt-1 mb-50 row">
              {SecondaryHeader && (
                <>
                  <SecondaryHeader {...headerProps} /> <hr />
                </>
              )}
              {PrimaryHeader && <PrimaryHeader {...headerProps} />}
            </div>
          }
          // --------------------------- [PAGINATION]
          pagination
          // REMOTE PROPS
          paginationServer={typeof remote !== 'undefined'}
          paginationTotalRows={remote ? remote.totalRows : data.length}
          progressPending={remote ? remote.changingPage : undefined}
          progressComponent={remote?.ProgressComponent}
          onChangePage={(page: number) => {
            remote && remote.onChangePage(page);
            setPage(page);
          }}
          onChangeRowsPerPage={(newPerPage, page) => {
            setPaginationPerPage(newPerPage);
            remote &&
              remote.onChangeRowsPerPage &&
              remote.onChangeRowsPerPage(newPerPage, page);
          }}
          paginationServerOptions={
            remote
              ? {
                  persistSelectedOnSort: true,
                }
              : undefined
          }
          // COMMON PROPS
          paginationDefaultPage={page}
          paginationResetDefaultPage={remote ? undefined : resetPage}
          paginationComponent={({ rowsPerPage, ...rest }: any) => (
            <TablePagination
              {...rest}
              currentPage={page}
              rowsPerPage={paginationPerPage}
              setPage={setPage}
              selected={selected}
              paginationText={paginationText}
              selectionText={selectionText}
              transFunc={t}
            />
          )}
          // --------------------------- [SORTING]
          sortServer={typeof remote !== 'undefined'}
          sortIcon={<IconHelper icon="IconChevronDown" size={10} />}
          onSort={(column, direction) => {
            handleSortIcons(column.sortFunction);
            remote &&
              remoteSort &&
              remoteSort(column.sortField ?? '', direction);
          }}
          sortFunction={!remote ? sortFunctionFactory(customSort) : undefined}
          // --------------------------- [SELECTION]
          onRowClicked={(row: any, e: React.MouseEvent) => {
            onRowClicked &&
              onRowClicked(
                (clicked ? clicked[keyField] : -1) === row[keyField] &&
                  (secondaryField
                    ? clicked[secondaryField] === row[secondaryField]
                    : true)
                  ? null
                  : row,
                e,
              );
            setResetPage(!resetPage);
          }}
          selectableRows={selectableRows}
          // Casting because of a problem between the ForwardRef typings and the ReactNode (but they're still the same)
          selectableRowsComponent={Checkbox as unknown as ReactNode}
          onSelectedRowsChange={selectionChangeFactory(
            setSelected,
            setResetPage,
            resetPage,
            onSelectionChange,
          )}
          clearSelectedRows={resetSelection}
          selectableRowsHighlight={selectableRowsHighlight}
          selectableRowSelected={selectableRowSelected}
          selectableRowDisabled={(row: any) =>
            adminFn ? adminFn(role ?? row.role) : false
          }
          selectableRowsComponentProps={{
            className: 'table-checkbox',
            color: 'primary',
            icon: <IconHelper icon="IconCheck" className="vx-icon" />,
            label: '',
            checkSize: 'md',
          }}
          // --------------------------- [STYLING]
          conditionalRowStyles={[
            ...conditionalRowStyles,
            // Style applied when selecting the row checkbox
            {
              when: (row: any) =>
                !!selected?.filter(
                  (r: any) =>
                    r[keyField] === row[keyField] &&
                    (secondaryField
                      ? r[secondaryField] === row[secondaryField]
                      : true),
                ).length,
              style: {
                backgroundColor: selectionHighlight
                  ? 'var(--shp-color-bg-table-selected)'
                  : '#fff',
                borderColor: selectionHighlight ? '#fff' : 'rgba(0,0,0,.12)',
              },
            },
            // Style applied when clicking on any part of a row
            {
              when: (row: any) =>
                (clicked ? clicked[keyField] : -1) === row[keyField] &&
                (secondaryField
                  ? clicked[secondaryField] === row[secondaryField]
                  : true),
              style: {
                backgroundColor: 'var(--shp-color-bg-table-selected)',
                borderColor: '#fff',
              },
            },
          ]}
          {...otherStyles}
          // --------------------------- [UTILITY]
          noDataComponent={
            <span className="mb-20">{emptyText ? t(emptyText) : null}</span>
          }
          // --------------------------- [OTHER]
          {...otherProps}
        />
      </div>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.selection.selected?.length ===
        nextProps.selection.selected?.length &&
      prevProps.selection.clicked === nextProps.selection.clicked &&
      JSON.stringify(prevProps.composition.data) ===
        JSON.stringify(nextProps.composition.data) &&
      prevProps.selection.resetSelection ===
        nextProps.selection.resetSelection &&
      prevProps.pagination.remote?.changingPage ===
        nextProps.pagination.remote?.changingPage &&
      prevProps.pagination.remote?.paginationPerPage ===
        nextProps.pagination.remote?.paginationPerPage
    );
  },
);
