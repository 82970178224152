import { Header } from '@tanstack/react-table';
import classnames from 'classnames';

import { IconHelper } from '@ds-web-iconhelper';

/**
 * This component is the UI representation of a `react-table` sorting indicator.
 * It shows if the selected column is sorted or not.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 *
 * @returns {FC} The `React Table` header sorting icons, fully rendered & managed.
 */
function Sorting<T extends object>({
  column: { getIsSorted, getNextSortingOrder, getCanSort },
  isHighlighted = false,
}: Header<T, unknown> & { isHighlighted?: boolean }) {
  const isSortingEnabled = getCanSort();
  const isSorted = getIsSorted() !== false;
  const nextSortOrder = getNextSortingOrder();
  const isSortedDesc = nextSortOrder && nextSortOrder !== 'desc';
  const isSortedAsc = !nextSortOrder || nextSortOrder === 'desc';
  const sortIcon = isSorted
    ? isSortedDesc
      ? 'IconSortDescending'
      : 'IconSortAscending'
    : isSortedDesc
    ? 'IconSortAscending'
    : 'IconSortDescending';

  return (
    <span
      className={classnames('react-table-header-sorticons', {
        'react-table-header-sort__asc': isSortedAsc,
        'react-table-header-sort__desc': isSortedDesc,
        'cursor-not-allowed': !isSortingEnabled,
        hidden: !isSortingEnabled,
        visible: isSortingEnabled && isSorted,
        invisible: isSortingEnabled && !isSorted,
        'opacity-100': isSorted && (isSortingEnabled || isHighlighted),
      })}
    >
      {<IconHelper icon={sortIcon} size={18} />}
    </span>
  );
}

export default Sorting;
