export type Cn22Item = {
  id: number;
  description: string;
  weight: number;
  qty: number;
  unitValue: number;
  origin: string;
  currency: string;
  hsCode: string | null;
};

export type CustomInfo = {
  contents_type?: string | null;
  non_delivery_option?: string | null;
  items: {
    currency: string;
    description: string;
    hscode: string | null;
    origin_country: string;
    quantity: number;
    unit_value: number;
    weight: number;
  }[];
};

export enum Cn22ContentType {
  return = 'return',
  merchandise = 'merchandise',
  returnedGoods = 'returnedGoods',
  documents = 'documents',
  gift = 'gift',
  sample = 'sample',
}

export enum NonDeliveryOptionType {
  return = 'return',
  abandon = 'abandon',
}
