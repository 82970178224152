import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import { Badge, TooltipWrapperByID } from '@shippypro/design-system-web';
import { Color } from '@shippypro/design-system-web/enums';

import { ErrorCategory } from '@web/types/order/error';
import { CarrierErrorMessageType } from '@web/features/ship/types';

interface IErrorCategoryBadge_NewProps {
  target: string;

  type: CarrierErrorMessageType | null;
}

const ErrorCategoryBadge_New: React.FC<IErrorCategoryBadge_NewProps> = ({
  target,
  type,
}) => {
  const { t } = useTranslation(),
    transTable = translations.ship.table;

  return (
    <TooltipWrapperByID
      target={target}
      text={type?.description}
      active={!!type?.description}
    >
      <Badge
        color={Color.danger}
        light
        pill
        className="!cursor-default text-light-purpleish mb-0 text-[12px] font-medium tracking-widest"
      >
        <span id={target} data-tag="allowRowEvents">
          {type ? type.name : t(transTable.errors[ErrorCategory.unknown])}
        </span>
      </Badge>
    </TooltipWrapperByID>
  );
};

export default ErrorCategoryBadge_New;
