import { FC } from 'react';
import { UncontrolledButtonDropdown as ReactstrapUncontrolledButtonDropdown } from 'reactstrap';

import { IUncontrolledButtonDropdownProps } from './types';

/**
 * A simple wrapper class for the `reactstrap: <UncontrolledButtonDropdown />` component.
 *
 * @interface IUncontrolledButtonDropdownProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const UncontrolledButtonDropdown: FC<
  IUncontrolledButtonDropdownProps
> = props => <ReactstrapUncontrolledButtonDropdown {...props} />;
