import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import CardView from '@web/components/common-ui/wrappers/card/CardView';
import { TooltipWrapperByREF } from '@shippypro/design-system-web';

import { IOrderInfoBlock } from './types';
import { ImageWithLabel } from '@web/components/common-ui/tables/columns';
import { getMarketplaceStatusString } from '@web/hooks/marketplaces/useGetMarketplace';

interface IOrderInfoProps extends IOrderInfoBlock {}

const OrderInfo: React.FC<IOrderInfoProps> = ({ order, marketplace }) => {
  const { t } = useTranslation(),
    transCommon = translations.common,
    transOrder = translations.order.info;

  return (
    <CardView
      col
      header={t(transOrder.orderInfo).toUpperCase()}
      name="order-info"
    >
      <div
        className="flex justify-between items-center"
        data-test="order-detail-info-mktp"
      >
        <p>{t(transCommon.salesChannel)}</p>
        {marketplace && (
          <TooltipWrapperByREF
            fullWidth={false}
            content={`${marketplace.label || marketplace.displayName} ${
              !marketplace.is_only_internal &&
              getMarketplaceStatusString(order?.marketplace_updated, t)
            }`}
          >
            <ImageWithLabel
              url={marketplace.image_url}
              alt={marketplace.marketplace_platform}
              label={marketplace.label ?? marketplace.marketplace_platform}
              bordered
              truncate={false}
              status={order?.marketplace_updated}
              showStatus={!marketplace.is_only_internal}
            />
          </TooltipWrapperByREF>
        )}
      </div>
      <div
        className="flex justify-between"
        data-test="order-detail-info-mktp-id"
      >
        <p>{t(transOrder.salesChannelID)}</p>
        <p>{marketplace && marketplace.marketplace_id}</p>
      </div>
      {order?.ebay_sale_id && order.ebay_user_id && (
        <div
          className="flex justify-between"
          data-test="order-detail-info-ebay-sale-user-id"
        >
          <p>{t(transOrder.ebaySaleUserId)}</p>
          <p>
            {order.ebay_sale_id} - {order.ebay_user_id}
          </p>
        </div>
      )}
      <div
        className="flex justify-between"
        data-test="order-detail-info-incoterm"
      >
        <p>{t(transOrder.incoterm)}</p>
        <p>{order?.incoterm}</p>
      </div>
      <div
        className="flex justify-between"
        data-test="order-detail-info-cashondelivery"
      >
        <p>{t(transCommon.form.cod)}</p>
        <p>{order?.payment.cashondelivery ?? 0}</p>
      </div>
    </CardView>
  );
};

export default OrderInfo;
