export const filterFunctionFactory =
  (
    handleFilter: ((filters: any, data: any) => any) | undefined,
    setValue: (value: any) => void,
    setFilteredData: (data: any) => void,
    setFilterNumber: (number: number) => void,
  ) =>
  (filters: any, data: any) => {
    if (typeof filters == 'undefined') return data;

    let {
      filterValue = null,
      filterData = {},
      filterNumber = 0,
    } = handleFilter ? handleFilter(filters, data) : {};
    setValue(filterValue);
    setFilteredData(filterData);
    setFilterNumber(filterNumber);
  };
