import { useCallback } from 'react';
import { useEffectOnce } from 'react-use';

/**
 * [HOOK] Returns a callback that changes the favicon of the page.
 * If no iconUrl is passed, it will default to the favicon.ico file.
 *
 * @returns {((iconUrl: string) => void)}
 */
export const useChangeFavicon = () => {
  const changeFavicon = useCallback((iconUrl = 'favicon.ico') => {
    let link = document.querySelector<HTMLAnchorElement>("link[rel~='icon']");
    if (link) link.href = `/public/${iconUrl}`;
  }, []);

  useEffectOnce(() => {
    changeFavicon();
  });

  return changeFavicon;
};
