import { useCallback } from 'react';
import { useLocalStorage } from 'react-use';

import { Dot } from '@ds-web/components/atoms/ui/dot/Dot';

import { IWithNewReleasePulsarProps } from './types';

/**
 * A special wrapper that adds a top-right pulsar to the wrapped component.
 * It's used to signal that said component is a new release, and that the user
 * should click on it to hide the pulsar.
 *
 * The hiding mechanism uses the localStorage, so the pulsar will be hidden
 * forever for the user (unless they clean their storage).
 *
 * N.B. Remember that, to work correctly, this component must be wrapped in a
 * tag that has the `position: relative` property set.
 *
 * @interface IWithNewReleasePulsarProps
 * @returns {JSX.Element}
 */
export const WithNewReleasePulsar: React.FC<IWithNewReleasePulsarProps> = ({
  children,
  releaseCode,
  onComponentClickCallback,
  ...dotProps
}) => {
  const [newReleases, setNewReleases] = useLocalStorage('app-new-releases', {});
  const setSingleReleaseInLocalStorage = useCallback(() => {
    setNewReleases({ ...newReleases, [releaseCode]: true });
    onComponentClickCallback?.(releaseCode);
  }, [newReleases, releaseCode, setNewReleases, onComponentClickCallback]);

  return (
    <>
      <div onClick={setSingleReleaseInLocalStorage}>{children}</div>
      {!newReleases?.[releaseCode] && <Dot {...dotProps} />}
    </>
  );
};
