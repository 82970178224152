// ########################## [IMPORTANT LIBRARIES]
import { FC, memo, useContext, useEffect, useMemo, useState } from 'react';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [DESIGN SYSTEM]
import { ModalHeader, ModalBody } from '@shippypro/design-system-web';
import { useGetDeviceSize } from '@shippypro/design-system-web/hooks';

// ########################## [FORMS]
import {
  LabelFormatSettingsForm,
  LabelFormatSettingsFormContext,
  useLabelFormatSettingsForm,
} from './forms/LabelFormatSettingsForm';

// ########################## [TYPES]
import { GLSFormatValue } from '../types';

// ########################## [CONTEXTS]
import { GLSItalyPrintFormatPickerContext } from '../context';

// ########################## [PAGE COMPONENTS]
import StyledGLSItalyPrintFormatPickerModal from '../styles';
import PrintFooter from './print-footer';
import FormatsList from './formats-list';

/**
 * This modal is responsible for letting the user choose the right format
 * for their GLS Italy orders labels. It is attached to a context that's
 * at root-feature-level to allow for it to be opened from several touch points.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const GLSItalyPrintFormatPicker: FC = memo(() => {
  const { t } = useTranslation(),
    trans = translations.ship.buttons.print.glsItaly.modal;

  // Checking if we're on a mobile viewport
  const { isMobile } = useGetDeviceSize();

  const { isOpen, closeDialog, info } = useContext(
    GLSItalyPrintFormatPickerContext,
  )!;

  // Preparing the state that holds the currently selected GLS Format, and resetting it when the modal opens
  const infoFormat = useMemo(() => info?.glsformat ?? -1, [info]);
  const [glsformat, setGlsFormat] = useState<GLSFormatValue>(infoFormat);

  useEffect(() => {
    setGlsFormat(infoFormat);
  }, [infoFormat, isOpen]);

  // Preparing the context for the dialog's settings
  const labelSettingsForm = useLabelFormatSettingsForm();

  return (
    <LabelFormatSettingsFormContext.Provider
      value={{ form: labelSettingsForm, selectedOrders: info?.ids ?? [] }}
    >
      <StyledGLSItalyPrintFormatPickerModal
        isOpen={isOpen}
        toggle={closeDialog}
        data-test="glsitaly-formatpicker-modal"
        backdrop="static"
      >
        <ModalHeader
          toggle={closeDialog}
          className="text-lg text-purpleish font-bold"
        >
          {t(trans.title, { count: info?.ids.length })}
        </ModalHeader>
        <ModalBody className="flex flex-col w-full">
          <span className="modal-body-title text-sm text-purpleish">
            {t(trans.subtitle, { count: info?.ids.length })}
          </span>
          <FormatsList
            ids={info?.ids}
            glsformat={glsformat}
            onFormatClick={setGlsFormat}
          />
          {!isMobile && (
            <LabelFormatSettingsForm form={labelSettingsForm} ids={info?.ids} />
          )}
        </ModalBody>
        <PrintFooter
          ids={info?.ids}
          glsformat={glsformat}
          closeDialog={closeDialog}
        />
      </StyledGLSItalyPrintFormatPickerModal>
    </LabelFormatSettingsFormContext.Provider>
  );
});
