import { FC } from 'react';
import { Navbar as ReactstrapNavbar } from 'reactstrap';
import classnames from 'classnames';

import { INavbarProps } from './types';

/**
 * A simple wrapper class for the `reactstrap: <Navbar />` component.
 *
 * @interface INavbarProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const Navbar: FC<INavbarProps> = ({ className, color, ...rest }) => (
  <ReactstrapNavbar
    className={classnames(className, {
      [`bg-${color}`]: !!color && color !== 'none',
    })}
    {...rest}
  />
);
