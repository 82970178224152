/**
 * * Returns the given string, Capitalized.
 *
 * @param {string} string The string to capitalize
 *
 * @return {string} The capitalized string
 */
export function toCapital(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
