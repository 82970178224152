import { useState, useCallback, useMemo } from 'react';

import { ITrackingDialogContextProps } from '@web/features/tracking-dialog/contexts/TrackingDialogContext';

/**
 * `[HOOK]` This dialog instances every info prop related to the TrackingDialog, from its state to its contents.
 * It is also used as a localised state that can get updated by an external source (I.e. A dedicated context).
 *
 * N.B. **ALREADY INSTANTIATED AT THE NEAR-TOP OF THE CODE TREE, NO NEED TO USE THIS ANYWHERE ELSE**
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 *
 * @returns {ITrackingDialogContextProps} All of the info needed for the dialog to function
 */
const useInstanceTrackingDialog = (): ITrackingDialogContextProps => {
  // Bootstrapping the modal's contents (& visibility)
  const [orderId, setOrderId] = useState<string | number | undefined>(
    undefined,
  );

  // Opening the modal and filling it with order ID
  const onTrackingDialogOpen = useCallback((orderId: string | number) => {
    setOrderId(orderId);
  }, []);

  // Closing the modal and destroying the info
  const onTrackingDialogClose = useCallback(() => {
    setOrderId(undefined);
  }, []);

  return useMemo(
    () => ({
      isOpen: Boolean(orderId),
      orderId,
      openTrackingDialog: onTrackingDialogOpen,
      closeTrackingDialog: onTrackingDialogClose,
    }),
    [onTrackingDialogOpen, onTrackingDialogClose, orderId],
  );
};

export default useInstanceTrackingDialog;
