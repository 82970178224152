import { FC } from 'react';
import { Separator as ContexifySeparator } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';

import { ISeparatorProps } from './types';

/**
 * A simple wrapper class for the `contexify: <Separator />` component.
 *
 * @interface ISeparatorProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const Separator: FC<ISeparatorProps> = props => (
  <ContexifySeparator {...props} />
);
