/**
 * The different views that the Manage Order Modal can be configured in. They represent the various flows
 * an order can take through its history, like shipping, editing, returning, etc...
 */
export enum ManageOrderModalView {
  // The most standard view - Select one or more orders from the `"Ready To Ship" Orders` tab and ship them
  ShipOrders = 'ShipOrders',
  // The view that allows the user to create an order from scratch, filling in all the details manually
  ManualShipment = 'ManualShipment',
  // The view that allows the user to fix one or more orders from the `"Error" Orders` tab and generate again a label
  FixOrders = 'FixOrders',
  // The view that allows the user to edit one or more orders that are already in the `"Ready To Ship" Orders` tab
  EditOrdersToShip = 'EditOrdersToShip',
  // The view that allows the user to edit one order already in the `"Shipped" Orders` tab
  EditOrdersShipped = 'EditOrdersShipped',
  // The view that allows the user to return one or more orders from the `"Shipped" Orders` tab
  ReturnShippedOrders = 'ReturnShippedOrders',
  // The view that allows the user to duplicate one order from the `"Shipped" Orders` tab
  DuplicateShippedOrder = 'DuplicateShippedOrder',
}

/**
 * The different views in which single order can be modified. Every view pertains to a specific aspect of the order
 * and allow the user to have full control over it.
 */
export enum ManageOrderModalEditView {
  // This view allows the user to see in full & edit the order's recipient address (street address, city, state, etc...) and save it to the Address Book
  EditRecipientAddress = 'EditRecipientAddress',
  // This view allows the user to see in full & edit the order's sender address (street address, city, state, etc...) and save it to the Address Book
  EditSenderAddress = 'EditSenderAddress',
  // This view allows the user to check the current parcels, add new ones, edit, remove and duplicate them
  ManageParcels = 'ManageParcels',
  // This view allows the user to see in full & edit the order's details and international customs declaration information (ETDs, CN22, return reason, etc...)
  EditDetailsAndDocs = 'EditDetailsAndDocs',
  // This view allows the user to see every carrier rate possible for the order, select the best one and change its custom carrier options
  ManageRates = 'ManageRates',
  // This view allows the user to review the order's details in a compact but complete view
  ReviewOrder = 'ReviewOrder',
}
