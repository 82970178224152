// ########################## [IMPORTANT LIBRARIES]
import { FC, RefObject, memo, useEffect, useState } from 'react';

// ########################## [TYPES]
import { TabConfiguration } from '@ds-web/components/templates/with-tabs/types';

interface IActiveTabMarkerProps {
  activeTab: TabConfiguration;
  activeTabRef: RefObject<HTMLDivElement>;
}

/**
 * [LAYOUT CARD WITH TOP TABS - "ACTIVE" TAB MARKER] A very simple black bar that marks the active tab.
 * It slides with an animation to whichever tab is selected by the user.
 *
 * @interface IActiveTabMarkerProps
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const ActiveTabMarker: FC<IActiveTabMarkerProps> = memo(
  ({ activeTabRef, activeTab }) => {
    const [barWidth, setBarWidth] = useState<number>(0);
    const [barPosition, setBarPosition] = useState<number>(0);

    useEffect(() => {
      if (activeTabRef.current) {
        if (
          activeTabRef.current.classList.contains('nav-item-dropdown') &&
          activeTabRef.current.offsetParent
        ) {
          setBarWidth(activeTabRef.current.offsetParent['offsetWidth']);
        } else {
          setBarWidth(activeTabRef.current.offsetWidth);
        }

        if (
          activeTabRef.current.classList.contains('nav-item-dropdown') &&
          activeTabRef.current.offsetParent
        ) {
          setBarPosition(activeTabRef.current.offsetParent['offsetLeft']);
        } else {
          setBarPosition(activeTabRef.current.offsetLeft);
        }
      }
    }, [
      activeTab,
      activeTab.mainContent.badgeNumber,
      activeTab.mainContent.hidden,
      activeTabRef,
    ]);

    return (
      <div
        className="animated-active-marker"
        style={{
          width: barWidth - 1,
          left: barPosition,
        }}
      />
    );
  },
);

export default ActiveTabMarker;
