import { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import { showErrorToastr } from '@shippypro/design-system-web/functions';

import {
  SwitchPlatformTogglePayload,
  SwitchPlatformToggleResult,
} from '@web/features/_global/types';

import { handleAPIError } from '@web/utils/@reduxjs/rtk-query';

import { useSwitchPlatformToggleMutation } from '@web/features/_global/api';

/**
 * Returns RTK Query hook with renamed data field.
 * Hook that executes the api call for editing the ui app preference platfrom
 *
 * @author Fabio Mezzina <fabio.mezzina@shippypro.com>
 */
export const useSwitchPlatformToggle = () => {
  const { t } = useTranslation();

  const [trigger, { data, error, ...results }] =
    useSwitchPlatformToggleMutation();

  const switchPlatformToggle = useCallback(async () => {
    const apiPayload: SwitchPlatformTogglePayload = {
      toggle_status: 0,
    };

    const result = await trigger(apiPayload);
    const data = await handleAPIError<SwitchPlatformToggleResult>(result);

    if (!data?.result) {
      showErrorToastr(
        t(translations.sideMenu.experienceModal.toastr.tryAgainLater),
        t(translations.common.errors.error),
      );
      return false;
    }

    return data?.result;
  }, [t, trigger]);

  return useMemo(
    () => ({
      switchPlatformToggle,
      ...results,
    }),
    [switchPlatformToggle, results],
  );
};
