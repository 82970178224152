import {
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  PropsWithChildren,
  ReactNode,
} from 'react';

import classnames from 'classnames';

export interface FlatCardProps
  extends Omit<
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    'title'
  > {
  title: string | ReactNode;
  action?: ReactNode;
}

/** TODO: move to design system
 * A simple layout with a formatted title
 * @author Fabio Fognani <fabio.fognani@shippypro.com>
 */
export const FlatCard: FC<PropsWithChildren<FlatCardProps>> = ({
  children,
  title,
  action,
  ...containerProps
}) => {
  return (
    <div
      {...containerProps}
      className={classnames('p-1', containerProps?.className)}
    >
      <div className="flex justify-between items-center border-b-[1px] pb-[4px] mb-1 text-section-title text-[0.857rem]">
        <p className="text-uppercase font-medium">{title}</p>
        {action}
      </div>
      {children}
    </div>
  );
};
