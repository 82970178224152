import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@web/utils/@reduxjs/toolkit';
import { FeatureState } from './types';

export const initialState: FeatureState = {
  open: false,
};

const changelogModalSlice = createSlice({
  name: 'changelogModal',
  initialState,
  reducers: {
    toggleChangelogModal(state, { payload }: PayloadAction<boolean>) {
      state.open = payload;
    },
  },
});

export const {
  actions: changelogModalActions,
  reducer,
  name: sliceKey,
} = changelogModalSlice;
