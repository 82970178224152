import { FetchArgs } from '@reduxjs/toolkit/query/react';

import { UserPreferencesDomain } from '@web/types/user/preferences';

import { BASE_MICROSERVICE_ALLOWED_METHODS, getMicroserviceQuery } from '..';
import { getMicroserviceUrl } from '@web/utils/@microservices/functions';

import { MicroserviceNames } from '@web/utils/@microservices/types';

type USERENGINE_ALLOWED_METHODS = Exclude<
  BASE_MICROSERVICE_ALLOWED_METHODS,
  'POST' | 'PATCH'
>;

/**
 * This method scaffolds the query object for the "User Engine" microservice API request.
 * It takes a url that goes AFTER the base one, an allowed REST method code and an optional body.
 * N.B. Said body is only used for non-GET requests.
 *
 * @param {string} url The url of the microservice endpoint.
 * @param {USERENGINE_ALLOWED_METHODS} method The REST method code.
 * @param {ArgT} [args] The body of the request.
 * @returns {FetchArgs} The query object.
 */
function getUserEngineMicroserviceQuery<ArgT>(
  url: string,
  method: USERENGINE_ALLOWED_METHODS,
  args?: ArgT,
): FetchArgs {
  return getMicroserviceQuery<ArgT>(
    `${getMicroserviceUrl(MicroserviceNames.USER_ENGINE)}/${url}`,
    method,
    args,
  );
}

/**
 * This method scaffolds the query object for the "User Engine" microservice API request.
 * It specifically targets the "preferences" endpoint part of the engine.
 * It takes the domain set of the user preferences object, an allowed REST method code and an optional body.
 * N.B. Said body is only used for non-GET requests.
 *
 * @param {UserPreferencesDomain} domain The domain set of the user preferences object.
 * @param {USERENGINE_ALLOWED_METHODS} method The REST method code.
 * @param {ArgT} [args] The body of the request.
 * @returns {FetchArgs} The query object.
 */
export function getUserEnginePreferencesMicroserviceQuery<
  ArgT extends { user_id: number; [key: string]: unknown | undefined },
>(
  domain: UserPreferencesDomain,
  method: USERENGINE_ALLOWED_METHODS,
  args: ArgT,
): FetchArgs {
  return getUserEngineMicroserviceQuery<ArgT>(
    `${args.user_id}/preferences/${domain}`,
    method,
    args,
  );
}
