// ########################## [IMPORTANT LIBRARIES]
import { useCallback, useEffect, useMemo } from 'react';

import { DropdownMenu, UncontrolledDropdown } from 'reactstrap';

import { getDomPath } from '@ds-web/utils/functions/dom';

import './DropdownSharedMenu.css';
import { IDropdownSharedMenu } from './types';

export const DropdownSharedMenu = ({
  id,
  hideOnLeave,
  hideOnScroll = true,
  position,
  children,
  hide,
  show,
  isActive,
  updatePosition,
}: IDropdownSharedMenu) => {
  const dropdownMenuId = useMemo(() => `${id}-dropdown-menu`, [id]);
  const { x, y } = position || {};

  const toggle = useCallback(() => {
    if (isActive) {
      hide();
    } else {
      show();
    }
  }, [isActive, show, hide]);

  useEffect(() => {
    if (!updatePosition) return;
    // We want to fix the menu position, bringing it back into the viewport if it overflows.
    // We're going to use setTimeout because if the rendering is not finished yet,
    // getBoundingClientRect will return all values equal to zero (...yes, it's weird).
    // So we basically want to do what follows:
    // - check if we have dimensions greater than zero
    // - if so, fix the position of the menu (if it overflows)
    // - if not, call the function recursively to schedule a new check
    let triesLeft = 500;
    const scheduleFixPosition = () => {
      setTimeout(() => {
        const { x, y } = position || {};
        const menuElem = document.getElementById(dropdownMenuId);

        if (menuElem) {
          const menuDimensions = menuElem?.getBoundingClientRect();

          if (x !== undefined && y !== undefined && menuDimensions) {
            const { width, height } = menuDimensions;

            if (!width || !height) {
              return --triesLeft > 0 && scheduleFixPosition();
            }

            // takes into consideration the width of the scrollbar
            const bodyDimensions = document.body.getBoundingClientRect();
            const left = Math.min(x, bodyDimensions.width - width);
            const top = Math.min(y, bodyDimensions.height - height);
            if (x !== left || top !== y) {
              updatePosition &&
                updatePosition({
                  x: left,
                  y: top,
                });
            }
          }
        }
      }, 10);
    };

    scheduleFixPosition();
    // linter says that T is missing in the hook dependencies array
    // seems to be a TypeScript ^4.0 problem
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePosition, dropdownMenuId, position]);

  useEffect(() => {
    if (hideOnScroll) {
      document.addEventListener('scroll', hide, false);
    }
    return function cleanup() {
      document.removeEventListener('scroll', hide, false);
    };
  }, [hideOnScroll, hide]);

  return (
    <UncontrolledDropdown
      onMouseLeave={() => hideOnLeave && hide && hide()}
      style={{ position: 'fixed', top: y, left: x, zIndex: 100 }}
      isOpen={isActive}
      toggle={(e: any) => {
        const clickedInsideDropwdown = getDomPath(e.target).find(elemId =>
          new RegExp(id).test(elemId),
        );

        if (!clickedInsideDropwdown) {
          toggle();
        }
      }}
    >
      <DropdownMenu
        onClick={toggle}
        id={dropdownMenuId}
        className="animate--fade-in"
      >
        {children}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
