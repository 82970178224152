import getSymbolFromCurrency from 'currency-symbol-map';

/**
 * This function attempts to translate the 3-letter currency code into a symbol
 * using an external library. If the currency code is not found, it returns the
 * currency code itself.
 *
 * @param {string | null | undefined} currency The currency 3-letter code
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 *
 * @returns {string} The currency symbol
 */
export const toCurrencySymbol = (
  currency: string | null | undefined,
): string => {
  const symbol = getSymbolFromCurrency(currency ?? 'EUR');

  return symbol ?? currency ?? '€';
};
