import { TFunction } from 'i18next';
import { translations } from '@shippypro/translations';
import { useTranslation } from 'react-i18next';

/**
 * [HOOK] function that gets a tracking status code (1, 2, 3...) and the corresponding icon
 * and returns an array with the icon code to display and a label with the correct translation.
 *
 * @author Fabio Fognani <fabio.fognani@shippypro.com>
 * @author Fabio Mezzina <fabio.mezzina@shippypro.com>
 *
 * @param trackingStatus {string} the Status code gotten from the API
 *
 * @returns {[string, string]} An array with the icon and the correct label for the code
 */
const useGetTrackingStatusIconAndLabel = (
  trackingStatus?: string | null,
): [string, string] => {
  const { t } = useTranslation();
  const icon = getTrackingStatusIcon(trackingStatus);
  const label = getTrackingStatusLabel(t, trackingStatus);
  return [icon, label];
};

export const getTrackingStatusLabel = (
  t: TFunction,
  trackingStatus?: string | null,
): string => {
  const transEvents = translations.order.events;

  switch (trackingStatus?.toString()) {
    case '1':
      return t(transEvents.infoReceived);
    case '2':
      return t(transEvents.inTransit);
    case '3':
      return t(transEvents.outForDelivery);
    case '4':
      return t(transEvents.failedAttempt);
    case '5':
      return t(transEvents.exception);
    case '6':
      return t(transEvents.delivered);
    default:
      return t(transEvents.noEvents);
  }
};

export const getTrackingStatusIcon = (
  trackingStatus?: string | null,
): string => {
  switch (trackingStatus?.toString()) {
    // info received
    case '1':
      return 'IconFileText';
    // in transit
    case '2':
      return 'IconTruck';
    // out for delivery
    case '3':
      return 'IconPackageExport';
    // failed attempt
    case '4':
      return 'IconAlertCircle';
    // exception
    case '5':
      return 'IconAlertCircle';
    // delivered
    case '6':
      return 'IconCheck';
    // no events
    default:
      return 'IconFileText';
  }
};

export default useGetTrackingStatusIconAndLabel;
