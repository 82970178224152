import styled from 'styled-components';

export const StyledPace = styled.div`
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  height: 4px;
  background-color: var(--shp-color-shippypro-magenta);

  transition: width 0.3s ease-in-out, opacity 0.5s ease-in-out;
  transition-delay: 0.3s;

  pointer-events: none;
`;
