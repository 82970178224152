// ########################## [IMPORTANT LIBRARIES]
import {
  Dispatch,
  SetStateAction,
  useCallback,
  FC,
  useEffect,
  useRef,
} from 'react';
import classnames from 'classnames';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [DESIGN SYSTEM]
import {
  Input,
  TooltipWrapperByREF,
  IconWrapper,
} from '@shippypro/design-system-web';
import { IconHelper } from '@shippypro/design-system-web/iconhelper';

// ########################## [UTILS]
import {
  deleteNoteConfirmation,
  keepEditingOrDiscardConfirmation,
} from '@web/utils/@sweetalerts';

// ########################## [DESIGN SYSTEM]
import { NoteBlock } from '@shippypro/design-system-web';

export type IOrderNoteProps = {
  category?: string;
  dataTest?: string;
  value: string;
  onChange?: (value: string) => unknown;
  minWidth?: string;
  maxWidth?: string;
  minHeight?: string;
  maxHeight?: string;
  maxChars?: number;
  disabled?: boolean;
  editable?: boolean;
  isEditing?: boolean;
  isSaving?: boolean;
  onSubmit?: (note: string) => void;
  setEditing?: (
    v: boolean,
    save: boolean,
  ) => void | Dispatch<SetStateAction<boolean>>;
};

/**
 * This is a stateless component for showing & editing notes
 * Editing can be enabled/disabled via props.
 *
 * @author Fabio Fognani <fabio.fognani@shippypro.com>
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
const OrderNote: FC<IOrderNoteProps> = ({
  category,
  dataTest,
  editable,
  isEditing,
  setEditing,
  value,
  onChange,
  maxChars = 250,
  minWidth = '64px',
  maxHeight = '400px',
  disabled,
  isSaving,
  onSubmit,
}) => {
  const { t } = useTranslation(),
    transOrderTooltips = translations.order.tooltips;

  const doSetEditing = useCallback(
    (v: boolean, save: boolean = true) => {
      return setEditing && setEditing(v, save);
    },
    [setEditing],
  );

  const onClose = useCallback(() => {
    doSetEditing(false);
  }, [doSetEditing]);

  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        isEditing &&
        event.target.id !== 'save-note-action' &&
        !ref.current?.contains(event.target)
      ) {
        document.removeEventListener('mousedown', handleClickOutside);
        keepEditingOrDiscardConfirmation(
          () => {
            doSetEditing(true);
            document.addEventListener('mousedown', handleClickOutside);
          },
          () => {
            doSetEditing(false, false);
          },
        );
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, isEditing, doSetEditing]);

  return (
    <NoteBlock
      editable={editable}
      category={category}
      isSaving={isSaving}
      onEditAction={() => {
        !disabled && doSetEditing(true);
      }}
      onDeleteAction={() => {
        !disabled &&
          deleteNoteConfirmation(() => {
            if (onChange) onChange('');
            if (onSubmit) onSubmit('');
          });
      }}
      isEditingIconComponent={
        <IconWrapper
          data-test="save-note-action"
          id="save-note-action"
          onClick={onClose}
        >
          <TooltipWrapperByREF
            placement="bottom"
            content={t(transOrderTooltips.saveNote)}
          >
            <IconHelper
              icon="IconCheck"
              className="!w-[16px] !h-[16px]"
              id="save-note-action"
              tabIndex={disabled ? -1 : 0}
            />
          </TooltipWrapperByREF>
        </IconWrapper>
      }
      noteCardBodyComponent={
        <div
          className={classnames('flex items-center w-full', {
            '!cursor-pointer': editable,
          })}
        >
          {isEditing ? (
            <div ref={ref} className="flex items-center space-x-1 w-full">
              {/* the actual input for edit mode */}
              <Input
                type="textarea"
                rows={Math.floor((value ?? '').length / 50) + 3} // auto resize rows
                autoFocus
                disabled={disabled}
                style={{ minWidth, maxHeight }}
                className="note-textarea w-full h-[70px] md:h-[unset]"
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    onClose();
                  }
                }}
                placeholder={t(
                  translations.ship.table.columns.actions.notePlaceholder,
                  { maxChars },
                )}
                onChange={e => {
                  if (onChange) {
                    onChange(e.target.value.substring(0, maxChars));
                  }
                }}
                value={value}
                data-test="order-note-input"
              />
            </div>
          ) : (
            <div className="flex items-center space-x-2 whitespace-normal">
              {/* this will hold the value of the note when not in edit mode */}
              <span
                data-test={dataTest}
                onClick={() => editable && doSetEditing(true)}
                className="text-left text-[color:--shp-color-text-body] leading-5"
                style={{
                  minWidth: editable ? minWidth : undefined,
                }}
              >
                {value}
              </span>
            </div>
          )}
        </div>
      }
    />
  );
};

export default OrderNote;
