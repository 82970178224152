import { FC } from 'react';

import { BreadcrumbItem as ReactstrapBreadcrumbItem } from 'reactstrap';

import { IBreadcrumbItemProps } from './types';

/**
 * A simple wrapper class for the `reactstrap: <BreadcrumbItem />` component.
 *
 * @interface IBreadcrumbItemProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const BreadcrumbItem: FC<IBreadcrumbItemProps> = props => {
  return <ReactstrapBreadcrumbItem {...props} />;
};
