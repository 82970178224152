import { FC, useState } from 'react';
import classnames from 'classnames';

import RippleButton from './_rippleEffect';

import { StyleType, BorderType } from '@ds-web/tokens/enums';

import { IButtonProps } from './types';

/**
 * The main `reactstrap: <Button />` component, complete with a ripple effect on click,
 * and the ability to change the style type, border type, border color, text color, and icon.
 * A button is filled and primary by default.
 *
 * @interface IButtonProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const Button: FC<IButtonProps> = ({
  styleType = StyleType.filled,
  color,
  borderType = BorderType.default,
  borderColor,
  textColor,
  icon,
  iconOnHover = undefined,
  className,
  children,
  ...rest
}) => {
  let outline = false,
    colorClass: string | undefined = color;

  // Manage style type between being filled, outlining the border or removing the background
  switch (styleType) {
    case StyleType.filled:
      break;
    case StyleType.outline:
      outline = true;
      break;
    case StyleType.flat:
      colorClass = `flat-${color}`;
      break;
  }

  // Manage icon changing on hover
  const [iconBtn, setIconBtn] = useState(icon);
  const handleMouseIn = () => {
    if (iconOnHover) setIconBtn(iconOnHover);
  };
  const handleMouseOut = () => {
    if (iconOnHover) setIconBtn(icon);
  };

  return (
    <RippleButton
      onMouseOver={handleMouseIn}
      onMouseOut={handleMouseOut}
      className={classnames(className, {
        [borderType]: borderType,
        [`border-${borderColor}`]: borderColor,
        [`text-${textColor}`]: textColor,
      })}
      color={colorClass}
      outline={outline}
      {...rest}
    >
      {iconBtn} {children}
    </RippleButton>
  );
};
