import { FC } from 'react';

import { CardTitle as ReactstrapCardTitle } from 'reactstrap';

import { ICardTitleProps } from './types';

/**
 * A simple wrapper class for the `reactstrap: <CardTitle />` component.
 *
 * @interface ICardTitleProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const CardTitle: FC<ICardTitleProps> = props => (
  <ReactstrapCardTitle {...props} />
);
