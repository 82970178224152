import styled from 'styled-components';

/**
 * This Layout is an addon to position a table column in a `sticky` manner
 * and make sure it doesn't move when scrolling said table horizontally.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const StickyLayout = styled.div`
  .react-table-header {
    .react-table-header-column:last-child {
      position: sticky;
      right: 0;
      z-index: 10;

      background-color: var(--shp-color-bg-table-header);
    }
  }
  .react-table-body-row {
    .react-table-body-row-cell:last-child {
      position: sticky;
      right: 0;
      z-index: 10;

      background-color: var(--shp-color-bg);
      right: -1px;
    }
  }
`;

export default StickyLayout;
