import { FC, MouseEventHandler, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import {
  TooltipWrapperByID,
  ImageWithOverlayAndStatus,
} from '@shippypro/design-system-web';

// ########################## [UTILITIES]
import { useUpdateOrdersMarketplace } from '@web/features/order/hooks/api/useUpdateOrdersMarketplace';
import { selectMktpOrdersUpdating } from '@web/features/order/selectors';

interface IConnectionAvatar {
  type: 'marketplace' | 'carrier';
  orderId: number;
  img: string;
  alt?: string | null;
  status: boolean | null;
  allowEvents?: boolean;
  isStatic?: boolean;
  allowTooltip?: boolean;
  hideStatus?: boolean;
  tooltipId?: string;
}

const ConnectionAvatar: FC<IConnectionAvatar> = ({
  orderId,
  type,
  img,
  alt,
  status,
  allowEvents = true,
  isStatic,
  allowTooltip = true,
  hideStatus = false,
  tooltipId,
}) => {
  const { t } = useTranslation(),
    transOrder = translations.order;

  const updatingOrders = useSelector(selectMktpOrdersUpdating);
  const { updateOrdersMarketplace, isLoading: updateMarketplaceLoading } =
    useUpdateOrdersMarketplace();

  const isUpdatingMarketplace =
    orderId !== null &&
    (updateMarketplaceLoading || updatingOrders.includes(orderId));

  const loading =
    (type === 'marketplace' && isUpdatingMarketplace) ||
    (type === 'carrier' && false); // not ready yet, but maybe in the future...

  const connectionAvatarId = useMemo(() => {
    return `connection-avatar-${type}-${orderId}`;
  }, [orderId, type]);

  const onClick = useMemo<MouseEventHandler<HTMLElement> | undefined>(() => {
    // Type marketplace never set
    /* istanbul ignore next */
    if (type === 'marketplace') {
      return e => {
        e.preventDefault();
        e.stopPropagation();
        if (loading) return;

        updateOrdersMarketplace([orderId], 1);
      };
    }
    // else undefined (otherwise AvatarWithStatus will show the "onHover" layer)
  }, [type, loading, updateOrdersMarketplace, orderId]);

  const tooltipTitle = useMemo(() => {
    // Type marketplace never set
    /* istanbul ignore next */
    if (type === 'marketplace') {
      return (
        <span className="whitespace-nowrap">
          {t(transOrder.updateMarketplace.clickToRefreshMarketplace)}
        </span>
      );
    } else {
      return <span className="whitespace-nowrap">{alt}</span>;
    }
  }, [type, alt, t, transOrder]);

  return (
    <TooltipWrapperByID
      target={tooltipId ?? connectionAvatarId}
      text={tooltipTitle}
      active={allowTooltip && Boolean(tooltipTitle)}
    >
      <ImageWithOverlayAndStatus
        img={img}
        alt={alt ?? ''}
        loading={loading}
        onClick={isStatic ? undefined : onClick}
        hideOverlay={isStatic}
        status={status}
        allowEvents={allowEvents}
        pictureProps={{
          id: tooltipId ?? connectionAvatarId,
        }}
        hideStatus={hideStatus}
      />
    </TooltipWrapperByID>
  );
};

export default ConnectionAvatar;
