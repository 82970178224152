import { FC } from 'react';

import { Breadcrumb as ReactstrapBreadcrumb } from 'reactstrap';
import classnames from 'classnames';

import { IBreadcrumbProps } from './types';

/**
 * A simple wrapper class for the `reactstrap: <Breadrcumb />` component.
 * It also adds the ability to change the separator type between symbols, arrows, slashes, etc...
 *
 * @interface IBreadcrumbProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const Breadcrumb: FC<IBreadcrumbProps> = ({
  separator,
  listClassName,
  ...rest
}: IBreadcrumbProps) => (
  <ReactstrapBreadcrumb
    listClassName={classnames(listClassName, {
      [`breadcrumb-${separator}`]: separator && separator !== 'default',
    })}
    {...rest}
  />
);
