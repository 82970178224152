export enum Size {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
}

export enum SizeWithXL {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}

export enum SizeWithXS {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
}

export enum FullSize {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}
