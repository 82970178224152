import { Progress } from 'reactstrap';
import styled from 'styled-components';

export const StyledProgress = styled(Progress)`
  .progress-bar {
    background: linear-gradient(to left, #7349dd, #46ebc8);
    box-shadow:
      0 3px 3px -5px #babfc7,
      0 2px 5px #babfc7;
    border-radius: 20px;
    color: #fff;
  }
`;
