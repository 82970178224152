// ########################## [IMPORTANT LIBRARIES]
import React from 'react';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [TYPES]
import { Order } from '@web/types/order';

// ########################## [PAGE COMPONENTS]
import CardView from '@web/components/common-ui/wrappers/card/CardView';
import DetailsCardHeader from './DetailsCardHeader';
import { FlatCard } from '@web/components/common-ui/wrappers/flat-card/flat-card';
import { OrderItemListItem } from '@web/components/common-features/order/OrderItemListItem';
import { AddressWithActionOnHover } from '@web/components/common-features/address/AddressWithActionsOnHover';

interface IDetailsCard {
  order: Order;
  isDutiable: boolean;
  className: string;
}

/**
 * The DetailsCard component gets the order from outside
 * and renders a card with recipient and items details.
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 *
 * @version 1.0.0
 * @interface IDetailsCard
 * @returns {JSX.Element}
 */
const DetailsCard: React.FC<IDetailsCard> = ({
  order,
  isDutiable,
  className,
}) => {
  const { t } = useTranslation(),
    transDetailsCard = translations.order.info.trackingDialog.detailsCard;

  const items = order.items;

  return (
    <CardView
      header={<DetailsCardHeader order={order} isDutiable={isDutiable} />}
      headerMuted={false}
      flex={false}
      hideActions
      headerCSS="w-full border-bottom-light details-card-header"
      bodyCSS="overflow-y-auto w-full h-100 details-card-body"
      cardCSS="items-baseline"
      className={className}
    >
      <FlatCard
        title={t(transDetailsCard.recipient)}
        className={'px-0'}
        data-test="customer-section"
      >
        <AddressWithActionOnHover address={order.address} />
      </FlatCard>

      <FlatCard title={t(transDetailsCard.items)} className={'px-0'}>
        {!items?.length && <p>{t(transDetailsCard.noItems)}</p>}
        {items?.map(item => (
          <OrderItemListItem
            item={item}
            key={item.id}
            currency={order.currency}
            showZoomOverlay={false}
          />
        ))}
      </FlatCard>
    </CardView>
  );
};

export default DetailsCard;
