import { FC } from 'react';

import OrderNote, {
  IOrderNoteProps,
} from '@web/components/common-features/order/notes/OrderNote';

export type IShippyProNoteProps = Omit<
  IOrderNoteProps,
  'color' | 'editable'
> & {
  editable?: boolean;
};

/**
 * This component hosts the note written on ShippyPro, with its fixed color.
 * This note is editable.
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 *
 * @version 1.0.0
 * @interface IShippyProNoteProps
 * @returns {JSX.Element}
 */

const ShippyProNote: FC<IShippyProNoteProps> = ({
  editable = true,
  ...rest
}) => {
  return <OrderNote editable={editable} dataTest="shippypro-note" {...rest} />;
};

export default ShippyProNote;
