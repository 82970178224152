// ########################## [IMPORTANT LIBRARIES]
import { MouseEvent, ReactNode, useCallback, useContext, useMemo } from 'react';

import { ColumnDef, createColumnHelper, Row } from '@tanstack/react-table';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [TYPES]
import { Order } from '@web/types/order';
import { MenuId } from '@web/features/ship/types';

// ########################## [DESIGN SYSTEM]
import { IconHelper } from '@shippypro/design-system-web/iconhelper';
import {
  InteractableIconButton,
  IconWrapper,
} from '@shippypro/design-system-web';

// ########################## [PAGE COMPONENTS]
import { TooltipWrapperByREF } from '@shippypro/design-system-web';

// ########################## [CONTEXTS]
import { OrdersContextMenuCtx } from '@web/features/ship/types/context-menu';
import { shipActions } from '@web/features/ship/slice';
import { useDispatch } from 'react-redux';

const columnHelper = createColumnHelper<Order>();

export const useGetContextMenuColumn = (
  orders: Order[],
  selectedOrders: Order[],
  menuId: MenuId,
  getCustomActions?: (row: Row<Order>) => ReactNode,
  showPrimaryAction: boolean = true,
  hideContextMenu: boolean = false,
  showSettingsAction: boolean = true,
  showLargeRowDensityOption: boolean = true,
  isFetching?: boolean,
): ColumnDef<Order> => {
  const { t } = useTranslation(),
    transTabSettingsDrawer = translations.ship.table.tabSettingsDrawer;

  const dispatch = useDispatch();

  const { useSharedMenu } = useContext(OrdersContextMenuCtx);
  const { show } = useSharedMenu(menuId);

  /**
   * For some reason, adding "show" as a dependency of showContextMenu
   * and displayMenu caused an infinite loop. I'm wrapping this in a callback
   * with no dependencies, in order to avoid the loop but without losing control
   * of other dependencies in the above mentioned hooks.
   *
   * TODO: find out why show causes the infinite loop, we may have to fix some
   * useCallback in design system
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const showMenu = useCallback(show, []);

  const displayMenu = useCallback(
    (e: MouseEvent<HTMLDivElement>, id: number) => {
      const order = orders.find(o => o.id === id);
      if (!order) return;
      showMenu({
        selectedOrders: [order],
        position: {
          x: e.clientX,
          y: e.clientY,
        },
        showPrimaryAction,
      });
    },
    [orders, showMenu, showPrimaryAction],
  );

  const handleTableSettingsOpen = useCallback(() => {
    dispatch(shipActions.openTableSettings(showLargeRowDensityOption));
  }, [dispatch, showLargeRowDensityOption]);

  return useMemo(
    () =>
      columnHelper.display({
        enableResizing: false,
        header: () => (
          <div className="w-full h-full flex justify-end items-center">
            <div
              className="cursor-pointer bg-[color:--shp-color-bg-table-header]"
              onClick={() => handleTableSettingsOpen()}
              data-test="table-settings-cta"
            >
              <TooltipWrapperByREF
                content={t(transTabSettingsDrawer.tableSettings)}
              >
                <InteractableIconButton icon="IconSettings" />
              </TooltipWrapperByREF>
            </div>
          </div>
        ),
        meta: {
          ignoreRowClick: true,
        },
        id: 'context-menu',
        size: getCustomActions ? 95 : 80,
        enableSorting: false,
        cell: ({ row }) =>
          documentationColumnRendererFn(
            getCustomActions,
            displayMenu,
            row.original,
            row,
            hideContextMenu,
            isFetching,
          ),
      }),
    [
      t,
      getCustomActions,
      transTabSettingsDrawer.tableSettings,
      handleTableSettingsOpen,
      displayMenu,
      hideContextMenu,
      isFetching,
    ],
  );
};

const documentationColumnRendererFn = (
  getCustomActions: ((row: Row<Order>) => ReactNode) | undefined,
  displayMenu: (e: MouseEvent<HTMLDivElement>, id: number) => void,
  { id }: Order,
  row: Row<Order>,
  hideContextMenu: boolean,
  isFetching?: boolean,
) => {
  return (
    <div className="h-[115%] flex justify-end items-center context-menu">
      <small className="hidden">{id}</small>
      {/* TODO: fix when completing the porting to the new tables  */}
      {getCustomActions && getCustomActions(row)}
      {!hideContextMenu && (
        <IconWrapper
          dataTest={`context-menu-toggle-${id}`}
          onClick={e => displayMenu(e, id)}
          disabled={isFetching}
        >
          <IconHelper icon="IconDots" size="22" />
        </IconWrapper>
      )}
    </div>
  );
};
