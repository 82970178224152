import { Color } from '@shippypro/design-system-web/enums';

import { Dot } from '@shippypro/design-system-web';
import { IconHelper } from '@shippypro/design-system-web/iconhelper';
import { Navbar as ShippyProNavbar } from '@shippypro/design-system-web';

import { NavbarAvatar, NavbarHelp } from './Elements';

import { useGetDeviceSize } from '@shippypro/design-system-web/hooks';
import { useGetSetupData } from '@web/features/_global/hooks/api/useGetSetupData';
import useLDFlag from '@web/hooks/useLDFlags';

export interface INavbarProps {
  name: string;
  email: string;
  img?: string;
  hide?: boolean;
  onClick: () => void;
}

const Navbar: React.FC<INavbarProps> = ({
  name,
  img,
  hide = false,
  email,
  onClick,
}) => {
  const { isMobile } = useGetDeviceSize();

  const {
    data: [, setup],
  } = useGetSetupData();
  const { newExperienceFlow } = useLDFlag();

  const iconSize = 20;
  return (
    <ShippyProNavbar
      color={Color.none}
      className="header-navbar navbar-expand-lg navbar navbar-with-menu navbar-light navbar-shadow floating-nav left-0 position-sticky top-6 !z-30 !mb-8"
      hidden={hide}
    >
      <div className="navbar-wrapper w-full">
        <div className="navbar-container content">
          <div
            className="navbar-collapse justify-between flex"
            id="navbar-mobile"
          >
            <div className="flex items-center !gap-8 sm:!gap-24">
              {isMobile && (
                <div className="relative">
                  <IconHelper
                    onClick={
                      /* istanbul ignore next */
                      () => onClick()
                    }
                    icon="IconMenu2"
                    size={17}
                  />
                  {setup &&
                    !setup.progress.setupCompleted &&
                    newExperienceFlow && (
                      <Dot
                        color={Color.accent}
                        dotClassName="left-[75%]"
                        isPulsing
                      />
                    )}
                </div>
              )}
            </div>

            <div className="xmr-10 xw-auto"></div>
            <ul className="nav navbar-nav navbar-nav-user right xw-100">
              <NavbarHelp email={email} iconSize={iconSize} />
              <NavbarAvatar email={email} name={name} img={img} />
            </ul>
          </div>
        </div>
      </div>
    </ShippyProNavbar>
  );
};

export default Navbar;
