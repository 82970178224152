// ########################## [IMPORTANT LIBRARIES]
import React, { useMemo } from 'react';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [DESIGN SYSTEM]
import { TooltipWrapperByID } from '@shippypro/design-system-web';
import { IconHelper } from '@shippypro/design-system-web/iconhelper';

// ########################## [TYPES]
import { Documentation } from '@web/types/order';

interface IAddedDocuments {
  id: string;
  documentation: Documentation[] | null;
}

/**
 * The AddedDocuments component gets the order documentation from outside
 * and renders a file text icon with the documentation count beside.
 * On hover it shows the list of file types.
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 *
 * @version 1.0.0
 * @interface IAddedDocuments
 * @returns {JSX.Element}
 */
const AddedDocuments: React.FC<IAddedDocuments> = ({ id, documentation }) => {
  const { t } = useTranslation(),
    transDocumentType = translations.common.documentType;

  const documentsList: string = useMemo(() => {
    return (
      documentation?.map(({ type }) => t(transDocumentType[type])).join(', ') ??
      ''
    );
  }, [documentation, t, transDocumentType]);

  return documentation && documentation.length > 0 ? (
    <TooltipWrapperByID
      tooltipProps={{
        placement: 'top',
      }}
      target={id}
      text={documentsList}
    >
      <div id={id} className="flex items-center text-sm">
        <IconHelper icon="IconFileText" size={13} className="mr-1" />
        {documentation.length}
      </div>
    </TooltipWrapperByID>
  ) : null;
};

export default AddedDocuments;
