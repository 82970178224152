import { Dispatch, SetStateAction, createContext } from 'react';

import { HelpSubSection, LiveChatObject } from '@web/features/help/types';

export interface IHelpSectionContextProps {
  isOpen: boolean;

  subSection: HelpSubSection;
  setSubSection: Dispatch<SetStateAction<HelpSubSection>>;

  liveChat: LiveChatObject;

  openHelpSection: () => void;
  closeHelpSection: () => void;
}

/**
 * This is the Help Context that is instantiated at the near-top of the component tree.
 * This allows any feature to open the section and give help to a user that is searching for:
 * * The Ticketing system;
 * * The Support Chat;
 * * The Help Center Articles;
 *
 * The context holds every info possible that the help section needs to function correctly:
 * * IF the section is open or not;
 * * A Handy handler function to OPEN the modal;
 * * A Handy handler function to CLOSE the modal;
 *
 * @example
 * const { openHelpSection } = useContext(HelpContext)!;
 * ...
 * <button onClick={openHelpSection}>Click Me for help!</button>
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const HelpSectionContext = createContext<IHelpSectionContextProps | null>(null);

export default HelpSectionContext;
