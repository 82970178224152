// ########################## [IMPORTANT LIB]
import { memo, useCallback } from 'react';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [DESIGN SYSTEM]
import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
} from '@shippypro/design-system-web';
import { Color, FullSize } from '@shippypro/design-system-web/enums';

// ########################## [PAGE COMPONENTS]
import { ModalFooter } from 'reactstrap';

// ########################## [UTILS]
import { goToLegacy } from '@web/utils/functions';

// ########################## [HOOKS]
import { useSwitchPlatformToggle } from '../hooks/api/useSwitchPlatformToggle';
import useGetLocaleLink from '@web/hooks/useGetLocaleLink';

interface IPreviousExperienceModalProps {
  isOpen: boolean;
  onClose: () => void;
}

/**
 * The modal to warn the client on the platform change
 *
 * @author [Fabio Mezzina] <fabio.mezzina@shippypro.com>
 */
export const PreviousExperienceModal: React.FC<IPreviousExperienceModalProps> =
  memo(({ isOpen, onClose }) => {
    const { t } = useTranslation(),
      trans = translations.sideMenu.experienceModal;

    const { switchPlatformToggle, isLoading } = useSwitchPlatformToggle();

    const goToPreviousExperience = useCallback(() => {
      switchPlatformToggle().then(res => {
        if (res) goToLegacy();
      });
    }, [switchPlatformToggle]);

    const deliverSurvey = useGetLocaleLink('deliverSurvey');

    return (
      <Modal
        className="modal-dialog-centered modal-wrapper"
        size={FullSize.md}
        isOpen={isOpen}
        data-test="new-experience-modal"
      >
        <ModalHeader color={'white' as Color} toggle={() => onClose()} />
        <ModalBody className="flex flex-col items-center">
          <h3 className="mb-2 text-center text-purpleish text-[24px] font-semibold">
            {t(trans.header)}
          </h3>
          <p className="text-center text-xl text-purpleish font-normal">
            {t(trans.body)}
          </p>
        </ModalBody>
        <ModalFooter className="mb-2 border-0 justify-evenly">
          <Button
            color="outline-primary"
            onClick={() => {
              onClose();
              window.open(deliverSurvey, '_blank');
            }}
            disabled={isLoading}
            className="tell-us-more"
          >
            {t(trans.tellUsMore)}
          </Button>
          <Button
            color="primary"
            onClick={() => goToPreviousExperience()}
            disabled={isLoading}
            className="go-to-previous"
          >
            {t(trans.cta)}
          </Button>
        </ModalFooter>
      </Modal>
    );
  });
