// ########################## [IMPORTANT LIBRARIES]
import { Dispatch, FC, ReactNode, SetStateAction } from 'react';
import { Collapse } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns/esm';

// ########################## [I18N]
import { translations } from '@shippypro/translations';

// ########################## [TYPES]
import { IOrderInfoBlock } from './types';

// ########################## [DESIGN SYSTEM]
import { IconHelper } from '@shippypro/design-system-web/iconhelper';
import {
  toCurrencySymbol,
  toLocalizedPrice,
} from '@shippypro/design-system-web/functions';

// ########################## [COMPONENTS]
import TrackingCodeBadge from './TrackingCodeBadge';

// ########################## [HOOKS]
import useParseOrderStatus from '@web/hooks/orders/useParseOrderStatus';
import { parseFileExtension } from '@web/hooks/useParseFileExtension';
import useGetTrackingStatusIconAndLabel from '@web/hooks/orders/useGetTrackingStatusIconAndLabel';
import useGetCarrierCloseoutLabel from '@web/hooks/orders/useGetCarrierCloseoutLabel';

// ########################## [UTILS]

export enum ShipmentInfoSectionType {
  Ship = 'SHIP',
  Return = 'RETURN',
}

export interface IShipmentInfoSectionProps extends IOrderInfoBlock {
  type?: ShipmentInfoSectionType; // defaults to shipment.is_return
  isOpen?: boolean;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
}

function getTitleIcon(type: ShipmentInfoSectionType) {
  let titleIcon: ReactNode | null = null;
  switch (type) {
    case ShipmentInfoSectionType.Ship:
      titleIcon = <IconHelper icon="IconSend" size={20} />;
      break;
    case ShipmentInfoSectionType.Return:
      titleIcon = <IconHelper icon="IconArrowBack" size={20} />;
      break;
  }
  return titleIcon;
}

const ShipmentInfoSection: FC<IShipmentInfoSectionProps> = ({
  order,
  type: masterType,
  isOpen = true,
  setIsOpen,
}) => {
  const { t } = useTranslation(),
    transShipmentInfo = translations.order.shipmentInfo,
    transStatuses = translations.order.statuses;

  const notAvailableLabel = t(translations.common.notAvailableShort);

  const shipment = order?.shipment,
    documentation = order?.documentation,
    is_printed = order?.is_printed,
    payment = order?.payment;

  const type =
    masterType ||
    (shipment?.is_return
      ? ShipmentInfoSectionType.Return
      : ShipmentInfoSectionType.Ship);

  const title =
    type === ShipmentInfoSectionType.Return
      ? t(transShipmentInfo.return)
      : t(transShipmentInfo.ship);

  const closeoutLabel = useGetCarrierCloseoutLabel(
    order?.shipment?.carrier_closeout,
  );

  const subTitle = [
    order?.carrier_name,
    order?.shipment?.service_name,
    order?.incoterm,
    closeoutLabel,
  ]
    .filter(Boolean)
    .join(' - ');

  const formattedDate = order?.shipment_date
    ? // *1000 because JS timestamps are in milliseconds, while PHP's are in seconds
      format(order.shipment_date * 1000, 'dd/MM/yyyy - hh:mm:ss')
    : notAvailableLabel;

  const trackingCode =
    type === ShipmentInfoSectionType.Ship
      ? shipment?.tracking_code
      : shipment?.return_tracking_code;

  const [, trackingStatus] = useGetTrackingStatusIconAndLabel(
    shipment?.tracking_status,
  );
  const parsedOrderStatus = useParseOrderStatus(order?.order_status);

  const shippingLabel = documentation?.find(doc => doc.type === 'label');
  const { ext, img } = parseFileExtension(shippingLabel?.file_url ?? '');

  return (
    <div>
      {/* HEADER */}
      <div className="ShipmentInfoSection-header">
        <div className="flex justify-between w-full">
          <div className="flex items-center space-x-2">
            {getTitleIcon(type)}
            <h3 className="text-xl leading-10 text-[18px]">{title}</h3>
          </div>
        </div>
        {subTitle && (
          <p
            onClick={() => setIsOpen && setIsOpen(cIsOpen => !cIsOpen)}
            className="text-primary cursor-pointer hover:underline"
            style={{ fontWeight: 600 }}
          >
            {subTitle}
          </p>
        )}
      </div>

      {/* BODY */}
      <Collapse isOpen={isOpen}>
        <div className="pt-2 flex flex-col space-y-2 ShipmentInfoSection-body">
          <div className="w-full flex justify-between items-center">
            <p>{t(transShipmentInfo.labelGenerated)}</p>
            <p>{formattedDate}</p>
          </div>
          {/* UNCOMMENT BELOW WHEN READY */}
          {/* <div className="w-full flex justify-between">
            <p>{t(transShipmentInfo.carrierOption)}</p>
            <p>{notAvailableLabel}</p>
          </div> */}
          <div className="w-full flex justify-between items-center">
            <p>{t(transShipmentInfo.trackingCode)}</p>
            {order && (
              <TrackingCodeBadge order={order} trackingCode={trackingCode} />
            )}
          </div>

          <div className="w-full flex justify-between items-center">
            <p>{t(transShipmentInfo.trackingStatus)}</p>
            <p>{trackingStatus}</p>
          </div>

          <div className="w-full flex justify-between items-center">
            <p>{t(transShipmentInfo.shippingLabel)}</p>
            <div>
              {/* SUPPORT-3450 temporarily hidden label icon as the client can print it without
              the order being updated ('is_printed' remains = 0) */}
              {shippingLabel && order?.is_printed ? (
                <div className="flex space-x-2 items-center">
                  {/* File type icon */}
                  <img
                    src={img}
                    alt={`${shippingLabel.type}-${ext}`}
                    width={20}
                    height={25}
                  />
                  <a
                    className="text-primary"
                    href={shippingLabel.file_url}
                    target="_blank"
                    download
                    rel="noreferrer"
                  >
                    {t(transShipmentInfo.viewLabel)}
                  </a>
                </div>
              ) : (
                notAvailableLabel
              )}
            </div>
          </div>

          <div className="w-full flex justify-between items-center">
            <p className="font-bold">{t(transShipmentInfo.status)}</p>

            <div className="flex space-x-2 items-center">
              <span
                className={
                  is_printed ? 'print' : shippingLabel ? 'clock' : 'muted'
                }
              >
                {is_printed ? (
                  <IconHelper icon="IconPrinter" size={15} />
                ) : shippingLabel ? (
                  <IconHelper icon="IconClock" size={15} />
                ) : (
                  <IconHelper
                    icon="IconClock"
                    size={15}
                    className="text-muted"
                  />
                )}
              </span>
              <p className="font-bold">
                {t(transStatuses[parsedOrderStatus.code])}
              </p>
            </div>
          </div>

          <div className="w-full flex justify-between items-center">
            <p>{t(transShipmentInfo.parcels)}</p>
            <p>{order?.parcels?.length || notAvailableLabel}</p>
          </div>

          <div className="w-full flex justify-between items-center">
            <p className="font-bold italic">
              {t(transShipmentInfo.customerNotes)}
            </p>
            <p className="font-bold italic">
              {order?.notes?.marketplace || notAvailableLabel}
            </p>
          </div>

          <div className="w-full flex justify-between items-baseline">
            <p>{t(transShipmentInfo.shipmentCosts)}</p>

            <div className="flex space-x-1">
              <div className="pr-1">
                <p className="text-right">
                  {t(transShipmentInfo.shipmentPaid)}:
                </p>
                <p className="text-right">
                  {t(transShipmentInfo.shipmentPaidByCustomer)}:
                </p>
              </div>
              <div>
                <p className="text-right">
                  {toLocalizedPrice(
                    toCurrencySymbol(payment?.shipment_cost_currency),
                    payment?.shipment_cost ?? 0,
                  )}
                </p>
                <p className="text-right">
                  {toLocalizedPrice(
                    toCurrencySymbol(payment?.shipment_amountpaid_currency),
                    payment?.shipment_amountpaid ?? 0,
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default ShipmentInfoSection;
