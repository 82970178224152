import { Cn22Item } from '@web/types/order';
import { ETDDocumentFile } from '../components/edit-views/edit-details-and-docs/components/forms/etd-docs-form';
import { ItemWithRollbackState } from './editing';

export type OrderCN22 = {
  contentsType?: string | null;
  nonDeliveryOption?: string | null;
  items?: CN22ItemWithRollbackState[];
};

export type CN22ItemWithRollbackState = ItemWithRollbackState<Cn22Item>;

export type OrderETD = {
  files: ETDDocumentFile[];
};
