import { FC } from 'react';
import { Modal as ReactstrapModal } from 'reactstrap';
import classnames from 'classnames';

import { IModalProps } from './types';

/**
 * A simple wrapper class for the `reactstrap: <Modal />` component.
 *
 * @interface IModalProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const Modal: FC<IModalProps> = ({ className, size, ...rest }) => (
  <ReactstrapModal
    className={classnames(className, {
      [`modal-${size}`]: size,
    })}
    {...rest}
  />
);
