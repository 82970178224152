// ########################## [IMPORTANT LIBRARIES]
import { FC, memo } from 'react';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';

// ########################## [DESIGN SYSTEM]
import { TooltipWrapperByREF } from '@shippypro/design-system-web';

// ########################## [PAGE COMPONENTS]
import { StatusBadge } from '@web/components/common-ui/wrappers/badge/StatusBadge';

// ########################## [HOOKS]
import { useGetMarketplaces } from '@web/features/_global/hooks/api/useGetMarketplaces';
import {
  getMarketplace,
  getMarketplaceStatusString,
} from '@web/hooks/marketplaces/useGetMarketplace';

interface IMarketplaceBadgeProps {
  mrkId: number | null;
  mrkPlatform: string;
  mrkUpdated?: boolean | null;
}

/**
 * Displays a simple marketplace badge with a tooltip
 *
 * @interface IMarketplaceBadgeProps
 * @author Fabio Mezzina <fabio.mezzina@shippypro.com>
 */
export const MarketplaceBadge: FC<IMarketplaceBadgeProps> = memo(
  ({ mrkId, mrkPlatform, mrkUpdated = null }) => {
    const { t } = useTranslation();
    const { allMarketplaces: marketplaces } = useGetMarketplaces();

    const marketplace = getMarketplace(marketplaces!, mrkId, mrkPlatform);

    if (!marketplace) return null;
    return (
      <TooltipWrapperByREF
        fullWidth={false}
        containerProps={{ className: 'shrink-0' }}
        content={`${marketplace.label || marketplace.displayName} 
        ${
          !marketplace.is_only_internal
            ? getMarketplaceStatusString(mrkUpdated, t)
            : ''
        }`}
      >
        <div
          className="p-[2px] border !border-[color:--shp-color-bg-highlight] rounded bg-white"
          data-test="marketplace-badge"
        >
          <StatusBadge
            status={mrkUpdated}
            showStatus={!marketplace.is_only_internal}
          >
            <img
              className="w-6 h-6"
              loading="lazy"
              alt={marketplace.displayName}
              src={marketplace.image_url}
            />
          </StatusBadge>
        </div>
      </TooltipWrapperByREF>
    );
  },
);
