// ########################## [IMPORTANT LIBRARIES]
import { FC, memo, useMemo, useState, useTransition } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import { Spinner } from 'reactstrap';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations_restricted';

// ########################## [DESIGN SYSTEM]
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
  Nav,
  NavLink,
} from '@ds-web/components';
import { IconHelper } from '@ds-web-iconhelper';
import { Color, StyleType } from '@ds-web/tokens/enums';
import { useGetDeviceSize } from '@ds-web/utils/hooks';

// ########################## [PAGE COMPONENTS]
import ActiveTabMarker from './ActiveTabMarker';
import ActionsMenu from './ActionsMenu';
import TabBadge from './TabBadge';

// ########################## [TYPES]
import { ITabsListingProps } from '@ds-web/components/templates/with-tabs/types';

/**
 * [LAYOUT CARD WITH TOP TABS - TABS LISTING] This component holds the actual tabs navigation buttons, decoupled from their content.
 * Every tab button shows a custom numeric badge and the tabs can be either a dropdown menu or a simple button.
 *
 * @interface ITabsListingProps
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const TabsListing: FC<ITabsListingProps> = memo(
  ({ activeTabRef, tabs, activeTabIndex, toggle }) => {
    const { t } = useTranslation();

    const { isTablet, isMobile } = useGetDeviceSize();

    const [pending, startTransition] = useTransition();
    const [nextTab, setNextTab] = useState(0);

    // Selecting the currently displayed tab based on the active "Tab Index"
    const activeTab = useMemo(
      () => tabs[activeTabIndex] ?? tabs[0],
      [activeTabIndex, tabs],
    );

    // Check if the active tab is in a dropdown menu
    const isSelectedTabInDropdown = useMemo(
      () => activeTab?.tabType === 'menu',
      [activeTab],
    );

    // Check if there are dropdown tabs
    const areThereDropdownTabs = useMemo(
      () => tabs.filter(t => t.tabType === 'menu').length > 0,
      [tabs],
    );

    return (
      <StyledContainer>
        <Nav
          type="tabs"
          className="flex-nowrap !gap-2 overflow-x-scroll lg:!overflow-visible whitespace-nowrap rounded-0"
        >
          <ActiveTabMarker activeTabRef={activeTabRef} activeTab={activeTab} />
          {tabs
            .filter(t => t.tabType === 'main')
            .map((tab, i) => {
              const {
                mainContent: {
                  badgeNumber = 0,
                  badgeColor = Color.none,
                  explainPopover,
                  hidden = false,
                  iconsSet = [],
                  actions = [],
                },
                url,
                icon,
                title,
                titleBadge,
                disabled,
              } = tab;

              const trueIndex = tabs.findIndex(t => t.url === url);
              const isTabActive = activeTabIndex === trueIndex;

              const tabId = `tab-${url}`;
              const badgeID = `${tabId}-badge`;

              return (
                <div
                  key={i}
                  data-test={tabId}
                  id={tabId}
                  className={classnames(
                    'card-top-tab nav-item flex cursor-pointer',
                    {
                      'items-center': badgeNumber >= 0,
                      '!hidden': hidden,
                      'tab-disabled !cursor-not-allowed opacity-60': disabled,
                    },
                  )}
                  ref={isTabActive ? activeTabRef : null}
                  onClick={() => {
                    if (disabled) return;

                    setNextTab(trueIndex);
                    startTransition(() => toggle(trueIndex));
                  }}
                >
                  <NavLink
                    active={isTabActive}
                    badge={badgeNumber}
                    className={classnames('gap-[10px]', {
                      '!cursor-not-allowed': disabled,
                    })}
                  >
                    {trueIndex === nextTab && pending ? (
                      <Spinner size="sm" className="!mr-3" />
                    ) : (
                      icon && (
                        <IconHelper
                          icon={icon}
                          stroke={isTabActive ? 2 : 1.5}
                        />
                      )
                    )}
                    {title}
                    {titleBadge}
                  </NavLink>

                  <TabBadge
                    badgeID={badgeID}
                    badgeColor={badgeColor}
                    badgeNumber={badgeNumber}
                    explainPopover={explainPopover}
                  />
                  {iconsSet.map((tabIcon, i) => (
                    <IconHelper
                      key={i}
                      icon={tabIcon.code}
                      className={tabIcon.className}
                      size={16}
                    />
                  ))}
                  {actions?.length > 0 && (
                    <ActionsMenu actions={actions} disabled={disabled} />
                  )}
                </div>
              );
            })}

          {areThereDropdownTabs && (
            <UncontrolledButtonDropdown
              tag="div"
              className="more-tab !p-0"
              data-test="more-tabs-tab"
            >
              <DropdownToggle
                className="!mr-0 !px-7 !py-[0.125rem] flex items-center nav-item nav-item-dropdown font-medium p-0"
                caret
                styleType={StyleType.flat}
                data-test="other-tabs-drop-btn"
                innerRef={isSelectedTabInDropdown ? activeTabRef : null}
              >
                <NavLink active={isSelectedTabInDropdown}>
                  {isSelectedTabInDropdown && (
                    <IconHelper icon={tabs[activeTabIndex].icon} stroke={2} />
                  )}
                  {isSelectedTabInDropdown
                    ? tabs[activeTabIndex].title
                    : t(translations.common.more)}
                </NavLink>
              </DropdownToggle>
              <DropdownMenu
                className={classnames('ship-other-tabs', {
                  'ship-other-tabs-fixed': isMobile || isTablet,
                })}
              >
                {tabs
                  .filter(t => t.tabType === 'menu')
                  .map((tab, i) => {
                    const {
                      mainContent: {
                        badgeNumber = 0,
                        badgeColor = Color.none,
                        explainPopover,
                        hidden = false,
                        actions = [],
                      },
                      url,
                      icon,
                      title,
                      buttonOverride,
                    } = tab;

                    const trueIndex = tabs.findIndex(t => t.url === url);
                    const isTabActive = activeTabIndex === trueIndex;

                    const tabId = `tab-${url}`;
                    const badgeID = `${tabId}-badge`;

                    const onClickHandler = buttonOverride
                      ? buttonOverride
                      : () => toggle(trueIndex);

                    return (
                      <DropdownItem
                        key={i}
                        onClick={onClickHandler}
                        className={classnames({ hidden })}
                      >
                        <div
                          id={tabId}
                          data-test={tabId}
                          className={classnames(
                            'nav-item nav-item-dropdown-button flex items-start',
                            {
                              'items-center': badgeNumber >= 0,
                            },
                          )}
                        >
                          <NavLink
                            active={isTabActive}
                            badge={badgeNumber}
                            className={classnames({
                              'active-nav-link': isTabActive,
                            })}
                          >
                            {icon && (
                              <IconHelper
                                icon={icon}
                                stroke={isTabActive ? 2 : 1.5}
                              />
                            )}
                            {title}
                          </NavLink>
                          <TabBadge
                            badgeID={badgeID}
                            badgeColor={badgeColor}
                            badgeNumber={badgeNumber}
                            explainPopover={explainPopover}
                          />
                          {actions?.length > 0 && (
                            <ActionsMenu actions={actions} disabled={false} />
                          )}
                        </div>
                      </DropdownItem>
                    );
                  })}
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          )}
        </Nav>
      </StyledContainer>
    );
  },
);

TabsListing.displayName = 'TabsListing_memoized';

const StyledContainer = styled.div`
  .nav-tabs {
    .nav-item {
      .custom-tab-actions {
        display: flex;
        height: 100%;
        justify-content: end;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;

        border-radius: 0px 6px 6px 0px;

        &:hover {
          .btn-group {
            border-radius: 0px 6px 6px 0px;

            &:hover {
              background-color: var(
                --shp-color-genericui-lightbluegray
              ) !important;
              color: var(--shp-color-genericui-primary);
            }
          }
        }
      }
    }
    .more-tab {
      .ship-other-tabs {
        transform: translate(0px, 35px) !important;
        .dropdown-item {
          height: 40px;
          padding: 0 var(--bs-dropdown-item-padding-x) !important;
          .nav-item {
            height: 100%;
            .custom-tab-actions {
              display: flex;
              position: absolute;
              top: 0;
              right: -18px;
              border-radius: 0px !important;
              &:hover {
                background-color: #e4e7e7 !important;
                border-radius: 0 !important;
                &::before {
                  height: 100%;
                  width: 21px;
                  content: '';
                  position: absolute;
                  right: 100%;
                  pointer-events: none;
                  background-image: linear-gradient(
                    to left,
                    #e4e7e7,
                    #e4e7e700
                  ) !important;
                }
                .btn-group {
                  border-radius: 0 !important;
                  &:hover {
                    background-color: #dce0e0 !important;
                    color: var(--shp-color-genericui-purpleish-grey);
                  }
                }
              }
              .btn-group {
                border-radius: 0 !important;
              }
            }
          }
        }
      }
    }
  }
`;

export default TabsListing;
