import { FC } from 'react';
import { PaginationItem as ReactstrapPaginationItem } from 'reactstrap';

import { IPaginationItemProps } from './types';

/**
 * A simple wrapper class for the `reactstrap: <PaginationItem />` component.
 *
 * @interface IPaginationItemProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const PaginationItem: FC<IPaginationItemProps> = (
  props: IPaginationItemProps,
) => <ReactstrapPaginationItem {...props} />;
