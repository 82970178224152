import React, { FC, useCallback, useEffect, useState } from 'react';

import { UncontrolledTooltip } from '@ds-web/components';

import { ITooltipWrapperByIDProps } from './types';

/**
 * This is an HOC that leverages the UncontrolledTooltip
 * The only props required are
 *  target -> needs to be a string in order to generate a target ID
 *  text -> the actual text the tooltip will show
 *
 * The child element will be cloned in order to inject the target 'id' prop
 *
 * @author Fabio Mezzina <fabio.mezzina@shippypro.com>
 */
export const TooltipWrapperByID: FC<ITooltipWrapperByIDProps> = ({
  tooltipProps,
  target,
  text,
  active = true,
  checkOverflow = false,
  avoidScrollHeightCheck = false,
  ...rest
}) => {
  const [showOverflow, setShowOverflow] = useState(true);

  const isElementTextOverflow = useCallback(
    (selector: string) => {
      const el = document.querySelector(selector);
      if (!el) return false;

      return (
        (avoidScrollHeightCheck ? false : el.scrollHeight > el.clientHeight) ||
        el.scrollWidth > el.clientWidth
      );
    },
    [avoidScrollHeightCheck],
  );

  useEffect(() => {
    if (checkOverflow) {
      isElementTextOverflow(`#${target}`)
        ? setShowOverflow(true)
        : setShowOverflow(false);
    }
  }, [checkOverflow, isElementTextOverflow, target, text]);

  const elementExists = document.getElementById(target);

  return (
    <>
      {React.cloneElement(rest.children, { id: target })}
      {active && showOverflow && elementExists && (
        <UncontrolledTooltip {...tooltipProps} target={target}>
          {text}
        </UncontrolledTooltip>
      )}
    </>
  );
};
