// ########################## [IMPORTANT LIBRARIES]
import { useMemo } from 'react';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

// ########################## [TRANSLATIONS]
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [TYPES]
import { Item, Order } from '@web/types/order';
import { RowDensity } from '@web/features/ship/types';

// ########################## [PAGE COMPONENTS]
import { ProductSlideCard } from '@web/components/common-features/order/ProductSlideCard';
import { ProductCarousel } from '@web/components/common-ui/tables/columns/ProductCarousel';
import { ProductSlideCardEmpty } from '@web/components/common-features/order/ProductSlideCardEmpty';

// ########################## [HOOKS]
import useGetTabColumnSize from '@web/features/ship/hooks/tabs/useGetTabColumnSize';

const columnHelper = createColumnHelper<Order>();

export const ProductAvatarsColumnCode = 'products';

/**
 * Column holding info about the products (items) contained into an order
 *
 * @returns react-table column configuration for the product column
 */
export const useGetProductAvatarsColumn = (
  rowDensity: RowDensity | null,
): ColumnDef<Order> => {
  const { t } = useTranslation(),
    transToShipTable = translations.ship.table,
    transToShip = transToShipTable.columns;

  const size = useGetTabColumnSize(ProductAvatarsColumnCode, 450);

  return useMemo(
    () =>
      columnHelper.display({
        header: t(transToShip.product),
        minSize: 300,
        size,
        maxSize: 800,
        enableSorting: false,
        id: 'product',
        cell: ({ row: { original, getIsExpanded } }) =>
          productAvatarsColumnRendererFn(
            t,
            getIsExpanded() ? RowDensity.S : rowDensity,
            original,
          ),
      }),
    [t, rowDensity, transToShip, size],
  );
};

const productAvatarsColumnRendererFn = (
  t: TFunction<'translation', undefined>,
  rowDensity: RowDensity | null,
  order: Order,
) => {
  const items: Item[] = order.items ?? [];
  const compact = rowDensity === RowDensity.S;

  if (!items.length) return <ProductSlideCardEmpty compact={compact} />;

  if (items.length === 1) {
    const item = items[0];
    return <ProductSlideCard item={item} compact={compact} />;
  }

  return <ProductCarousel order={order} compact={compact} />;
};
