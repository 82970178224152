export enum BaseColor {
  primary = 'primary',
  accent = 'accent',
  success = 'success',
  info = 'info',
  warning = 'warning',
  danger = 'danger',
}

export enum ExtColor {
  none = 'none',
  light = 'light',
  secondary = 'secondary',
  dark = 'dark',
}

export enum Color {
  none = 'none',
  primary = 'primary',
  success = 'success',
  accent = 'accent',
  info = 'info',
  warning = 'warning',
  danger = 'danger',
  light = 'light',
  secondary = 'secondary',
  dark = 'dark',
  blue = 'blue',
  lightblue = 'lightblue',
  purple = 'purple',
  purpleish = 'purpleish',
}
