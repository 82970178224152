import { FC } from 'react';
import classnames from 'classnames';

import { IInteractableIconButtonProps } from './types';

import { Button } from '@ds-web/components/atoms/ui/buttons/button/Button';
import { IconHelper } from '@ds-web-iconhelper';
import { BorderType, StyleType } from '@ds-web/tokens/enums';

/**
 * A simple button component that displays a single icon with custom hover effect.
 *
 * @interface IInteractableIconButtonProps
 * @author Fabio Mezzina <fabio.mezzina@shippypro.com>
 */
export const InteractableIconButton: FC<IInteractableIconButtonProps> = ({
  icon,
  size = '22',
  className = '',
  label,
  ...rest
}) => {
  return (
    <Button
      className={classnames('!p-2 interactable-icon-btn', className)}
      color="purpleish"
      styleType={StyleType.flat}
      borderType={BorderType.round}
      {...rest}
    >
      <IconHelper icon={icon} size={size} />
      {label && (
        <span className="interactable-button-label pt-0.5">{label}</span>
      )}
    </Button>
  );
};
