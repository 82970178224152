import React, { FC } from 'react';
import classnames from 'classnames';
import { IconHelper } from '@ds-web-iconhelper';
import { INestedDropdownItemProps } from '../types';

/*
 This component is meant to be used inside a NestedDropDownItem. If you would like to use the component as a link pass the "to" prop aka a href,
 if u need the component to do something different you can pass the action prop, the action prop needs to be a callback function.
 NOTE: if u pass the "to" prop the callback action will be ignored.

 @author [Carlo Briganti] <carlo.briganti@shippypro.com>
 */

export const NestedDropdownItem: FC<INestedDropdownItemProps> = ({
  to,
  target,
  title,
  icon,
  action,
  iconSize,
  className = '',
  textClassName = '',
}) => {
  return to ? (
    <a
      href={to}
      className={classnames(
        className,
        'flex items-center dropdown-item first:rounded-t last:rounded-b w-auto',
      )}
      target={target || '_blank'}
      rel="noreferrer"
    >
      {icon && <IconHelper icon={icon} size={iconSize || 13} />}
      <span className={classnames('ml-2', textClassName)}>{title}</span>
    </a>
  ) : (
    <span
      className={classnames(
        className,
        'flex items-center dropdown-item first:rounded-t last:rounded-b w-auto',
      )}
      role="button"
      onClick={action}
    >
      {icon && <IconHelper icon={icon} size={iconSize || 13} />}
      <span className={classnames('ml-2', textClassName)}>{title}</span>
    </span>
  );
};
