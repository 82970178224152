import { useCallback, useState } from 'react';

import { copyToClipboard } from '@ds-web/utils/functions';

/**
 * This hooks returns a function to copy a string to the clipboard and a
 * boolean indicating the string has been copied that lasts true for 2 seconds
 *
 * @param {string} text The string to copy
 *
 * @author Fabio Mezzina <fabio.mezzina@shippypro.com>
 */
export const useTextCopiedWithTimeout = (text: string) => {
  // tooltip text change to "Copied" for 2 seconds after click
  const [isCopied, setIsCopied] = useState(false);

  const onCopyClick = useCallback(
    e => {
      e.stopPropagation();
      copyToClipboard(text ?? '', false);
      setIsCopied(true);

      setTimeout(() => setIsCopied(false), 2000);
    },
    [text],
  );

  return { isCopied, onCopyClick };
};
