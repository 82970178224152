// ########################## [IMPORTANT LIBRARIES]
import { MouseEvent, useMemo } from 'react';

import { ColumnDef, Row } from '@tanstack/react-table';

// ########################## [DESIGN SYSTEM]
import {
  IGetSelectColumnConfigOptions,
  getSelectColumnConfig,
} from '@shippypro/design-system-web';

// ########################## [TYPES]
import { Order } from '@web/types/order';

export type UseGetSelectColumnProps = {
  onToggleSelectSingleRow: (e: MouseEvent, row: Row<Order>) => void;
  onToggleSelectAllRows: (e: MouseEvent) => void;
  selectedOrderIds: number[];
  areAllChecked: boolean;
  indeterminate: boolean;
};

/**
 * Used for toggling selection on a single row or on the whole table page
 *
 * @returns react-table column configuration for the "checkbox" column
 */
export const useGetSelectColumn = (
  props?: IGetSelectColumnConfigOptions<Order>,
): ColumnDef<Order> => {
  return useMemo(
    () =>
      getSelectColumnConfig<Order>({
        ...props,
        ignoreRowClick: true,
        checkboxName: row => `select-row-${row.original.id}`,
      }),
    [props],
  );
};

/**
 * React-table has its own "internal" representation of selected rows, via useRowSelect.
 * But in some cases, having a split representation (internal to react-table + global state)
 * can lead to issues, like loops (internal selection changes, triggering global state update,
 * triggering a new internal selection update).
 * This hook provides the props to handle the selection from the outside, bypassing the internal selection.
 *
 * @param orders all the orders displayed on the table
 * @param selectedOrders the list of selected orders
 * @param onSelectionChange a callback used to update the selected orders with new values
 * @returns a react-table configuration to enable for manual selection
 */
export const useGetSelectColumnManualController = (
  orders: Order[],
  selectedOrders: Order[],
  onSelectionChange: (orders: Order[]) => void,
) => {
  return useMemo(
    () => ({
      onToggleSelectSingleRow: (_, row) => {
        const isRowAlreadySelected = selectedOrders.find(
          ({ id }) => id === row.original.id,
        );
        if (isRowAlreadySelected) {
          onSelectionChange(
            selectedOrders.filter(o => o.id !== row.original.id),
          );
        } else {
          onSelectionChange([...selectedOrders, row.original]);
        }
      },
      onToggleSelectAllRows: () => {
        onSelectionChange(
          selectedOrders.length === orders.length ? [] : orders,
        );
      },
      areAllChecked: selectedOrders.length === orders.length,
      selectedOrderIds: selectedOrders.map(o => o.id),
      indeterminate:
        selectedOrders.length !== orders.length && selectedOrders.length !== 0,
    }),
    [onSelectionChange, orders, selectedOrders],
  );
};
