import { memo, useRef, useState, FC, useCallback, ReactNode } from 'react';
import classnames from 'classnames';

import { UncontrolledTooltip } from '@shippypro/design-system-web';
import { IconHelper } from '@shippypro/design-system-web/iconhelper';
import { scrollIntoView } from '@shippypro/design-system-web/functions';

import {
  ItemDescImage,
  NO_IMAGE,
} from '@web/components/common-features/order/types';
import ImagePreview from '@web/components/common-features/order/UI/ImagePreview';
import { StatusBadge } from '@web/components/common-ui/wrappers/badge/StatusBadge';

interface IImageWithLabelProps {
  url?: string | null;
  icon?: string | null;
  alt: string;
  label: string;
  urlList?: ItemDescImage[];
  subtext?: string | null | ReactNode;
  bordered?: boolean;
  truncate?: boolean;
  allowEvents?: boolean;
  clickable?: boolean;
  highlighted?: boolean;
  allowMagnify?: boolean;
  textClassName?: string;
  subtextClassName?: string;
  status?: boolean | null;
  showStatus?: boolean;
}

const ImageWithLabel: FC<IImageWithLabelProps> = memo(
  ({
    url,
    alt,
    label,
    subtext,
    urlList = [],
    icon = null,
    bordered = false,
    truncate = true,
    allowEvents = false,
    clickable = true,
    highlighted = false,
    allowMagnify = false,
    textClassName = '',
    status = null,
    showStatus = false,
  }) => {
    const urls: ItemDescImage[] = urlList.length
      ? urlList
      : [{ url: url ?? NO_IMAGE }];
    const noImages = !urls.some(({ url }) => url !== NO_IMAGE);

    const titleRef = useRef<HTMLParagraphElement | null>(null);

    /* istanbul ignore next */
    const handleClick = (_, scroll) => {
      scroll && scrollIntoView(scroll);
    };

    const [mustShowTitleTooltip, setMustShowTitleTooltip] = useState(false);

    const onRefChange = useCallback(
      node => {
        titleRef.current = node;
        setMustShowTitleTooltip(node && node.offsetWidth < node.scrollWidth);
      },
      [setMustShowTitleTooltip],
    );

    return (
      <div
        className={classnames('flex items-center justify-start', {
          'w-full': truncate,
        })}
      >
        {(urlList.length || url) && (
          <StatusBadge status={status} showStatus={showStatus} className="mr-4">
            <ImagePreview
              items={urls}
              bordered={bordered}
              alt={alt}
              allowEvents={allowEvents}
              allowMagnify={!noImages && allowMagnify}
              className="flex-none"
              previewClassName="w-10 h-10"
            />
          </StatusBadge>
        )}

        {icon && (
          <IconHelper
            icon={icon}
            size={26}
            className="!mr-5 ml-1 flex-none color text-[color:--shp-color-bg-highlight]"
          />
        )}
        <div
          className={classnames('flex flex-col items-start justify-center', {
            'w-full': truncate,
          })}
        >
          <p
            ref={onRefChange}
            className={classnames(
              textClassName,
              'text-truncate text-light-purpleish mb-0 font-bold',
              {
                'w-[90%]': truncate,
                'cursor-pointer hover:underline': clickable,
              },
            )}
            onClick={
              /* istanbul ignore next */
              e =>
                clickable &&
                handleClick(e, '.DrawerView-show .order-detail-products-list')
            }
            data-tag={allowEvents && 'allowRowEvents'}
          >
            {label}
          </p>
          {mustShowTitleTooltip && (
            <UncontrolledTooltip target={titleRef} trigger="hover">
              {label}
            </UncontrolledTooltip>
          )}
          {subtext ? (
            typeof subtext === 'string' ? (
              <p
                className={classnames(
                  'text-truncate text-muted mb-0 text-sm cursor-pointer hover:underline',
                  {
                    'w-full': truncate,
                  },
                )}
                data-tag={allowEvents && 'allowRowEvents'}
              >
                {subtext}
              </p>
            ) : (
              subtext
            )
          ) : null}
        </div>
      </div>
    );
  },
);

export default ImageWithLabel;
