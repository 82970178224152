import { memo } from 'react';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import { ModalFooter } from '@shippypro/design-system-web';

interface IFooterProps {}

/**
 * **COMPONENT: Footer**
 *
 * Just a simple "modal footer" component that displays a link to the Changelogfy website.
 * Totally removable to avoid the sponsorship, but still, I doesn't hurt to have it nonetheless.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 * @interface IFooterProps
 */
const Footer: React.FC<IFooterProps> = memo(() => {
  const { t } = useTranslation(),
    trans = translations.changelog;

  return (
    <ModalFooter>
      {' '}
      <a
        href="https://changelogfy.com/"
        target="_blank"
        rel="noreferrer"
        className="footer-link"
      >
        {t(trans.poweredBy)}
      </a>
    </ModalFooter>
  );
});

export default Footer;
