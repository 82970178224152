import { FC } from 'react';
import { Label } from 'reactstrap';
import classnames from 'classnames';

import { Input } from '@ds-web/components/atoms/form/inputs/input/Input';

import { IRadioProps } from './types';

/**
 * A custom input field shaped to resemble a functioning radio button.
 *
 * @interface IRadioProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const Radio: FC<IRadioProps> = ({
  className,
  color,
  defaultChecked,
  value,
  disabled,
  name,
  id,
  onClick,
  onChange,
  ref,
  checked,
  label,
}: IRadioProps) => (
  <div
    className={classnames(className, 'form-check', {
      [`form-check-${color}`]: color,
    })}
  >
    <Input
      type="radio"
      defaultChecked={defaultChecked}
      value={value}
      disabled={disabled}
      name={name}
      id={id}
      onClick={onClick}
      onChange={onChange}
      ref={ref}
      checked={checked}
    />
    {label ? (
      <Label className="form-check-label" for={id}>
        {label}
      </Label>
    ) : null}
  </div>
);
