import { Order } from '@web/types/order';
import {
  AdditionalInfoAddressType,
  AdditionalInfoName,
} from '@web/types/order/additional-info';

type ReturnType = null | {
  type: AdditionalInfoAddressType;
  code: string;
  icon: string;
};

/**
 * **[HOOK]** This hook returns the address type of an order,
 * taken from different sources (I.E. Amazon Info, etc...)
 *
 * @param {Order} order The order to get the tags from
 * @returns {ReturnType} The address type info of the order (code, icons, etc...), if any
 */
const useGetOrderAddressType = (order: Order | null | undefined) => {
  return getOrderAddressType(order);
};

export const getOrderAddressType = (
  order: Order | null | undefined,
): ReturnType => {
  const addressType = order?.additional_info?.find(
    info => info.additional_info_name === AdditionalInfoName.AddressType,
  )?.additional_info_value;

  if (!addressType) return null;

  return {
    type: AdditionalInfoAddressType[addressType as AdditionalInfoAddressType],
    code: addressType.toLowerCase(),
    icon:
      addressType === AdditionalInfoAddressType.Residential
        ? 'IconHome'
        : 'IconBuildingStore',
  };
};

export default useGetOrderAddressType;
