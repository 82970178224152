import classnames from 'classnames';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import { Color } from '@shippypro/design-system-web/enums';

import { BadgeWithCustomHover } from '@web/components/common-ui/tables/columns';

interface IOrderSplitBadgeProps {
  className?: string;
  compact?: boolean;
}

/**
 * Summary badge that shows if the order has been split
 *
 * @interface IOrderSplitBadgeProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const OrderSplitBadge: React.FC<IOrderSplitBadgeProps> = ({
  className,
  compact,
}) => {
  const { t } = useTranslation(),
    transOrdSplit = translations.order.statuses.split;

  return (
    <BadgeWithCustomHover
      color={Color.secondary}
      dataTest="order-split-badge"
      className={classnames(
        'flex items-center !gap-1 max-w-fit cursor-default',
        className,
      )}
      icon="IconArrowFork"
      hasHover={compact}
      label={compact ? '' : t(transOrdSplit)}
      target="order-issplit"
    >
      {t(transOrdSplit)}
    </BadgeWithCustomHover>
  );
};

export default OrderSplitBadge;
