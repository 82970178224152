import { useState, useEffect } from 'react';

import { ArrangeMenuOptions, MenuItem } from '../types';

/**
 * This Hook is responsable for checking the Viewport and removing any menu item
 * that needs to be hidden, giving back the list of IDs to allow the Collector
 * component to wrap them inside of it.
 *
 * @param {Array} items The list of items to be checked
 * @param {string} activeTab The currently active tab, to allow a UI refresh on change
 *
 * @returns {Array} The list of items to be hidden and moved under the collector
 */
const useArrangeMenu = (
  items: MenuItem[],
  activeTab: string,
  options?: ArrangeMenuOptions,
) => {
  const [hidden, setHidden] = useState<MenuItem[]>([]);

  useEffect(() => {
    arrangeMenu(setHidden, items, options);

    // adding an observer to the navmenu to intercept its resizing
    const navMenuElement = document.getElementsByClassName('navmenu-nav')[0];
    const resizeObserver = new ResizeObserver(() => {
      // without requestAnimationFrame the ResizeObserver will be triggered in loop
      window.requestAnimationFrame(() => {
        arrangeMenu(setHidden, items, options);
      });
    });
    resizeObserver.observe(navMenuElement);

    return () => {
      resizeObserver.unobserve(navMenuElement);
    };
  }, [items, activeTab, options]);

  return hidden;
};

/**
 * Checks the window size against an element's children and intercepts
 * them when they wrap into a new line, adding the 'wrapped' class to them.
 *
 * @param {Function} hiddenHandler The function to set the hidden items list
 * @param {Array} items The list of items to be checked
 *
 * @function arrangeMenu
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 *
 * @returns void
 */
const arrangeMenu = (
  hiddenHandler: (list: MenuItem[]) => void,
  items: MenuItem[],
  options?: ArrangeMenuOptions,
): void => {
  // Getting the main Navmenu container
  const navbar = document.getElementsByClassName('navmenu-nav') as any;
  const container = navbar[0];

  if (!container) {
    console.warn('container element not found');
    return;
  }
  if (!container.querySelector('.navmenu-collector')) {
    return;
  }

  // Getting the collector
  const collector = items.find(i => i.collector) as MenuItem;
  const collPlace = items.indexOf(collector);
  const collectorItem = items[collPlace] ?? false;
  let collWidth = container.querySelector('.navmenu-collector').offsetWidth;
  // Getting the Navmenu children
  const children = document.querySelectorAll(
    '.navbar-nav > .navmenu-item:not(.navmenu-collector)',
  ) as any;

  let hiddenItems = [...(collector?.children ?? [])];
  if (!collectorItem.children?.length) {
    hiddenItems.splice(collPlace, 0, collectorItem);
  }
  const firstHiddenItem = hiddenItems[0];
  for (const child of children) {
    const childId = child.id.split('-')[2];

    const elemWidth = child.offsetWidth;

    if (
      elemWidth &&
      container.offsetWidth > collWidth + elemWidth + (options?.margin || 70)
    ) {
      // If the container width is bigger than the child+collector, increase the collector
      collWidth += elemWidth;
      child.classList.remove('wrapped');
    } else {
      // Else hide the child
      child.classList.add('wrapped');
      const foundMenu = items.find(item => item.id === childId) as MenuItem;

      if (items.indexOf(foundMenu) > collPlace) {
        hiddenItems.push(foundMenu);
      } else {
        hiddenItems.splice(hiddenItems.indexOf(firstHiddenItem), 0, foundMenu);
      }
    }
  }

  hiddenHandler(hiddenItems);
};

export default useArrangeMenu;
