import { FC } from 'react';
import styled from 'styled-components';

import { IRowIconWrapperProps } from './types';

/**
 * This component displays a styled div for row icons.
 * Children prop must be an icon.
 *
 * @interface IRowIconWrapperProps
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
export const RowIconWrapper: FC<IRowIconWrapperProps> = ({
  dataTest = '',
  disabled = false,
  onClick = () => {},
  children,
}) => (
  <div className="relative w-fit">
    <StyledRowIconWrapper
      disabled={disabled}
      onClick={onClick}
      data-test={dataTest}
    >
      {children}
    </StyledRowIconWrapper>
  </div>
);

export const StyledRowIconWrapper = styled.div<{ disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  margin-right: 20px;
`;
