// ########################## [IMPORTANT LIBRARIES]
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import * as Sentry from '@sentry/react';

// ########################## [STATE MANAGEMENT]
import { GlobalStyle } from '@web/assets/styles/global-styles';

// ########################## [LOCALISATION]
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [UTILITIES]
import { GetToken, isTestingEnv } from '@web/utils/functions/session';

// ########################## [ALT PAGES]
import Error from '@web/features/_global/layout/Errors/GenericError';
import Preload from '@web/features/_global/layout/Preload/Preload';

// ########################## [ROUTES]
import Authenticated from '@web/features/_global/authenticated';
import { OfflineRoutes, IsOfflineUrl } from '@web/features/routes';

// ########################## [UTILS]
import { qzAuthenticate } from '@web/utils/@qz-tray/functions';

// ########################## [HOOKS]
import { useGetUser } from './_global/hooks/api/useGetUser';
import useGlobalData from './_global/hooks/useGlobalData';
import { useGetQzTrayAuthData } from './ship/hooks/api/printing/useGetQZTrayAuthData';

const TOKEN_NOT_VALID_ERROR = 'tokenNotValid';

export function App() {
  const { i18n, t } = useTranslation(),
    title = `${t(translations.common.shippypro)}`,
    offline = IsOfflineUrl(window.location.pathname);

  const navigate = useNavigate();
  const { getQzTrayAuthData } = useGetQzTrayAuthData();

  const [token, setToken] = useState();

  const { errorReason: userError } = useGetUser({ skip: !token });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setToken(GetToken());
  });

  /* istanbul ignore next */
  useEffect(() => {
    const handler = () => {
      const newToken = GetToken();
      setToken(newToken);
    };
    document.addEventListener('refreshed-token', handler, false);
    return () => {
      document.removeEventListener('refreshed-token', handler, false);
    };
  }, [setToken]);

  const {
    isLoading,
    isReady,
    fetchAll: fetchAllGlobalData,
  } = useGlobalData({ skip: !token });

  /* istanbul ignore next */
  useEffect(() => {
    // this way, globalData will be invalidated
    if (offline) fetchAllGlobalData();

    // this way, we force the app to request the token if the localstorage is empty
    if (!offline && !GetToken() && !isTestingEnv())
      navigate('/request-authcode');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offline]);

  const error = useMemo(() => {
    if (!token) {
      return TOKEN_NOT_VALID_ERROR;
    }
    return userError;
  }, [userError, token]);

  // Preparing the authentication functions for the QZTray Certs
  useEffect(() => {
    try {
      qzAuthenticate(getQzTrayAuthData);
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
    }
  }, [getQzTrayAuthData]);

  return (
    <>
      <Helmet
        defer={false}
        titleTemplate={`%s - ${title}`}
        defaultTitle={title}
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content={title} />
      </Helmet>

      {offline ? (
        <OfflineRoutes />
      ) : error ? (
        error === TOKEN_NOT_VALID_ERROR ? (
          <Preload /> // waiting for the token refresh or redirect to login (in session.ts)
        ) : (
          <Error error={error} />
        )
      ) : isLoading && !isReady ? (
        <Preload />
      ) : (
        <Authenticated />
      )}
      <GlobalStyle />
    </>
  );
}
