// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [TYPES]
import { Order } from '@web/types/order';
import { Marketplace } from '@web/types/global';

// ########################## [PAGE COMPONENTS]
import { BadgeWithCustomHover } from '@web/components/common-ui/tables/columns';
import { OrderStatus as OrderStatusBlock } from '@web/features/ship/components/tables/blocks';

// ########################## [HOOKS]
import { parseOrderStatus } from '@web/hooks/orders/useParseOrderStatus';

export type IStatusBadgeProps = {
  order: Pick<
    Order,
    'id' | 'currency' | 'total_amount' | 'order_status' | 'marketplace_platform'
  >;
  marketplace?: Marketplace;
  target: string;
  hasHover?: boolean;
};

/**
 * This badge shows the order status and, if necessary, order details on hover.
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 *
 * @version 1.0.0
 * @interface IStatusBadgeProps
 * @returns {JSX.Element}
 */

const StatusBadge: React.FC<IStatusBadgeProps> = ({
  order,
  marketplace,
  target,
  hasHover = true,
}) => {
  const { t } = useTranslation(),
    transStatus = translations.order.statuses;

  const status = parseOrderStatus(order.order_status);

  return (
    <BadgeWithCustomHover
      color={status.color}
      hasHover={hasHover}
      label={t(transStatus[status.code])}
      target={target}
      allowEvents
    >
      <OrderStatusBlock
        marketplace_platform={order.marketplace_platform}
        status={t(transStatus[status.code])}
        totalAmount={order.total_amount ?? '0'}
        currency={order.currency}
        marketplace={marketplace}
      />
    </BadgeWithCustomHover>
  );
};

export default StatusBadge;
