import { FC, memo } from 'react';
import classnames from 'classnames';

import { IActionsButton } from './types';

import { StyleType } from '@ds-web/tokens/enums';

import { IconHelper } from '@ds-web-iconhelper';
import {
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Button,
  ButtonDropdown,
  NestedDropdown,
  NestedDropdownItem,
  TooltipWrapperByREF,
} from '@ds-web/components';

/**
 * This button can be a simple flat button or a dropdown button with nested dropdowns.
 *
 * @author Valeria Curseri<valeria.curseri@shippypro.com>
 *
 * @returns {JSX.Element}
 */
export const ActionButton: FC<IActionsButton> = memo(
  ({
    label,
    icon,
    onClick,
    dropdownState = false,
    setDropdownState,
    nestedActionsConfigArray,
    disabled = false,
    dataTest = '',
    className = '',
    toggleClassName = '',
    tooltip,
  }) => {
    const dropdownClasses = 'flex items-center w-3/6 sm:w-auto';
    const IconComponent = <IconHelper icon={icon} />;

    return (
      <div>
        <TooltipWrapperByREF disabled={!tooltip} content={tooltip}>
          {nestedActionsConfigArray && setDropdownState ? (
            <ButtonDropdown
              className={classnames(className)}
              isOpen={dropdownState}
              toggle={() => setDropdownState(!dropdownState)}
              data-test={dataTest ?? 'dropdown-button'}
            >
              <DropdownToggle
                color="none"
                styleType={StyleType.flat}
                caret
                className={classnames(
                  '!text-black !fill-black',
                  dropdownClasses,
                  toggleClassName,
                )}
                disabled={disabled}
              >
                {icon && IconComponent}
                {label}
              </DropdownToggle>
              <DropdownMenu
                end
                className="!top-0 !left-0 !bottom-auto inset-0 free-position-dropdown w-fit"
              >
                {nestedActionsConfigArray &&
                  nestedActionsConfigArray.map((action, i) =>
                    action.nestedActionsConfigArray ? (
                      <NestedDropdown
                        key={i}
                        isOpen={action.dropdownState ?? false}
                        mouseLeave={() => action.setDropdownState!(false)}
                        xDirection="right"
                        click={() => action.setDropdownState!(prev => !prev)}
                        mouseEnter={() => action.setDropdownState!(true)}
                        title={action.label}
                        className={classnames('!min-w-[250px]', {
                          'text-danger': action.isRed,
                          'text-black': !action.isRed,
                        })}
                      >
                        {action.nestedActionsConfigArray.map(nestedAction => {
                          return (
                            <NestedDropdownItem
                              key={nestedAction.label}
                              className={classnames(
                                'group/dropdown active:!text-white',
                                {
                                  'text-danger': nestedAction.isRed,
                                  '!text-black': !nestedAction.isRed,
                                },
                              )}
                              textClassName={classnames(
                                'group-active/dropdown:!text-white',
                                {
                                  'text-danger': nestedAction.isRed,
                                  '!text-black': !nestedAction.isRed,
                                },
                              )}
                              action={() => {
                                nestedAction.onClick!();
                                setDropdownState!(!dropdownState);
                              }}
                              title={nestedAction.label}
                            />
                          );
                        })}
                      </NestedDropdown>
                    ) : (
                      <TooltipWrapperByREF
                        key={i}
                        disabled={!action.tooltip}
                        content={action.tooltip}
                      >
                        <DropdownItem
                          className={classnames(
                            dropdownClasses,
                            'active:!text-white',
                            {
                              'text-danger': action.isRed,
                              '!text-black': !action.isRed,
                              'text-muted !cursor-not-allowed': action.disabled,
                            },
                          )}
                          hidden={action.hide}
                          disabled={action.disabled}
                          tag="a"
                          onClick={action.onClick}
                          data-test={action.dataTest}
                        >
                          {action.label}
                        </DropdownItem>
                      </TooltipWrapperByREF>
                    ),
                  )}
              </DropdownMenu>
            </ButtonDropdown>
          ) : (
            <Button
              icon={icon ? IconComponent : undefined}
              color="none"
              styleType={StyleType.flat}
              tag="a"
              onClick={onClick}
              disabled={disabled}
              className={classnames('border-none', className)}
              data-test={dataTest ?? 'flat-button'}
            >
              {label}
            </Button>
          )}
        </TooltipWrapperByREF>
      </div>
    );
  },
);
