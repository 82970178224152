import { FC } from 'react';
import BootstrapSweetAlert from 'react-bootstrap-sweetalert';

import { ISweetAlertProps } from './types';

/**
 * A simple wrapper class for the `sweet alerts: <SweetAlert />` component.
 *
 * @interface ISweetAlertProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const SweetAlert: FC<ISweetAlertProps> = props => (
  <BootstrapSweetAlert {...props} />
);
