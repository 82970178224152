import { useMemo } from 'react';

import { toCapital } from '@shippypro/design-system-web/functions';

import { useGetOrderQuery } from '@web/features/order/api';
import { GetOrderQueryPayload } from '@web/features/order/types';
import { OrderCategory } from '@web/types/order';

import { CustomRTKQueryOptions } from '@web/utils/@reduxjs/rtk-query';

export type GetOrderPayload = {
  category: OrderCategory | string;
  id: string;

  external_id?: string | null;
  marketplace_id?: string | number | null;
  marketplace_platform?: string | null;
};

/**
 * Returns a `useGetOrderQuery` RTK Query hook with its data field renamed.
 * This hook fetches the order from the database by its id and category.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 *
 * @param {GetOrderPayload} payload the payload to pass to the `GetOrder` hook
 * @param {CustomRTKQueryOptions} options subset of RTK Query hook options
 */
export const useGetOrder = (
  payload: GetOrderPayload,
  options?: CustomRTKQueryOptions,
) => {
  const { category, id, external_id, marketplace_id, marketplace_platform } =
    payload;
  let apiPayload: GetOrderQueryPayload = {
    category: '',
    filters: {
      get_specific_order: {
        toship_order_id: undefined,
        shippypro_id: undefined,
        external_id,
        marketplace_id,
        marketplace_platform,
      },
    },
  };

  switch (category) {
    case OrderCategory.toShip.toLocaleLowerCase():
      if (Boolean(id)) {
        apiPayload.filters.get_specific_order.toship_order_id = id;
      }

      apiPayload.category = toCapital(OrderCategory.toShip);
      break;
    default:
      if (Boolean(id)) {
        apiPayload.filters.get_specific_order.shippypro_id = id;
      }

      apiPayload.category = toCapital(category);
      break;
  }

  const { data: orders, ...rest } = useGetOrderQuery(apiPayload, options);

  return useMemo(
    () => ({
      order: orders?.data[0],
      ...rest,
    }),
    [orders, rest],
  );
};
