import {
  DetailedHTMLProps,
  FC,
  ImgHTMLAttributes,
  useRef,
  useState,
} from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

import { Popover, PopoverWithClickoutside } from '@shippypro/design-system-web';
import { IPopoverWithClickoutsideProps } from '@shippypro/design-system-web/types';
import { IconHelper } from '@shippypro/design-system-web/iconhelper';

import imgPlaceholderSrc from '@web/assets/img/icons/tabler/camera-off.svg';

export interface ImagePreviewThumbnailProps {
  src?: string | null;
  className?: string;
  width?: string | number;
  height?: string | number;
  showZoomOverlay?: boolean;
  innerRef?: React.RefObject<HTMLDivElement>;
}

export const ImagePreviewThumbnail: FC<ImagePreviewThumbnailProps> = ({
  src,
  className,
  showZoomOverlay = true,
  width = 64,
  height = 64,
  innerRef,
}) => {
  const target = useRef<HTMLDivElement | null>(null);
  const [isHoveringImg, setIsHoveringImg] = useState(false);

  const shouldShowZoomOverlay = showZoomOverlay && src && isHoveringImg;

  return (
    <div
      id="image-preview"
      data-test="image-preview"
      onClick={e => e.stopPropagation()}
      onMouseEnter={() => setIsHoveringImg(true)}
      onMouseLeave={() => setIsHoveringImg(false)}
      ref={innerRef ?? target}
      className={classnames(
        'bg-white',
        'rounded-md overflow-hidden relative',
        className,
        { 'no-image': !src },
      )}
    >
      <img
        src={src || imgPlaceholderSrc}
        className="relative z-1"
        alt="productimg"
        loading="lazy"
        height={height}
        width={width}
      />
      {shouldShowZoomOverlay && (
        <>
          <div
            className="
              absolute
              top-0 left-0
              flex justify-center items-center 
              w-full h-full
              bg-black opacity-60 
              z-10
              rounded-md
              magnifying-block
            "
          />
          <span
            className="
              absolute
              top-0 left-0
              cursor-pointer
              flex justify-center items-center
              w-full h-full
              z-20
              rounded-md
            "
          >
            {<IconHelper icon="IconZoomIn" size={20} color="white" />}
          </span>
        </>
      )}
    </div>
  );
};

export interface ImagePreviewProps extends ImagePreviewThumbnailProps {
  popoverProps?: Partial<IPopoverWithClickoutsideProps>;
  imgPopoverProps?: Partial<
    DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
  >;
}

export const ImagePreview: FC<ImagePreviewProps> = ({
  src,
  popoverProps,
  imgPopoverProps,
  ...rest
}) => {
  const imgContainerRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <ImagePreviewThumbnail
        className="border-1 border-gray"
        src={src}
        {...rest}
        innerRef={imgContainerRef}
      />
      <PopoverWithClickoutside
        target={imgContainerRef}
        disabled={!src}
        {...popoverProps}
        PopoverComponent={StyledPopover}
        className="relative z-[1100]" // needed to appear on top of a <Modal> component (z-index = 1050)
        closeOnScroll={true}
      >
        <img
          src={src ?? ''}
          className="max-w-[300px] max-h-[300px]"
          alt=""
          loading="lazy"
          data-test="image-preview-popover"
          {...imgPopoverProps}
        />
      </PopoverWithClickoutside>
    </>
  );
};

export const StyledPopover = styled(Popover)`
  .popover-arrow::after {
    border-top-color: var(--shp-color-genericui-purpleish) !important;
  }
  .popover-inner {
    padding: 1px;
    background-color: var(--shp-color-genericui-purpleish) !important;
    overflow: hidden;
  }
`;

export default ImagePreview;
