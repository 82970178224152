export enum PaywallDesign {
  // A design with just a text list, no images
  PureText = 'PureText',
  // A design divided in two blocks, one has text and one has a `feature preview` image
  TwoRowsSingleImage = 'TwoRowsSingleImage',
  // A design divided in two blocks, one has text and one has a `feature preview` video
  TwoRowsSingleVideo = 'TwoRowsSingleVideo',
}

export type PaywallCTA = {
  // The text of the primary action button
  text: string;
  // Its custom action handler, fired on click
  onClick: () => void;
};

export type PaywallInfo = {
  // The translated name of the paywalled feature;
  feature: string;
  // The TRANSLATION KEY (not translated) of the description, used to interpolate tags like <b />, etc...
  description: string;
  // The array of translated feature details (I.e. what the feature has to offer)
  details: string[];
  // The `Call to Action` info of the paywall (buy now, contact sales...)
  cta: PaywallCTA;
  // The secondary `Call to Action` info
  secondaryCta?: PaywallCTA;
  // The color of the Paywall gradient background
  gradientColor?: string;
};

export interface TwoRowsOneImageLayoutPaywallInfo extends PaywallInfo {
  // The ShippyPro CDN url of the feature preview image
  imageUrl: string;
}

export interface TwoRowsOneVideoLayoutPaywallInfo extends PaywallInfo {
  // The ShippyPro CDN url of the feature preview video
  videoUrl: string;
}
