import { getUnixTime } from 'date-fns';
import { useMemo, useState } from 'react';
import { useSessionStorage } from 'react-use';

/**
 * A hook that returns a boolean indicating whether the given feature has been snoozed or not,
 * and a function to snooze it.
 * The snoozing time is based on session since is stored in SessionStorage.
 *
 * @param {string} code - The code of the release to get data from.
 * @returns {object} - An object containing the featureSessionSnoozed state and the setFeatureSessionSnoozed function.
 *
 * @author Marcello Aguzzi <marcello.aguzzi@shippypro.com>
 */
export const useSessionSnoozeFeature = (code: string) => {
  const [sessionSnoozedFeatures, setSessionSnoozedFeatures] = useSessionStorage(
    'session-snoozed-features',
    {},
  );
  const [featureSessionSnoozed, setFeatureSessionSnoozed] = useState(
    Boolean(sessionSnoozedFeatures?.[code]),
  );

  return useMemo(
    () => ({
      featureSessionSnoozed: featureSessionSnoozed,
      setFeatureSessionSnoozed: () => {
        setFeatureSessionSnoozed(true);
        setSessionSnoozedFeatures({
          ...sessionSnoozedFeatures,
          [code]: getUnixTime(new Date()),
        });
      },
    }),
    [
      code,
      setSessionSnoozedFeatures,
      sessionSnoozedFeatures,
      featureSessionSnoozed,
    ],
  );
};
