import { i18n, translationsJson } from './instance';

import { convertLanguageJsonToObject } from './translations';

// Create the 'translations' object to provide full intellisense support for the static json files.
convertLanguageJsonToObject(translationsJson.gateway.translation);
// Adding a second conversion to render the objects HMReloadable.
convertLanguageJsonToObject(translationsJson.en.translation);

export { i18n, translationsJson };
