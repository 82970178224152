import { useMemo } from 'react';
import { useHasActivePlanQuery } from '@web/features/_global/api';
import { CustomRTKQueryOptions } from '@web/utils/@reduxjs/rtk-query';

/**
 * Returns RTK Query hook with renamed data field.
 * Returns whether the current user has an active plan or not.
 *
 * @author Marco Zanella <marco.zanella@shippypro.com>
 *
 */
export const useHasActivePlan = (options?: CustomRTKQueryOptions) => {
  const {
    data = {
      hasActivePlan: true,
      redirectPath: '',
    },
    ...rest
  } = useHasActivePlanQuery({}, options);

  return useMemo(
    () => ({
      data,
      ...rest,
    }),
    [data, rest],
  );
};
