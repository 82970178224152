import { ReactNode } from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import classnames from 'classnames';

import {
  FormGroup,
  FormGroupProps,
  FormText,
  FormTextProps,
  FormFeedback,
  FormFeedbackProps,
  Label,
} from 'reactstrap';

import { HelperText, Input } from '@shippypro/design-system-web';
import { IInputProps } from '@shippypro/design-system-web/types';

export interface ControlledSwitchProps<FormType extends FieldValues>
  extends Pick<
    FormGroupProps,
    | 'row'
    | 'check'
    | 'inline'
    | 'floating'
    | 'disabled'
    | 'tag'
    | 'cssModule'
    | 'className'
  > {
  name: Path<FormType>;
  control: Control<FormType>;
  label?: ReactNode;
  description?: ReactNode;
  switchProps?: Omit<IInputProps, 'checked' | 'value' | 'onChange'>;
  color?: IInputProps['color'];
  errorTextProps?: FormFeedbackProps;
  errorText?: ReactNode;
  successTextProps?: FormTextProps;
  successText?: ReactNode;
  helperTextProps?: FormTextProps;
  helperText?: ReactNode;
  disabled?: boolean;
  required?: boolean;
  dataTest?: string;
  iconTrue?: ReactNode;
  iconFalse?: ReactNode;
}

export function ControlledSwitch<FormType extends FieldValues>({
  name,
  label,
  description,
  control,
  switchProps,
  color,
  helperTextProps,
  errorTextProps,
  errorText,
  successTextProps,
  successText,
  helperText,
  className,
  disabled,
  required,
  iconTrue,
  iconFalse,
  dataTest,
  ...rest
}: ControlledSwitchProps<FormType>) {
  return (
    <Controller
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormGroup {...rest} className={classnames('!mb-0', className)}>
          <Label className="form-check-label" id={`${name}-id`}>
            <div className="form-check form-switch">
              <Input
                type="switch"
                {...switchProps}
                color={color ?? switchProps?.color}
                name={name}
                onChange={onChange}
                onClick={onChange}
                checked={value}
                required={required ?? switchProps?.required}
                disabled={disabled ?? switchProps?.isDisabled}
                valid={Boolean(successText)}
                invalid={Boolean(error || errorText)}
                data-test={dataTest ?? name}
              />
              <div className="flex flex-col">
                <span
                  className={classnames('pl-1', {
                    'font-semibold': !!description,
                  })}
                >
                  {label}
                </span>
                <span className="pl-1 text-sm">{description}</span>
              </div>
              {iconTrue && iconFalse && (
                <span className="form-check-label">
                  <span className="switch-icon-left">{iconTrue}</span>
                  <span className="switch-icon-right">{iconFalse}</span>
                </span>
              )}
            </div>
          </Label>
          <div>
            {(error || errorText) && (
              <FormFeedback {...errorTextProps}>
                {errorText ?? error?.message}
              </FormFeedback>
            )}
            {successText && (
              <FormText color="success" {...successTextProps}>
                {successText}
              </FormText>
            )}
            {helperText && (
              <HelperText {...helperTextProps}>{helperText}</HelperText>
            )}
          </div>
        </FormGroup>
      )}
      name={name}
    />
  );
}
