import { FC } from 'react';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { ICircularProgressProps, StrokeLineCap } from './types';

/**
 * This is a circular progress bar component
 * It builts upon the react-circular-progressbar component
 * It extract almost all the props accepted by the 'buildStyles' component function
 *    and exposes them as optional props that can be directly passed
 *    to the brand new CircularProgress component
 *
 * @author Fabio Mezzina <fabio.mezzina@shippypro.com>
 */
export const CircularProgress: FC<ICircularProgressProps> = ({
  size,
  strokeLinecap = StrokeLineCap.round,
  textSize = '20',
  pathColor = '#011627',
  textColor = '#011627',
  trailColor = '#989aad',
  backgroundColor = 'rgba(0, 0, 0, 0)',
  pathTransitionDuration = 0.5,
  ...circularProgressProps
}) => {
  const customStyle = {
    strokeLinecap,
    textSize: `${textSize}px`,
    pathColor,
    textColor,
    trailColor,
    backgroundColor,
    pathTransitionDuration,
  };

  return (
    <div style={{ width: `${size}px` }}>
      <CircularProgressbar
        {...circularProgressProps}
        styles={buildStyles(customStyle)}
        text={`${circularProgressProps.value}%`}
      />
    </div>
  );
};
