/**
 * This function receives the errors object of a form
 * and returns the total number of errors.
 * NOTE that this works only with one level of nested forms.
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 *
 * @version 1.0.0
 *
 * @param {Record<string, Record<string, any>>} errors  form errors: the key is the field name or form name, while the value is
 *                                          the error message or an object containing more error fields
 * @returns number
 */
export const getFormErrorsLength = (
  errors: Record<string, Record<string, any>>,
): number => {
  let errorsLength = 0;
  Object.values(errors).forEach((firstLevelError, i) => {
    // if: firstLevelError is an array, it must have nested errors objects inside
    // (this is the case of a form containing more forms)
    /* istanbul ignore else */
    if (firstLevelError.length) {
      // each one of these form could contain errors, so for each one of them
      firstLevelError.forEach(secondLevelError => {
        // we sum the errors length to the existing length
        errorsLength += Object.values(secondLevelError).length;
      });
      // else: it is just an error (simple first level field error)
    } else {
      // so it's enough to sum 1 to the existing length
      errorsLength++;
    }
  });
  return errorsLength;
};
