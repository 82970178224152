import { FC, PropsWithChildren } from 'react';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations_restricted';

import { IHoverableItemWithCopy } from './types';

import { CopyButton } from '@ds-web/components/molecules/ui/buttons/copy-button/CopyButton';
import { GenericHoverableItem } from '@ds-web/components/molecules/ui/wrappers/hoverable-items/generic-hoverable-item/GenericHoverableItem';
import { TooltipWrapperByREF } from '@ds-web/components/molecules/ui/tooltips/tooltip-wrapper-byref/TooltipWrapperByREF';

import { useTextCopiedWithTimeout } from '@ds-web/utils/hooks/dom/text-copied-with-timeout/text-copied-with-timeout';

/**
 * HOC for the GenericHoverableItem component that implements a copy to clipboard behavior when clicking on it
 *
 * @param {string} textToCopy The text to copy
 * @param {?ReactNode} reveals The element to be revealed, optional
 *
 * @author Fabio Mezzina <fabio.mezzina@shippypro.com>
 */
export const HoverableItemWithCopy: FC<
  PropsWithChildren<IHoverableItemWithCopy>
> = ({ textToCopy, reveals, children, ...rest }) => {
  const { t } = useTranslation();
  const transCommon = translations.common;

  const { isCopied, onCopyClick } = useTextCopiedWithTimeout(textToCopy);

  return (
    <GenericHoverableItem
      reveals={reveals ?? <CopyButton value={textToCopy} />}
      actionOnClick={e => onCopyClick(e)}
      {...rest}
    >
      <TooltipWrapperByREF
        content={
          isCopied ? t(transCommon.copied) : t(transCommon.copyToClipboard)
        }
        innerClassName="min-w-[100px]"
      >
        {children}
      </TooltipWrapperByREF>
    </GenericHoverableItem>
  );
};
