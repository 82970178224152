import { FC } from 'react';
import { Popover as ReactstrapPopover } from 'reactstrap';

import { IPopoverProps } from './types';

/**
 * A simple wrapper class for the `reactstrap: <Popover />` component.
 *
 * @interface IPopoverProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const Popover: FC<IPopoverProps> = props => (
  <ReactstrapPopover {...props} />
);
