import { FC } from 'react';
import ContentLoader from 'react-content-loader';

import { ISkeletonProps } from './types';

/**
 * A simple wrapper class for the `content-loader: <Skeleton />` component.
 *
 * @interface ISkeletonProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const Skeleton: FC<ISkeletonProps> = props => (
  <ContentLoader {...props} />
);
