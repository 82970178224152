// ########################## [IMPORTANT LIBRARIES]
import { FC, useCallback, useRef, useState, MouseEvent, Fragment } from 'react';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [DESIGN SYSTEM]
import { StyleType } from '@shippypro/design-system-web/enums';
import {
  Button,
  PopoverBody,
  PopoverHeader,
  TooltipWrapperByREF,
} from '@shippypro/design-system-web';
import { IconHelper } from '@shippypro/design-system-web/iconhelper';

// ########################## [TYPES]
import { Documentation } from '@web/types/order';

// ########################## [PAGE COMPONENTS]
import TextWithCustomHover from '../TextWithCustomHover';
import DocumentationBlock from '@web/features/ship/components/tables/blocks/Documentation';

// ########################## [HOOKS]
import { useCreateDocumentArchive } from '@web/features/ship/hooks/api/export/useCreateDocumentArchive';
import useGetShipCategory from '@web/features/ship/hooks/useGetShipCategory';

interface IDocumentsBadgeProps {
  documentation: Documentation[];
  orderId: number;
  completeLabel?: boolean;
  customActionLabel?: string;
  customActionOnClick?: () => void;
  minLenghtForCompleteLabel?: number;
}
/**
 * Displays a documentation badge with indication on documents count
 * Clicking the icon, a popover with a full list of downloadable files appear
 *
 * @interface IDocumentsBadgeProps
 * @author Fabio Mezzina <fabio.mezzina@shippypro.com>
 */
export const DocumentsBadge: FC<IDocumentsBadgeProps> = ({
  documentation,
  orderId,
  completeLabel = false,
  customActionLabel,
  customActionOnClick,
  minLenghtForCompleteLabel = 1,
}) => {
  const { t } = useTranslation(),
    transCommon = translations.common;

  const category = useGetShipCategory();

  const divRef = useRef<HTMLDivElement | null>(null);

  // Handle opening and closing the popover
  const [isOpen, setIsOpen] = useState(false);
  const togglePopover = () => {
    setIsOpen(!isOpen);
  };

  const { createDocumentArchive, isLoading } = useCreateDocumentArchive();

  //Not used already
  /* istanbul ignore next */
  const onCreateDocumentArchive = useCallback(
    (e: MouseEvent): void => {
      if (isLoading) {
        return;
      }
      const id = Number(e.currentTarget.getAttribute('data-id'));
      createDocumentArchive({
        id: id,
        category: category,
      });
    },
    [category, createDocumentArchive, isLoading],
  );

  return (
    <div className="documents-badge" ref={divRef}>
      <TextWithCustomHover
        label={
          <TooltipWrapperByREF
            content={`${t(transCommon.countDocumentation, {
              count: documentation.length,
            })}`}
          >
            <span
              className="cursor-pointer flex mt-[3px] hover:text-primary"
              onClick={() => togglePopover()}
            >
              <IconHelper icon="IconNotes" size={18} />
              {documentation.length > minLenghtForCompleteLabel ? (
                <span className="mt-[3px] text-sm font-bold">
                  {completeLabel
                    ? t(transCommon.listDocumentation, {
                        count: documentation.length,
                      })
                    : documentation.length}
                </span>
              ) : null}
            </span>
          </TooltipWrapperByREF>
        }
        target={`toship-popover-rowdocuments-${orderId}`}
        type="popover"
        placement="bottom"
        isOpenProp={isOpen}
      >
        <PopoverHeader className="documents-popover-header">
          <div className="d-flex justify-content-between">
            <span className="d-flex flex-row lowercase">
              {t(translations.common.countDocumentation, {
                count: documentation.length,
              })}
            </span>
            <Button
              styleType={StyleType.flat}
              style={{ borderRadius: 0 }}
              disabled={isLoading}
              data-id={orderId}
              onClick={
                !!customActionOnClick
                  ? () => {
                      setIsOpen(false);
                      customActionOnClick();
                    }
                  : onCreateDocumentArchive
              }
              className="download-all d-flex flex-row text-md cursor-pointer p-0 text-white hover:underline"
            >
              {!!customActionLabel
                ? customActionLabel
                : t(transCommon.downloadAll)}
            </Button>
          </div>
        </PopoverHeader>
        <PopoverBody
          className="documents-popover-body max-h-72 overflow-y-auto shadow-down"
          data-test="order-detail-documents"
        >
          {documentation.map((document: Documentation, index: number) => (
            <Fragment key={index}>
              <DocumentationBlock
                key={`${document.file_url}_${index}`}
                documentation={document}
              />
              {index < documentation.length - 1 && <hr />}
            </Fragment>
          ))}
        </PopoverBody>
      </TextWithCustomHover>
    </div>
  );
};
