import { rootApi } from '@web/utils/@reduxjs/rtk-query';

import { getSearchEngineGlobalSearchQuery } from '@web/utils/@reduxjs/microservices/search-engine';
import { GetGlobalSearchPayload, GetGlobalSearchResult } from './types';

// Define a service using a base URL and expected endpoints
export const globalSearchApi = rootApi.injectEndpoints({
  endpoints: build => ({
    /**
     * API: getSearchResults
     *
     */
    getSearchResults: build.query<
      GetGlobalSearchResult,
      GetGlobalSearchPayload
    >({
      query: args => getSearchEngineGlobalSearchQuery('GET', args, 'search'),
      providesTags: () => ['GlobalSearch'],
    }),
    // [APPEND NEW APIS ABOVE] < Needed for generating API Hooks seamlessly
  }),
});

export const {
  useLazyGetSearchResultsQuery,
  // [EXPORT NEW APIS ABOVE] < Needed for generating API Hooks seamlessly
} = globalSearchApi;
