import axios from 'axios';

import { getOrCreateRefreshTokenPromise } from '@web/utils/functions/session';

/**
 * Client used as the main vector for any Data Fetching from the Backend
 */
const mainAxiosClient = axios.create();

/**
 * Client used to intercept an "Expired OAuth Token" request and debounce everything
 * until we can get a fresh new token back
 */
const retryAxiosClient = axios.create();

mainAxiosClient.interceptors.response.use(
  // success case
  r => r,
  // error handling
  error => {
    if (error.response?.status === 403) {
      console.log('Running interceptor on main Axios client');
      const companyDomainParts = error.request.responseURL.split('.');
      const companyDomain =
        companyDomainParts[companyDomainParts.length - 2] +
        companyDomainParts[companyDomainParts.length - 2];
      console.log(`Company domain: ${companyDomain}`);
      if (error.request.responseURL.includes(companyDomain)) {
        //Delete the old tokens
        localStorage.removeItem('portal-token');
        //Redirect to the PKCE entry-point
        window.location.reload();
      }
    } else if (error.response?.status === 401) {
      return getOrCreateRefreshTokenPromise().then(token => {
        if (!token) {
          console.error('Token not resolved by refreshTokenPromise');
          return;
        }

        if (error.config.headers) {
          error.config.headers['Authorization'] = `Bearer ${token}`;
        }
        if (error.config.data) {
          // if we don't parse this as json, it will be sent as string
          // and the request will fail with status 422
          error.config.data = JSON.parse(error.config.data);
        }
        return retryAxiosClient.request(error.config);
      });
    }
    console.log(
      `Uncaught error response with status: ${error.response?.status}`,
    );
    throw error;
  },
);

retryAxiosClient.interceptors.response.use(
  //Passthrough for successful responses
  r => r,
  error => {
    console.log('Using retry Axios client!');
    console.log(error);
  },
);
