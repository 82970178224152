/**
 * This function fetches the current URL Query Parameters.
 * (A bit redundant for now but could be expanded in the future)
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 *
 * @returns {URLSearchParams} The parameters object
 */
export const getUrlQueryParams = (): URLSearchParams => {
  return new URLSearchParams(window.location.search);
};
