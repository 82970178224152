import { FC } from 'react';
import { DropdownToggle as ReactstrapDropdownToggle } from 'reactstrap';

import { StyleType } from '@ds-web/tokens/enums';

import { IDropdownToggleProps } from './types';

/**
 * A simple wrapper class for the `reactstrap: <DropdownToggle />` component.
 *
 * @interface IDropdownToggleProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const DropdownToggle: FC<IDropdownToggleProps> = ({
  styleType,
  color,
  ...rest
}) => {
  let outline = false,
    colorClass = color;

  switch (styleType) {
    case StyleType.filled:
      break;
    case StyleType.outline:
      outline = true;
      break;
    case StyleType.flat:
      colorClass = `flat-${color}`;
      break;
  }
  return (
    <ReactstrapDropdownToggle {...rest} outline={outline} color={colorClass} />
  );
};
