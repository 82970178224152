import styled from 'styled-components';

import { Modal } from '@shippypro/design-system-web';

import { DialogForMobile } from '@web/components/common-ui/dialogs/DialogForMobile';

/**
 * This stylising wrapper customises the `GLS Italy - Format Picker` modal with some handy specifics
 * based on the Zeplin UI mocks: https://zpl.io/1yvARqm
 * 
 * **N.B.** This Styliser contains ONLY the main/general formatting styles, like bg-color, margins, padding and borders.
            The other styles are al singularly applied through Tailwind to divide between the modal styling and the various
            components stylings. This utility is NOT to be used for anything other than the general weights&params of the whole modal.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const StyledGLSItalyPrintFormatPickerModal = styled(Modal)`
  // Importing the DialogForMobile styles to give this modal a default mobile behaviour
  ${DialogForMobile}

  // Giving a dynamic width and positioning to the entire modal
  min-width: 65%;
  margin: auto;
  margin-top: 5rem;

  .modal-body {
    padding-left: 2.25rem;
    padding-right: 2.25rem;

    border-bottom-left-radius: 0.357rem;
    border-bottom-right-radius: 0.357rem;

    background-color: var(--bs-body-bg);

    #format-list {
      margin-top: 2.5rem;

      .format-card {
        padding: 0.875rem 0.875rem 0.875rem 0.875rem;

        border-radius: 6px;

        background-color: var(--shp-color-bg);

        // Added a transparent border to avoid having the UI shift completely on hover
        border: solid 1px transparent;

        &:hover {
          border: solid 1px var(--shp-color-genericui-light);
        }

        &[data-selected='true'] {
          border: solid 1px var(--shp-color-genericui-accent);
        }
      }
    }
  }

  .modal-footer {
    padding-top: 0;

    background-color: var(--bs-body-bg);
  }

  // Making sure that, whenever the checkbox is, it doesn't shrink and its label is aligned to the top
  .form-check {
    align-items: start;

    input[type='checkbox'] {
      flex-shrink: 0;
    }
  }
`;

export default StyledGLSItalyPrintFormatPickerModal;
