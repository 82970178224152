import { memo } from 'react';

// ########################## [DESIGN SYSTEM]
import { LoadingView } from '@shippypro/design-system-web';

import { StyledModal } from './styles';

/**
 * This component represents the main modal in its "full loading" state.
 * It's used to subside while the site is loading the lazy-loaded JS from the server.
 *
 * @interface ILazyLoadingModalProps
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const LazyLoadingModal = memo(() => {
  return (
    <StyledModal
      backdrop="static"
      isOpen={true}
      data-test="manageorder-modal_lazyloading"
    >
      <LoadingView />
    </StyledModal>
  );
});

export default LazyLoadingModal;
