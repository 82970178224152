import { useMemo, useState } from 'react';
import { useLocalStorage } from 'react-use';

/**
 * A hook that returns a boolean indicating whether the given ref is currently in the viewport or not.
 *
 * @param {string} code - The code of the release to get data from.
 * @returns {object} - An object containing the featureVisited state and the setFeatureVisited function.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const useGetLastReleaseData = (code: string) => {
  const [newReleases] = useLocalStorage('app-new-releases', {});
  const [featureVisited, setFeatureVisited] = useState(
    Boolean(newReleases?.[code]),
  );

  return useMemo(
    () => ({
      featureVisited,
      setFeatureVisited: () => setFeatureVisited(true),
    }),
    [featureVisited],
  );
};
