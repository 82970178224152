import { FC, Fragment, memo } from 'react';

import { Checkbox } from '@ds-web/components/atoms/form/checkbox/Checkbox';

import { formatLocale } from '@ds-web/utils/functions';

import ListItemCell from './ListItemCell';
import ListAction from './ListAction';
import ListIcon from './ListIcon';

import { ListViewReservedFields, IListItemProps } from './types';

const ListItem: FC<IListItemProps> = memo(
  ({ config, row, columns, ...rest }) => {
    const values = Object.values(row),
      keys = Object.keys(row),
      hasId = keys[0] ? keys[0].toUpperCase() === 'ID' : false,
      hiddenFields = columns.filter(c => c.hidden).map(c => c.name),
      { selectable } = config,
      selected = rest.selected.includes(values[0]),
      handleSelected = () => {
        if (selectable)
          rest.handleSelect(
            selected
              ? rest.selected.filter(s => s !== values[0])
              : [...rest.selected, values[0]],
          );
      },
      highlight = config.rowHighlight ? config.rowHighlight(row) : false;

    return (
      <div
        className={`list-view-item ${selectable && 'cursor'} ${
          selected && 'selected'
        } ${highlight && 'list-view-row-highlighted'}`}
      >
        <div
          className="flex w-full items-center"
          onClick={() => handleSelected()}
        >
          {values.map((value, i) => (
            <Fragment key={i}>
              {!(hasId && !i) &&
                !hiddenFields.some(f => f === keys[i]) &&
                keys[i] !== ListViewReservedFields.icon && (
                  <ListItemCell
                    className={`w-full ${
                      ((hasId && i === 1) || (!hasId && !i)) && 'ai-center'
                    }`}
                    width={columns[i]?.width}
                    key={i}
                    bold={(hasId && i === 1) || (!hasId && !i)}
                  >
                    {((hasId && i === 1) || (!hasId && !i)) && selectable && (
                      <Checkbox
                        className="mr-1"
                        checked={selected}
                        onChange={() => handleSelected()}
                      />
                    )}
                    <ListIcon columns={columns} row={row} cell={keys[i]} />
                    {keys[i] === ListViewReservedFields.date &&
                    value.start &&
                    value.end
                      ? `${formatLocale(
                          value.start,
                          config.dateFormat,
                          config.language,
                        )} - ${formatLocale(
                          value.end,
                          config.dateFormat,
                          config.language,
                        )}`
                      : value}
                  </ListItemCell>
                )}
            </Fragment>
          ))}
          {config.actions ? (
            <ListItemCell className="cell-actions">
              <ListAction config={config} row={row} />
            </ListItemCell>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  },
);

export default ListItem;
