import { Address } from '@web/types/common/address';
import { FilteredCarrier, RateCarrierOptions } from '@web/types/global/carrier';
import { BaseAPIResult } from '@web/types/common/APIResults';
import { APIGatewayBulkResponse } from '@web/types/global/api-gateway';
import { ManageOrderModalAPIActions } from '../types';
import { Attribute } from '@web/types/common/attribute';
import {
  CustomInfo,
  ETDDocument,
  Note,
  OrderRates,
  Parcel,
  Payment,
  Rate,
} from '@web/types/order';

// --------- [ ADDRESSES ]

export type AddressMutationResult = BaseAPIResult<boolean> & {
  address: Address;
};

// --------- [ PARCELS ]

export type OrderParcelsQueryPayload = {
  external_id: string;
  marketplace_id: number;
  marketplace_platform: string;
};

export type OrderParcelsBulkQueryPayload = OrderParcelsQueryPayload[];

export type OrderParcelsBulkMSQueryPayload = number[];

export type OrderParcelsQueryResult = {
  orderId: number;
  groups: OrderParcelsGroup[];
};

export type OrderParcelsGroup = {
  shippypro_id?: number;
  external_id: string;
  parcels: Parcel[];
};

export type CustomInfoGroup = {
  order_id: number;
  custom_info?: CustomInfo;
};

type BaseParcelQueryPayload = {
  id: number;
  category: string;
};

type BaseParcelResponse = BaseAPIResult<boolean> & {};

export type AddParcelQueryPayload = BaseParcelQueryPayload & {
  parcel: Omit<Parcel, 'id' | 'package' | 'sku'>;
};

export type EditParcelQueryPayload = BaseParcelQueryPayload & {
  parcel: Omit<Parcel, 'package' | 'sku'>;
};

export type AddOrEditParcelResult = BaseParcelResponse & {
  parcel: Parcel;
};

export type DeleteParcelQueryPayload = BaseParcelQueryPayload & {
  parcel_ids: number[];
};

export type DeleteParcelResult = BaseParcelResponse & {
  parcel_ids: number[];
};

// --------- [ RATES ]
export type OrderRatesBulkQueryPayload = OrderRatesQueryPayload[];

export type OrderRatesQueryPayload = {
  to_address: Address;
  from_address: Address | undefined;
  parcels: Parcel | Parcel[];
  insurance: number;
  insurance_currency: string;
  cashondelivery: number;
  cashondelivery_currency: string;
  content_description: string | null;
  total_amount: string | number;
  shipping_service: string;
  rate_carriers: FilteredCarrier[];
  id: number | null;
  external_id: string;
  category: string;
};

export type OrderRatesQueryResult = OrderRates & { order_id: number };

export type OrderRatesBulkQueryResult = OrderRatesQueryResult[];

// --------- [ CARRIER OPTIONS ]
export type OrderCarrierOptionsQueryPayload = {
  to_address?: Address;
  carrier_id?: number;
  carrier_name?: string;
};

export type OrderCarrierOptionsQueryResult =
  | RateCarrierOptions[]
  | { Error: string };

export type OrderCarrierOptionsBulkQueryPayload = {
  order_id: number;
  transaction_id: string;
  marketplace_id: number;
  marketplace_platform: string;
  to_address?: Address;
  carriers: [
    {
      carrier_id: number;
      carrier_name: string;
    },
  ];
};

export type OrderCarrierOptionsBulkQueryResult = {
  order_id: number;
  transaction_id: string;
  carriers: [
    {
      carrier_id: number;
      carrier_name: string;
      options: RateCarrierOptions[] | { error: string };
    },
  ];
};

// --------- [ CUSTOM INFO ]
export type OrderCustomInfoQueryPayload = {
  order_id: number;
  category: string;
  parcels: Parcel[];
};

export type BulkOrderForCustomInfoQueryPayload = {
  id: number;
  parcels: Parcel[];
};

export type OrderCustomInfoBulkQueryPayload = {
  category: string;
  orders: BulkOrderForCustomInfoQueryPayload[];
};

export type OrderCustomInfoBulkQueryResult = [
  {
    order_id: number;
    custom_info: CustomInfo;
  },
];

export type BulkedCustomInfoQueryResult = Record<
  string,
  APIGatewayBulkResponse<CustomInfo>
>;
export type BulkedCustomInfoQueryPayload = Record<
  string,
  {
    action: ManageOrderModalAPIActions.OrderGetCustomInfo;
    payload: OrderCustomInfoQueryPayload;
  }
>;

// --------- [ ETD DOCUMENTS ]
export type GetETDDocumentsQueryPayload = {
  external_id: string;
  marketplace_id: number;
  marketplace_platform: string;
  customer_name?: string;
};

export type GetETDDocumentsBulkQueryPayload = (GetETDDocumentsQueryPayload & {
  order_id: number;
})[];

export type GetETDDocumentsBulkQueryResult = {
  external_id: string;
  order_id: number;
  documents: ETDDocument[];
}[];

export type UploadETDDocumentsMutationPayload = {
  external_id: string;
  customer_name?: string;
  attachments: {
    file_name: string;
    type: string;
    base64File: string;
  }[];
};

export type UploadETDDocumentsResponse = {
  data: Omit<UploadETDDocumentsMutationPayload, 'attachments'>;
  responses: Omit<BaseAPIResult<boolean>, 'message'>[];
};

export type DeleteETDDocumentsMutationPayload = {
  ids: number[];
};

export type EditETDDocumentTypeMutationPayload = {
  id: number;
  type: number;
};

// --------- [ EDIT RECIPIENT ADDRESS ]
export type EditRecipientAddressQueryPayload = {
  id: number;
  address: Address;
};

export type EditRecipientAddressMutationResult = AddressMutationResult & {};

// --------- [ EDIT DESCRIPTION ]
export type EditItemsDescriptionQueryPayload = {
  id: number;
  items_description: string;
};

export type EditItemsDescriptionMutationResult = BaseAPIResult<boolean> & {
  items_description: string;
};

// --------- [ SUBMISSION ]
export type OrderShipOrEditMutationPayload = {
  id: number | null;
  external_id: string;
  marketplace_id: string | null;
  marketplace_platform: string | null;
  to_address: Address;
  from_address: Address | undefined;
  parcels: Parcel[];
  total_amount: string | null;
  currency: string;
  description: string | null;
  payment: Payment;
  incoterm: string | null;
  bill_account_number: string | null;
  notes: Note | null;
  is_return: boolean;
  async: boolean;
  attributes: Attribute[] | null;
  category: string;
  label: {
    type: null;
    width: null;
    height: null;
    rotate: null;
    page_number: null;
    split_pages: null;
  };
  cn22info?: CustomInfo;
  rate: Rate | null;
  pricing?: {
    zone_name?: string;
    weight_range?: string;
    detail?: {
      type: string;
      price: number;
      desc: string;
    }[];
  } | null;
  carrier_options: {
    name: string;
    value: unknown;
  }[];
  event_log_body_params?: { [key: string]: string | boolean | number };
};
