import type { Marketplace } from '@web/types/global';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import {
  toCurrencySymbol,
  toLocalizedPrice,
} from '@shippypro/design-system-web/functions';

interface IOrderStatusProps {
  status: string;
  totalAmount: string;
  currency: string;
  marketplace_platform: string;
  marketplace?: Pick<Marketplace, 'label' | 'displayName'>;
}

const OrderStatus: React.FC<IOrderStatusProps> = ({
  status,
  totalAmount,
  currency,
  marketplace_platform,
  marketplace,
}) => {
  const { t } = useTranslation(),
    transPopovers = translations.ship.table.columns.popovers;

  return (
    <div
      className="flex flex-col items-start justify-center"
      data-test="data-table-popover"
    >
      <p className="m-0">{marketplace?.label ?? marketplace_platform}</p>
      <p className="m-0">
        {t(transPopovers.totalSales)}{' '}
        {toLocalizedPrice(
          toCurrencySymbol(currency),
          !!totalAmount ? +totalAmount : 0,
        )}
      </p>
      <p className="m-0">{status}</p>
    </div>
  );
};

export default OrderStatus;
