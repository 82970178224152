import classnames from 'classnames';

import { Badge } from '../badge/Badge';

import { IAvatarProps } from './types';
import { BaseColor } from '@ds-web/tokens/enums';

/**
 * This component is used to render an avatar to the page.
 * Said avatar is a circular element that can contain an image, a text or an icon.
 * It can also have a badge on the top right corner and a colored status element at the bottom right corner.
 *
 * @interface IAvatarProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const Avatar = ({
  id,
  className,
  color,
  size,
  content,
  icon,
  badgeUp,
  badgeColor = BaseColor.primary,
  badgeText = ' ',
  img,
  imgHeight,
  imgWidth,
  status,
  ...rest
}: IAvatarProps) => (
  <div
    id={id}
    className={classnames('avatar', className, {
      [`bg-${color}`]: !!color,
      [`avatar-${size}`]: !!size,
    })}
    {...rest}
  >
    {!img ? (
      <span
        className={classnames('avatar-content', {
          'position-relative': badgeUp,
        })}
      >
        {content}

        {icon ?? null}
        {badgeUp ? (
          <Badge color={badgeColor} className="badge-sm badge-up" pill>
            {badgeText}
          </Badge>
        ) : null}
      </span>
    ) : (
      <img
        src={img}
        alt="avatarImg"
        height={imgHeight && !size ? imgHeight : 32}
        width={imgWidth && !size ? imgWidth : 32}
      />
    )}
    <span
      className={classnames(`avatar-status-${status}`, { hidden: !status })}
    ></span>
  </div>
);
