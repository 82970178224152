import { FC } from 'react';
import { UncontrolledDropdown, DropdownToggle } from 'reactstrap';
import classnames from 'classnames';

import { DropdownMenu } from '@ds-web/components/atoms/ui/dropdowns/dropdown';

import NavmenuItem from './NavmenuItem';
import NavmenuDropdownItem from './NavmenuDropdownItem';
import NavmenuCollectorItem from './NavmenuCollectorItem';

import useGetActiveTabData from './hooks/useGetActiveTabData';

import { INavmenuDropdownProps, MenuItem } from './types';

const NavmenuDropdown: FC<INavmenuDropdownProps> = ({
  item,
  activeTab,
  tabChangeHandler,
  hiddenItems = [],
}) => {
  const ChildCmp = item.collector ? NavmenuCollectorItem : NavmenuDropdownItem;
  const children: MenuItem[] = item.collector
    ? hiddenItems
    : item.children ?? [];

  const { displayActiveItem, isCheckFather } = useGetActiveTabData(
    children,
    activeTab,
    item,
  );
  const isSingleCollector = children[0] === item && children.length === 1;

  return (
    <>
      <UncontrolledDropdown
        inNavbar
        disabled={item.disabled}
        className={classnames('navmenu-item navmenu-dropdown', {
          'md:ml-auto': item.right,
          'nav-menu-disabled': item.disabled,
          'navmenu-collector': item.collector,
          'md:hidden': isSingleCollector,
        })}
        id={`nav-item-${item.id}`}
      >
        <DropdownToggle className="navmenu-dropdown-toggle" nav tag="div">
          <NavmenuItem
            item={displayActiveItem ?? item}
            activeTab={isCheckFather ? item.id : activeTab}
            tabChangeHandler={() => {}}
            caret={Boolean(children.length)}
          />
        </DropdownToggle>
        <DropdownMenu
          className={classnames('!min-w-fit', {
            '!right-0': item.right,
          })}
        >
          {children?.map(item => (
            <ChildCmp
              item={item}
              activeTab={activeTab}
              tabChangeHandler={tabChangeHandler}
              clickHandler={item.clickHandler}
              key={item.id}
            />
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
      <div
        className={classnames('hidden', {
          'md:flex md:visible': isSingleCollector,
        })}
      >
        <NavmenuItem
          item={item}
          activeTab={activeTab}
          tabChangeHandler={tabChangeHandler}
          clickHandler={item.children ? undefined : item.clickHandler}
        />
      </div>
    </>
  );
};

export default NavmenuDropdown;
