import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@web/utils/@reduxjs';

import { initialState } from './slice';

import { OrderCategory } from '@web/types/order';

const selectDomain = (state: RootState) => state.ship || initialState;

// 'Orders Actions' Selectors

export const selectEditOrderNotesDialog = createSelector(
  [selectDomain],
  shipState => shipState.editOrderNotesDialog,
);

export const selectOrdersPrinting = createSelector(
  [selectDomain],
  shipState => shipState.actions.printing,
);

export const selectExportPerformingAction = createSelector(
  [selectDomain],
  shipState => shipState.actions.exportPerformingAction,
);

export const selectNewDocPerformingAction = createSelector(
  [selectDomain],
  shipState => shipState.actions.newDocPerformingAction,
);

// 'Order Info Drawer' Selectors

export const selectOrderDrawer = createSelector(
  [selectDomain],
  shipState => shipState.infoDrawer,
);

export const selectHighlightedOrder = createSelector(
  [selectOrderDrawer],
  drawer => drawer.order,
);

// 'Order InvoiceInfo Drawer' Selectors

export const selectInvoiceInfoDrawer = createSelector(
  [selectDomain],
  shipState => shipState.invoicePackingSlipDrawer,
);

// 'Order Picking List Drawer' Selectors

export const selectPickingListDrawer = createSelector(
  [selectDomain],
  shipState => shipState.pickingListDrawer,
);

// 'Order Summary Drawer' Selectors
export const selectOrderSummaryDrawer = createSelector(
  [selectDomain],
  shipState => shipState.orderSummaryDrawer,
);

// Table settings drawer is opened
export const selectTableSettingsIsOpen = () =>
  createSelector(
    [selectDomain],
    shipState => shipState.tablesSettings.drawerOpen,
  );

// Edit views drawer is opened
export const selectEditViewsDrawerIsOpen = () =>
  createSelector([selectDomain], shipState => shipState.editViewsDrawer.open);

// Edit views tabs list
export const selectEditViewsDrawerTabs = () =>
  createSelector([selectDomain], shipState => shipState.editViewsDrawer.tabs);

// Table settings drawer large option display
export const selectTableSettingsShowLarge = createSelector(
  [selectDomain],
  shipState => shipState.tablesSettings.showLargeRowDensityOption,
);

// Table settings by category
export const selectTableSettingsByCategory = (category: OrderCategory) =>
  createSelector(
    [selectDomain],
    shipState => shipState.tablesSettings[category],
  );

// Get carriers error messages
export const selectCarriersErrorMessages = () =>
  createSelector(
    [selectDomain],
    shipState => shipState.carriersErrorsMessages?.errorMessages,
  );

// Get carriers error suggestions
export const selectCarriersErrorSuggestions = () =>
  createSelector(
    [selectDomain],
    shipState => shipState.carriersErrorsMessages?.suggestions,
  );
