import { Italian } from 'flatpickr/dist/l10n/it';
import { Spanish } from 'flatpickr/dist/l10n/es';
import { French } from 'flatpickr/dist/l10n/fr';
import { German } from 'flatpickr/dist/l10n/de';

/**
 * Returns the correct lang locale for the flatpickr library based on the passed country code.
 *
 * @param {string} code The locale country code (it, es, fr...)
 * @returns {CustomLocale} The locale config for the flatpickr library
 */
export const getFlatpickrLocaleFromI18Next = code => {
  switch (code) {
    case 'it':
      return Italian;
    case 'es':
      return Spanish;
    case 'fr':
      return French;
    case 'de':
      return German;
    default:
      return 'default';
  }
};
