import { useContext } from 'react';

import UserConfiguredTabsContext from '@web/features/ship/contexts/user-configured-tabs';

/**
 * This Hook gets the size of a table column from the current tab configuration,
 * returning the default size if the column size was not already set.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 *
 * @param {string} apiColCode The code of the column to get the size of
 * @param {number} defaultSize The default size to return if the column size was not set
 * @returns {number} The size of the column
 */
const useGetTabColumnSize = (apiColCode: string, defaultSize: number) => {
  const {
    utils: { currentAPITab },
  } = useContext(UserConfiguredTabsContext)!;

  const currentAPIColumn = currentAPITab?.configured_table_columns.find(
    c => c.code === apiColCode,
  );

  return currentAPIColumn?.size ?? defaultSize;
};

export default useGetTabColumnSize;
