import { FC, useEffect, useRef, useState } from 'react';
import { useInterval } from 'react-use';

import { IPaceProps } from './types';
import { StyledPace } from './styles';

/**
 * A custom top-progress bar that shows a fake loading state
 * while the FE fetches new data from the BE.
 *
 * @interface IPaceProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const Pace: FC<IPaceProps> = ({ toggle, complete }) => {
  // Setting the current progress of the loading bar, expressed in a percentage value used for the width of the bar
  const [progress, setProgress] = useState(0);
  // Adding a changeable step length to the progress bar, to add a bit of randomness to the loading time
  const stepLengthRef = useRef(0);

  useInterval(
    () => {
      stepLengthRef.current += Math.random() * 200;

      setProgress(1 - Math.exp((-1 * stepLengthRef.current) / 1000));
    },
    // If the `toggle` or `complete` props change, the loading bar will either stop or run to completion
    toggle && !complete ? 500 + 200 * Math.random() : null,
  );

  // Reset the progress bar after a while when the `complete` prop changes to `true`
  // to give the component a smooth opacity transition feel
  useEffect(() => {
    if (complete) {
      const timeout = setTimeout(() => {
        setProgress(0);
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [complete]);

  return (
    <StyledPace
      className="pace-progress-bar"
      style={{
        // The loading bar will be gradually hidden when the `complete` prop is `true`
        opacity: complete ? 0 : 1,
        // Changing the width of the loading bar based on the current progress and completion status
        width: !progress ? `0%` : complete ? `100%` : `${progress * 100}%`,
      }}
    />
  );
};
