import { FC, memo } from 'react';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import { Button } from '@shippypro/design-system-web';
import { Color } from '@shippypro/design-system-web/enums';
import { IconHelper } from '@shippypro/design-system-web/iconhelper';

import { browserLanguage } from '@web/utils/@translations/browser-language';

interface IHelpCenterProps {}

/**
 * A handy list of help articles with a search feature to find more specific pieces of information.
 * The basic, non-searched list of articles changes depending on the page we're on.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const HelpCenter: FC<IHelpCenterProps> = memo(() => {
  const { t } = useTranslation(),
    trans = translations.help,
    transHome = trans.home;

  const localeStr = browserLanguage === 'it' ? 'it/' : '';

  return (
    <section
      data-test="helpcenter-subsection"
      className="relative w-full flex flex-col"
    >
      <div
        className="w-full flex flex-col sticky top-0 bg-white"
        data-test="helpcenter-subsection_header"
      >
        <h3 className="!p-4 !pt-4 text-[color:--shp-color-text-cardtitle] font-bold uppercase">
          {t(transHome.helpCenter)}
        </h3>
        <span className="!mx-0 border-b-2 border-[color:--shp-color-bg-main]" />
      </div>
      <div
        className="w-full !mt-4 !mb-4 !pb-0 !px-4 flex flex-col !gap-4"
        data-test="helpcenter-subsection_gotosite"
      >
        <p>{t(transHome.needInfo)}</p>
        <Button
          data-test="open-support-site-btn"
          color={Color.primary}
          icon={<IconHelper icon="IconLifebuoy" size={18} />}
          className="w-full sm:w-fit flex justify-center items-center !gap-2"
          onClick={
            /* istanbul ignore next */
            () =>
              window.open(
                `https://help.shippypro.com/${localeStr}knowledge`,
                '_blank',
              )
          }
        >
          {t(transHome.goTo)}
        </Button>
      </div>
      <span className="!mx-0 border-b-2 border-[color:--shp-color-bg-main]" />
    </section>
  );
});

export default HelpCenter;
