import { FC } from 'react';

import { IconHelper } from '@ds-web-iconhelper';
import { UncontrolledTooltip } from '@ds-web/components/atoms/ui/tooltip/UncontrolledTooltip';

import { IListIconProps } from './types';

const ListIcon: FC<IListIconProps> = ({ columns, cell, row, ...rest }) => {
  const iconColumn = columns.find(c => c.data === cell && c.icon),
    iconId = `list-view-icon-target-${row.id}`;
  return iconColumn ? (
    <>
      <span className="list-view-icon" id={iconId}>
        <IconHelper icon={row.icon?.code} size={16} />
      </span>
      <UncontrolledTooltip placement="top" target={iconId}>
        {row['icon']['tooltip']}
      </UncontrolledTooltip>
    </>
  ) : null;
};

export default ListIcon;
