import * as SentryReact from '@sentry/react';

import { configureStore } from '@reduxjs/toolkit';

import rootReducers from '@web/utils/@reduxjs/RootReducers';

import { rootApi } from './rtk-query';

function configureAppStore() {
  const sentryReduxEnhancer = SentryReact.createReduxEnhancer({});

  const store = configureStore({
    reducer: rootReducers,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }).concat(rootApi.middleware) as any,
    enhancers: [sentryReduxEnhancer],
    devTools: process.env.NODE_ENV !== 'production',
  });

  return store;
}

export const store = configureAppStore();
