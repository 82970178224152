// ########################## [IMPORTANT LIB]
import { FC, memo, useState } from 'react';
import styled from 'styled-components';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [TYPES]
import { Order } from '@web/types/order';

// ########################## [DESIGN SYSTEM]
import { Carousel, Slide } from '@shippypro/design-system-web';

// ########################## [PAGE COMPONENTS]
import { Navigation, Pagination } from 'swiper';
import { ProductSlideCard } from '@web/components/common-features/order/ProductSlideCard';
import { ProductsModal } from '@web/components/common-features/order/ProductsModal';

interface IProductCarousel {
  order: Order;
  compact?: boolean;
}

/**
 * Displays a carousel for which every element is a ProductSlideCard
 * Clicking on a single card triggers a modal with the complete list of items to appear
 *
 * @interface IProductCarousel
 * @author Fabio Mezzina <fabio.mezzina@shippypro.com>
 */
export const ProductCarousel: FC<IProductCarousel> = memo(
  ({ order, compact = false }) => {
    const { t } = useTranslation(),
      transCommon = translations.common;

    const [isProductsModalOpen, setIsProductsModalOpen] = useState(false);

    // TODO: this is needed in order to avoid the order accordion to open
    // no other solutions found from the swiper-react library
    document
      .querySelectorAll('.swiper-button-next')
      .forEach(el => el.addEventListener('click', e => e.stopPropagation()));
    document
      .querySelectorAll('.swiper-button-prev')
      .forEach(el => el.addEventListener('click', e => e.stopPropagation()));

    return (
      <>
        <StyledCarousel
          modules={[Navigation, Pagination]}
          slidesPerView={1}
          navigation={true}
          pagination={
            compact
              ? false
              : {
                  type: 'custom',
                  renderCustom: (_, current, total) =>
                    ` ${t(transCommon.currentOfTotal, { current, total })} `,
                }
          }
          loop={true}
          data-test="product-carousel"
          compact={`${compact}`}
        >
          {order.items?.map((item, i) => (
            <Slide key={i} className="cursor-pointer">
              <ProductSlideCard
                item={item}
                clickHandler={e => {
                  e.stopPropagation();
                  setIsProductsModalOpen(true);
                }}
                compact={compact || undefined}
                index={i + 1}
                total={order.items?.length}
              />
            </Slide>
          ))}
        </StyledCarousel>
        <ProductsModal
          items={order.items}
          currency={order.currency}
          isOpen={isProductsModalOpen}
          onClose={() => setIsProductsModalOpen(false)}
        />
      </>
    );
  },
);

const StyledCarousel = styled(Carousel)<{ compact: 'false' | 'true' }>`
  &.swiper {
    padding: 0 !important;
  }

  .swiper-button-next {
    padding-bottom: 10px;
    right: 0;
    top: ${p => (p.compact === 'true' ? '75%' : '65%')};
  }
  .swiper-button-prev {
    padding-bottom: 10px;
    left: 0;
    top: ${p => (p.compact === 'true' ? '75%' : '65%')};
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 12px;
    font-weight: bold;
  }

  .swiper-pagination {
    font-size: 0.875rem;
    bottom: 3px;
    display: none;
  }

  .swiper-button-disabled {
    visibility: hidden;
    opacity: 0;
    transition: 0.3s ease-in-out;
  }
`;
