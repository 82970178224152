import { useMemo, useCallback } from 'react';
import { useGetMarketplacesQuery } from '@web/features/_global/api';
import { CustomRTKQueryOptions } from '@web/utils/@reduxjs/rtk-query';

/**
 * Returns RTK Query hook with renamed data field.
 * Returns user connected marketplaces.
 *
 * @author Fabio Fognani <fabio.fognani@shippypro.com>
 *
 * @param {CustomRTKQueryOptions} options subset of RTK Query hook options
 */
export const useGetMarketplaces = (options?: CustomRTKQueryOptions) => {
  const { data, ...rest } = useGetMarketplacesQuery(undefined, options);
  const activeMarketplaces = useMemo(() => data?.filter(m => m.active), [data]);

  const hasMarketplaceConnected = useCallback(
    (slug: string) =>
      activeMarketplaces?.some(m => m.marketplace_platform === slug) ?? false,
    [activeMarketplaces],
  );

  return useMemo(() => {
    return {
      data,
      ...rest,
      activeMarketplaces,
      allMarketplaces: data,
      hasMarketplaceConnected,
    };
  }, [data, rest, activeMarketplaces, hasMarketplaceConnected]);
};
