// ########################## [TYPES]
import { Carrier } from '@web/types/global';
import { OrderToManage } from './order';

/**
 * Small type to manage the filtering of the orders in the modal, based on a generic filter configuration.
 *
 * @template TValue The type of the active value that will be used to filter the orders
 */
export type ResourcesFilter<TValue = boolean> = {
  applied: boolean;
  value: TValue;

  toggle: (turnOff?: true) => void;
  checkOrder: (order: OrderToManage) => boolean;

  filteredCount: number;
  noCriteriaMatch: boolean;
  someCriteriaMatch: boolean;
  allCriteriaMatch: boolean;
};

/**
 * Small type to manage the filtering of the rates in the modal, based on a generic filter configuration.
 *
 * @template TValue The type of the active value that will be used to filter the rates
 */
export type CarriersArrayFilter = {
  applied: boolean;
  value: Carrier[];

  filterRatesByCarrier: (
    activeCarriers: Carrier[],
    reselectCheapest?: boolean,
  ) => void;
};
