export enum HelpAPIActions {
  fakeGetAction = 'FakeGetAction',
  // [EXPORT NEW ACTIONS ABOVE] < Needed for generating API Hooks seamlessly
}

export enum HelpSubSection {
  home = 'home',
  ticketing = 'ticketing',
  livechat = 'livechat',
}

// -------- [GET HELP ARTICLES]

export interface GetHelpArticlesQueryPayload {
  query: string;
}

export interface GetHelpArticlesQueryResult {
  url: string;
  title: string;
  description: string;
}

// -------- [LIVE CHAT]

export type LiveChatObject = {
  hasLoaded: boolean;
  activeConversation: boolean;
  openHandler: () => void;
  closeHandler: () => void;
};

export type WindowWithLiveChat = Window &
  typeof globalThis & {
    HubSpotConversations: {
      widget: {
        load: (options: { widgetOpen: boolean }) => void;
        status: () => {
          loaded: boolean;
          pending: boolean;
        };
        open: () => void;
        close: () => void;
      };
      on: (event: string, callback: ((payload) => void) | null) => void;
      off: (event: string, callback: ((payload) => void) | null) => void;
      resetAndReloadWidget: () => void;
    };
    hsConversationsSettings: {
      inlineEmbedSelector?: string;
      loadImmediately: boolean;
    };
  };
