import { FC } from 'react';
import styled from 'styled-components';

import { IIconWrapperProps } from './types';

/**
 * This component displays a styled rounded icon with animation on hover.
 * Children prop must be an icon.
 *
 * @interface IIconWrapperProps
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
export const IconWrapper: FC<IIconWrapperProps> = ({
  id,
  className = '',
  disabled = false,
  onClick = () => {},
  children,
  dataTest,
}) => (
  <div className="relative w-fit">
    <StyledIconWrapper
      id={id}
      className={className}
      disabled={disabled}
      onClick={onClick}
      data-test={dataTest}
    >
      {children}
    </StyledIconWrapper>
  </div>
);

export const StyledIconWrapper = styled.div<{ disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border-radius: 50%;
  padding: 6px;
  margin-right: -8px;
  &:hover {
    color: ${({ disabled }) =>
      disabled ? 'inherit' : 'var(--shp-color-text-title)'};
    background-color: ${({ disabled }) =>
      disabled ? 'transparent' : '#e6ebf0'};
  }
`;
