import React, { FC } from 'react';

import {
  Area,
  AreaChart as DefaultAreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { IMultiAreaChartDataProps } from './types';

import { toLocalizedDecimal } from '@ds-web/utils/functions';

import { Dot } from '@ds-web/components';

// Defaults
const gradientDefaults = [
  {
    stopColor: '#7349DD',
    id: 'colorShp',
  },
];
const YAxisPropsDefaults = [
  { YDataKey: 'yValue', width: 35, chartPadding: { top: 25 } },
];
const areasDefaults = [
  {
    YAxisProps: ['yValue'],
    type: 'linear',
    stroke: '#7349DD',
    fillOpacity: 1,
    fill: 'url(#colorShp)',
  },
];

/**
 * A component that render and multi area chart.
 *
 * @interface IMultiAreaChartDataProps
 * @author Federico Mauri <federico.mauri@shippypro.com>
 */
export const MultiAreaChart: FC<IMultiAreaChartDataProps<string>> = ({
  data,
  tickCount = 2,
  gradients = gradientDefaults,
  XDataKey = 'xName',
  YAxisProps = YAxisPropsDefaults,
  areas = areasDefaults,
}) => {
  return (
    <ResponsiveContainer width="98%" height="99%">
      <DefaultAreaChart data={data} margin={{ right: 0, left: 0 }}>
        <defs>
          {gradients.map(gradient => (
            <linearGradient id={gradient.id} x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor={gradient.stopColor}
                stopOpacity={0.4}
              />
              <stop
                offset="95%"
                stopColor={gradient.stopColor}
                stopOpacity={0}
              />
            </linearGradient>
          ))}
        </defs>

        <XAxis dataKey={XDataKey} tickCount={tickCount} />
        {YAxisProps.map(YAxisProps => (
          <YAxis
            key={YAxisProps.YDataKey}
            dataKey={YAxisProps.YDataKey}
            width={YAxisProps.width}
            tickFormatter={value => toLocalizedDecimal(value, 0, 0)}
            padding={YAxisProps.chartPadding}
          />
        ))}
        <CartesianGrid />
        <Tooltip
          content={data =>
            data.active &&
            data.payload && (
              <div className="recharts-custom-tooltip">
                <p className="font-light">{data.label}</p>
                <div className="active">
                  {data.payload.map(i => {
                    return (
                      <div className="flex items-center" key={i.dataKey}>
                        <Dot
                          dotClassName="!relative !size-[10px] !mb-0 pt-[2px] mr-[6px]"
                          color={i.color}
                        />
                        <span className="flex font-bold text-xl text-black">
                          {i.payload[i.dataKey ?? 0]}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )
          }
        />
        {areas.map((areaProp, index) => {
          return (
            <Area
              type={areaProp.type}
              dataKey={YAxisProps[index].YDataKey}
              stroke={areaProp.stroke}
              fillOpacity={areaProp.fillOpacity}
              fill={areaProp.fill}
            />
          );
        })}
      </DefaultAreaChart>
    </ResponsiveContainer>
  );
};
