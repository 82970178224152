import { useState, useCallback, useMemo } from 'react';

import { ITopProgressBarContextProps } from '@web/features/top-progress-bar/contexts/TopProgressBarContext';
import { useToggle } from 'react-use';

/**
 * `[HOOK]` This hook instances every default info prop related to the Top Progress Bar.
 * It is also used as a localised state that can get updated by an external source (I.e. A dedicated context).
 *
 * N.B. **ALREADY INSTANTIATED AT THE NEAR-TOP OF THE CODE TREE, NO NEED TO USE THIS ANYWHERE ELSE**
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 *
 * @returns {ITopProgressBarContextProps} All of the info needed for the top progress bar to function
 */
const useInstanceTopProgressBar = (): ITopProgressBarContextProps => {
  const [toggle, setToggle] = useToggle(false);
  const [complete, setComplete] = useState(false);

  // Function that automatically manages the toggleable state of the Top Progress Bar component
  // And can discern between a loading and a completed state on its own
  const toggleTopProgressBar = useCallback(
    nextVal => {
      if (nextVal === toggle) return;

      setToggle(nextVal);
      setComplete(!nextVal);
    },
    [setToggle, toggle],
  );

  return useMemo(
    () => ({
      toggle,
      complete,
      toggleTopProgressBar,
    }),
    [complete, toggle, toggleTopProgressBar],
  );
};

export default useInstanceTopProgressBar;
