import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import { useGetUserConfiguredTabsQuery } from '@web/features/ship/api';
import { useGetUser } from '@web/features/_global/hooks/api/useGetUser';

import { parseAPIError } from '@web/utils/@reduxjs/rtk-query';
import { showErrorToastr } from '@shippypro/design-system-web/functions';

import { DEFAULT_DeliverViews } from '@web/utils/@reduxjs/microservices/user-engine/defaults';

/**
 * Returns RTK Query hook with renamed data field.
 * It also returns a default value in case of error or while loading the true dataset.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const useGetUserConfiguredTabs = () => {
  const { t } = useTranslation(),
    transCommon = translations.common,
    transErr = transCommon.errors;

  const { user } = useGetUser();
  const {
    data: userConfiguredTabs,
    error,
    isLoading,
    ...rest
  } = useGetUserConfiguredTabsQuery(
    { user_id: user?.id ?? -1 },
    { skip: !user },
  );

  const parsedError = useMemo(
    /* istanbul ignore next */
    () => parseAPIError(error),
    [error],
  );
  if (parsedError)
    showErrorToastr(t(transErr.error), parsedError, {
      toastId: 'useGetUserConfiguredTabs',
    });

  const arePrefsNotLoaded = useMemo(
    () => (isLoading && !userConfiguredTabs) || error || !userConfiguredTabs,
    [error, isLoading, userConfiguredTabs],
  );

  return useMemo(
    () => ({
      data: arePrefsNotLoaded ? DEFAULT_DeliverViews : userConfiguredTabs,
      views_list: arePrefsNotLoaded
        ? DEFAULT_DeliverViews.preferences.views_list
        : userConfiguredTabs?.preferences.views_list ?? [],
      error: parsedError,
      isLoading,
      ...rest,
    }),
    [arePrefsNotLoaded, userConfiguredTabs, isLoading, parsedError, rest],
  );
};
