import { FC } from 'react';

import { Card as ReactstrapCard } from 'reactstrap';

import { ICardProps } from './types';

/**
 * A simple wrapper class for the `reactstrap: <Card />` component.
 *
 * @interface ICardProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const Card: FC<ICardProps> = props => <ReactstrapCard {...props} />;
