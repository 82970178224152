import { useCallback, useContext } from 'react';

import {
  GLSFormatCode,
  GLSFormatConfiguration,
  GLSItalyPrintFormatPickerContext,
  useGetGLSItalyFormatValueFromCode,
} from '@web/features/ship/components/dialogs/glsitaly-printformat-picker';
import { Order } from '@web/types/order';

import { BRT_NAME } from '@web/features/ship/tabs/Shipped/components/buttons/print-btn/BtnPrintBRT';
import { GLSITALY_NAME } from '@web/features/ship/tabs/Shipped/components/buttons/print-btn/BtnPrintGLS';

import { usePrintOrders } from '@web/features/ship/hooks/api/printing/usePrintOrder';
import { usePrintBrtLabels } from '@web/features/ship/hooks/api/printing/usePrintBRTLabels';

/**
 * [HOOK] This hook manage the printing logic behind a single order print
 * It takes into account if the carrier used is BRT or GLS as they have custom print behaviour and preferences
 * It then returns a callback and the isLoading prop
 *
 * @returns {Function, boolean}
 *
 * @author Fabio Mezzina <fabio.mezzina@shippypro.com>
 */
export const usePrintSingleOrder = () => {
  // Normal Print Logics
  const { printOrders, isLoading } = usePrintOrders();

  // BRT Print Logics
  const { printBrtLabels } = usePrintBrtLabels();

  // GLS Italy Print Logics
  const defaultFormat = localStorage.getItem(
    GLSFormatConfiguration.SingleLabel,
  ) as keyof typeof GLSFormatCode | null;
  const defaultFormatValue = useGetGLSItalyFormatValueFromCode(defaultFormat);

  const { openDialog } = useContext(GLSItalyPrintFormatPickerContext)!;

  // Now let's put them all together
  const printOrder = useCallback(
    (order: Order) => {
      switch (order.carrier_name) {
        case BRT_NAME:
          printBrtLabels({ ids: [order.id] });
          break;
        case GLSITALY_NAME:
          if (defaultFormat)
            printOrders({ ids: [order.id], glsformat: defaultFormatValue });
          else openDialog({ ids: [order.id], glsformat: defaultFormatValue });
          break;
        default:
          printOrders({ ids: [order.id] });
      }
    },
    [
      printBrtLabels,
      defaultFormat,
      printOrders,
      defaultFormatValue,
      openDialog,
    ],
  );

  return { printOrder, isLoading };
};
