import styled from 'styled-components';

export const HoverableDiv = styled.div`
  display: flex;
  gap: 8px;
  padding: 4px 8px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 3px;

  .hover-visible {
    opacity: 0;
    position: absolute;
    right: 0;
  }
  &:hover .hover-visible {
    opacity: 100;
    background: var(--shp-color-bg-highlight);
  }

  &:hover {
    background: var(--shp-color-bg-highlight);
  }
`;
