// ########################## [IMPORTANT LIBRARIES]
import { useEffect, useState } from 'react';
import { parseAPIError } from '@web/utils/@reduxjs/rtk-query';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [STATE MANAGEMENT]

// ########################## [FORMS]

// ########################## [TYPES]
import { IOrderInfoBlock } from './types';
import { Order } from '@web/types/order';

// ########################## [DESIGN SYSTEM]
import { Button, CardLoading } from '@shippypro/design-system-web';
import { Color, StyleType } from '@shippypro/design-system-web/enums';

// ########################## [PAGE COMPONENTS]
import CardError from '@web/components/common-ui/wrappers/card/CardError';
import CardView from '@web/components/common-ui/wrappers/card/CardView';
import OrderTimeline from './OrderTimeline';

// ########################## [UTILS]

// ########################## [CONTEXTS]

// ########################## [HOOKS]
import useGetOrderTimelineConfig from '@web/hooks/orders/useGetOrderTimelineConfig';
import { useGetOrderTimeline } from '@web/features/order/hooks/api/useGetOrderTimeline';

interface IOrderTimelineCardProps extends IOrderInfoBlock {
  order: Order;
  cardAction?: () => void;
  expanded?: boolean;
}

/**
 * The OrderTimelineCard component is a card wrapping the OrderTimeline component. It receives the order from outside,
 * together with the card action (if any) and the info about whether to expand the timeline or not. It renders a card with
 * title and action, wrapping the timeline with a button to expand it that is tha case.
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 *
 * @version 1.0.0
 * @interface IOrderTimelineCardProps
 * @returns {JSX.Element}
 */
const OrderTimelineCard: React.FC<IOrderTimelineCardProps> = ({
  order,
  cardAction,
  expanded,
}) => {
  const { t } = useTranslation(),
    transOrder = translations.order.info;

  const {
    getTimeline,
    data: events,
    isLoading,
    isError,
    error,
  } = useGetOrderTimeline();

  const [showNumber, setShowNumber] = useState(expanded ? 0 : -4);
  const timeline = useGetOrderTimelineConfig((events ?? []).slice(showNumber));
  const hasMore = timeline.length < (events ?? []).length;

  // Fetching the Order's timeline
  useEffect(() => {
    if (order) getTimeline(order);
  }, [order, getTimeline]);

  return (
    <CardView
      col
      header={t(transOrder.orderTimeline).toUpperCase()}
      name="timeline"
      customAction={typeof cardAction !== 'undefined'}
      actionFn={cardAction}
      actionIcon="IconArrowsMaximize"
    >
      <OrderTimeline
        isLoading={isLoading}
        isError={isError}
        timeline={timeline}
        loadingComponent={<CardLoading />}
        errorComponent={
          <CardError fullScreen noCard error={parseAPIError(error)} />
        }
      >
        {hasMore ? (
          <Button
            className="timeline-more"
            color={Color.primary}
            styleType={StyleType.flat}
            onClick={() => setShowNumber(0)}
          >
            {t(transOrder.showMoreEvents)}
          </Button>
        ) : undefined}
      </OrderTimeline>
    </CardView>
  );
};

export default OrderTimelineCard;
