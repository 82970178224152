import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import { IOrderInfoBlock } from './types';

import { Timeline } from '@shippypro/design-system-web';
import { TimelineEvent } from '@shippypro/design-system-web/types';

interface IOrderTimelineProps extends IOrderInfoBlock {
  isLoading: boolean;
  isError: boolean;
  loadingComponent?: JSX.Element;
  errorComponent?: JSX.Element;
  timeline: TimelineEvent[];
  children?: JSX.Element;
}

/**
 * The OrderTimeline component receives the timeline from outside, together with loading and error components
 * and statuses and renders the order track and trace timeline.
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 *
 * @version 1.0.0
 * @interface IOrderTimeline
 * @returns {JSX.Element}
 */

const OrderTimeline: React.FC<IOrderTimelineProps> = ({
  isLoading,
  isError,
  loadingComponent,
  errorComponent,
  timeline,
  children,
}) => {
  const { t } = useTranslation(),
    transOrder = translations.order.info;

  return (
    <div className="card-timeline">
      {isLoading && loadingComponent && loadingComponent}
      {isError && errorComponent && errorComponent}
      {timeline && timeline.length ? (
        <Timeline data={timeline} className="w-auto" />
      ) : (
        !isLoading &&
        !isError && (
          <div className="flex w-full justify-center">
            <p className="text-muted">{t(transOrder.noEvents)}</p>
          </div>
        )
      )}
      {children}
    </div>
  );
};

export default OrderTimeline;
