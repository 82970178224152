// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from '@web/utils/@reduxjs/toolkit';

import { FeatureState } from './types';

// The initial state of the Onboarding feature
export const initialState: FeatureState = {};

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {},
});

export const {
  actions: onboardingActions,
  reducer,
  name: sliceKey,
} = onboardingSlice;
