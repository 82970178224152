const NON_UPDATABLE_MARKETPLACES = [
  'Manual shipment',
  'Excel',
  'APIOrders',
  'Returns',
];

/**
 * Given a marketplace platform, check against an array of marketplaces that can't be updated
 * Return whether that marketplace could be updated
 *
 * @param {string} marketplace
 * @returns {boolean}
 * @author [Fabio Mezzina] <fabio.mezzina@shippypro.com>
 */
export function canUpdateMarketplace(marketplace: string): boolean {
  return !NON_UPDATABLE_MARKETPLACES.includes(marketplace);
}
