export type IGLSItalyPrintFormatPickerInfo = {
  ids: number[];
  glsformat: GLSFormatValue;
};

export type GLSFormatValue = -1 | 0 | 1 | 2 | 3 | 4 | 5;

export enum GLSFormatCode {
  'a4_4Labels' = 0,
  'a4_topLeft' = 1,
  '10x15_originalDimensions' = 2,
  'a4_topCenter' = 3,
  '10x10' = 4,
  '2labels_10x10' = 5,
}

export type GLSFormatOption = {
  format: GLSFormatValue;
  code: GLSFormatCode;
};

export enum GLSFormatConfiguration {
  SingleLabel = 'glsitaly-labelformat_single',
  MultipleLabels = 'glsitaly-labelformat_multiple',
}
