import styledComponents from 'styled-components';
import { AccordionHeader } from '../AccordionHeader';

export const StyledHoverableAccordionHeader = styledComponents(AccordionHeader)`
  &:hover {
    background-color: var(--shp-color-bg-highlight);
    svg,
    p {
      color: var(--shp-color-genericui-primary);
    }
  }
`;
