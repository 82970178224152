import { FC, memo } from 'react';
import {
  Offcanvas as ReactstrapOffcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from 'reactstrap';

import classnames from 'classnames';
import { Spinner } from 'reactstrap';

import { Button } from '@ds-web/components/atoms/ui/buttons/button/Button';

import { IconHelper } from '@ds-web-iconhelper';
import { StyleType } from '@ds-web/tokens/enums';

import { IOffcanvasProps } from './types';
import { TooltipWrapperByREF } from '@ds-web/components/molecules/ui/tooltips/tooltip-wrapper-byref/TooltipWrapperByREF';

/**
 * The main `reactstrap: <Offcanvas />` component, that can overlay the content of the rest of the page when opened.
 *
 * @interface IOffcanvasProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const Offcanvas: FC<IOffcanvasProps> = memo(
  ({
    show,
    direction = 'end',
    backdrop = true,
    scrollable = false,
    header,
    showButtons = true,
    leftBtnTxt,
    rightBtnTxt,
    loadingTxt,
    handleToggle,
    handleLeft,
    handleRight,
    isDangerous = false,
    loading = false,
    disableLeft,
    disableRight,
    colorLeft,
    colorRight,
    children,
    bigButton,
    id,
    backdropTransition,
    offcanvasTransition,
    offcanvasClassname,
    headerClassName,
    bodyClassName,
  }) => {
    return (
      <ReactstrapOffcanvas
        direction={direction}
        isOpen={show}
        toggle={handleToggle}
        backdrop={backdrop}
        scrollable={scrollable}
        className={classnames(offcanvasClassname, {
          'border-danger': isDangerous,
          '!border-[color:--shp-color-genericborder-primary-light]':
            !isDangerous,
        })}
        id={id}
        backdropTransition={backdropTransition}
        offcanvasTransition={offcanvasTransition}
      >
        <OffcanvasHeader
          toggle={handleToggle}
          className={classnames(
            {
              'bg-danger': isDangerous,
              'bg-white': !isDangerous,
            },
            headerClassName,
          )}
        >
          {header}
        </OffcanvasHeader>
        <OffcanvasBody
          className={classnames(
            'bg-white border-y !border-x-0 border-[color:--shp-color-genericborder-primary-light] !px-0',
            bodyClassName,
          )}
        >
          {children}
        </OffcanvasBody>
        {bigButton && (
          <TooltipWrapperByREF
            disabled={!bigButton.tooltip}
            content={bigButton.tooltip}
          >
            <div
              className={`${
                bigButton.className ?? ''
              } page-offcanvas-footer !px-6 !py-4 bg-white`}
            >
              <Button
                color={bigButton.color || 'primary'}
                onClick={() => bigButton.handleClick()}
                disabled={loading || bigButton.disabled}
                className="w-full flex items-center justify-center gap-1"
              >
                {loading && !isDangerous ? (
                  <>
                    <Spinner color="white" size="sm" />
                    <span className="ml-50">{loadingTxt}</span>
                  </>
                ) : (
                  <>
                    {bigButton.icon && <IconHelper icon={bigButton.icon} />}
                    {bigButton.txt}
                  </>
                )}
              </Button>
            </div>
          </TooltipWrapperByREF>
        )}
        {showButtons && !bigButton && (
          <div className="page-offcanvas-footer !px-6 d-flex justify-content-end align-items-center !py-7">
            <Button
              color={colorLeft || 'primary'}
              onClick={() => handleLeft && handleLeft()}
              disabled={loading || disableLeft}
            >
              {loading && !isDangerous ? (
                <>
                  <Spinner color="white" size="sm" />
                  <span className="ml-50">{loadingTxt}</span>
                </>
              ) : (
                leftBtnTxt
              )}
            </Button>
            <Button
              className="ml-10"
              color={colorRight || 'danger'}
              styleType={StyleType.outline}
              outline
              onClick={() => handleRight && handleRight()}
              disabled={loading || disableRight}
            >
              {loading && isDangerous ? (
                <>
                  <Spinner color="white" size="sm" />
                  <span className="ml-50">{loadingTxt}</span>
                </>
              ) : (
                rightBtnTxt
              )}
            </Button>
          </div>
        )}
      </ReactstrapOffcanvas>
    );
  },
);
