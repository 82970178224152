import { FC, memo, useContext } from 'react';

import { IconHelper } from '@shippypro/design-system-web/iconhelper';

import { PaywallInfo } from '@web/features/paywall/types';

import PaywallContext from '@web/features/paywall/contexts/PaywallContext';

interface IFeatureDetailsProp {
  info?: PaywallInfo;
}

/**
 * This block shows every detail that the feature offer, displayed in a handy list.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const FeatureDetails: FC<IFeatureDetailsProp> = memo(({ info: infoProp }) => {
  const { info: paywallInfo } = useContext(PaywallContext)!;

  const info = infoProp ?? paywallInfo;

  return (
    <div id="paywall-details" className="px-2 md:!px-8">
      <ul>
        {info?.details.map((detail, i) => (
          <li key={i} className="paywall-single-detail-block">
            <div className="paywall-single-detail-block-check w-6 h-6 flex justify-center items-center bg-accent rounded-full">
              <IconHelper icon="IconCheck" size={18} color="white" />
            </div>
            <span className="paywall-single-detail-block-text">{detail}</span>
          </li>
        ))}
      </ul>
    </div>
  );
});

export default FeatureDetails;
