/**
 * Returns a NOT negative number or a fallback value if the input is negative.
 *
 * @param {number | undefined | null} num - The input number to check.
 * @param {number | undefined | null} [fallback=null] - The fallback value to return if the input is negative.
 * @returns {number | undefined | null} - The NOT negative input number or the fallback value.
 */
export function notNegativeNumOrFallback(
  num: number | undefined | null,
  fallback: number | undefined | null = null,
) {
  return num && num > -1 ? num : fallback;
}
