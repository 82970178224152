import { TFunction } from 'i18next';
import { translations } from '@shippypro/translations';

import { DateRange } from '@web/types/filters';
import { endOfDay, getUnixTime, parse, startOfDay } from 'date-fns';

/**
 * Utils to get a date from and a date to from a string interval
 *
 * @param interval
 * @param customStartDate date from which start calculate a range (es. yesterday and not today)
 * @author Federico Mauri <federico.mauri@shippypro.com>
 */
const getDatesFromRange = (interval: DateRange, customStartDate?: Date) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const startDate = customStartDate ?? today;

  const endOfCurrentDay = new Date().setHours(23, 59, 59, 999);
  const endOfCurrentDayDate = new Date(endOfCurrentDay);

  const yesterday = new Date(today);
  yesterday.setHours(0, 0, 0, 0);
  yesterday.setDate(today.getDate() - 1);

  const endOfYesterday = new Date(yesterday).setHours(23, 59, 59, 999);
  const endOfYesterdayDate = new Date(endOfYesterday);

  switch (interval) {
    case DateRange.Today:
      return [today, endOfCurrentDayDate];
    case DateRange.Yesterday:
      return [yesterday, endOfYesterdayDate];
    case DateRange.LastWeek:
      const lastWeek = new Date(startDate).setDate(startDate.getDate() - 6);
      const lastWeekDate = new Date(lastWeek);
      return [lastWeekDate, startDate];
    case DateRange.LastMonth:
      const lastMonth = new Date(startDate).setDate(startDate.getDate() - 29);
      const lastMonthDate = new Date(lastMonth);
      return [lastMonthDate, startDate];
    default:
      return [startDate, endOfCurrentDayDate];
  }
};

const getRangeLabels = (t: TFunction) => {
  const transFilters = translations.ship.table.filters;
  return {
    [DateRange.Today]: t(transFilters.today),
    [DateRange.Yesterday]: t(transFilters.yesterday),
    [DateRange.LastWeek]: t(transFilters.lastWeek),
    [DateRange.LastMonth]: t(transFilters.lastMonth),
  };
};

const getLabelsFromRange = (label: DateRange, t: TFunction): string => {
  const labels = getRangeLabels(t);
  return labels[label];
};

export const getDatesFromAndToFromRange = (
  dateRange: Date[] | (Date | undefined)[] | DateRange | undefined,
  customStartDate?: Date,
  format: string = "yyyy-MM-dd'T'HH:mm:ss.SSSX",
) => {
  let dateFrom: number | undefined = undefined;
  let dateTo: number | undefined = undefined;
  if (typeof dateRange === 'string') {
    const dateRangeValues = getDatesFromRange(dateRange, customStartDate);
    if (dateRangeValues?.length) {
      dateFrom = getUnixTime(startOfDay(dateRangeValues[0]));
      dateTo = getUnixTime(endOfDay(dateRangeValues[1]));
    }
  } else if (dateRange && dateRange.length === 1 && dateRange[0]) {
    dateFrom = getUnixTime(startOfDay(dateRange[0]));
  } else if (
    dateRange &&
    dateRange.length > 1 &&
    dateRange[0] &&
    dateRange[1]
  ) {
    // FIXME: Standardise date formatting throughout the filters to avoid multiple-formattings
    const formattedFrom =
      typeof dateRange[0] === 'string'
        ? parse(dateRange[0], format, new Date())
        : dateRange[0];
    const formattedTo =
      typeof dateRange[1] === 'string'
        ? parse(dateRange[1], format, new Date())
        : dateRange[1];

    dateFrom = getUnixTime(startOfDay(formattedFrom));
    dateTo = getUnixTime(endOfDay(formattedTo));
  }

  return {
    dateFrom,
    dateTo,
  };
};

export { getDatesFromRange, getRangeLabels, getLabelsFromRange };
