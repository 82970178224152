import { FC } from 'react';
import { Nav as ReactstrapNav } from 'reactstrap';
import classnames from 'classnames';

import { Color } from '@ds-web/tokens/enums';

import { INavProps } from './types';

/**
 * A simple wrapper class for the `reactstrap: <Nav />` component.
 *
 * @interface INavProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const Nav: FC<INavProps> = ({
  className,
  type,
  color = Color.primary,
  fill,
  justified,
  center,
  end,
  ...rest
}) => (
  <ReactstrapNav
    className={classnames(className, {
      [`nav-pill-${color}`]: type === 'pills',
      'nav-fill': fill,
      'nav-justified': justified,
      'justify-content-center': center,
      'justify-content-end': end,
    })}
    pills={type === 'pills'}
    tabs={type === 'tabs'}
    {...rest}
  />
);
