import { FC, memo } from 'react';
import { Spinner } from 'reactstrap';

/**
 * A simple view that shows up when something's loading, and displays a very basic spinner at the center of its container element.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const LoadingView: FC = memo(() => {
  return (
    <div className="w-full h-full flex flex-col !gap-1 justify-center items-center">
      <Spinner size="lg" />
    </div>
  );
});
