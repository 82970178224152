import { useEffect, useMemo, useState } from 'react';

/**
 * A hook that returns a boolean indicating whether the given ref is currently in the viewport or not.
 *
 * @param {React.RefObject<HTMLElement>} ref - The ref to observe.
 * @returns {boolean} - A boolean indicating whether the ref is in the viewport.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const useIsInViewport = ref => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting),
      ),
    [],
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
};
