// ########################## [REDUX]
import { useSelector } from 'react-redux';
import { selectCarriersErrorMessages } from '@web/features/ship/selectors';

// ########################## [TRANSLATIONS]
import { TFunction } from 'i18next';

// ########################## [TYPES]
import {
  CarrierErrorMessageType,
  CarriersErrorMessages,
} from '@web/features/ship/types';
import { useCallback, useMemo } from 'react';
import { translations } from '@shippypro/translations';

export const useGetErrorMessageType = () => {
  const carriersErrorMessages = useSelector(selectCarriersErrorMessages());

  const getErrorMessageType = useCallback(
    (
      t: TFunction<'translation', undefined>,
      message: string,
      carrierName: string | null,
      carriersErrorMessages?: CarriersErrorMessages,
    ): CarrierErrorMessageType | null => {
      // No type for not supported carriers
      if (
        !carrierName ||
        !carriersErrorMessages ||
        !carriersErrorMessages[carrierName]
      )
        return null;

      // Find the matching error message in the carrier list
      const carrierErrorsList = carriersErrorMessages[carrierName];
      const messageObj = carrierErrorsList
        ? carrierErrorsList.find(e =>
            message.match(
              new RegExp(e.message.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1')),
            ),
          )
        : null;

      // No type if we don't find a match
      if (!messageObj) return null;

      // Gets the type's translations
      const typeTransObj = messageObj.type;

      // This is a guard, in case the type is yet to be mapped :)
      /* istanbul ignore next */
      if (!typeTransObj) return null;

      const transName =
        translations.ship.carrierErrorSuggestions.types[typeTransObj.code]
          ?.name;
      const transDescription =
        translations.ship.carrierErrorSuggestions.types[typeTransObj.code]
          ?.description;

      return {
        parent: null,
        id: typeTransObj.id,
        code: typeTransObj.code,
        name: transName ? t(transName) : typeTransObj.name,
        description: transDescription
          ? t(transDescription)
          : typeTransObj.description,
      };
    },
    [],
  );

  return useMemo(
    () => ({ getErrorMessageType, carriersErrorMessages }),
    [getErrorMessageType, carriersErrorMessages],
  );
};
