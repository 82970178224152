import classnames from 'classnames';

import { Color } from '@shippypro/design-system-web/enums';

import { BadgeWithCustomHover } from '@web/components/common-ui/tables/columns';

interface IOrderTypeBadgeProps {
  className?: string;
  orderType: string;
  compact?: boolean;
}

/**
 * Summary badge that shows if the order has Cash On Delivery as a payment method.
 *
 * @interface IOrderTypeBadgeProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const OrderTypeBadge: React.FC<IOrderTypeBadgeProps> = ({
  className,
  orderType,
  compact = false,
}) => {
  return (
    <BadgeWithCustomHover
      color={Color.warning}
      className={classnames(
        'order-type-badge flex items-center !gap-1 max-w-fit cursor-default !bg-[--shp-color-bg-warning-light] !text-[--shp-color-text-warning-label]',
        className,
      )}
      icon="IconArrowBadgeUpFilled"
      hasHover={compact}
      label={compact ? '' : orderType}
      target="order-ordtype"
    >
      {orderType}
    </BadgeWithCustomHover>
  );
};

export default OrderTypeBadge;
