import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations_restricted';

import { Button, TooltipWrapperByREF } from '@ds-web/components';

import { IconHelper } from '@ds-web-iconhelper';
import { StyleType } from '@ds-web/tokens/enums';

import { IMailToButtonProps } from './types';

/**
 * Button with Mail icon and mailto logics.
 *
 * @interface IMailToButtonProps
 * @author Fabio Fognani <fabio.fognani@shippypro.com>
 */
export const MailToButton: FC<IMailToButtonProps> = ({
  email,
  tooltip,
  iconProps,
  tooltipProps,
  btnProps,
}) => {
  const { t } = useTranslation();
  const transEmail = translations.common.sendEmailTo;

  return (
    <TooltipWrapperByREF
      content={
        tooltip ??
        t(transEmail, {
          email,
        })
      }
      {...tooltipProps}
    >
      <Button
        className="p-[4px]"
        styleType={StyleType.flat}
        onClick={() => (window.location.href = `mailto:${email}`)}
        disabled={!email}
        {...btnProps}
      >
        <IconHelper
          icon="IconMail"
          size={17}
          className="cursor-pointer"
          {...iconProps}
        />
      </Button>
    </TooltipWrapperByREF>
  );
};
