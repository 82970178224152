import { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from '@web/utils/@reduxjs/toolkit';

import { FeatureState } from './types';

// The initial state of the DataVault feature
export const initialState: FeatureState = {};

const dataVaultSlice = createSlice({
  name: 'dataVault',
  initialState,
  reducers: {
    someAction(state, action: PayloadAction<any>) {},
  },
});

export const {
  actions: dataVaultActions,
  reducer,
  name: sliceKey,
} = dataVaultSlice;
