/**
 * * Returns the given string, with the first letter in lower caps.
 *
 * @param {string} string The string to lower
 *
 * @return {string} The lowered string
 */
export function toLowerFirst(string: string): string {
  return string.charAt(0).toLowerCase() + string.slice(1);
}
