// ########################## [IMPORTANT LIBRARIES]
import React from 'react';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [DESIGN SYSTEM]
import { TooltipWrapperByID } from '@shippypro/design-system-web';
import { IconHelper } from '@shippypro/design-system-web/iconhelper';

// ########################## [TYPES]
import { Country } from '@web/types/global';

interface IExtraEUCountry {
  id: string;
  country: Country | string;
}

/**
 * The ExtraEUCountry block gets the order country from outside
 * and renders a globe icon with a string like 'Extra EU' beside.
 * On hover it shows the country.
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 *
 * @version 1.0.0
 * @interface IExtraEUCountry
 * @returns {JSX.Element}
 */
const ExtraEUCountry: React.FC<IExtraEUCountry> = ({ id, country }) => {
  const { t } = useTranslation(),
    transDetailsCard = translations.order.info.trackingDialog.detailsCard;

  return (
    <TooltipWrapperByID
      tooltipProps={{
        placement: 'top',
      }}
      target={id}
      text={t(transDetailsCard.extraEU, { country: country })}
    >
      <div id={id} className="flex items-center text-sm">
        <IconHelper icon="IconWorld" size={13} className="mr-1" />
        {t(transDetailsCard.extraEU)}
      </div>
    </TooltipWrapperByID>
  );
};

export default ExtraEUCountry;
