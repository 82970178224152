/**
 * Converts a date format to be compatible with the flatpickr library.
 *
 * @param {string} format the Formatting string for a Date object
 * @returns {string} The format changed to be compatible with the flatpickr library
 */
export const dateFormatToFlatPickr = format => {
  return format
    .replace('MMM', 'M')
    .replace('MM', 'm')
    .replace('YYYY', 'Y')
    .replace('DD', 'd')
    .replace(' - HH:mm A', '');
};
