// ########################## [IMPORTANT LIBRARIES]
import React from 'react';

// ########################## [TYPES]
import { Order } from '@web/types/order';
import { Carrier } from '@web/types/global';

// ########################## [DESIGN SYSTEM]

// ########################## [PAGE COMPONENTS]
import ConnectionAvatar from '@web/components/common-ui/tables/columns/ConnectionAvatar';

interface ITrackingCardHeader {
  order: Pick<Order, 'id' | 'carrier_name'>;
  carrier?: Pick<Carrier, 'label' | 'status' | 'image_url'>;
}

/**
 * The TrackingCardHeader component gets the order from outside
 * and renders the avatar of the carrier used.
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 *
 * @version 1.0.0
 * @interface ITrackingCardHeader
 * @returns {JSX.Element}
 */
const TrackingCardHeader: React.FC<ITrackingCardHeader> = ({
  order,
  carrier,
}) => {
  return (
    <div className="font-medium text-lg h-10">
      {carrier?.image_url && (
        <ConnectionAvatar
          orderId={order.id}
          type="carrier"
          img={carrier?.image_url}
          alt={carrier?.label ?? order.carrier_name}
          status={carrier?.status || null}
          isStatic
        />
      )}
      {order.carrier_name}
    </div>
  );
};

export default TrackingCardHeader;
