import { useRef, useState, FC, useEffect, useCallback } from 'react';
import { Navigation, Pagination, Keyboard } from 'swiper';
import styled from 'styled-components';
import classnames from 'classnames';

import {
  UncontrolledTooltip,
  Carousel,
  Slide,
} from '@shippypro/design-system-web';
import { IconHelper } from '@shippypro/design-system-web/iconhelper';
import { useGetDeviceSize } from '@shippypro/design-system-web/hooks';

// Importing as Img because switching between FeatherIcon and DivImg would be a lot of hassle
import CameraOff from '@web/assets/img/icons/tabler/camera-off.svg';

import { ItemDescImage, NO_IMAGE } from '../types';

const StyledTooltip = styled(UncontrolledTooltip)`
  .tooltip-inner {
    padding: 2px;
    z-index: 50;
    background-color: white !important;
  }
`;

const StyledCarouselDesc = styled.div`
  color: #6e6b7b;
  font-weight: 600;
  padding: 0.25rem 0.25rem 0 0.25rem;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

interface IImagePreviewProps {
  items: ItemDescImage[];
  alt: string;
  bordered?: boolean;
  allowEvents?: boolean;
  allowMagnify?: boolean;
  className?: string;
  previewClassName?: string;
}

// TODO: move to design system + add documentation
const ImagePreview: FC<IImagePreviewProps> = ({
  items,
  alt,
  bordered = false,
  allowEvents = false,
  allowMagnify = false,
  className,
  previewClassName,
}) => {
  const mainImage: ItemDescImage = items.length ? items[0] : { url: NO_IMAGE };
  const firstNoImage = mainImage.url === NO_IMAGE;
  const multiple = items.length > 1;

  const pictureRef = useRef(null);
  const { isMobile } = useGetDeviceSize();
  const [isHoveringImg, setIsHoveringImg] = useState(false);
  const [canImageBeOpen, setCanImageBeOpen] = useState(false);

  // Binding the ESC key to close the popup
  /* istanbul ignore next */
  const escFunction = useCallback(({ key }) => {
    if (key === 'Escape') {
      setCanImageBeOpen(false);
      setIsHoveringImg(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  return (
    <div
      onMouseEnter={
        /* istanbul ignore next */
        () => setCanImageBeOpen(true)
      }
      onMouseLeave={
        /* istanbul ignore next */
        () => setCanImageBeOpen(false)
      }
      className={classnames({
        border: bordered,
        [className || '']: className,
      })}
      onClick={e => e.stopPropagation()}
    >
      <div
        onMouseEnter={
          /* istanbul ignore next */
          () => setIsHoveringImg(true)
        }
        onMouseLeave={
          /* istanbul ignore next */
          () => setIsHoveringImg(false)
        }
        ref={pictureRef}
        data-tag={allowEvents && 'allowRowEvents'}
        className={classnames(
          'image-preview bg-white rounded-md overflow-hidden relative',
          {
            [previewClassName || '']: previewClassName,
            '!bg-[length:80%] no-image': firstNoImage,
          },
        )}
      >
        <img
          src={firstNoImage ? CameraOff : mainImage.url}
          className="relative z-1 w-full h-full"
          alt="productimg"
          loading="lazy"
        />
        {isHoveringImg && allowMagnify && (
          <>
            <div
              className="
                absolute
                top-0 left-0
                flex justify-center items-center 
                w-full h-full
                bg-black opacity-60
                z-10
                rounded-md
                magnifying-block
              "
            />
            <span
              className="
                absolute
                top-0 left-0
                cursor-pointer
                flex justify-center items-center
                w-full h-full
                z-20
                rounded-md
              "
            >
              {<IconHelper icon="IconZoomIn" size={20} color="white" />}
            </span>
          </>
        )}
      </div>

      {(allowMagnify && canImageBeOpen) || isMobile ? (
        <StyledTooltip trigger="click" target={pictureRef}>
          <Carousel
            modules={[Navigation, Pagination, Keyboard]}
            slidesPerView={1}
            navigation={multiple}
            keyboard={{
              enabled: multiple,
            }}
            pagination={
              multiple
                ? {
                    dynamicBullets: true,
                    type: 'bullets',
                    clickable: true,
                  }
                : false
            }
            loop={multiple}
            className="bg-white rounded-sm shadow-md min-h-[235px] max-h-[235px]"
          >
            {items.map((item, i) => (
              <Slide key={i}>
                <div className="flex flex-col w-full h-full">
                  <StyledCarouselDesc>{item.description}</StyledCarouselDesc>
                  <picture className="flex justify-center items-center">
                    {item.url !== NO_IMAGE ? (
                      <img
                        src={item.url}
                        alt={alt}
                        data-tag={allowEvents && 'allowRowEvents'}
                        className="size-[150px] !mt-4"
                        loading="lazy"
                      />
                    ) : (
                      <IconHelper
                        icon="IconCameraOff"
                        className="w-[35%] h-[35%] mt-16 text-muted !p-3"
                      />
                    )}
                  </picture>
                </div>
              </Slide>
            ))}
          </Carousel>
        </StyledTooltip>
      ) : null}
    </div>
  );
};

export default ImagePreview;
