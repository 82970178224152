import { isEqual } from 'lodash';

import { isNullOrEmpty } from '@shippypro/design-system-web/functions';

/**
 * This function gets an object of filtering values and checks which one
 * is currently active.
 *
 * @param {object} values The object containing the filters
 * @param {object} initialValues The first values of the filters
 * @returns {number} The number of active filters
 */
export function countFilters(values: object, initialValues: object): number {
  return Object.entries(values).filter(([key, value]) => {
    return (
      /***
       * I re-ordered the following checks in order to leverage on the && operator's behaviour
       * and avoid executing checks that are slower in terms of performance (minor optimization)
       */
      // priceLimit is not checked since it's not a filter value
      key !== 'priceLimit' &&
      key !== 'paymentSearchAll' && // first check (best performance)
      !isNullOrEmpty(value) && // second check (intermediate performance)
      !isEqual(value, initialValues[key]) // last check (worst performance)
    );
  }).length;
}
