// ########################## [IMPORTANT LIBRARIES]
import { useMemo } from 'react';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [TYPES]
import { Order } from '@web/types/order';

// ########################## [PAGE COMPONENTS]
import ConnectionAvatar from '@web/components/common-ui/tables/columns/ConnectionAvatar';

// ########################## [UTILS]
import { canUpdateMarketplace } from '@web/features/ship/functions/marketplaces';

// ########################## [HOOKS]
import { getMarketplace } from '@web/hooks/marketplaces/useGetMarketplace';
import { useGetMarketplaces } from '@web/features/_global/hooks/api/useGetMarketplaces';
import { getCarrier } from '@web/hooks/carriers/useGetCarrier';
import { useGetCarriers } from '@web/features/_global/hooks/api/useGetCarriers';
import { Carrier, Marketplace } from '@web/types/global';

const columnHelper = createColumnHelper<Order>();

export const useGetConnectionsColumn = (): ColumnDef<Order, string> => {
  const { t } = useTranslation(),
    transToShipTable = translations.ship.table,
    transToShip = transToShipTable.columns;

  const { allMarketplaces: marketplaces } = useGetMarketplaces();
  const { carriers } = useGetCarriers();

  return useMemo(
    () =>
      columnHelper.accessor('marketplace_platform', {
        header: t(transToShip.connections),
        minSize: 200,
        size: 200,
        maxSize: 200,
        id: 'marketplace',
        enableSorting: false,
        cell: ({ row: { original } }) =>
          connectionsColumnRendererFn(marketplaces, carriers, original),
      }),
    [marketplaces, t, transToShip, carriers],
  );
};

const connectionsColumnRendererFn = (
  marketplaces: Marketplace[] | undefined,
  carriers: Carrier[] | undefined,
  { id, marketplace_id, marketplace_platform, carrier_id, carrier_name }: Order,
) => {
  const marketplace = getMarketplace(
    marketplaces!,
    marketplace_id,
    marketplace_platform,
  );

  const carrier = getCarrier(carriers ?? [], carrier_id, carrier_name);

  if (!marketplace) return <p>...</p>;

  return (
    <div
      className="flex items-center justify-start w-full"
      data-tag="allowRowEvents"
    >
      {marketplace.image_url && (
        <ConnectionAvatar
          orderId={id}
          type="marketplace"
          img={marketplace.image_url}
          alt={marketplace.label || marketplace_platform}
          status={marketplace.status === 1}
          allowEvents
          isStatic={!canUpdateMarketplace(marketplace_platform)}
        />
      )}
      {carrier?.image_url && (
        <ConnectionAvatar
          orderId={id}
          type="carrier"
          img={carrier?.image_url}
          alt={carrier?.label || carrier_name}
          status={carrier?.status || null}
          allowEvents
        />
      )}
    </div>
  );
};
