import { useCallback } from 'react';
import { Country } from '@web/types/global';

import { useGetSenderInfo } from '@web/features/_global/hooks/api/useGetSenderInfo';
import { useIsCountryEu } from './useIsCountryEu';

export type ShipmentDutiableCallback = (
  countryFrom?: string | Country,
  countryTo?: string | Country,
) => boolean;

/**
 * This hook receives two countries from an order (sender & recipient) and checks if said shipment is 'dutiable'.
 * With 'dutiable' we mean: "A Shipment whose countries of origin and destination differ AND at least one of them is not in the EU".
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 *
 * @returns {ShipmentDutiableCallback} A callback function that actually gives back the check
 */
export function useIsShipmentDutiable(): ShipmentDutiableCallback {
  const { senderInfo } = useGetSenderInfo();

  const isEuCallback = useIsCountryEu();

  const senderCountry = senderInfo?.address.country;
  return useCallback(
    (countryTo?: string | Country, countryFrom?: string | Country) => {
      let parsedCountryFrom = countryFrom ?? senderCountry;

      // Check if countryTo or parsedCountryFrom is empty
      // countryTo normally it should be blank when starting a manual shipment for example
      if (!countryTo || !parsedCountryFrom) {
        return false;
      } else {
        return (
          parsedCountryFrom !== countryTo &&
          (!isEuCallback(parsedCountryFrom, false) ||
            !isEuCallback(countryTo, false))
        );
      }
    },
    [isEuCallback, senderCountry],
  );
}
