import { FC, memo } from 'react';
import { Spinner } from 'reactstrap';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations_restricted';

import { Button } from '@ds-web/components/atoms/ui/buttons/button/Button';
import { IButtonWithLoadingStateProps } from './types';

/**
 * A simple button component that displays a loading state when the `loading` prop is set to `true`.
 *
 * It contains a `Spinner` component and a text that can be translated using the `common.loading` key.
 *
 * @interface IButtonWithLoadingStateProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const ButtonWithLoadingState: FC<IButtonWithLoadingStateProps> = memo(
  ({ loading = false, loadingText, children, ...props }) => {
    const { t } = useTranslation(),
      transCommon = translations.common;

    return (
      <Button {...props} disabled={props.disabled || loading}>
        <div className="w-full flex justify-center items-center !gap-2 text-lg md:text-base">
          {loading ? (
            <>
              <Spinner size="sm" />
              <span className="btnloading-text">
                {loadingText ?? t(transCommon.loading)}
              </span>
            </>
          ) : (
            children
          )}
        </div>
      </Button>
    );
  },
);
