import { createContext } from 'react';

import { IPaceProps } from '@shippypro/design-system-web/types';

export interface ITopProgressBarContextProps extends IPaceProps {
  toggleTopProgressBar: (toggle: boolean) => void;
}

/**
 * This is the `Top Progress Bar` Context that is instantiated at the near-top of the component tree.
 * This allows any feature to show a progress bar on the top of the page while something is loading.
 *
 * @example
 * const { toggleTopProgressBar } = useContext(TopProgressBarContext)!;
 * ...
 * useEffect(() => {
 *   if (dataFromBE)
 *    toggleTopProgressBar(false);
 * }, [dataFromBE])
 * ...
 * <button onClick={() => toggleTopProgressBar(true)}>Load new data!</button>
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const TopProgressBarContext = createContext<ITopProgressBarContextProps | null>(
  null,
);

export default TopProgressBarContext;
