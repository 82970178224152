// ########################## [IMPORTANT LIBRARIES]
import { FC, memo } from 'react';

// ########################## [DESIGN SYSTEM]
import { TooltipWrapperByREF } from '@shippypro/design-system-web';

// ########################## [HOOKS]
import { useGetCarriers } from '@web/features/_global/hooks/api/useGetCarriers';
import { getCarrier } from '@web/hooks/carriers/useGetCarrier';

interface ICarrierBadgeProps {
  carrierId: number | null;
  carrierName: string | null;
}

/**
 * Displays a simple carrier badge with a tooltip
 *
 * @interface ICarrierBadgeProps
 * @author Fabio Mezzina <fabio.mezzina@shippypro.com>
 */
export const CarrierBadge: FC<ICarrierBadgeProps> = memo(
  ({ carrierId, carrierName }) => {
    const { carriers } = useGetCarriers();

    const carrier = getCarrier(carriers!, carrierId, carrierName);

    if (!carrier) return null;
    return (
      <TooltipWrapperByREF
        fullWidth={false}
        containerProps={{ className: 'shrink-0' }}
        content={`${carrier.label || carrier.displayName}`}
      >
        <div
          className="p-[2px] border !border-[color:--shp-color-bg-highlight] rounded bg-white"
          data-test="carrier-badge"
        >
          <img
            className="w-6 h-6"
            loading="lazy"
            alt={carrier.displayName}
            src={carrier.image_url}
          />
        </div>
      </TooltipWrapperByREF>
    );
  },
);
