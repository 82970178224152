import { CircularProgressbarWrapperProps } from 'react-circular-progressbar/dist/types';

export interface ICircularProgressProps
  extends CircularProgressbarWrapperProps {
  size?: string | number;
  strokeLinecap?: StrokeLineCap;
  textSize?: string | number;
  pathColor?: string;
  textColor?: string;
  trailColor?: string;
  backgroundColor?: string;
  pathTransitionDuration?: number;
}

export enum StrokeLineCap {
  round = 'round',
  butt = 'butt',
}
