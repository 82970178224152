import { useMemo, useState } from 'react';
import { useLocalStorage } from 'react-use';
import { getUnixTime, subDays } from 'date-fns';

/**
 * A hook that returns a boolean indicating whether the given feature has been snoozed or not,
 * and a function to snooze it.
 * The snoozing time is 30 days.
 *
 * @param {string} code - The code of the release to get data from.
 * @returns {object} - An object containing the featureSnoozed state and the setFeatureSnoozed function.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const useSnoozeFeature = (code: string) => {
  const [snoozedFeatures, setSnoozedFeatures] = useLocalStorage(
    'snoozed-features',
    {},
  );
  const [featureSnoozed, setFeatureSnoozed] = useState(
    Boolean(snoozedFeatures?.[code]),
  );

  return useMemo(
    () => ({
      featureSnoozed: featureSnoozed
        ? getUnixTime(subDays(new Date(), 30)) < snoozedFeatures?.[code]
        : false,
      setFeatureSnoozed: () => {
        setFeatureSnoozed(true);
        setSnoozedFeatures({
          ...snoozedFeatures,
          [code]: getUnixTime(new Date()),
        });
      },
    }),
    [code, setSnoozedFeatures, snoozedFeatures, featureSnoozed],
  );
};
