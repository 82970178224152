import { FC, memo, useContext } from 'react';
import classnames from 'classnames';

import HelpSectionContext from '@web/features/help/contexts/HelpSectionContext';

import StyledLiveChatContainer from './styles';

import { HelpSubSection } from '@web/features/help/types';

interface ILiveChatProps {}

/**
 * This component is the container of the Live Chat iFrame that is embedded in the div with the
 * id "shippypro-livechat". The whole component is only hidden in CSS so that the chat can load in the background
 * and start receiving messages and updating the user without them having to open the section > chat.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const LiveChat: FC<ILiveChatProps> = memo(() => {
  const { subSection } = useContext(HelpSectionContext)!;

  return (
    <StyledLiveChatContainer
      data-test="embedded-livechat-section"
      className={classnames({
        hidden: subSection !== HelpSubSection.livechat,
      })}
    >
      <div id="shippypro-livechat" />
    </StyledLiveChatContainer>
  );
});

export default LiveChat;
