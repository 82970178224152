/**
 * Remove from the input string characters that can break backend XSS filter
 * NB Currently ONLY backslashes are removed, substituting them with slashes
 * @param input The string to be sanitized
 * @return The sanitized string
 */
export const sanitizeString = (input: string): string => {
  return input.replaceAll('\\', '/');
};

/**
 * Remove from input string characters that can break backend XSS filter
 * using {@link sanitizeString} logic if a NOT null and NOT undefined string is
 * specified as input
 * @param input The string to be sanitized
 * @return The sanitized string if present, undefined otherwise
 */
export const sanitizeOptString = (
  input: string | null | undefined,
): string | null => {
  if (!input) return null;
  return sanitizeString(input);
};
