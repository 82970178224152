import { FC } from 'react';
import { UncontrolledPopover as ReactstrapUncontrolledPopover } from 'reactstrap';

import { IUncontrolledPopoverProps } from './types';

/**
 * A simple wrapper class for the `reactstrap: <UncontrolledPopover />` component.
 *
 * @interface IUncontrolledPopoverProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const UncontrolledPopover: FC<IUncontrolledPopoverProps> = props => (
  <ReactstrapUncontrolledPopover {...props} />
);
