import {
  CellContext,
  ColumnDef,
  Row,
  createColumnHelper,
} from '@tanstack/react-table';

import Expansion from '../components/handlers/Expansion';

export type GetExpandColumnConfigProps<T extends object> = {
  id?: string;
  wantExpandAll?: boolean;
  canExpandRow?: boolean | ((row: Row<T>) => boolean);
  customOnExpandAllFn?: (expanded: boolean) => void;
  customOnExpandRowFn?: (expanded: boolean) => void;
};

/**
 * This is a common column used to show an `Expand (+All)` icon with its own logics.
 *
 * @param {?string} id The ID of the column you want to create.
 * @returns {ColumnDef<T>} An `Expand` column with the correct typing of your dataset.
 */
export const getExpandColumnConfig = <T extends object>(
  props?: GetExpandColumnConfigProps<T>,
): ColumnDef<T> => {
  const columnHelper = createColumnHelper<T>();

  const {
    id = 'expand-column',
    wantExpandAll = false,
    canExpandRow = undefined,
    customOnExpandAllFn = () => {},
    customOnExpandRowFn = () => {},
  } = props || {};

  return columnHelper.display({
    id,
    enableResizing: false,
    header: ({ table: { toggleAllRowsExpanded, getIsAllRowsExpanded } }) => {
      return wantExpandAll ? (
        // This is the Top `Expand All` component that opens every single row in view
        <Expansion
          toggleExpandedHandler={() => {
            // Passing the opposite value of the current expansion state to the custom function
            customOnExpandAllFn(!getIsAllRowsExpanded());

            toggleAllRowsExpanded();
          }}
          isExpanded={getIsAllRowsExpanded()}
          isExpandAll
        />
      ) : null;
    },
    cell: info => rowExpansionCell(info, canExpandRow, customOnExpandRowFn),
    aggregatedCell: info =>
      rowExpansionCell(info, canExpandRow, customOnExpandRowFn),
    size: 40,
  });
};

const rowExpansionCell = <T extends object>(
  { row }: CellContext<T, unknown>,
  canExpandRow: boolean | ((row: Row<T>) => boolean) | undefined,
  customOnExpandRowFn: (expanded: boolean) => void,
) => {
  const canExpand = canExpandRow
    ? typeof canExpandRow === 'boolean'
      ? // shortcut to allow all rows to be expanded without performing a function call (more performant)
        canExpandRow
      : // with the custom function we can enable just some rows to be expanded
        canExpandRow(row)
    : // if no custom option is passed, just use the default condition for row expansion
      row.getCanExpand();

  return canExpand ? (
    <Expansion
      toggleExpandedHandler={() => {
        // Passing the opposite value of the current expansion state to the custom function
        customOnExpandRowFn(!row.getIsExpanded());

        row.getToggleExpandedHandler()();
      }}
      style={{
        // We can even use the row.depth property
        // and paddingLeft to indicate the depth
        // of the row
        paddingLeft: `${row.depth * 2}rem`,
      }}
      isExpanded={row.getIsExpanded()}
    />
  ) : null;
};
