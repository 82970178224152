import { ReactNode, useCallback, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import classnames from 'classnames';

// ########################## [INTL-TEL-INPUT LIBRARY]
import IntlTelInput, { CountryData } from 'react-intl-tel-input';
import { parsePhoneNumberFromString, CountryCode } from 'libphonenumber-js';

import {
  FormGroup,
  FormGroupProps,
  Label,
  LabelProps,
  FormTextProps,
  FormFeedback,
  FormFeedbackProps,
} from 'reactstrap';

import { HelperText, UncontrolledTooltip } from '@shippypro/design-system-web';
import {
  IInputProps,
  IInputGroupProps,
} from '@shippypro/design-system-web/types';

export interface ControlledTelInputProps<FormType extends FieldValues>
  extends Pick<
    FormGroupProps,
    | 'row'
    | 'check'
    | 'inline'
    | 'floating'
    | 'disabled'
    | 'tag'
    | 'cssModule'
    | 'className'
  > {
  name: Path<FormType>;
  type?: IInputProps['type'];
  control: Control<FormType>;
  label?: ReactNode;
  defaultValue?: string;
  defaultCountry?: string;
  placeholder?: string;
  inputProps?: Omit<IInputProps, 'value' | 'onKeyDown'> &
    (
      | {
          onKeyDown?: IInputProps['onKeyDown'];
          forbiddenChars?: never;
        }
      | {
          onKeyDown?: never;
          forbiddenChars?: string[] | RegExp;
        }
    ) & {
      onChange: (number: string, isValid: boolean) => void;
    };
  inputGroupProps?: IInputGroupProps;
  labelProps?: LabelProps;
  errorModes?: ('formFeedback' | 'tooltip')[];
  errorTextProps?: FormFeedbackProps;
  errorText?: ReactNode;
  successTextProps?: FormFeedbackProps;
  successText?: ReactNode;
  helperTextProps?: FormTextProps;
  helperText?: ReactNode;
  disabled?: boolean;
  required?: boolean;
  icon?: ReactNode;
  endIcon?: ReactNode;
  endLabelIcon?: ReactNode;
  dataTest?: string;
  inputGroupLeftSideElement?: JSX.Element | null;
  separateDialCode?: boolean;
  onSelectFlag?: (number: string, countryData: CountryData) => void;
  actionOnBlur?: () => void;
}

export function ControlledTelInput<FormType extends FieldValues>({
  name,
  type,
  label,
  placeholder,
  control,
  inputProps,
  inputGroupProps,
  labelProps,
  helperTextProps,
  // here we can support one or more ways to show errors: FormFeedback, tooltip...
  errorModes = ['formFeedback'],
  errorTextProps,
  errorText,
  successTextProps,
  successText,
  helperText,
  className,
  disabled,
  required,
  icon,
  endIcon,
  endLabelIcon,
  dataTest,
  inputGroupLeftSideElement = null,
  defaultCountry,
  separateDialCode = false,
  onSelectFlag,
  actionOnBlur,
  ...rest
}: ControlledTelInputProps<FormType>) {
  const isRequired = required ?? inputProps?.required;
  const tooltipTargetRef = useRef<HTMLParagraphElement | null>(null);

  const onInputsChange = useCallback(
    (number: string, countryData: CountryData) => {
      const countryCode = countryData?.iso2?.toUpperCase() ?? 'GB';
      let parsedPhoneNumber = parsePhoneNumberFromString(
        number,
        countryCode as CountryCode,
      );
      const isValidPhoneNumber = Boolean(parsedPhoneNumber?.isValid());

      inputProps?.onChange?.(number, isValidPhoneNumber);
    },
    [inputProps],
  );

  useEffect(() => {
    if (separateDialCode) {
      const element = document.querySelector('.selected-dial-code');

      if (element) {
        element.classList.remove('selected-dial-code');
      }
    }
  }, [separateDialCode]);

  return (
    <StyledTelInput>
      <div ref={tooltipTargetRef}>
        <Controller
          control={control}
          render={({ field: { value }, fieldState: { error } }) => (
            <FormGroup
              {...rest}
              className={classnames('!mb-0 w-full', className)}
            >
              <div className="flex space-x-1 label-icon-block">
                {label && (
                  <Label {...labelProps}>
                    {label}
                    {isRequired && <span className="ml-[2px]">*</span>}
                  </Label>
                )}
                {endLabelIcon}
              </div>
              {errorModes.includes('tooltip') && (error || errorText) && (
                <UncontrolledTooltip target={tooltipTargetRef} trigger="hover">
                  {errorText ?? error?.message}
                </UncontrolledTooltip>
              )}
              <IntlTelInput
                allowDropdown={true}
                preferredCountries={['it', 'es', 'gb', 'fr', 'de']}
                containerClassName="intl-tel-input"
                separateDialCode={separateDialCode}
                style={{ display: 'block' }}
                fieldName={name}
                // Add is-invalid class to input if error
                inputClassName={classnames(
                  'form-control',
                  (error || errorText) && 'is-invalid',
                )}
                value={value ?? ''}
                defaultCountry={defaultCountry?.toLowerCase()}
                onPhoneNumberChange={(_, formattedNumber, countryData) =>
                  onInputsChange(formattedNumber, countryData)
                }
                onPhoneNumberBlur={actionOnBlur}
                onSelectFlag={(number, countryData) => {
                  onSelectFlag?.(number, countryData);
                  onInputsChange(number, countryData);
                }}
                placeholder={placeholder ?? inputProps?.placeholder}
                disabled={disabled ?? inputProps?.isDisabled}
                data-test={dataTest ?? name}
              />
              {(error || errorText) && errorModes.includes('formFeedback') && (
                <FormFeedback {...errorTextProps}>
                  {errorText ?? error?.message}
                </FormFeedback>
              )}
              {successText && (
                <FormFeedback valid {...successTextProps}>
                  {successText}
                </FormFeedback>
              )}
              {helperText && (
                <HelperText {...helperTextProps}>{helperText}</HelperText>
              )}
            </FormGroup>
          )}
          name={name}
        />
      </div>
    </StyledTelInput>
  );
}

const cssValues = css`
  .intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code {
    padding-left: 0.65rem;
  }
  .intl-tel-input.allow-dropdown.separate-dial-code .selected-flag {
    display: flex;
  }
  .iti-flag {
    margin-right: 0.5rem;
  }
  .country-list {
    z-index: 10;
  }
`;

const StyledTelInput = styled.div`
  ${cssValues}
`;
