export const selectionChangeFactory =
  (
    setSelected: (rows: any) => void,
    setResetPage: (reset: boolean) => void,
    resetPage: boolean,
    onSelectionChange?: (rows: any) => void,
  ) =>
  ({ selectedRows }: any) => {
    setSelected((prev: any) => {
      const rowsMap = new Map();
      prev.forEach((row: any) => rowsMap.set(row.id, row));

      // ADD NEW
      selectedRows.forEach((row: any) => {
        const exists = rowsMap.has(row.id);

        if (!exists) {
          rowsMap.set(row.id, row);
        }
      });
      // REMOVE OLD
      prev.forEach((row: any) => {
        const exists = selectedRows.find((r: any) => r.id === row.id);

        if (!exists) {
          rowsMap.delete(row.id);
        }
      });

      const mergedRows = [...rowsMap.values()];
      onSelectionChange && onSelectionChange(mergedRows);
      return mergedRows;
    });
    setResetPage(!resetPage);
  };
