// ########################## [IMPORTANT LIBRARIES]
import { FC, memo } from 'react';

// ########################## [FEATURE COMPONENTS]
import TopBar from './components/top-bar';

/**
 * **FEATURE: GlobalSearch**
 *
 * *"I don't know who or where you are, but I'm going to find you 📍"*
 * The home of the Global Search Textbox, able to search for orders, documents, etc...
 *
 * @link T.B.D.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const GlobalSearch: FC = memo(props => {
  return <TopBar />;
});

GlobalSearch.displayName = 'GlobalSearch_memoized';
