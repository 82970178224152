import { FC } from 'react';

import classnames from 'classnames';

import { TooltipWrapperByREF, IconWrapper } from '@ds-web/components';
import { IconHelper } from '@ds-web-iconhelper';

import { IDeleteNoteIconProps } from './types';

/**
 * This icon is the delete icon hosted in notes components
 *
 * @interface IDeleteNoteIconProps
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
export const DeleteNoteIcon: FC<IDeleteNoteIconProps> = ({
  tooltipText,
  action,
  disabled = false,
}) => (
  <IconWrapper
    disabled={disabled}
    className={classnames({
      '!cursor-not-allowed': disabled,
    })}
    onClick={e => {
      e.stopPropagation();
      action();
    }}
    id="delete-note-action"
    dataTest="delete-note-action"
  >
    <TooltipWrapperByREF
      placement="bottom"
      content={tooltipText}
      disabled={disabled}
    >
      <IconHelper
        icon="IconTrash"
        className="!w-[16px] !h-[16px] text-danger"
        id="delete-note-action"
        tabIndex={disabled ? -1 : 0}
      />
    </TooltipWrapperByREF>
  </IconWrapper>
);
