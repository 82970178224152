import { Fragment, RefObject } from 'react';
import classnames from 'classnames';

import { IReactTableProps } from './types';

import Footer from './components/footer';
import Header from './components/header';
import Body from './components/body';
import Pagination from './components/handlers/Pagination';

/**
 * This component is the UI representation of a `react-table`. By passing it the `useTable` hook instance,
 * it renders the entirety of the desired table and manages its state.
 *
 * N.B. This component is ONLY responsible for the UI/UX of the table; Columns, data, initial state and other
 * configurations must be passed to it and are NOT automatically assumed.
 *
 * @version 1.0.0
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 *
 * @interface IReactTableProps The instance coming from the `useTable` hook + some other configuration props.
 *
 * @returns {FC} The `React Table` component, fully rendered & managed.
 */
export const ReactTable = <T extends object>(
  tableArgs: IReactTableProps<T>,
) => {
  const { customComponents } = tableArgs;

  const hasVirtualisation = !!customComponents?.virtualisation;

  // Checking if the table has a custom layout that wraps more styles for it
  const TableLayout = customComponents?.CustomLayout ?? Fragment;
  const layoutProps = customComponents?.customLayoutProps ?? {};

  return (
    <TableLayout {...layoutProps}>
      <table
        ref={
          customComponents?.virtualisation
            ?.tableContainerRef as RefObject<HTMLTableElement>
        }
        className={classnames('react-table rdt_Table', {
          'react-table-virtualisation-container': hasVirtualisation,
        })}
        style={
          hasVirtualisation
            ? { height: `${customComponents?.virtualisation?.totalSize}px` }
            : undefined
        }
      >
        {/* HEADER | This component manages the column headers, with possibile checkbox selectors, expansion icons or sorting icons */}
        <Header {...tableArgs} />
        {/* BODY   | This component manages the rows, JSX elements defined in the `data` list. It can also render selectors and expansion icons */}
        <Body {...tableArgs} />
        {/* FOOTER | This component manages the columns footers, JSX elements defined in the `columns` list */}
        <Footer {...tableArgs} />
      </table>
      {customComponents?.hasPagination && <Pagination {...tableArgs} />}
    </TableLayout>
  );
};
