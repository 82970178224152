import { FC } from 'react';
import { Label } from 'reactstrap';
import classnames from 'classnames';

import { Input } from '@ds-web/components/atoms/form/inputs/input/Input';

import { ICheckboxProps } from './types';

/**
 * A custom input field shaped to resemble a functioning checkbox.
 * It can also have an inteterminate state.
 *
 * @interface ICheckboxProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const Checkbox: FC<ICheckboxProps> = ({
  className,
  color,
  indeterminate,
  defaultChecked,
  checked,
  value,
  disabled,
  onClick,
  onChange,
  name,
  label,
  ...rest
}) => (
  <div
    className={classnames('form-check', className, {
      [`form-check-${color}`]: color,
      'form-check-indeterminate': indeterminate,
    })}
  >
    <Input
      type="checkbox"
      defaultChecked={defaultChecked}
      checked={checked}
      value={value}
      disabled={disabled}
      onClick={onClick}
      onChange={onChange ? onChange : undefined}
      name={name}
      {...rest}
    />
    <Label className="form-check-label" for={name}>
      {label}
    </Label>
  </div>
);
