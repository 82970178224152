// ########################## [IMPORTANT LIBRARIES]
import { FC } from 'react';
import classnames from 'classnames';

import { IProgressBarProps } from './types';
import { StyledProgress } from './style';

/**
 * Styled wrapper class for the `reactstrap: <Progress />` component.
 *
 * @interface IProgressBarProps
 * @author Federico Mauri <federico.mauri@shippypro.com>
 */
export const LinearStyledProgressBar: FC<IProgressBarProps> = ({
  percentage,
  className,
}) => {
  return (
    <StyledProgress
      value={percentage}
      className={classnames(
        className,
        'h-6 max-w-[70%] content-center mx-auto',
      )}
    >
      {`${percentage}%`}
    </StyledProgress>
  );
};
