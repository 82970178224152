import { FC, memo, useContext } from 'react';
import classnames from 'classnames';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import { IconHelper } from '@shippypro/design-system-web/iconhelper';

import HelpSectionContext from '@web/features/help/contexts/HelpSectionContext';

import { HelpSubSection } from '@web/features/help/types';

interface IHeaderProps {}

/**
 * The header of the help section. It displays a welcome text
 * and changes to a back button when the user is in a sub-section.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const Header: FC<IHeaderProps> = memo(() => {
  const { t } = useTranslation(),
    trans = translations.help;

  const { subSection, setSubSection } = useContext(HelpSectionContext)!;

  const isHome = subSection === HelpSubSection.home;

  return (
    <div
      className={classnames('text-primary text-xl font-bold', {
        'cursor-pointer': !isHome,
      })}
      onClick={() => !isHome && setSubSection(HelpSubSection.home)}
    >
      {isHome ? (
        t(trans.title)
      ) : (
        <span className="flex items-center !gap-2" data-test="help-header-back">
          <IconHelper icon="IconArrowLeft" size={20} />
          {t(trans.back)}
        </span>
      )}
    </div>
  );
});

export default Header;
