import { FC } from 'react';
import { TabContent as ReactstrapTabContent } from 'reactstrap';

import { ITabContentProps } from './types';

/**
 * A simple wrapper class for the `reactstrap: <TabContent />` component.
 *
 * @interface ITabContentProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const TabContent: FC<ITabContentProps> = (props: ITabContentProps) => (
  <ReactstrapTabContent {...props} />
);
