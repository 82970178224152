import { FC, memo } from 'react';
import classnames from 'classnames';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

interface IProductSlideCardEmptyProps {
  compact?: boolean;
}

/**
 * Displays a product placeholder card with 'no items available' text
 *
 * @author Fabio Mezzina <fabio.mezzina@shippypro.com>
 */
export const ProductSlideCardEmpty: FC<IProductSlideCardEmptyProps> = memo(
  ({ compact }) => {
    const { t } = useTranslation(),
      noItemsAvailable = translations.ship.pickingList.order.noItemsAvailable;

    return (
      <div
        data-test="product-slide-card-empty"
        className={classnames(
          'bg-white h-[52px] flex justify-center items-center truncate border-[1px] rounded-lg w-[85%] mx-auto',
          {
            '!h-[40px] !py-2': compact,
          },
        )}
      >
        {t(noItemsAvailable)}
      </div>
    );
  },
);
