import classnames from 'classnames';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import {
  Badge,
  PopoverBody,
  PopoverHeader,
} from '@shippypro/design-system-web';
import { BadgeWithCustomHover } from '@web/components/common-ui/tables/columns';
import { Color } from '@shippypro/design-system-web/enums';

interface IOrderTagsBadgeProps {
  tags: string[];
  target: string;
  className?: string;
  onlyCount?: boolean;
}

/**
 * Summary badge that shows the number of tags present in an order
 * and gives access to the tags popover to look at them in detail.
 *
 * @interface IOrderTagsBadgeProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const OrderTagsBadge: React.FC<IOrderTagsBadgeProps> = ({
  tags,
  target,
  className,
  onlyCount = false,
}) => {
  const { t } = useTranslation(),
    transColumns = translations.ship.table.columns,
    transShipmentInfo = translations.order.shipmentInfo;

  return (
    <BadgeWithCustomHover
      color={Color.none}
      hasHover
      label={
        onlyCount
          ? `+${tags.length}`
          : t(transShipmentInfo.tags, { count: tags.length })
      }
      target={target}
      allowEvents
      type="popover"
      className={classnames(
        'text-purpleish cursor-pointer',
        {
          '!bg-[--shp-color-bg-main]': !onlyCount,
        },
        className,
      )}
    >
      <PopoverHeader>
        {t(transColumns.tags, { count: tags.length })}
      </PopoverHeader>
      <PopoverBody
        className="max-w-md overflow-y-auto shadow-down flex flex-wrap items-center !gap-2"
        data-test="order-detail-tags"
      >
        {tags.map(tag => (
          <Badge
            key={tag}
            className="truncate !bg-[--shp-color-bg-main] text-purpleish"
          >
            {tag}
          </Badge>
        ))}
      </PopoverBody>
    </BadgeWithCustomHover>
  );
};

export default OrderTagsBadge;
