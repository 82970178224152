import { useMemo } from 'react';

import { CustomRTKQueryOptions } from '@web/utils/@reduxjs/rtk-query';

import { GetStatusPageAlertsQueryPayload } from '@web/features/shared/types';

import { useGetStatusPageAlertsQuery } from '@web/features/shared/api';

/**
 * Returns RTK Query hook with renamed data field.
 *
 * @param {CustomRTKQueryOptions} options subset of RTK Query hook options
 */
export const useGetStatusPageAlerts = (
  payload: GetStatusPageAlertsQueryPayload = {},
  options?: CustomRTKQueryOptions,
) => {
  const { data: statuspagealerts, ...rest } = useGetStatusPageAlertsQuery(
    payload,
    options,
  );

  return useMemo(
    () => ({
      statuspagealerts,
      ...rest,
    }),
    [statuspagealerts, rest],
  );
};
