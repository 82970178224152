import { useCallback } from 'react';
import { Country } from '@web/types/global';

import { useGetCountries } from '@web/features/_global/hooks/api/useGetCountries';

/**
 * This hook receives an order and compares the order country with the country global state, to check the property is_EU.
 * It returns true if the order is being shipped in EU territory, false otherwise.
 *
 * @author Fabio Mezzina <fabio.mezzina@shippypro.com>
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export function useIsCountryEu(): (
  country?: string | Country,
  EUIfNotFound?: boolean,
) => boolean {
  const { countries } = useGetCountries();

  return useCallback(
    (country = '', EUIfNotFound = true) => {
      let isEu: boolean | undefined;
      if (typeof country === 'string') {
        isEu = (countries || []).find(co => co.alpha_2_code === country)?.is_EU;
      } else {
        isEu = country?.is_EU;
      }

      // isEu could be undefined if we are in manual shipment (recipient country is blank, so the .find method returns undefined).
      // In this case we suppose the ship to be EU and prevent unnecessary api call
      return isEu !== undefined ? isEu : EUIfNotFound;
    },
    [countries],
  );
}
