import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import gateway from '../files/.gateway.json';
import it from '../files/it.json';
import en from '../files/en.json';
import fr from '../files/fr.json';
import de from '../files/de.json';
import es from '../files/es.json';

export const translationsJson = {
  gateway: {
    translation: gateway,
  },
  en: {
    translation: en,
  },
  it: {
    translation: it,
  },
  fr: {
    translation: fr,
  },
  de: {
    translation: de,
  },
  es: {
    translation: es,
  },
};

export const i18n = i18next
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: translationsJson,
    fallbackLng: 'en',
    // lng: 'en',
    debug: false, //we don't need it for now
    //process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',

    returnNull: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });
