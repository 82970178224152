import { FC, memo } from 'react';
import classnames from 'classnames';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import { IconHelper } from '@shippypro/design-system-web/iconhelper';

import { formatAlertDate } from '@web/features/shared/alerts/functions';

import useGetPollingTimeWithIdlenessCheck from '@web/hooks/useGetAlertingPollingTime';
import { useGetStatusPageAlerts } from '@web/features/shared/hooks/api/useGetStatusPageAlerts';

interface ISystemStatusProps {}

/**
 * **[COMPONENT]** The system-status component.
 * This component show the current system's status to the user, signaling if there are any pertinent incidents.
 *
 * @interface ISystemStatusProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const SystemStatus: FC<ISystemStatusProps> = memo(() => {
  const { t } = useTranslation(),
    trans = translations.help.systemStatus;

  // Checking if the StatusPage APIs are returning any kind of alert
  const pollingObj = useGetPollingTimeWithIdlenessCheck(120000);
  const {
    statuspagealerts: { status_alerts = [], is_whitelabel_user = false } = {},
  } = useGetStatusPageAlerts({}, pollingObj);

  const hasAlerts = status_alerts.length > 0;
  const onlyOneAlert = status_alerts.length === 1;
  const uniqueAlertComponents = [
    ...new Set(status_alerts.map(alert => alert.component)),
  ].join(', ');

  const lastUpdate = formatAlertDate(status_alerts[0]?.updated_at);

  return (
    !is_whitelabel_user && (
      <>
        <span className="!pt-4 !mx-0 border-b-2 border-[color:--shp-color-bg-main]" />
        <section
          data-test="systemstatus-subsection"
          className="w-full bg-white !p-4 !pb-0 "
        >
          <div
            data-test="systemstatus-card"
            className={classnames('!px-4 !py-2 flex !gap-2 rounded-md', {
              'bg-green-100': !hasAlerts,
              'has-alerts bg-slate-100 cursor-pointer': hasAlerts,
              'single-alert': onlyOneAlert,
            })}
            onClick={
              /* istanbul ignore next */
              () =>
                hasAlerts &&
                window.open('https://status.shippypro.com/', '_blank')
            }
          >
            <IconHelper
              icon={hasAlerts ? 'IconAlertTriangle' : 'IconCheck'}
              size={17}
              className={classnames('!mt-0.5 shrink-0', {
                'text-success': !hasAlerts,
                'text-warning': hasAlerts,
              })}
              stroke={2}
            />
            <div className="flex flex-col">
              <span
                data-test="systemstatus-card_title"
                className="text-primary font-semibold line-clamp-1"
              >
                {!hasAlerts
                  ? t(trans.allOperational)
                  : onlyOneAlert
                  ? status_alerts[0]?.name
                  : t(trans.severalIssues, {
                      count: status_alerts.length,
                      components: uniqueAlertComponents,
                    })}
              </span>
              <span data-test="systemstatus-card_time" className="text-primary">
                {t(trans.updatedOn, { date: lastUpdate })}
              </span>
            </div>
          </div>
        </section>
      </>
    )
  );
});

export default SystemStatus;
