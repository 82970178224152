import classnames from 'classnames';

import {
  Badge,
  UncontrolledTooltip,
  UncontrolledPopover,
} from '@shippypro/design-system-web';
import { Color } from '@shippypro/design-system-web/enums';
import { IconHelper } from '@shippypro/design-system-web/iconhelper';

interface IBadgeWithCustomHoverProps {
  label: string;
  color: Color;
  className?: string;
  hasHover?: boolean;
  trigger?: 'hover' | 'click' | 'legacy';
  target?: string;
  type?: 'popover' | 'tooltip';
  placement?: 'top' | 'bottom' | 'left' | 'right';
  children?: React.ReactNode;
  allowEvents?: boolean;
  icon?: string;
  iconAndLabelClassnames?: string;
  handleOnClick?: VoidFunction;
  dataTest?: string;
}

const BadgeWithCustomHover: React.FC<IBadgeWithCustomHoverProps> = ({
  label,
  color,
  className,
  hasHover = false,
  trigger = 'hover',
  target,
  type = 'tooltip',
  placement = 'top',
  children,
  allowEvents = false,
  icon = '',
  iconAndLabelClassnames = '',
  handleOnClick = () => {},
  dataTest,
}) => {
  const HoverComponent =
    type === 'tooltip' ? UncontrolledTooltip : UncontrolledPopover;

  return (
    <>
      <Badge
        id={target}
        color={color}
        light
        pill
        className={classnames(
          'text-truncate text-light-purpleish mb-0 text-[12px] font-medium tracking-widest',
          className,
        )}
        onClick={handleOnClick}
        data-test={dataTest}
      >
        <span
          className={classnames(iconAndLabelClassnames, {
            flex: !!icon,
          })}
          data-tag={allowEvents && 'allowRowEvents'}
        >
          {icon && (
            <IconHelper
              className={classnames({ 'mr-1': Boolean(label) })}
              icon={icon}
            />
          )}
          {label}
        </span>
      </Badge>
      {hasHover && target && (
        <HoverComponent
          target={target}
          placement={placement}
          trigger={trigger}
          data-test="badge-tooltip"
        >
          {children}
        </HoverComponent>
      )}
    </>
  );
};

export default BadgeWithCustomHover;
