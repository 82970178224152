import { ToastOptions } from 'react-toastify';

import { translations, i18n } from '@shippypro/translations_restricted';

import { Toastr } from '@ds-web/components';
import { IconHelper } from '@ds-web-iconhelper';
import { Color } from '@ds-web/tokens/enums';

type ToastrFunction = (
  title?: string | null,
  desc?: string | null | JSX.Element,
  option?: ToastOptions,
) => void;

type LiteToastrFunction = (title?: string | null) => void;

export const toastrTemplate = async (
  status: Color | null = null,
  title: string | null = null,
  desc: string | null | JSX.Element = null,
  icon?: JSX.Element,
) => {
  const t = await i18n,
    trans = translations.common;

  return (
    <Toastr.Template
      title={title || t(trans.success)}
      // Done because we use a slightly different method of calling a toastr, but this works perfectly anyway
      // There's probably a TS bug in the library, since the other methods of calling a toastr allow for the JSX.Element type
      desc={(desc || t(trans.operationCompleted)) as string}
      color={status || Color.none}
      icon={icon}
    />
  );
};

export const toastrSecondaryTemplate = async (title: string | null = null) => {
  const t = await i18n,
    trans = translations.common;

  return <Toastr.SecondaryTemplate title={title || t(trans.success)} />;
};

/**
 * Displays a common toastr with a primary color, an info icon and the given text.
 *
 * @param {?string} title The title of the toastr
 * @param {?string} desc The description of the toastr
 * @param {?ToastOptions} options The options of the toastr
 * @returns {void}
 */
export const showToastr: ToastrFunction = async (
  title = null,
  desc = null,
  options = undefined,
) =>
  Toastr.toast(
    await toastrTemplate(
      Color.primary,
      title,
      desc,
      <IconHelper
        icon="IconInfoCircle"
        className="!h-[1.15rem] !w-[1.15rem] -mt-[1px]"
      />,
    ),
    options,
  );

/**
 * Displays a success toastr with a success color and the given text.
 *
 * @param {?string} title The title of the toastr
 * @param {?string} desc The description of the toastr
 * @param {?ToastOptions} options The options of the toastr
 * @returns {void}
 */
export const showSuccessToastr: ToastrFunction = async (
  title = null,
  desc = null,
  options = undefined,
) =>
  Toastr.toast.success(
    await toastrTemplate(Color.success, title, desc),
    options,
  );

/**
 * Displays a info toastr with a info color and the given text.
 *
 * @param {?string} title The title of the toastr
 * @param {?string} desc The description of the toastr
 * @param {?ToastOptions} options The options of the toastr
 * @returns {void}
 */
export const showInfoToastr: ToastrFunction = async (
  title = null,
  desc = null,
  options = undefined,
) => Toastr.toast.info(await toastrTemplate(Color.info, title, desc), options);

/**
 * Displays a warning toastr with a warning color and the given text.
 *
 * @param {?string} title The title of the toastr
 * @param {?string} desc The description of the toastr
 * @param {?ToastOptions} options The options of the toastr
 * @returns {void}
 */
export const showWarningToastr: ToastrFunction = async (
  title = null,
  desc = null,
  options = undefined,
) =>
  Toastr.toast.warning(
    await toastrTemplate(Color.warning, title, desc),
    options,
  );

/**
 * Displays a danger toastr with a danger color and the given text.
 *
 * @param {?string} title The title of the toastr
 * @param {?string} desc The description of the toastr
 * @param {?ToastOptions} options The options of the toastr
 * @returns {void}
 */
export const showErrorToastr: ToastrFunction = async (
  title = null,
  desc = null,
  options = undefined,
) =>
  Toastr.toast.error(await toastrTemplate(Color.danger, title, desc), options);

/**
 * Displays a black, secondary toastr that starts from the bottom-center.
 *
 * @param {?string} title The title of the toastr
 * @returns {void}
 */
export const showSecondaryToastr: LiteToastrFunction = async (title = null) =>
  Toastr.toast.dark(await toastrSecondaryTemplate(title), {
    position: 'bottom-center',
    hideProgressBar: true,
    autoClose: 3000,
  });
