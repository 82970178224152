import { FC, memo, useContext } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import PaywallContext from '@web/features/paywall/contexts/PaywallContext';

/**
 * This block pertains every readonly top info showed in the paywall modal:
 * * The pre-title string;
 * * The main title (name of the paywalled feature);
 * * The description of the current paywall;
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const TopInfo: FC = memo(() => {
  const { t } = useTranslation(),
    trans = translations.paywall;

  const { info } = useContext(PaywallContext)!;

  return (
    <div id="paywall-top-info" className="px-2 md:!px-8">
      <h3>{t(trans.getAccessTo)}</h3>
      {info && (
        <>
          <h1>{info?.feature}</h1>
          <h4>
            <Trans
              i18nKey={info?.description}
              components={{
                b: <b />,
              }}
            />
          </h4>
        </>
      )}
    </div>
  );
});

export default TopInfo;
