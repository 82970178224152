import { FC, memo } from 'react';

import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

import { MetricTrend } from '@ds-web/components';
import { toLocalizedDecimal } from '@ds-web/utils/functions/numbers/localised-decimal/localised-decimal';

import { IAreaChartMetricsBodyProps } from './types';

/**
 * This Component renders a `Area Chart` view of a metric, with its total count,
 * its trend and the actual graph.
 *
 * @interface IAreaChartMetricsBodyProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const AreaChartMetricsBody: FC<IAreaChartMetricsBodyProps> = memo(
  ({
    code = 'chart',
    summary,
    trend,
    trendTooltip,
    invertTrendColor,
    data,
    locale = 'en-US',
  }) => {
    const trendTarget = `${code}-trend-tooltip`;
    const trendNum = +(trend || '0');

    return (
      <section
        id="metricsareachart-container"
        className="w-full flex flex-col justify-between items-start"
      >
        <div
          id="metricsareachart-summary-block"
          className="w-full flex justify-start items-baseline !gap-6"
        >
          <span
            id="metricsareachart-summary"
            className="font-bold text-4xl text-black"
          >
            {summary || 0}
          </span>
          <MetricTrend
            id={trendTarget}
            value={trendNum}
            invertTrendColor={invertTrendColor}
            tooltip={trendTooltip}
          />
        </div>
        <div
          id="metricsareachart-graphcontainer"
          className="recharts-wrapper !mt-5"
        >
          <ResponsiveContainer width="98%" height="98%">
            <AreaChart data={data} margin={{ right: 0, left: 0 }}>
              <defs>
                <linearGradient id="colorShp" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#7349DD" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#7349DD" stopOpacity={0} />
                </linearGradient>
              </defs>
              <CartesianGrid />
              <XAxis dataKey="xName" tickCount={2} />
              <YAxis
                dataKey="yValue"
                width={35}
                tickFormatter={value => toLocalizedDecimal(value, 0, 0)}
                padding={{ top: 25 }}
              />
              <Tooltip
                content={data =>
                  data.active &&
                  data.payload && (
                    <div className="recharts-custom-tooltip">
                      <p className="font-light">{data.label}</p>
                      <div className="active">
                        {data.payload.map(i => {
                          return (
                            <div className="flex items-center" key={i.dataKey}>
                              <span className="font-bold text-xl text-black">
                                {i.payload[i.dataKey ?? 0]}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )
                }
              />
              <Area
                type="monotone"
                dataKey="yValue"
                stroke="#7349DD"
                fillOpacity={1}
                fill="url(#colorShp)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </section>
    );
  },
);
