import { useCallback, useEffect, useMemo } from 'react';
import {
  DeepMap,
  DeepPartial,
  FieldValues,
  FormState,
  UseFormReturn,
} from 'react-hook-form';

export interface UseUnproxyFormStateFieldsOptions<F extends FieldValues> {
  fields?: (keyof FormState<F>)[];
}

/***
 * This is kind of a hack but it's no black magic :)
 *
 * From React Hook Form's documentation:
 * "formState is wrapped with a Proxy to improve render performance
 * and skip extra logic if specific state is not subscribed to.
 * Therefore make sure you invoke or read it before a render in
 * order to enable the state update."
 * (https://react-hook-form.com/api/useform/formstate)
 *
 * This hook simply accesses formState fields so they can be subscribed to
 * from any part of the app.
 * If you need to share a form between multiple components, i.e. creating
 * the form via useForm in a parent component and passing it as a prop to
 * a child component, you can use this hook before rendering the parent
 * component, in order to correctly share the updated value of the formState
 * (with children components).
 */
export const useUnproxyFormStateFields = function <F extends FieldValues>(
  form: UseFormReturn<F>,
  options?: UseUnproxyFormStateFieldsOptions<F>,
) {
  const { fields } = options || {};

  const dummyFormState: FormState<F> = useMemo(
    () => ({
      isDirty: false,
      isSubmitted: false,
      isSubmitSuccessful: false,
      isSubmitting: false,
      isValidating: false,
      isValid: false,
      submitCount: 0,
      defaultValues: undefined,
      dirtyFields: {} as DeepMap<DeepPartial<F>, boolean>,
      touchedFields: {} as DeepMap<DeepPartial<F>, boolean>,
      errors: {},
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    const allFormStateFields = Object.keys(
      dummyFormState,
    ) as (keyof FormState<F>)[];
    const fieldsToUnproxy = fields ?? allFormStateFields;

    fieldsToUnproxy.forEach(field => {
      /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
      const { [field]: _ } = form.formState;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dummyFormState, form, fields]);
};

/**
 * This hooks returns a promise used by the loadOptions selectProps of the Controlled selects
 * as callback to fetch and populates options list
 */
export function useReturnLoadOptionsPromise() {
  const returnLoadOptionsPromise = useCallback(
    <T>(
      search_term: string,
      promise: Promise<T>,
      minChars: number = 3,
    ): Promise<T> => {
      if (search_term.length >= minChars) {
        return promise;
      } else {
        // This is needed as the AsyncSelect component always show us the "loading" spinner
        // even if no request has been made (it takes a callback or a promise to execute, and show the spinner when the execution starts)
        return Promise.resolve([] as T);
      }
    },
    [],
  );
  return returnLoadOptionsPromise;
}
