import { FC, PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import CardView from '@web/components/common-ui/wrappers/card/CardView';

import { IOrderInfoBlock } from './types';
import ShipmentInfoSection from './ShipmentInfoSection';

const ShipmentInfoWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation(),
    transOrder = translations.order.info;
  return (
    <CardView
      col
      header={t(transOrder.shipmentInfo).toUpperCase()}
      name="shipment-info"
    >
      {children}
    </CardView>
  );
};

interface IShipmentInfoProps extends IOrderInfoBlock {}

const ShipmentInfo: FC<IShipmentInfoProps> = ({ order }) => {
  const { t } = useTranslation(),
    transOrder = translations.order.info;

  const [isShipmentInfoOpen, setShipmentInfoOpen] = useState(true);

  // empty state
  if (!order?.shipment) {
    return (
      <ShipmentInfoWrapper>
        <div className="flex w-full justify-center">
          <p className="text-muted">{t(transOrder.noShipments)}</p>
        </div>
      </ShipmentInfoWrapper>
    );
  }

  return (
    <ShipmentInfoWrapper>
      <ShipmentInfoSection
        order={order}
        isOpen={isShipmentInfoOpen}
        setIsOpen={setShipmentInfoOpen}
      />
    </ShipmentInfoWrapper>
  );
};

export default ShipmentInfo;
