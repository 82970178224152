// ########################## [IMPORTANT LIBRARIES]
import { useMemo } from 'react';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [TYPES]
import { Order } from '@web/types/order';
import { Badge } from '@shippypro/design-system-web';
import { RowDensity } from '@web/features/ship/types';

// ########################## [UTILS]
import { getOrderTags } from '@web/hooks/orders/useGetOrderTags';
import { OrderTagsBadge } from '@web/components/common-features/order';

// ########################## [HOOKS]
import useGetTabColumnSize from '@web/features/ship/hooks/tabs/useGetTabColumnSize';

const columnHelper = createColumnHelper<Order>();

export const TagsColumnCode = 'tags';

export const useGetTagsColumn = (
  rowDensity: RowDensity | null,
): ColumnDef<Order, string> => {
  const { t } = useTranslation(),
    transToShipTable = translations.ship.table,
    transToShip = transToShipTable.columns;

  const size = useGetTabColumnSize(TagsColumnCode, 300);

  return useMemo(
    () =>
      columnHelper.accessor('additional_info.additional_info_name', {
        header: t(transToShip.tags_zero),
        minSize: 150,
        size,
        maxSize: 400,
        id: 'additional_info',
        enableSorting: false,
        cell: ({ row: { original: order, getIsExpanded } }) =>
          tagsColumnRendererFn(
            getIsExpanded() ? RowDensity.S : rowDensity,
            order,
          ),
      }),
    [rowDensity, t, transToShip.tags_zero, size],
  );
};

const tagsColumnRendererFn = (rowDensity: RowDensity | null, order: Order) => {
  const maxTagsNumber = rowDensity === RowDensity.S ? 2 : 3;

  const tags = getOrderTags(order);

  return (
    <div
      data-test="order-tags"
      className="w-full flex flex-wrap !gap-1 truncate"
    >
      {tags && (
        <>
          {tags.slice(0, maxTagsNumber).map(tag => (
            <Badge
              key={tag}
              className="truncate !bg-[--shp-color-bg-main] text-purpleish"
            >
              {tag}
            </Badge>
          ))}
          {tags.length > maxTagsNumber && (
            <OrderTagsBadge
              tags={tags.slice(maxTagsNumber)}
              target={`columnrow-tags-${order.id}`}
              onlyCount
            />
          )}
        </>
      )}
    </div>
  );
};
