import { useMemo } from 'react';

import { CustomRTKQueryOptions } from '@web/utils/@reduxjs/rtk-query';

import { useGetFeatureFlaggingCustomParametersQuery } from '@web/features/_global/api';

/**
 * Returns RTK Query hook with renamed data field.
 * This Hook fetches every configured LaunchDarkly field from the User's account and
 * uses it to initialise & identify the proper LaunchDarkly session here on the App
 *
 * @param {CustomRTKQueryOptions} options subset of RTK Query hook options
 */
export const useGetFeatureFlaggingCustomParameters = (
  options?: CustomRTKQueryOptions,
) => {
  const { data: getFeatureFlaggingCustomParameters, ...rest } =
    useGetFeatureFlaggingCustomParametersQuery(undefined, options);

  return useMemo(
    () => ({
      data: getFeatureFlaggingCustomParameters,
      ...rest,
    }),
    [getFeatureFlaggingCustomParameters, rest],
  );
};
