/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useCallback } from 'react';

import classnames from 'classnames';

import { DropdownItem } from '@ds-web/components/atoms/ui/dropdowns/dropdown';

import { IconHelper } from '@ds-web-iconhelper';

import { INavmenuDropdownItemProps } from './types';

const NavmenuDropdownItem: FC<INavmenuDropdownItemProps> = ({
  item,
  activeTab,
  tabChangeHandler,
  clickHandler,
  caret = false,
}) => {
  const onClick = useCallback(() => {
    if (item.externalUrl || item.disabled || item.children) return;

    clickHandler ? clickHandler() : tabChangeHandler(item.id);
  }, [
    clickHandler,
    item.children,
    item.disabled,
    item.externalUrl,
    item.id,
    tabChangeHandler,
  ]);

  return (
    <a
      href={!item.disabled ? item.externalUrl : undefined}
      target={!item.disabled ? '_blank' : ''}
      rel="noreferrer"
      className={classnames({
        '!pointer-events-none !cursor-not-allowed': item.disabled,
      })}
      id={`nav-item-${item.id}`}
    >
      <DropdownItem
        toggle={item.children ? false : true}
        disabled={item.disabled}
        className={classnames('!flex items-center', {
          'navmenu-dropdown-item-active': activeTab === item.id,
          'nav-menu-disabled': item.disabled,
        })}
        onClick={onClick}
      >
        {item.icon && (
          <IconHelper icon={item.icon} size={14} className="mr-1" />
        )}
        {item.title}
        {caret && (
          <IconHelper icon="IconChevronDown" size={14} className="ml-1" />
        )}
      </DropdownItem>
    </a>
  );
};

export default NavmenuDropdownItem;
