import { useMemo } from 'react';

// ########################## [HOOKS]
import { useGetSetupData } from '@web/features/_global/hooks/api/useGetSetupData';

/**
 * [HOOK] This Hook checks if the user has completed the onboarding process and/or the setup process.
 *
 * @author Fabio Mezzina <fabio.mezzina@shippypro.com>
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 *
 * @version 2.0.0
 * @returns {[boolean, boolean, boolean]}
 */
const useHasUserCompletedOnboardingOrSetup = (): [
  boolean,
  boolean,
  boolean,
] => {
  const { isLoading, hideOnboarding, data, isFetching } = useGetSetupData();

  return useMemo(() => {
    const [onboarding, setup] = data;

    const isSetupCompleted =
      !isLoading && !isFetching && setup && setup.progress.setupCompleted;

    const isOnboardingCompleted =
      !isLoading &&
      !isFetching &&
      onboarding &&
      (onboarding.onboardingCompleted || hideOnboarding);

    return [isOnboardingCompleted, isSetupCompleted, isLoading || isFetching];
  }, [isLoading, hideOnboarding, data, isFetching]);
};

export default useHasUserCompletedOnboardingOrSetup;
