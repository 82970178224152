import { FC, memo, useEffect } from 'react';
import classnames from 'classnames';

import type { StatusPageAlert } from '@web/features/shared/types/statuspage';

import StyledCard from './styles';

import AlertClose from './alert-close';
import AlertBody from './alert-body';
import AlertIcon from './alert-icon';

interface IAlertCardProps {
  floating?: boolean;

  alert?: StatusPageAlert;
  hideLatestUpdateCta: boolean;

  dismissAlert: (id: string) => void;
}

/**
 * **[COMPONENT]** The alert-card component.
 * The actual StatusPage alert card UI. Said card is **NOT** translated as of now.
 *
 * @interface IAlertCardProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const AlertCard: FC<IAlertCardProps> = memo(
  ({ floating = true, alert, hideLatestUpdateCta, dismissAlert }) => {
    const isMaintenance =
      (alert && alert.hasOwnProperty('scheduled_for')) ?? false;

    // Clamping the title if it is too long
    useEffect(() => {
      const p = document.querySelector('.status-alert-title p');
      const div = document.querySelector('.status-alert-title');
      const divHeight = div?.clientHeight ?? 0;

      if (!p?.innerHTML) return;

      while ((p?.clientHeight ?? 0) > divHeight) {
        const text = p?.innerHTML ?? '';

        /* istanbul ignore if */
        if (!/\s/.test(text)) {
          // this case handles an infinite loop that occurred when the first word was too long to display in the banner
          // this also occurred when the first word was the only word and was too long to display, now we simply
          // truncate characters within the word itself.

          if (text.length <= 10) {
            // There's a certain point at which the zoom can make it so the text's height will never be within the div's height.
            // This at least happens on Safari because the formatting is weird.
            // This makes sure we don't get stuck in an infinite loop in this case.
            return;
          }

          // replace the last character with ...
          p.innerHTML = text.replace(/\w(\W*)$/, '...');
        } else {
          p.innerHTML = text.replace(/\W*\s(\S)*$/, '...'); // replace last word with ...
        }
      }
    }, [alert]);

    return (
      <StyledCard>
        <div
          className={classnames('status-alert-wrapper', {
            visible: Boolean(alert),
            maintenance: isMaintenance,
            floating,
          })}
          id="status-alert-div"
        >
          <AlertIcon isMaintenance={isMaintenance} />

          <AlertBody
            name={alert?.name ?? ''}
            hideLatestUpdateCta={hideLatestUpdateCta}
            isMaintenance={isMaintenance}
            scheduledFor={alert?.scheduled_for}
            scheduledUntil={alert?.scheduled_until}
            update={alert?.update}
            updatedAt={alert?.updated_at}
          />

          <AlertClose
            dismissAlert={() => dismissAlert(alert?.id ?? 'nOtfOunD123')}
          />
        </div>
      </StyledCard>
    );
  },
);

export default AlertCard;
