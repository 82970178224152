// ########################## [IMPORTANT LIB]
import { FC, memo } from 'react';
import classnames from 'classnames';

// ########################## [TRANSLATIONS]
import { translations } from '@shippypro/translations';
import { useTranslation } from 'react-i18next';

// ########################## [DESIGN SYSTEM]
import { IconHelper } from '@shippypro/design-system-web/iconhelper';

// ########################## [TYPES]
import { Item } from '@web/types/order';

interface IProductSlideCard {
  item: Item;
  clickHandler?: (e) => void;
  compact?: boolean;

  index?: number;
  total?: number;
}

/**
 * Displays a card with an image and a description of a single product
 *
 * @interface IProductSlideCard
 * @author Fabio Mezzina <fabio.mezzina@shippypro.com>
 */
export const ProductSlideCard: FC<IProductSlideCard> = memo(
  ({ item, clickHandler, compact = false, index, total }) => {
    const { t } = useTranslation(),
      transCommon = translations.common,
      transItem = translations.ship.table.items;

    const { image_url } = item;
    return (
      <div
        data-test="product-slide-card"
        className="relative bg-white flex !gap-2 item-center truncate border-[1px] rounded-lg w-[85%] mx-auto"
        onClick={clickHandler}
      >
        {!compact && (
          <div className="product-image size-[70px] flex flex-col shrink-0 rounded-bl-lg justify-center items-center relative border-r-[1px]">
            {image_url ? (
              <img
                src={image_url}
                alt={item.description}
                className="w-full h-full border-1 border-gray shrink-0"
                loading="lazy"
              />
            ) : (
              <IconHelper
                icon="IconCameraOff"
                className="w-[4rem] h-[4rem] text-muted !p-3 border-1 border-gray rounded-md shrink-0"
              />
            )}
            {item.quantity && item.quantity > 0 ? (
              <span className="item-qty absolute top-0 left-0 rounded-tl-[0.375rem] bg-[color:--shp-color-tableheader-bg] text-sm font-bold px-[3px] leading-[1.125rem]">
                x{item.quantity}
              </span>
            ) : null}
          </div>
        )}
        <div
          className={classnames(
            'w-full truncate flex flex-col justify-center py-[3px] leading-[1.125rem]',
            {
              '!mx-2': compact,
            },
          )}
        >
          <strong className="w-full flex justify-between !gap-1 truncate text-[1rem] !text-[--shp-color-text-tab-title] font-bold">
            <span className="slide-title truncate">{item.description}</span>
            {compact && item.quantity && item.quantity > 0 && (
              <span className="text-sm">x{item.quantity}</span>
            )}
          </strong>
          <span
            className={classnames('slide-desc flex flex-col', {
              '!flex-row !gap-1': compact,
            })}
          >
            {[
              item.sku && `${t(transItem.sku)}: ${item.sku}`,
              item.ean && `${t(transItem.ean)}: ${item.ean}`,
            ]
              .filter(Boolean)
              .map((el, i) => (
                <div
                  key={i}
                  className="truncate text-sm text-[color:--shp-color-text-label] leading-[1rem]"
                >
                  {el}
                </div>
              ))}
          </span>
        </div>
        {!compact && (total ?? 0) > 1 && (
          <div className="absolute right-2 bottom-0 text-sm">
            {t(transCommon.countOfTotal, { count: index, total })}
          </div>
        )}
      </div>
    );
  },
);
