import { Address } from '@web/types/common/address';
import { RateCarrierOptions } from '@web/types/global';
import {
  Cn22ContentType,
  Cn22Item,
  ErrorRate,
  NonDeliveryOptionType,
  Order,
  Parcel,
  Rate,
} from '@web/types/order';
import { RateCarrierOptionsValuesObject } from '@web/types/order/carrier-options';
import { OrderValidation } from './validation';
import { OrderDetails } from './details';
import { OrderCN22, OrderETD } from './documents';

export type OrderToManage = {
  original: Order;
  addresses: {
    recipient: Address;
    sender: Address;
  };
  details: OrderDetails;
  cn22Data: {
    isLoading: boolean;
    cn22: OrderCN22;
  };
  etdData: {
    isLoading: boolean;
    etdDocs: OrderETD;
  };
  parcelsData: { isLoading: boolean; parcels: Parcel[] };
  ratesData: {
    isLoading: boolean;
    rates: OrderCarrierRates;
  };
  validationData: {
    isLoading: boolean;
    validations: OrderValidation;
  };
};

export type GoodsDetails = OrderDetails & OrderCN22;

export type OrderCarrierRates = {
  // The rate that was selected before the user changed it
  rollback?: CarrierRate<Rate | ErrorRate>;

  available: CarrierRate<Rate>[];
  error: CarrierRate<ErrorRate>[];
};

export type CarrierRate<TRate extends Rate | ErrorRate> = {
  isSelected: boolean;
  isError: boolean;
  isHidden: boolean;

  rate: TRate;

  carrierOptions: RateCarrierOptions[];
  selectedCarrierOptionsObject: RateCarrierOptionsValuesObject | undefined;
  // The carrier options that were selected before the user changed them
  oldCarrierOptionsSelection?: RateCarrierOptionsValuesObject;
};

export type ParsedOrderCN22 = {
  id: number;
  cn22?: {
    contentsType: Cn22ContentType;
    nonDeliveryOption: NonDeliveryOptionType;
    items: Cn22Item[];
  };
};
