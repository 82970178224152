import { FC, memo } from 'react';

import classnames from 'classnames';

import { NoteBlock } from '@ds-web/components';

import { IGenericNoteProps } from './types';

/**
 * This is a stateless component for showing & editing notes
 * Editing can be enabled/disabled via props.
 *
 * @interface IGenericNoteProps
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
export const GenericNote: FC<IGenericNoteProps> = memo(
  ({
    category,
    dataTest,
    editable,
    value,
    minWidth = '64px',
    isSaving,
    bgColorClassName = '',
    onEditAction,
    onDeleteAction,
    className = 'text-[color:--shp-color-text-body]',
  }) => (
    <NoteBlock
      className={className}
      wFull={false}
      editable={editable}
      bgColorClassName={bgColorClassName}
      category={category}
      isSaving={isSaving}
      onEditAction={() => {
        onEditAction && onEditAction();
      }}
      onDeleteAction={() => {
        onDeleteAction && onDeleteAction();
      }}
      noteCardBodyComponent={
        <div
          className={classnames('flex items-center max-w-full', {
            '!cursor-pointer': editable,
          })}
        >
          <div className="flex items-center space-x-2 whitespace-normal max-w-full">
            {/* this will hold the value of the note when not in edit mode */}
            <span
              data-test={dataTest}
              className="text-left leading-5 !mr-16 truncate"
              style={{
                minWidth: editable ? minWidth : undefined,
              }}
            >
              {value}
            </span>
          </div>
        </div>
      }
    />
  ),
);
