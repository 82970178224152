import { FC } from 'react';
import { Tooltip as ReactstrapTooltip } from 'reactstrap';

import { IUTooltipProps } from './types';

/**
 * A simple wrapper class for the `reactstrap: <Tooltip />` component.
 *
 * @interface ITooltipProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const Tooltip: FC<IUTooltipProps> = props => (
  <ReactstrapTooltip {...props} />
);
