import CardError from '@web/components/common-ui/wrappers/card/CardError';

interface IGenericErrorProps {
  error: string;
}

const Error: React.FC<IGenericErrorProps> = ({ error }) => {
  return (
    <CardError
      error={error}
      fullScreen
      noCard
      className="flex h-full generic-error"
    />
  );
};

export default Error;
