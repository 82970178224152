import { useEffect, useState } from 'react';

/**
 * [HOOK] Checks if an elment has a scrollbar
 *
 * @param {string} selector css selector
 * @param {string} axis 'x' or 'y' to check horizontal or vertical scrolling - default = 'y'
 *
 * @returns {boolean}
 */
export function useIsElementScrollable(
  selector: string,
  axis: 'y' | 'x' = 'y',
) {
  const [isScrollable, setIsScrollable] = useState(false);

  useEffect(() => {
    if (isElementScrollable(selector, axis)) setIsScrollable(true);
  }, [axis, selector]);

  return isScrollable;
}

/**
 * Checks if an elment has a scrollbar
 *
 * @param {string} selector css selector
 * @param {string} axis 'x' or 'y' to check horizontal or vertical scrolling - default = 'y'
 *
 * @returns {boolean}
 */
function isElementScrollable(selector: string, axis: 'y' | 'x' = 'y') {
  const clientAxis = axis === 'y' ? 'clientHeight' : 'clientWidth';
  const scrollAxis = axis === 'y' ? 'scrollHeight' : 'scrollWidth';

  const element = document.querySelector(`${selector}`);

  if (element) return element[scrollAxis] > element[clientAxis];
  return false;
}
