import classnames from 'classnames';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import { Color } from '@shippypro/design-system-web/enums';

import { BadgeWithCustomHover } from '@web/components/common-ui/tables/columns';

interface IOrderMergedBadgeProps {
  className?: string;
  compact?: boolean;
}

/**
 * Summary badge that shows if the order has been merged
 *
 * @interface IOrderMergedBadgeProps
 * @author Sebastian Ocampo <sebastian.ocampo@shippypro.com>
 */
const OrderMergedBadge: React.FC<IOrderMergedBadgeProps> = ({
  className,
  compact,
}) => {
  const { t } = useTranslation(),
    transOrdMerged = translations.order.statuses.merged;

  return (
    <BadgeWithCustomHover
      color={Color.accent}
      dataTest="order-merged-badge"
      className={classnames(
        'flex items-center !gap-1 max-w-fit cursor-default',
        className,
      )}
      icon="IconArrowMerge"
      hasHover={compact}
      label={compact ? '' : t(transOrdMerged)}
      target="order-ismerged"
    >
      {t(transOrdMerged)}
    </BadgeWithCustomHover>
  );
};

export default OrderMergedBadge;
