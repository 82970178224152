import { FC } from 'react';
import classnames from 'classnames';

import { Avatar, TooltipWrapperByID } from '@ds-web/components';

import { IAvatarGroupProps } from './types';

/**
 * This Component groups several Avatars together and gives them
 * an optional 'tooltip on hover' effect.
 *
 * @interface IAvatarGroupProps The list of avatar props that make up the group
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const AvatarGroup: FC<IAvatarGroupProps> = ({
  items,
  className,
  pullUp = true,
}) => {
  return (
    <div className={classnames('avatar-group', className)}>
      {items.map((item, i) => {
        const target = item.tooltip?.target ?? '';

        return (
          <TooltipWrapperByID
            tooltipProps={{
              placement: item.placement ?? 'top',
            }}
            target={target}
            text={item.tooltip?.text}
            active={!!item?.tooltip}
            key={`${i}_${target}`}
          >
            <Avatar
              id={target}
              className={classnames({ 'pull-up': pullUp }, item.className)}
              {...item}
            />
          </TooltipWrapperByID>
        );
      })}
    </div>
  );
};
