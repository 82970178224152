import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const DEFAULT_PAGE = 'redirect-check';

type NavigationData = {
  activeRoute: string;
  onTabChange: (tab: string) => void;
  isNavbarHidden: boolean;
};

/**
 * Hook that manages the Active Route switching using react-router v6.
 * This function parses the active route and renders it using the
 * `useNavigate` hook, then creates a function to change the active route
 * programmatically and returns both variables.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 *
 * @returns {NavigationData} The active route and the function to change it.
 */
const useNavigationData = (): NavigationData => {
  // React Router V6 Navigation
  const navigate = useNavigate(),
    { pathname, search } = useLocation();

  // Setting the active route or forcing it to default
  const parsedPath = pathname.substring(pathname.indexOf('/') + 1) + search;
  const chosenRoute = !parsedPath ? DEFAULT_PAGE : parsedPath;

  useEffect(() => {
    if (!parsedPath) navigate(`/${DEFAULT_PAGE}`);
  }, [parsedPath, navigate]);

  // Creating the 'Route Change' function
  const onTabChange = (tab: string): void => navigate(`/${tab}`); //setActiveRoute(tab);

  return {
    activeRoute: chosenRoute,
    onTabChange,
    isNavbarHidden: getIsNavbarHidden(chosenRoute),
  };
};

const getIsNavbarHidden = (route: string): boolean => {
  switch (route.split('/')[0].split('?')[0]) {
    case 'setup':
      return true;
    default:
      return false;
  }
};

export default useNavigationData;
