import { FC } from 'react';
import { TabPane as ReactstrapTabPane } from 'reactstrap';

import { ITabPaneProps } from './types';

/**
 * A simple wrapper class for the `reactstrap: <TabPane />` component.
 *
 * @interface ITabPaneProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const TabPane: FC<ITabPaneProps> = props => (
  <ReactstrapTabPane {...props} />
);
