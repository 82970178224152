import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@web/utils/@reduxjs/toolkit';

import { FeatureState } from './types';

// The initial state of the Order feature
export const initialState: FeatureState = {
  mktpOrdersUpdating: [],
  sendingMail: false,
  labelMailIds: [],
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    OrderUpdateMarketplaceUpdating(
      state,
      action: PayloadAction<{ ids: number[] }>,
    ) {
      state.mktpOrdersUpdating = action.payload.ids;
    },
    OrderUpdateMarketplaceUpdated(state) {
      state.mktpOrdersUpdating = [];
    },
    OrderMailSending(state) {
      state.sendingMail = true;
    },
    OrderMailSent(state) {
      state.sendingMail = false;
    },
    // sets the ids of which we want to send the labels via mail
    OrderSendLabelsMailSetIds(
      state,
      { payload }: PayloadAction<number[] | null>,
    ) {
      // the presence of selected ids is used to show the dialog
      state.labelMailIds = payload;
    },
  },
});

export const { actions: orderActions, reducer, name: sliceKey } = orderSlice;
