import { memo, useCallback } from 'react';
import { format } from 'date-fns';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import { useDispatch } from 'react-redux';
import { changelogModalActions } from '@web/features/changelog-modal/slice';

import { ModalHeader } from '@shippypro/design-system-web';
import { Color } from '@shippypro/design-system-web/enums';

import { CacheItem } from '@web/utils/functions';

interface IHeaderProps {}

/**
 * **COMPONENT: Header**
 *
 * Just a simple "modal header" component that displays a "What's new" title and has an 'x' button to close the modal.
 * Upon closing it manually using said button, a new `lastChangelog` row will be saved in the localstorage, so the modal
 * will not re-open until a new release is published.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 * @interface IHeaderProps
 */
const Header: React.FC<IHeaderProps> = memo(() => {
  const { t } = useTranslation(),
    trans = translations.changelog;

  const dispatch = useDispatch();

  const closeModal = useCallback(() => {
    CacheItem('lastChangelog', null, parseInt(format(new Date(), 'T')));
    dispatch(changelogModalActions.toggleChangelogModal(false));
  }, [dispatch]);

  return (
    <ModalHeader color={Color.primary} toggle={closeModal}>
      {t(trans.whatsNew)}
    </ModalHeader>
  );
});

export default Header;
