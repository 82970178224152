import { FC, memo } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

import { useTranslation } from 'react-i18next';

import { StyleType } from '@ds-web/tokens/enums';

import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from '@ds-web/components';
import { IconHelper } from '@ds-web-iconhelper';

import { IActionsMenuProps } from '@ds-web/components/types';

/**
 * This component show the actions menu for custom tab
 *
 * @interface IActionsMenuProps
 *
 * @author Federico Mauri <federico.mauri@shippypro.com>
 */
const ActionsMenu: FC<IActionsMenuProps> = memo(
  ({ actions, disabled = false, className = '' }) => {
    const { t } = useTranslation();

    return (
      <StyledActionsMenu
        className={classnames('custom-tab-actions', className)}
      >
        <UncontrolledButtonDropdown
          tag="div"
          className="nav-item dropdown px-0 mx-0"
          data-test="custom-action-menu-button"
        >
          <DropdownToggle
            className="flex items-center nav-item nav-item-dropdown rounded !px-1"
            styleType={StyleType.flat}
            disabled={disabled}
            onClick={e => {
              // Prevent tab selection
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <IconHelper icon="IconDotsVertical" size={16} stroke={2.5} />
          </DropdownToggle>
          <DropdownMenu>
            {actions.map((el, i) => (
              <DropdownItem
                key={i}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();

                  el.action(e);
                }}
                className={classnames(
                  'flex items-center gap-1 text-[color:--shp-color-text-tab-title] font-medium',
                  el.className,
                )}
                data-test="tab-actions-btn"
              >
                <div className="fonticon-wrap">
                  <IconHelper icon={el.icon} size={16} />
                </div>
                <label className="fonticon-classname">{t(el.text)}</label>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </StyledActionsMenu>
    );
  },
);

const StyledActionsMenu = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export default ActionsMenu;
