import { useState, useCallback, useEffect } from 'react';

import { IHelpSectionContextProps } from '@web/features/help/contexts/HelpSectionContext';

import { HelpSubSection } from '@web/features/help/types';

import useManageSupportChat from './useManageSupportChat';

/**
 * `[HOOK]` This hook instances every info prop related to the Help section, from its state to its contents.
 * It is also used as a localised state that can get updated by an external source (I.e. A dedicated context).
 *
 * N.B. **ALREADY INSTANTIATED AT THE NEAR-TOP OF THE CODE TREE, NO NEED TO USE THIS ANYWHERE ELSE**
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 *
 * @returns {IHelpSectionContextProps} All of the info needed for the help section to function
 */
const useInstanceHelpSection = (): IHelpSectionContextProps => {
  // Bootstrapping the section's contents (& visibility)
  const [isOpen, setIsOpen] = useState(false);

  // Bootstrapping the section's current view (sub-section)
  const [subSection, setSubSection] = useState<HelpSubSection>(
    HelpSubSection.home,
  );

  // Bootstrapping the live chat
  const liveChat = useManageSupportChat();
  useEffect(() => {
    liveChat.hasLoaded && liveChat.openHandler();
  }, [liveChat]);

  // Opening the section and filling it with its info
  const onHelpSectionOpen = useCallback(
    (subSection: HelpSubSection = HelpSubSection.home) => {
      setIsOpen(true);

      setSubSection(subSection);
    },
    [],
  );

  // Closing the section and destroying the info
  const onHelpSectionClose = useCallback(() => setIsOpen(false), []);

  return {
    isOpen,
    subSection,
    setSubSection,
    liveChat,
    openHelpSection: onHelpSectionOpen,
    closeHelpSection: onHelpSectionClose,
  };
};

export default useInstanceHelpSection;
