// ########################## [TRANSLATIONS]
import { translations } from '@shippypro/translations';
import { TFunction } from 'i18next';

// ########################## [TYPES]
import type { Marketplace } from '@web/types/global';

// ########################## [HOOKS]
import { useGetMarketplaces } from '@web/features/_global/hooks/api/useGetMarketplaces';

/**
 * [HOOK] useGetMarketplace returns a single marketplace from your configured
 * set of marketplaces depending on the composite key [ID <-> Platform Name]
 *
 * @returns {Marketplace | undefined} the found Marketplace object
 *
 * @author [Emanuele Moricci](https://github.com/emanuele-moricci-shippypro)
 */
const useGetMarketplace = (
  id: number | null | undefined,
  platform: string | null | undefined,
): Marketplace | undefined => {
  const { allMarketplaces: marketplaces } = useGetMarketplaces();

  return getMarketplace(marketplaces ?? [], id, platform);
};

export const getMarketplace = (
  marketplaces: Marketplace[],
  id: number | null | undefined,
  platform: string | null | undefined,
) => {
  id = platform === 'Manual shipment' ? 1 : id;
  return (marketplaces ?? []).find(
    m => m.marketplace_id === id && m.marketplace_platform === platform,
  );
};

export const getMarketplaceStatusString = (
  marketplaceStatus: boolean | null | undefined,
  t: TFunction,
) => {
  if (marketplaceStatus === null) return '';
  else
    return marketplaceStatus
      ? `- ${t(translations.order.updateMarketplace.updatedStatus)}`
      : `- ${t(translations.order.updateMarketplace.notUpdatedStatus)}`;
};

export default useGetMarketplace;
