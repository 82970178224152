import i18n from 'i18next';
import { translations } from '@shippypro/translations';

import { PaywallCTA } from '@web/features/paywall/types';

/**
 * The common CTA used for when the Paywall can ONLY be unlocked
 * by talking to a Sales Rep.
 *
 * @param {string} utmSource The UTM source to help with the ShippyPro Sales Analytics
 * @param {string} utmCampaign The UTM campaign to help with the ShippyPro Sales Analytics
 *
 * @returns {PaywallCTA} The Paywall's CTA info
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
/* istanbul ignore next */
export const ctaSales: (
  utmSource: string,
  utmCampaign: string,
) => PaywallCTA = (utmSource, utmCampaign) => ({
  text: i18n.t(translations.paywall.ctas.talkToSales),
  onClick: () =>
    window.open(
      `${process.env.NX_LEGACY_URL}/en/contact-us?utm_medium=paywall&utm_source=${utmSource}&utm_campaign=${utmCampaign}`,
      '_blank',
    ),
});

/**
 * The common CTA used for when the Paywall can be unlocked by upgrading to Professional.
 *
 * @param {string} utmSource The UTM source to help with the ShippyPro Sales Analytics
 * @param {string} utmCampaign The UTM campaign to help with the ShippyPro Sales Analytics
 *
 * @returns {PaywallCTA} The Paywall's CTA info
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
/* istanbul ignore next */
export const ctaProfessional: (
  utmSource: string,
  utmCampaign: string,
) => PaywallCTA = (utmSource, utmCampaign) => ({
  text: i18n.t(translations.paywall.ctas.upgradeToProfessional),
  onClick: () =>
    window.open(
      `${process.env.NX_LEGACY_URL}/panel/new-plan-orders-amount.html?plan_id=3&utm_source=${utmSource}&utm_campaign=${utmCampaign}`,
      '_blank',
    ),
});
