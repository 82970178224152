import { FC } from 'react';
import { FallbackProps } from 'react-error-boundary';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import { Button } from '@shippypro/design-system-web';
import { Color, StyleType } from '@shippypro/design-system-web/enums';

import Error_Video from '@web/assets/videos/error-500.mp4';

import Index from '@web/components/common-ui/wrappers/seo';

const FatalErrorPage: FC<FallbackProps> = ({ resetErrorBoundary }) => {
  const { t } = useTranslation(),
    trans = translations.pageFatalError;

  return (
    <Index title={t(trans.pageTitle)} page={t(trans.pageTitle)}>
      <main
        id="500-page"
        className="min-h-full flex flex-col w-full justify-center items-center !gap-2"
      >
        <video autoPlay muted loop className="w-[30rem] h-[25rem]">
          <source src={Error_Video} type="video/mp4" />
        </video>
        <h1 className="font-bold text-primary text-5xl">{t(trans.title)}</h1>
        <h4 className="text-base text-primary text-center m-0">
          {t(trans.description)}
        </h4>
        <Button
          className="!mt-5"
          color={Color.primary}
          styleType={StyleType.filled}
          onClick={() => resetErrorBoundary()}
        >
          {t(trans.cta)}
        </Button>
      </main>
    </Index>
  );
};

export default FatalErrorPage;
