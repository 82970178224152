/**
 * This function takes in an object of string params and converts into into a query param string
 * to put into the current url (without page reload)
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 *
 * @param {Record<string, string>} params An key<>value pair object to parse into a query param string
 * @returns {void}
 */
export const updateUrlQueryParams = (params: Record<string, string>): void => {
  const queryParams = Object.keys(params)
    .map(function (k) {
      return `${k}="${params[k]}"`;
    })
    .join('&');

  window.history.replaceState(
    null,
    '',
    `${window.location.pathname}?${queryParams}`,
  );
};
