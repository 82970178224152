import pdfFileImage from '@web/assets/img/icons/pdf-file.svg';
import zplFileImage from '@web/assets/img/icons/zpl-file.svg';
import eplFileImage from '@web/assets/img/icons/epl-file.svg';
import genericFileImage from '@web/assets/img/icons/gen-file.svg';

type ParsedFileExtension = {
  img: string;
  ext: string;
};

/**
 * [HOOK] This hook takes a file url and extracts its extension,
 * parsing it to return a handy image icon.
 * The image icons are defined in the assets/img/icons folder.
 * The mapped extensions for now are the following:
 * * PDF
 * * ZPL
 * * Generic (any other extension)
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 * @author Fabio Mezzina <fabio.mezzina@shippypro.com>
 *
 * @param {string} fileUrl The url of the file we want to parse
 * @returns {string} The image icon for the given file
 */
const useParseFileExtension = (fileUrl: string): ParsedFileExtension => {
  return parseFileExtension(fileUrl);
};

export const parseFileExtension = (fileUrl: string): ParsedFileExtension => {
  const fileName = fileUrl.split('/').pop();
  const fileExt = fileName?.split('.').pop();

  switch (fileExt) {
    case 'pdf':
      return { ext: 'pdf', img: pdfFileImage };
    case 'zpl':
      return { ext: 'zpl', img: zplFileImage };
    case 'epl':
      return { ext: 'epl', img: eplFileImage };
    default:
      return { ext: fileExt ?? '', img: genericFileImage };
  }
};

export default useParseFileExtension;
