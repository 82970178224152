import { FC, memo } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { Skeleton } from '@ds-web/components';

/**
 * A skeleton loader for the Condensed Metrics Card.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const CondensedMetricsSkeleton: FC = memo(() => {
  return (
    <Card className="h-80 overflow-hidden">
      <CardHeader className="h-[4.5rem] border-b-[1px] border-b-[color:--shp-color-divider]">
        <Skeleton className="w-full">
          <circle cx="12" cy="12" r="12" />
          <rect x="30" y="5" width="134" height="14" rx="2" />
        </Skeleton>
      </CardHeader>
      <CardBody className="p-2">
        <Skeleton
          className="w-full"
          viewBox="0 0 400 200"
          preserveAspectRatio="xMidYMax meet"
        >
          {/* FIRST CONDENSED BLOCK */}
          <rect x="0" y="0" width="128" height="26" rx="13" />
          <rect x="75%" y="5" width="77" height="14" rx="2" />
          <rect x="95%" y="3" width="18" height="18" rx="18" />
          {/* SECOND CONDENSED BLOCK */}
          <rect x="0" y="60" width="128" height="26" rx="13" />
          <rect x="75%" y="70" width="77" height="14" rx="2" />
          <rect x="95%" y="68" width="18" height="18" rx="18" />
        </Skeleton>
      </CardBody>
    </Card>
  );
});
