import { FC, memo, useCallback, useMemo } from 'react';
import Nouislider from 'nouislider-react';

import { Input } from '../inputs/input/Input';

import '@ds-web/assets/scss/react/libs/noui-slider/noui-slider.scss';

import { ISliderProps } from './types';

/**
 * The slider component leveraging the nouislider from https://refreshless.com/nouislider/
 * This slider accepts also a "hasInput" prop to show two inputs below the slider, used for changing the slider's min-max range
 *  onMinChange and onMaxChange can be used to manage the min-max range state
 *
 * @interface ISliderProps
 * @author Fabio Mezzina <fabio.mezzina@shippypro.com>
 */
export const Slider: FC<ISliderProps> = memo(props => {
  const allowedKeys = useMemo(
    () => [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      'Backspace',
      'Enter',
      'ArrowLeft',
      'ArrowRight',
    ],
    [],
  );

  const checkKeyPressIsNumeric = useCallback(
    (e: { key: string; preventDefault(): void }) => {
      if (allowedKeys.indexOf(e.key) === -1) {
        e.preventDefault();
      }
    },
    [allowedKeys],
  );

  return (
    <>
      <Nouislider {...props} />
      {props.hasInput && (
        <div className="flex mt-1 gap-6">
          <Input
            type="number"
            min={0}
            max={props.to - 1}
            value={props.from}
            className="text-center slider-min-input"
            onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
              e.target.select();
            }}
            onKeyDown={(e: { key: string; preventDefault: () => void }) => {
              checkKeyPressIsNumeric(e);
            }}
            onKeyUp={(e: { key: string; preventDefault: () => void }) => {
              checkKeyPressIsNumeric(e);
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              props.onMinChange(e)
            }
          />
          <Input
            type="number"
            min={props.from + 1}
            value={props.to}
            className="text-center slider-max-input"
            onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
              e.target.select();
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              props.onMaxChange(e)
            }
            onKeyDown={(e: { key: string; preventDefault: () => void }) => {
              checkKeyPressIsNumeric(e);
            }}
            onKeyUp={(e: { key: string; preventDefault: () => void }) => {
              checkKeyPressIsNumeric(e);
            }}
          />
        </div>
      )}
    </>
  );
});
