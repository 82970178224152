import { Dispatch, SetStateAction, createContext } from 'react';

import { Printer, PrinterListType } from '@web/utils/@qz-tray/types';

export interface IQzTrayManagerContexttProps {
  isQzConnectionUp: boolean;
  isQzConnecting: boolean;
  printersList: PrinterListType;
  selectedPrinter: Printer | null;
  printerChanged: boolean; // (?)

  setPrinter: (printer: string, mode: 'setFavourite' | 'setDefault') => void;
  setPrintersList: Dispatch<SetStateAction<PrinterListType>>;
  qzReconnectHandler: (
    retries?: number,
    onReconnectSuccess?: () => void,
    onReconnectFail?: () => {},
  ) => void;
}

/**
 * This is the `QzTray Manager` Context that is instantiated at the near-top of the component tree.
 * This allows any feature to access QzTray connection/printers state
 *
 * @author Fabio Mezzina <fabio.mezzina@shippypro.com>
 */
const QzTrayManagerContext = createContext<IQzTrayManagerContexttProps | null>(
  null,
);

export default QzTrayManagerContext;
