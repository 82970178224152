import { FC } from 'react';

import { CardHeader as ReactstrapCardHeader } from 'reactstrap';

import { ICardHeaderProps } from './types';

/**
 * A simple wrapper class for the `reactstrap: <CardHeader />` component.
 *
 * @interface ICardHeaderProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const CardHeader: FC<ICardHeaderProps> = props => (
  <ReactstrapCardHeader {...props} />
);
