import { FC, memo } from 'react';
import { formatDistanceToNow } from 'date-fns';

import { formatAlertDate } from '@web/features/shared/alerts/functions';

interface IAlertBodyProps {
  name: string;

  hideLatestUpdateCta: boolean;

  isMaintenance: boolean;

  scheduledFor?: string;
  scheduledUntil?: string;

  update?: string;
  updatedAt?: string;
}

/**
 * **[COMPONENT]** The alert-body component.
 * The Body of the StatusPage Alert. It displays different info, depending on the  or  mode.
 *
 * @interface IAlertBodyProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const AlertBody: FC<IAlertBodyProps> = memo(
  ({
    name,
    hideLatestUpdateCta,
    isMaintenance,
    scheduledFor,
    scheduledUntil,
    update,
    updatedAt = new Date(),
  }) => {
    return (
      <div className="status-alert-content">
        <h1 className="status-alert-title">
          <p>{name}</p>
        </h1>

        <div className="status-alert-item status-alert-context">
          {isMaintenance ? (
            <span>
              Scheduled from {formatAlertDate(scheduledFor)}
              <br /> to {formatAlertDate(scheduledUntil)}
            </span>
          ) : (
            <span>
              {update}
              <br /> Last Update {formatDistanceToNow(new Date(updatedAt))} ago
            </span>
          )}
        </div>

        {!hideLatestUpdateCta && (
          <div className="status-alert-item view-link">
            <a
              className="page-link"
              href="https://status.shippypro.com"
              target="_blank"
              rel="noreferrer"
            >
              View latest updates
            </a>
          </div>
        )}
      </div>
    );
  },
);

export default AlertBody;
