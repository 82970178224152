import { useState, memo, useRef } from 'react';
import { InputGroupText } from 'reactstrap';
import classnames from 'classnames';
import { useDebounce } from 'react-use';
import { isEqual } from 'lodash';

import { Button } from '@ds-web/components/atoms/ui/buttons/button/Button';
import { Input } from '@ds-web/components/atoms/form/inputs/input/Input';
import { InputGroup } from '@ds-web/components/atoms/form/inputs/input-group/InputGroup';
import { TooltipWrapperByREF } from '@ds-web/components/molecules/ui/tooltips/tooltip-wrapper-byref/TooltipWrapperByREF';

import { Color, Size, StyleType } from '@ds-web/tokens/enums';
import { IconHelper } from '@ds-web-iconhelper';

import { ISearchBarProps } from './types';

/**
 * The SearchBar component is used to filter a list of items.
 * Each time the value of the internal input change, a timeout starts and after a delay of 800ms, a new Get request is made
 *
 * @interface ISearchBarProps
 * @author Fabio Mezzina <fabio.mezzina@shippypro.com>
 * @author Federico Mauri <federico.mauri@shippypro.com>
 */

export const SearchBar: React.FC<ISearchBarProps> = memo(
  ({
    disabled = false,
    placeholder = '',
    handleSearch,
    initialSearchText,
    tooltipContent = '',
    dataTest = '',
    alwaysOpen = false,
  }) => {
    // Anchoring a ref object to the HTML input
    const inputRef = useRef<HTMLInputElement>(null);

    // Handling the local state of the input value - debouncing the value to avoid API call spamming
    const [searchText, setSearchText] = useState(initialSearchText ?? '');
    const [debouncedSearch, setDebouncedSearch] = useState(searchText);
    const firstRender = useRef(true);
    useDebounce(
      () => {
        setSearchText(debouncedSearch);

        // Functional comment for the poor unfortunate souls who will stumble upon here:
        // Since the filters connected to the react-table get updated by several external sources (state, payload, APIs, etc...)
        // when switching tabs the whole table could not load on certain scenarios that depend SOLELY by this debounce effect,
        // that HAS to always run the first time to make sure that the `handleSearch` function is always taken into account
        // when bootstrapping a table or any other component that uses this search bar. It's not the searchbar's fault, it's the
        // way the various tables were implemented with their multiple dependencies on the ways the filters were implemented.
        // TODO: fix this behaviour, on the tables implementation side.
        if (
          debouncedSearch !== null &&
          (firstRender.current || !isEqual(initialSearchText, debouncedSearch))
        )
          handleSearch(searchText);

        firstRender.current = false;
      },
      800,
      [searchText, debouncedSearch],
    );

    // Opening/closing the search element (from button to input)
    const [isSearchOpen, setIsSearchOpen] = useState(
      !!searchText || alwaysOpen,
    );

    // Handling the input focus on open when clicking the searchbox compressed button
    if (isSearchOpen && !alwaysOpen) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 200);
    }

    return (
      <>
        <InputGroup
          className={classnames('searchbar w-auto input-group-merge', {
            hidden: !isSearchOpen,
          })}
          size={Size.md}
        >
          <InputGroupText
            id="searchbar-icon"
            className={classnames('border-r-0', {
              'input-group--disabled text-muted bg-[color:--shp-color-bg-highlight]':
                disabled,
              'border-0': !isSearchOpen,
            })}
          >
            <IconHelper icon="IconSearch" size={17} />
          </InputGroupText>
          <Input
            innerRef={inputRef}
            placeholder={placeholder}
            value={debouncedSearch}
            onChange={e => setDebouncedSearch(e.target.value)}
            disabled={disabled}
            maxLength={512}
            className={classnames({
              '!w-0 border-0': !isSearchOpen,
            })}
            onBlur={() =>
              !debouncedSearch && !alwaysOpen && setIsSearchOpen(false)
            }
            data-test={`${dataTest}-input`}
          />
          {debouncedSearch && (
            <InputGroupText
              className={classnames(
                'searchbar-close',
                'cursor-pointer pl-0 border-l-0',
                {
                  'text-muted bg-[color:--shp-color-bg-highlight]': disabled,
                },
              )}
              onClick={() => {
                setDebouncedSearch('');
                setSearchText('');
                if (!alwaysOpen) setIsSearchOpen(false);
              }}
            >
              <IconHelper icon="IconX" size={17} />
            </InputGroupText>
          )}
        </InputGroup>
        <TooltipWrapperByREF
          content={tooltipContent}
          disabled={isSearchOpen || !tooltipContent}
          fullWidth={false}
          containerProps={{
            className: classnames({
              hidden: isSearchOpen,
            }),
          }}
        >
          <Button
            className={classnames('searchbar-btn !p-[0.725rem]', {
              hidden: isSearchOpen,
            })}
            color={Color.primary}
            styleType={StyleType.flat}
            size={Size.md}
            onClick={() => !alwaysOpen && setIsSearchOpen(true)}
            disabled={disabled}
            id={dataTest}
            data-test={dataTest}
          >
            <IconHelper icon="IconSearch" size={18} />
          </Button>
        </TooltipWrapperByREF>
      </>
    );
  },
);
