import { createContext } from 'react';

export interface ITrackingDialogContextProps {
  isOpen: boolean;
  openTrackingDialog: (orderId: string | number) => void;
  closeTrackingDialog: () => void;
  orderId?: string | number | undefined;
}

/**
 * This is the Tracking Dialog Context that is instantiated at the near-top of the component tree.
 * This allows any feature to open the modal and show a user every tracking info.
 * The context holds every info possible that the modal needs to function correctly: the current order.
 *
 * @example
 * const { openTrackingDialog } = useContext(TrackingDialogContext)!;
 * ...
 * <button onClick={() => openTrackingDialog<Order>(order)}>Click Me!</button>
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
const TrackingDialogContext = createContext<ITrackingDialogContextProps | null>(
  null,
);

export default TrackingDialogContext;
