import { FC } from 'react';
import { PopoverHeader as ReactstrapPopoverHeader } from 'reactstrap';

import { IPopoverHeaderProps } from './types';

/**
 * A simple wrapper class for the `reactstrap: <PopoverHeader />` component.
 *
 * @interface IPopoverHeaderProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const PopoverHeader: FC<IPopoverHeaderProps> = props => (
  <ReactstrapPopoverHeader {...props} />
);
