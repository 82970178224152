import { useCallback, FC, ReactNode } from 'react';

// ########################## [TYPES]
import { IOrderInfoBlock } from '../types';

// ########################## [COMPONENTS]
import NotesSection from './NotesSection';

// ########################## [HOOKS]
import { useSaveOrderNote } from '@web/features/order/hooks/api/useSaveOrderNote';
import useParseOrderStatus from '@web/hooks/orders/useParseOrderStatus';

interface IUpdateNotesProps extends IOrderInfoBlock {
  header?: null | ReactNode;
}

/**
 * this component shows notes and performs the update of ShippyPro notes (the only
 * editable kind) via the dedicated endpoint
 * @author Fabio Fognani <fabio.fognani@shippypro.com>
 */
const UpdateNotes: FC<IUpdateNotesProps> = ({ order, header }) => {
  const { saveOrderNote, isLoading: isSavingNote } = useSaveOrderNote(
    true,
    'saveOrderNote',
  );
  const category = useParseOrderStatus(order?.order_status).category;

  const notes = order?.notes;
  const shippyProNote = notes?.shippypro;

  const onSubmit = useCallback(
    async (newValue: string) => {
      if (order?.id && category && newValue !== shippyProNote) {
        await saveOrderNote(order.id, category, newValue);
      }
    },
    [order, category, shippyProNote, saveOrderNote],
  );

  return (
    <NotesSection
      header={header}
      notes={notes}
      onSubmitShippyProNote={onSubmit}
      isSavingShippyProNote={isSavingNote}
      isShippyProNoteEditable
    />
  );
};

export default UpdateNotes;
