import { FC, memo } from 'react';

import {
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  BarChart,
  Bar,
  LabelList,
} from 'recharts';

import { toLocalizedDecimal } from '@ds-web/utils/functions/numbers/localised-decimal/localised-decimal';

import { IBarChartMetricsBodyProps } from './types';

/**
 * This Component renders a `Bar Chart` view of a metric, with its total count,
 * its trend and the actual graph.
 *
 * @interface IBarChartMetricsBodyProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const BarChartMetricsBody: FC<IBarChartMetricsBodyProps> = memo(
  ({ data, locale = 'en-US', XAxisFormatter, TopBarLabelFormatter }) => {
    const maxYValue = Math.max(...data.map(d => d.yValue));
    const yAxisDomain = [0, maxYValue + maxYValue * 0.25]; // Add 25% padding to the top of the chart

    return (
      <section
        id="metricsbarchart-container"
        className="w-full flex flex-col justify-between items-start"
      >
        <div
          id="metricsbarchart-graphcontainer"
          className="recharts-wrapper !mt-5"
        >
          <ResponsiveContainer width="98%" height="100%">
            <BarChart
              data={data}
              margin={{ right: 0, left: 0, top: 10, bottom: 0 }}
            >
              <CartesianGrid vertical={false} />
              <XAxis dataKey="xName" tick={XAxisFormatter} interval={0} />
              <YAxis
                dataKey="yValue"
                width={35}
                domain={yAxisDomain}
                tickFormatter={value => toLocalizedDecimal(value, 0, 0)}
              />
              <Bar
                type="monotone"
                dataKey="yValue"
                fill="#b6a0ed"
                fillOpacity={1}
                radius={[3, 3, 0, 0]}
                isAnimationActive={false}
              >
                <LabelList
                  dataKey="yValue"
                  position="top"
                  content={TopBarLabelFormatter}
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </section>
    );
  },
);
