import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { translations } from '@shippypro/translations';

import { IconHelper } from '@shippypro/design-system-web/iconhelper';
import { CardWrapper } from '@shippypro/design-system-web';

interface ICardErrorProps {
  error: string | null;
  customText?: string;
  fullScreen?: boolean;
  noCard?: boolean;
  table?: boolean;
  className?: string;
}

// TODO: move to design system + add documentation
const CardError: React.FC<ICardErrorProps> = memo(
  ({
    error,
    customText,
    fullScreen = false,
    noCard = false,
    table = false,
    className = '',
  }) => {
    const { t } = useTranslation();

    return (
      <CardWrapper
        middle={fullScreen}
        cardClassName={classnames(className, {
          'full-size': fullScreen,
          'no-card': noCard,
        })}
        wrapperClassName={classnames({
          'app-card flex flex-col items-center !flex-none': fullScreen,
          'table-error-card flex flex-col items-center': table,
        })}
      >
        <IconHelper
          icon="IconAlertTriangle"
          size={100}
          className="text-danger"
        />
        <br />
        <h1 className="text-2xl font-medium text-purpleish">
          {error && t(translations.common['errors'][error])}{' '}
        </h1>
        {customText && (
          <h6 className="text-lg lh25 text-light-purpleish">{customText}</h6>
        )}
      </CardWrapper>
    );
  },
);

export default CardError;
