import { FC, memo, useEffect, useState } from 'react';
import { Row } from 'reactstrap';

import { ListViewMode, ListViewOrder, ListViewReservedFields } from './types';
import ListFilters from './ListFilters';
import ListHeader from './ListHeader';
import ListItem from './ListItem';
import GridItem from './GridItem';

import { Pagination } from '@ds-web/components/atoms/ui/pagination/Pagination';
import { PaginationArrows } from '@ds-web/tokens/enums';

import { isDateBetweenRange } from '@ds-web/utils/functions';

import '@ds-web/assets/scss/base/components/list-view.scss';

import { IListViewProps } from './types';

/**
 * The ListView is a custom component created as a table with list/grid modes, sorting/filtering and customizable actions.
 * You can find the documentation here: {@link https://app.tettra.co/teams/shippypro/pages/listview}
 *
 * @param {ListViewConfig} config The configuration object for the ListView
 * @param {ListViewColumn[]} columns The list of configured columns
 * @param {object[]} data The list of data fetched from the API
 *
 * @return {JSX.Element}          The ListView Component
 * @version 1.5.0
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 * @interface ListViewProps
 * @example You can find a clear example on the design-system-storybook solution
 */
export const ListView: FC<IListViewProps> = memo(
  ({ config, columns, data }) => {
    const [page, setPage] = useState<number>(1),
      [order, setOrder] = useState<ListViewOrder>(
        config.order ?? ListViewOrder.asc,
      ),
      [selected, setSelected] = useState<string[]>([]),
      [search, setSearch] = useState<string>(''),
      [dates, setDates] = useState<Date[]>([]),
      [mode, setMode] = useState<ListViewMode>(
        config.mobile ? ListViewMode.grid : ListViewMode.list,
      ),
      pageMaxRows = config.rowsPerPage ?? 10,
      listViewData = [...data],
      filteredData = listViewData
        .sort((a: { [key: string]: string }, b: { [key: string]: string }) =>
          order === ListViewOrder.asc
            ? Object.values(a)[0].toString().localeCompare(Object.values(b)[0])
            : Object.values(b)[0].toString().localeCompare(Object.values(a)[0]),
        )
        .filter((row: any, i: any) => {
          dates[0] && dates[0].setHours(0, 0, 0, 0);
          dates[dates.length - 1] &&
            dates[dates.length - 1].setHours(23, 59, 59, 999);

          const searchRes =
              !search ||
              (search &&
                Object.values(row)
                  .join('|')
                  .toLowerCase()
                  .indexOf(search.toLowerCase()) !== -1),
            dateField = row[ListViewReservedFields.date],
            datesRes =
              dateField &&
              (!dates.length ||
                isDateBetweenRange(
                  dateField['start'],
                  dates[0],
                  dates[dates.length - 1],
                ) ||
                isDateBetweenRange(
                  dateField['end'],
                  dates[0],
                  dates[dates.length - 1],
                ));

          return searchRes && datesRes;
        })
        .map((row: any, i: any) =>
          mode === ListViewMode.list ? (
            <ListItem
              key={i}
              config={config}
              row={row}
              columns={columns}
              selected={selected}
              handleSelect={selection => setSelected(selection)}
            />
          ) : (
            <GridItem
              key={i}
              config={config}
              row={row}
              columns={columns}
              selected={selected}
              handleSelect={selection => setSelected(selection)}
            />
          ),
        ),
      pagedData = filteredData.slice(
        (page - 1) * pageMaxRows,
        page * pageMaxRows,
      );

    useEffect(() => {
      setMode(config.mobile ? ListViewMode.grid : ListViewMode.list);
    }, [config.mobile]);

    return (
      <div className="list-view">
        <ListFilters
          config={config}
          selected={selected}
          search={(k: string) => setSearch(k)}
          dates={dates}
          setDates={(k: Date[]) => setDates(k)}
          mode={mode}
          toggleMode={(m: ListViewMode) => setMode(m)}
        />
        <ListHeader
          mode={mode}
          config={config}
          data={listViewData}
          columns={columns}
          order={order}
          toggleOrder={(o: ListViewOrder) => setOrder(o)}
          selected={selected}
          setSelected={(selection: string[]) => setSelected(selection)}
        />
        <Row className="justify-between">
          {listViewData.length === 0 ? (
            <span className="text-center w-full text-purpleish mt-20 mb-20">
              {config.emptyText}
            </span>
          ) : filteredData.length === 0 ? (
            <span className="text-center w-full text-purpleish mt-20 mb-20">
              {config.noMatchText}
            </span>
          ) : (
            ''
          )}
          {pagedData}
        </Row>
        <div
          className={`${
            selected.length ? 'list-view-selection' : 'list-view-pagination'
          }`}
        >
          {selected.length ? (
            <div className="text-[#6e6b7b]">
              {config.translations.selected} {selected.length}
            </div>
          ) : null}
          <Pagination
            arrows={PaginationArrows.separated}
            rowsPerPage={pageMaxRows}
            rowCount={filteredData.length}
            onChangePage={(p: number) => setPage(p)}
            currentPage={page}
          />
        </div>
      </div>
    );
  },
);
