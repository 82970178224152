import { FC } from 'react';
import { Pagination as ReactstrapPagination } from 'reactstrap';
import classnames from 'classnames';
import styled from 'styled-components';

import { IPaginationBoxProps } from './types';

/**
 * A simple wrapper class for the `reactstrap: <Pagination />` component.
 *
 * @interface IPaginationBoxProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const PaginationBox: FC<IPaginationBoxProps> = ({
  className,
  color,
  direction,
  border,
  arrows,
  ...rest
}: IPaginationBoxProps) => (
  <StyledPagination>
    <ReactstrapPagination
      className={classnames(className, {
        [`pagination-${color}`]: color,
        [`justify-content-${direction}`]: direction,
        'border-round': border === 'round',
        'border-square': border === 'square',
        'pagination-separated': arrows === 'separated',
      })}
      {...rest}
    />
  </StyledPagination>
);

const StyledPagination = styled.div`
  .page-link {
    font-weight: 400 !important;
    width: 28px;
    display: initial;
  }
  .active .page-link {
    font-weight: 700 !important;
  }
  .pagination-sm {
    align-items: center;
    .page-item .page-link {
      padding: 0 0.4rem;
      font-size: 0.8rem !important;
      line-height: 2.15 !important;
      width: 24px;
    }
    .next-item .page-link,
    .prev-item .page-link {
      border-top-right-radius: 50% !important;
      border-bottom-right-radius: 50% !important;
      padding: 0 0.2rem;
    }
  }

  .pagination-md {
    align-items: center;
    .page-item .page-link {
      padding: 0.5rem;
      font-size: 1.25rem !important;
      line-height: 1 !important;
    }
    .next-item .page-link,
    .prev-item .page-link {
      border-top-right-radius: 50% !important;
      border-bottom-right-radius: 50% !important;
      width: 30.8px;
    }
  }

  .pagination-lg {
    align-items: center;
    .page-item .page-link {
      font-size: 1rem;
      scale: unset;
      width: 33px;
    }
    .next-item .page-link,
    .prev-item .page-link {
      width: 35px;
    }
  }

  &.border-round {
    &.pagination-separated {
      .page-item:not(.prev-item).active {
        &:nth-child(2) {
          overflow: hidden;
          border-top-left-radius: 50% !important;
          border-bottom-left-radius: 50% !important;
          button.page-link {
            border-top-right-radius: 50% !important;
            border-bottom-right-radius: 50% !important;
          }
        }
        &:nth-last-child(2) {
          overflow: hidden;
          border-top-right-radius: 50% !important;
          border-bottom-right-radius: 50% !important;
          button.page-link {
            border-top-left-radius: 50% !important;
            border-bottom-left-radius: 50% !important;
          }
        }
      }
    }
    .active button {
      border-radius: 50% !important;
      transform: unset !important;
    }
  }
  &.border-square {
    .pagination {
      .page-item.next-item button {
        border-radius: 0 !important;
      }
      .page-item.prev-item {
        button {
          border-radius: 0 !important;
        }
        ~ {
          .page-item:nth-child(2) .page-link {
            border-radius: 0 !important;
          }
          .page-item:nth-last-child(-n + 2) .page-link {
            border-radius: 0 !important;
          }
          .page-item.active {
            > .page-link {
              border-radius: 0 !important;
            }
            &:nth-child(2) .page-link {
              border-radius: 0 !important;
            }
          }
        }
      }
    }
  }
`;
