import { useForm, UseFormProps } from 'react-hook-form';

import { ILabelFormatSettingsForm } from './types';

import { useUnproxyFormStateFields } from '@web/utils/@react-hook-form/hooks';

export const useLabelFormatSettingsForm = (
  formProps?: UseFormProps<ILabelFormatSettingsForm>,
) => {
  const form = useForm<ILabelFormatSettingsForm>({
    mode: 'onChange',
    ...formProps,
    defaultValues: {
      saveAsDefault: true,
    },
  });

  useUnproxyFormStateFields(form);

  return form;
};
