import { FC } from 'react';
import { DropdownMenu as ReactstrapDropdownMenu } from 'reactstrap';

import { IDropdownMenuProps } from './types';

/**
 * A simple wrapper class for the `reactstrap: <DropdownMenu />` component.
 *
 * @interface IDropdownMenuProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const DropdownMenu: FC<IDropdownMenuProps> = (
  props: IDropdownMenuProps,
) => <ReactstrapDropdownMenu {...props} />;
