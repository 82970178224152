import {
  DetailedHTMLProps,
  HTMLAttributes,
  ReactNode,
  memo,
  useEffect,
  useRef,
  useState,
} from 'react';
import classnames from 'classnames';

import { Tooltip, Popover } from '@shippypro/design-system-web';

interface ITextWithCustomHoverProps {
  label: string | ReactNode;
  startIcon?: ReactNode;
  target?: string;
  type?: 'popover' | 'tooltip';
  labelProps?: DetailedHTMLProps<
    HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  >;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  fitParent?: boolean;
  displayBlock?: boolean;
  children?: React.ReactNode;
  className?: string;
  isOpenProp?: boolean;
  popperClassName?: string;
}

// TODO: move to design system + add documentation
const TextWithCustomHover: React.FC<ITextWithCustomHoverProps> = memo(
  ({
    label,
    startIcon,
    target,
    labelProps,
    type = 'tooltip',
    placement = 'top',
    fitParent = false,
    displayBlock = false,
    children,
    className,
    isOpenProp = false,
    popperClassName = '',
  }) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const HoverComponent = type === 'tooltip' ? Tooltip : Popover;

    const [isOpen, setIsOpen] = useState(isOpenProp);
    useEffect(() => {
      setIsOpen(isOpenProp);
    }, [isOpenProp]);

    useEffect(() => {
      const close = e => {
        if (e.keyCode === 27) {
          setIsOpen(false);
        }
      };

      window.addEventListener('keydown', close);
      return () => window.removeEventListener('keydown', close);
    }, []);

    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsOpen(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      window.addEventListener('keydown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        window.removeEventListener('keydown', handleClickOutside);
      };
    }, [ref, setIsOpen]);

    const targetRef = useRef<HTMLDivElement | null>(null);

    return (
      <>
        {isOpen && (
          <div className="fixed w-screen h-screen left-0 top-0 opacity-0 z-20" />
        )}
        <div
          ref={targetRef}
          className={classnames(
            {
              'cursor-pointer': !!target,
              'max-w-full': fitParent,
              'inline-flex': !displayBlock,
              'd-block': displayBlock,
            },
            className,
            'space-x-1',
          )}
        >
          {startIcon}
          <span
            id={target}
            {...labelProps}
            className={classnames(
              'text-truncate text-light-purpleish mb-0 z-10',
              labelProps?.className,
            )}
            onClick={e => e.stopPropagation()}
          >
            {label}
          </span>
        </div>

        {target && (
          <HoverComponent
            placement={placement}
            popperClassName={classnames('min-w-[300px]', popperClassName)}
            target={targetRef}
            isOpen={isOpen}
            toggle={() => setIsOpen(!isOpen)}
            onClick={e => e.stopPropagation()}
            trigger="click"
          >
            <div ref={ref}>{children}</div>
          </HoverComponent>
        )}
      </>
    );
  },
);

export default TextWithCustomHover;
