import {
  createSharedMenuCtx,
  getSharedMenuProvider,
} from '@shippypro/design-system-web';

import { Order } from '@web/types/order';

export interface ITableContextMenuProps {
  hideOnLeave?: boolean;
  hideOnScroll?: boolean;
}

export type OrdersCustomData = {
  selectedOrders: Order[];
  clickedElement: EventTarget | null;
  position: null | {
    x: number;
    y: number;
  };
  showPrimaryAction: boolean;
};

export const defaultCustomData: OrdersCustomData = {
  selectedOrders: [],
  position: null,
  clickedElement: null,
  showPrimaryAction: true,
};

export const OrdersContextMenuCtx =
  createSharedMenuCtx<OrdersCustomData>(defaultCustomData);

export const OrdersContextMenuProvider =
  getSharedMenuProvider(OrdersContextMenuCtx);
