import { useCallback, useEffect, useMemo } from 'react';
import { parseAPIError } from '@web/utils/@reduxjs/rtk-query';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import { showErrorToastr } from '@shippypro/design-system-web/functions';

import { useSaveOrderNoteMutation } from '@web/features/order/api';

import { useDispatch, useSelector } from 'react-redux';
import { selectOrderDrawer } from '@web/features/ship/selectors';
import { shipActions } from '@web/features/ship/slice';

/**
 * [HOOK] Returns a `useSaveOrderNoteMutation` RTK Query hook with its action field renamed.
 * This hook updates the `user_note` field of a given order on the database.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const useSaveOrderNote = (
  updateInfoDrawer = true,
  cacheKey?: string,
) => {
  const { t } = useTranslation(),
    trans = translations.common,
    transArchive = translations.ship.buttons.archive,
    transErr = trans.errors;

  const dispatch = useDispatch();
  const { order: infoDrawerOrder } = useSelector(selectOrderDrawer);

  const [saveMutation, { data, error, ...results }] = useSaveOrderNoteMutation({
    fixedCacheKey: cacheKey,
  });
  const saveOrderNote = useCallback(
    async (orderId: number, category: string, note: string) => {
      const apiPayload = {
        id: orderId,
        category,
        note,
      };

      await saveMutation(apiPayload);

      /**
       * This shouldn't be necessary in an ideal world.
       * Right now the order is copied as-is in the infoDrawer via a dispatched
       * action (OrderDetailOpen) on click, so there's no chance it will change
       * when the orders list gets updated. Here we're checking if the infoDrawer
       * is open and the currently selected order is the one we're editing notes for.
       * If so, we "re-open" the info drawer with the updated order.
       *
       * TODO: NOTES: PLEASE normalize this part and remove this ugly violation of coding's best practices.
       */
      if (
        updateInfoDrawer &&
        infoDrawerOrder &&
        infoDrawerOrder.id === orderId
      ) {
        dispatch(
          shipActions.OrderDetailOpen({
            ...infoDrawerOrder, // some parts could have changed elsewhere
            notes: {
              carrier: infoDrawerOrder?.notes?.carrier ?? null,
              marketplace: infoDrawerOrder?.notes?.marketplace ?? null,
              shippypro: note,
            },
          }),
        );
      }
    },
    [saveMutation, updateInfoDrawer, infoDrawerOrder, dispatch],
  );

  // Manage api error toastr
  useEffect(() => {
    if (data?.error) {
      showErrorToastr(t(transErr.error), data?.error);
    } else if (error) showErrorToastr(t(transErr.error), parseAPIError(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, t, transArchive.orderArchived, transErr]);

  return useMemo(
    () => ({
      saveOrderNote,
      ...results,
    }),
    [results, saveOrderNote],
  );
};
