// ########################## [IMPORTANT LIB]
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';

// ########################## [TRANSLATIONS]
import { translations } from '@shippypro/translations';
import { useTranslation } from 'react-i18next';

// ########################## [TYPES]
import { FirstLevelLink, MiddleLevelLink } from '@web/features/_global/types';

// ########################## [DESIGN SYSTEM]
import { Switch, CardWrapper } from '@shippypro/design-system-web';
import { IconHelper } from '@shippypro/design-system-web/iconhelper';
import { Color } from '@shippypro/design-system-web/enums';

// ########################## [PAGE COMPONENTS]
import { StyledSideMenu } from './styles';
import { PreviousExperienceModal } from './modals/PreviousExperienceModal';
import MenuItem from './Elements/MenuItem';
import UserMenu from './Elements/UserMenu';

// ########################## [UTILS]
import ShippyProLogo from '@web/assets/img/icons/shippypro/logo-color.png';
import ShippyProLogoLabel from '@web/assets/img/icons/shippypro/logo-label.png';
import { goToLegacy } from '@web/utils/functions';

// ########################## [HOOKS]
import useSideMenuLinks from '@web/features/_global/hooks/useSideMenuLinks';
import useLDFlag from '@web/hooks/useLDFlags';
import { useGetUser } from '@web/features/_global/hooks/api/useGetUser';

interface ISideMenuProps {
  isMobile: boolean;
  isSideMenuPinned: boolean;
  setIsSideMenuPinned: Dispatch<SetStateAction<boolean>>;

  onClick: () => void;
  children?: ReactNode;
}

/**
 * The ShippyPro side menu that contains all the platform features' links
 * At the moment, it is generated from a static JSON (menuConfig.json)
 *
 * @author [Fabio Mezzina] <fabio.mezzina@shippypro.com>
 */
const SideMenu: React.FC<ISideMenuProps> = ({
  isMobile,
  isSideMenuPinned: isPinned,
  setIsSideMenuPinned: setIsPinned,
  onClick,
}) => {
  const { uiApp, newExperienceFlow } = useLDFlag();

  const { t } = useTranslation(),
    menuTrans = translations.sideMenu;

  const navigate = useNavigate();

  const { user } = useGetUser();

  // Menu state
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const showCollapsed = isCollapsed && !isPinned && !isMobile;

  // Accordions menu state
  const [openedAccordionId, setOpenedAccordionId] = useState<string[]>([]);
  const toggleAccordion = useCallback(
    (idToOpen: string) => {
      setOpenedAccordionId(openedIds =>
        openedIds.includes(idToOpen)
          ? openedIds.filter(openedId => openedId !== idToOpen)
          : [...openedIds, idToOpen],
      );
    },
    [setOpenedAccordionId],
  ); // SHIP-1166 TODO: Close other first level dropdowns if a new first level dropdown is open

  // Close second level menu when the entire menu is collapsed
  useEffect(() => {
    showCollapsed && setOpenedAccordionId([]);
  }, [showCollapsed]);

  /**
   * Manage the menu link redirection based on the "isExternal" property
   *  true -> redirect to drupal
   *  false -> navigate to the Deliver route
   */
  const manageLink = useCallback(
    (
      link: string | null,
      isExternal: boolean | null,
      isCustom: boolean | null,
    ) => {
      if (!link) return;
      if (isExternal && !isCustom) {
        goToLegacy(link, true);
      } else if (isCustom) {
        window.open(link, '_blank');
      } else {
        navigate(link);
      }
    },
    [navigate],
  );

  /**
   * Check if the provided menu is
   * not selected (-1)
   * selected (1)
   * has a subMenu selected (0)
   */
  const isMenuSelected = useCallback(
    (menu: FirstLevelLink | MiddleLevelLink) => {
      let isSelected = -1;
      const urlPathList = new URL(window.location.href).pathname
        .split('/')
        .slice(1);

      if (menu.link) {
        if (urlPathList.includes(getMultiLevelMenuLink(menu.link)))
          isSelected = 1;
      } else if ('subMenu' in menu && menu.subMenu) {
        menu.subMenu.forEach(m => {
          if (m.link && urlPathList.includes(getMultiLevelMenuLink(m.link)))
            isSelected = 0;
        });
      }

      return isSelected;
    },
    [],
  );

  /**
   * Links can be divided in several blocks, all splitted by an `/` symbol.
   * This Function helps parse them, E.g. `Analytics/Analysis`
   */
  const getMultiLevelMenuLink = (link: string): string => {
    const splittedLink = link.split('/');

    return splittedLink.length > 1
      ? splittedLink[splittedLink.length - 1] ?? '-1'
      : splittedLink[0];
  };

  // The statically generated menu links
  const menuLinks = useSideMenuLinks();

  // state variable for the new experience toggle
  const [isDeliverSelected, setIsDeliverSelected] = useState(true);

  // User data
  const userData = {
    name: user ? user.full_name : '',
    img: user?.imageData,
    email: user ? user.email : '',
  };

  const [delayHandler, setDelayHandler] = useState<NodeJS.Timeout>();
  const handleMouseEnter = useCallback(() => {
    setDelayHandler(
      setTimeout(() => {
        setIsCollapsed(false);
      }, 350),
    );
  }, []);

  const handleMouseLeave = useCallback(() => {
    clearTimeout(delayHandler);
    setIsCollapsed(true);
  }, [delayHandler]);

  return (
    <StyledSideMenu>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={classnames(
          // z index to 1001 to always cover the banners
          'sp-side-menu absolute z-[1001] h-full overflow-y-auto overflow-x-hidden',
          // 'transition: 0.25s ease-in-out 0s',
          'transition-all ease-in-out duration-200',
          {
            'collapsed !w-[60px]': showCollapsed,
            '!w-[250px]': !isCollapsed || isMobile,
            '!w-[250px] relative mr-[-80px]': isPinned && !isMobile,
          },
        )}
        data-test="shippypro-menu"
      >
        <CardWrapper
          centered
          cardClassName="!mb-0 h-full rounded-none bg-primary"
          bodyClassName={classnames('h-full', {
            '!p-0': isCollapsed,
            '!px-[5px] !py-0': !isCollapsed || isPinned,
          })}
          wrapperClassName="app-card h-full d-flex flex-column justify-start"
        >
          {/* Navbar upper part */}
          <div>
            {/* Logo + ShippyPro + Pin menu */}
            <div
              className={classnames('flex items-center', {
                'justify-center': showCollapsed,
              })}
            >
              <img
                data-test="menu-logo"
                src={ShippyProLogo}
                width="50"
                height="50"
                alt="ShippyPro"
              />{' '}
              {/* Show logo label only when non collapsed */}
              {showCollapsed || (
                <span className="flex items-center justify-between w-full">
                  <img
                    data-test="menu-logo-label"
                    src={ShippyProLogoLabel}
                    width="100"
                    height="100"
                    alt="ShippyPro"
                    className="ml-[11px] mt-[5px]"
                  />
                  {/* Mobile: Close CTA | Non-Mobile: Pin menu CTA */}
                  {isMobile ? (
                    <IconHelper
                      icon="IconX"
                      size={17}
                      onClick={() => onClick()}
                      className="cursor-pointer"
                      data-test="menu-close-mobile"
                    />
                  ) : (
                    <IconHelper
                      icon={isPinned ? 'IconCircleDot' : 'IconCircle'}
                      size={17}
                      onClick={() => setIsPinned(p => !p)}
                      className="cursor-pointer"
                      data-test="menu-pinned"
                    />
                  )}
                </span>
              )}
            </div>

            {/* Switch Platform Toggle */}
            {!uiApp && !newExperienceFlow && (
              <div
                className={classnames(
                  'category-label text-muted text-left text-sm my-1',
                  {
                    '!ml-0 text-center !text-xl': showCollapsed,
                  },
                )}
                data-test="new-experience-switch"
              >
                {showCollapsed ? (
                  '...'
                ) : (
                  <div className="flex items-center gap-1 w-full !mb-0">
                    <Switch
                      color={Color.primary}
                      type="switch"
                      name="new-experience-toggle"
                      className="dark-form-check"
                      onClick={() => setIsDeliverSelected(v => !v)}
                      checked={isDeliverSelected}
                    />
                    <p className="font-bold text-[15px] text-white">
                      {t(menuTrans.newExperience)}
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>

          <div
            id="sidemenuscroll-content"
            className="hover:overflow-y-auto overflow-x-hidden"
          >
            {/* Generated Menu */}
            {menuLinks.map((list, i) => (
              <MenuItem
                key={i}
                list={list}
                i={i}
                showCollapsed={showCollapsed}
                openedAccordionIds={openedAccordionId}
                toggleAccordion={toggleAccordion}
                manageLink={manageLink}
                isMenuSelected={isMenuSelected}
              ></MenuItem>
            ))}
          </div>

          {/* Navbar lower part */}
          {!isMobile && (
            <UserMenu
              showCollapsed={showCollapsed}
              openedAccordionIds={openedAccordionId}
              toggleAccordion={toggleAccordion}
              manageLink={manageLink}
              isMenuSelected={isMenuSelected}
              email={userData.email}
              name={userData.name}
              img={userData.img}
            />
          )}
        </CardWrapper>
      </div>
      <PreviousExperienceModal
        isOpen={!isDeliverSelected}
        onClose={() => setIsDeliverSelected(true)}
      />
      {/* Mobile overlay - on click, closes the side menu */}
      {isMobile && (
        <div
          className="sp-side-menu-overlay z-40 w-screen h-screen absolute"
          onClick={() => onClick()}
        ></div>
      )}
    </StyledSideMenu>
  );
};

export default SideMenu;
