// This is the ONLY folder where importing straight from the Tabler library is allowed
// eslint-disable-next-line no-restricted-imports
import * as TablerIcon from '@tabler/icons-react';
import * as ShippyProIcons from './Shippypro';

import classnames from 'classnames';

import { Size } from '../enums';

import { IIconHelperProps } from './types';

/**
 * A special component that can search through the entire library of
 * available icons and display the correct one on screen.
 *
 * @interface IIconHelperProps
 *
 * @example
 * <IconHelper icon="IconBrandInstagram" size={Size.lg} className="text-primary" />
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const IconHelper = ({
  icon = 'IconCircle',
  size = Size.md,
  stroke = 1.5,
  className = '',
  ...rest
}: IIconHelperProps) => {
  // Finding the icon in the TablerIcon/ShippyPro libraries
  const FoundIconComponent = TablerIcon[icon] || ShippyProIcons[icon];

  // Setting the size and stroke of the icon based on the passed props
  let iconSize = typeof size === 'number' ? size : 18,
    iconStroke = stroke;

  switch (size) {
    case Size.sm:
      iconSize = 14;
      iconStroke = 1.2;
      break;
    case Size.lg:
      iconSize = 20;
      iconStroke = 1.5;
      break;
  }

  // If the icon is found, render it, otherwise render a default one from Tabler (circle)
  return FoundIconComponent ? (
    <FoundIconComponent
      {...rest}
      size={iconSize}
      icon={icon}
      stroke={iconStroke}
      className={classnames('outline-none', className)}
    />
  ) : (
    <TablerIcon.IconCircle {...rest} size={iconSize} stroke={iconStroke} />
  );
};
