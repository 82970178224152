import { memo, ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';

import { toCapital } from '@shippypro/design-system-web/functions';

export interface ISeoProps {
  title?: string;
  page: string;
  children?: ReactNode;
  hasBreadcrumb?: boolean;
}

const Seo: React.FC<ISeoProps> = memo(
  ({ title, page, children }: ISeoProps) => {
    return (
      <>
        <Helmet defer={false}>
          {title && <title>{title}</title>}
          <meta
            name="description"
            content={`Description of ${toCapital(page)}`}
          />
          <meta name="Document-Policy" content="js-profiling" />
        </Helmet>
        <div className="site-content" />
        {children}
      </>
    );
  },
);

export default Seo;
