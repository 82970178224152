import { FC } from 'react';

import { CardBody as ReactstrapCardBody } from 'reactstrap';

import { ICardBodyProps } from './types';

/**
 * A simple wrapper class for the `reactstrap: <CardBody />` component.
 *
 * @interface ICardBodyProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const CardBody: FC<ICardBodyProps> = props => (
  <ReactstrapCardBody {...props} />
);
