import { Dispatch, SetStateAction } from 'react';

import { ScrollToOptions } from '@tanstack/react-virtual';
import { ErrorRate, Order, Rate } from '@web/types/order';
import { Address } from '@web/types/common/address';
import { CarrierRate, OrderCarrierRates } from './order';

export type VirtualisationIndexScrollingObject = {
  setVisibleIndex: VirtualisationScrollFunction;
  visibleIndex: number;
};

export type VirtualisationIndexScrollingHandler = Dispatch<
  SetStateAction<VirtualisationIndexScrollingObject | undefined>
>;

export type VirtualisationScrollFunction = (
  index: number,
  opts?: ScrollToOptions,
) => void;

// This type is required to prepare all data for bulking carrier options, preparing data in advance then creating promise based on that input
// After that we can use the promises result to update rates cycling through array of above type previously created
export type CarrierOptionsBulkPayloadPreparationRequiredData = {
  order: Order;
  toAddress: Address;
  selectedRate: CarrierRate<Rate>;
  lastSelectedRate: CarrierRate<Rate> | CarrierRate<ErrorRate> | undefined;
  previouslySelectedRate: Rate | undefined;
  parsedRates: OrderCarrierRates;
};
