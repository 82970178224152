import styled from 'styled-components';

export const StyledPace = styled.div`
  z-index: 999;
  left: 0;
  top: 0;

  min-height: 4px;
  max-height: 4px;
  height: 100%;

  pointer-events: none;

  transition:
    width 0.4s ease-in-out,
    opacity 0.5s ease-in-out;
  transition-delay: 0.3s;
`;
