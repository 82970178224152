import { FC } from 'react';
import classnames from 'classnames';

import { FormText } from 'reactstrap';

import { IWarningText } from './types';
import { IconHelper } from '@ds-web-iconhelper';

/**
 * A simple helper text for form fields
 *
 * @interface IWarningText
 * @author Fabio Mezzina <fabio.mezzina@shippypro.com>
 */
export const WarningText: FC<IWarningText> = ({
  children,
  props: { className, ...props } = {},
}) => {
  return (
    <FormText
      className={classnames(
        'warning-text !text-[color:--shp-color-text-helper] flex items-center !gap-1 !p-1',
        className,
      )}
      {...props}
    >
      <IconHelper
        icon="IconAlertTriangleFilled"
        className="text-warning"
        size={14}
      />
      {children}
    </FormText>
  );
};
