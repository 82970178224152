// ########################## [IMPORTANT LIBRARIES]
import { useCallback, useMemo } from 'react';
import classnames from 'classnames';
import ReactCountryFlag from 'react-country-flag';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

// ########################## [TRANSLATIONS]
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [DESIGN SYSTEM]
import {
  PopoverBody,
  PopoverHeader,
  TooltipWrapperByID,
  CopyButton,
  MailToButton,
  TooltipWrapperByREF,
  HoverableItemWithCopy,
  Badge,
} from '@shippypro/design-system-web';
import { Color } from '@shippypro/design-system-web/enums';
import { IconHelper } from '@shippypro/design-system-web/iconhelper';

// ########################## [TYPES]
import { Order } from '@web/types/order';
import { Address } from '@web/types/common/address';
import { RowDensity } from '@web/features/ship/types';

// ########################## [UTILS]
import { getOrderAddressType } from '@web/hooks/orders/useGetOrderAddressType';

// ########################## [PAGE COMPONENTS]
import { TextWithCustomHover } from '@web/components/common-ui/tables/columns';

// ########################## [HOOKS]
import useGetTabColumnSize from '@web/features/ship/hooks/tabs/useGetTabColumnSize';

const columnHelper = createColumnHelper<Order>();

export const CustomerColumnCode = 'customer';

export const useGetCustomerColumn = (
  rowDensity: RowDensity | null,
): ColumnDef<Order, string> => {
  const { t } = useTranslation(),
    transToShipTable = translations.ship.table,
    transToShip = transToShipTable.columns;

  const size = useGetTabColumnSize(CustomerColumnCode, 150);

  const getCountryFlag = useCallback(
    (address: Address) => (
      <TooltipWrapperByREF
        fullWidth={false}
        content={address.country as string}
      >
        <ReactCountryFlag
          className="!w-6 !h-6 pr-1"
          countryCode={address.country as string}
          title={address.country as string}
          svg
          loading="lazy"
        />
      </TooltipWrapperByREF>
    ),
    [],
  );

  return useMemo(
    () =>
      columnHelper.accessor('address.name', {
        header: t(transToShip.customer),
        minSize: 150,
        size,
        maxSize: 400,
        id: 'customer',
        enableSorting: true,
        cell: ({ row: { original, getIsExpanded } }) =>
          customerColumnRendererFn(
            t,
            getIsExpanded() ? RowDensity.S : rowDensity,
            getCountryFlag,
            original,
          ),
      }),
    [t, transToShip.customer, size, rowDensity, getCountryFlag],
  );
};

export const customerColumnRendererFn = (
  t: TFunction<'translation', undefined>,
  rowDensity: RowDensity | null,
  getCountryFlag: (address: Address) => JSX.Element,
  order: Order,
) => {
  const transToShipTable = translations.ship.table,
    transToShip = transToShipTable.columns,
    transForm = translations.common.form,
    transPopovers = transToShip.popovers,
    transOrder = translations.order.info,
    transShipInfo = translations.order.shipmentInfo;

  const { id, address } = order;

  const target = `order-customer-${id}`;

  const fullAddress = [
    [address.street1, address.street2].filter(Boolean).join(', '),
    [address.zip, address.city].filter(Boolean).join(' - '),
    [address.state, address.country].filter(Boolean).join(', '),
  ].filter(Boolean);

  const summaryAddress = [
    [address.zip, address.city].filter(Boolean).join(' '),
    [address.country].filter(Boolean).join(' - '),
  ].filter(Boolean);

  const addressType = getOrderAddressType(order);

  return (
    // prevent propagation to row onClick handler
    <span onClick={e => e.stopPropagation()} className="max-w-full">
      <TextWithCustomHover
        label={
          <div
            className={classnames('flex items-start', {
              'flex-col': rowDensity !== RowDensity.S,
              '!gap-2': rowDensity === RowDensity.S,
            })}
          >
            {rowDensity === RowDensity.S && getCountryFlag(address)}
            <TooltipWrapperByID
              tooltipProps={{
                placement: 'bottom',
              }}
              target={`${target}-name`}
              text={address.name}
              checkOverflow
            >
              <span className="text-[--shp-color-text-tab-title] font-bold max-w-full truncate">
                {address.name}
              </span>
            </TooltipWrapperByID>
            {rowDensity !== RowDensity.S && (
              <div
                className="flex items-center text-truncate"
                style={{ width: '-webkit-fill-available' }}
              >
                <span className="shrink-0">{getCountryFlag(address)}</span>
                <TooltipWrapperByID
                  tooltipProps={{
                    placement: 'bottom',
                  }}
                  target={`${target}-summaryaddress`}
                  text={fullAddress.join(' ')}
                  checkOverflow
                >
                  <span className="summary-address font-normal text-[color:--shp-color-text-table-header] max-w-full truncate">
                    {summaryAddress.join(', ')}
                  </span>
                </TooltipWrapperByID>
              </div>
            )}
          </div>
        }
        target={target}
        type="popover"
        fitParent
      >
        <PopoverHeader className="flex !gap-2 items-center">
          <span>{t(transPopovers.customerDetails)}</span>
          {addressType && (
            <Badge
              className="flex items-center !gap-1"
              color={Color.accent}
              pill
            >
              <IconHelper icon={addressType.icon} stroke={1} size={20} />
              <p className="leading-[0]">
                {t(transShipInfo.addressType[addressType.code])}
              </p>
            </Badge>
          )}
        </PopoverHeader>
        <PopoverBody>
          <div className="w-full flex flex-col space-y-2">
            {address.company && (
              <div className="w-full">
                <p className="text-muted m-0">{t(transForm.companyName)}</p>
                <HoverableItemWithCopy textToCopy={address.company}>
                  {address.company}
                </HoverableItemWithCopy>
              </div>
            )}

            <div className="w-full">
              <p className="text-muted m-0">{t(transForm.address)}</p>
              <HoverableItemWithCopy textToCopy={fullAddress.join(' ')}>
                <div className="truncate">
                  {fullAddress.map((addressPart, i) => (
                    <div className="truncate" key={i}>
                      {addressPart}
                    </div>
                  ))}
                </div>
              </HoverableItemWithCopy>
            </div>

            <div className="w-full">
              <p className="text-muted m-0">{t(transPopovers.phoneNumber)}</p>
              <HoverableItemWithCopy textToCopy={address.phone}>
                {address.phone ?? (
                  <span className="text-muted">
                    {t(transOrder.phoneMissing)}
                  </span>
                )}
              </HoverableItemWithCopy>
            </div>

            <div className="w-full">
              <p className="text-muted m-0">{t(transPopovers.emailAddress)}</p>
              <HoverableItemWithCopy
                textToCopy={address.email}
                reveals={
                  <div className="flex space-x-1">
                    <MailToButton email={address.email} />
                    <CopyButton value={address.email} />
                  </div>
                }
              >
                {address.email ?? (
                  <span className="text-muted">
                    {t(transOrder.emailMissing)}
                  </span>
                )}
              </HoverableItemWithCopy>
            </div>
          </div>
        </PopoverBody>
      </TextWithCustomHover>
    </span>
  );
};
