import { FC, PropsWithChildren } from 'react';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import { TooltipWrapperByID } from '@shippypro/design-system-web';

import { Item } from '@web/types/order';

import ImagePreview from '@web/components/common-ui/images/image-preview';

import {
  toCurrencySymbol,
  toLocalizedPrice,
} from '@shippypro/design-system-web/functions';

export interface OrderItemListItemProps extends PropsWithChildren {
  item: Item;
  currency: string;
  showZoomOverlay?: boolean;
  showPrice?: boolean;
}

/**
 * This component is rendered as the expandible part of an order row
 * @author Fabio Fognani<fabio.fognani@shippypro.com>
 */
export const OrderItemListItem: FC<OrderItemListItemProps> = ({
  item,
  currency,
  showZoomOverlay,
  showPrice = true,
  children,
}) => {
  const { t } = useTranslation(),
    transCommon = translations.common,
    transItem = translations.ship.table.items;

  const imgSrc = item.image_url;
  return (
    <div data-test="order-item-list-item" className="flex gap-1 mb-1">
      <div className="flex flex-column items-center">
        <ImagePreview
          src={imgSrc}
          imgPopoverProps={{ alt: item.description }}
          showZoomOverlay={showZoomOverlay}
        />
        <p className="text-secondary font-medium">
          {t(transCommon.quantityShort)} {item.quantity}
        </p>
      </div>
      <div className="flex justify-between items-start gap-1 w-full truncate">
        <div className="flex flex-column space-y-1 w-full truncate">
          <TooltipWrapperByID
            target={`item-id-${item.id}`}
            text={item.description}
            checkOverflow
          >
            <h4 className="font-medium text-[color:--shp-color-text-title] whitespace-pre-wrap">
              {item.description}
            </h4>
          </TooltipWrapperByID>
          <div className="text-sm truncate text-[color:--shp-color-text-label]">
            {item.asin && (
              <span className="d-block">
                {t(transItem.asin)}: {item.asin}
              </span>
            )}
            {item.ean && (
              <span className="d-block">
                {t(transItem.ean)}: {item.ean}
              </span>
            )}
            {item.sku && (
              <span className="d-block">
                {t(transItem.sku)}: {item.sku}
              </span>
            )}
          </div>
        </div>
        {showPrice && (
          <div>
            <h4 className="font-medium text-[color:--shp-color-text-label] text-right whitespace-nowrap">
              {toLocalizedPrice(
                toCurrencySymbol(currency),
                !!item.price ? +item.price : 0,
              )}
            </h4>
          </div>
        )}
        {children}
      </div>
    </div>
  );
};
