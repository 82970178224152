import { CSSProperties } from 'react';
import classnames from 'classnames';
import { Row, flexRender } from '@tanstack/react-table';

import Expansion from '../handlers/Expansion';
import TableMarks from './Marks';

import { IReactTableProps } from '@ds-web/components/organisms/tables/react-table/types';

export type TableRowProps<T extends object> = {
  row: Row<T>;
  tableProps: IReactTableProps<T>;
  rowStyle: CSSProperties;
};

/**
 * This component is the UI representation of a `react-table` row.
 * It displays the `data` list into an actual table rows layout and manages:
 * * Row Selection (w/ checkbox);
 * * Row Expansion (w/ icon);
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 *
 * @returns {FC} The `React Table` row, fully rendered & managed.
 */
function TableRow<T extends object>({
  row,
  tableProps,
  rowStyle,
}: TableRowProps<T>) {
  const {
    getVisibleCells,
    getIsExpanded,
    getIsSelected,
    getToggleExpandedHandler,
    subRows,
  } = row;

  const { customComponents } = tableProps;

  return (
    <tr
      className={classnames(
        'react-table-body-row rdt_TableRow',
        /* Applying custom classNames for a certain row (IF configured)
          In case of background color rules, set the haveHighlightedRow params
          in VirtualLayout to prevent background color mismatching */
        customComponents?.getCustomTrClassName &&
          customComponents?.getCustomTrClassName(row),
        {
          // Giving the right class to a highlighted row (IF configured)
          'react-table-body-row-highlighted':
            customComponents?.highlight?.id === row.id,
          'react-table-body-row-selected': getIsSelected(),
        },
      )}
      onClick={e =>
        // Highlighting a clicked row (IF configured)
        customComponents?.setHighlight && customComponents?.setHighlight(row, e)
      }
      style={rowStyle}
      {...(customComponents?.getCustomTrProps &&
        customComponents?.getCustomTrProps(row))}
    >
      <TableMarks row={row} tableProps={tableProps} rowStyle={rowStyle} />
      {getVisibleCells().map(cell => {
        return (
          <td
            key={cell.id}
            className={`react-table-body-row-cell body-row-cell--${cell.id} rdt_TableCell`}
            {...(customComponents?.getCustomTdProps &&
              customComponents?.getCustomTdProps(cell))}
            {...{
              style: {
                width: cell.column.getSize(),
              },
            }}
          >
            {cell.getIsGrouped() ? (
              // If it's a grouped cell, add an expander and row count
              <section className="react-table-body-row-cell-group">
                <Expansion
                  toggleExpandedHandler={getToggleExpandedHandler()}
                  isExpanded={getIsExpanded()}
                />{' '}
                {flexRender(cell.column.columnDef.cell, cell.getContext())}{' '}
                <span className="react-table-body-row-cell-group-aggregationcount">
                  ({subRows.length})
                </span>
              </section>
            ) : cell.getIsAggregated() ? (
              // If the cell is aggregated, use the Aggregated renderer for cell
              flexRender(
                cell.column.columnDef.aggregatedCell ??
                  cell.column.columnDef.cell,
                cell.getContext(),
              )
            ) : cell.getIsPlaceholder() ? null : (
              // For cells with repeated values, render null (^)
              // Otherwise, just render the regular cell
              flexRender(cell.column.columnDef.cell, cell.getContext())
            )}
          </td>
        );
      })}
    </tr>
  );
}

export default TableRow;
