import { FC, memo } from 'react';
import { Row, Col } from 'reactstrap';

import {
  GLSFormatCode,
  GLSFormatOption,
  GLSFormatValue,
} from '@web/features/ship/components/dialogs/glsitaly-printformat-picker/types';

import FormatCard from './FormatCard';

interface IFormatsListProps {
  ids?: number[];
  glsformat?: GLSFormatValue;
  onFormatClick: (format: GLSFormatValue) => void;
}

/**
 * This component holds the full list of every configured GLS Italy Label Format.
 * It prepares the modular array (numbers from 0 to 5, -1 being `NONE`) and displays
 * them as needed (1 ord. selected = 5 formats, > 1 ord. selected = 6 formats).
 *
 * @interface IFormatsListProps
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const FormatsList: FC<IFormatsListProps> = memo(
  ({ ids = [], glsformat = -1, onFormatClick }) => {
    // Creating every GLS Italy Format (from value 0 to 5)
    const formatsList: GLSFormatOption[] = Object.keys(GLSFormatCode)
      .filter(key => !isNaN(Number(key)))
      .map(key => {
        const code = GLSFormatCode[key as keyof typeof GLSFormatCode];
        const format = parseInt(key) as GLSFormatValue;

        return { code, format };
      });

    return (
      <section id="format-list">
        <Row>
          {formatsList.map(({ code, format }) => (
            <Col key={format} xs="12" sm="12" md="6" lg="6" xl="4">
              <FormatCard
                glsFormatValue={format}
                glsFormatCode={code}
                onClick={onFormatClick}
                selected={format === glsformat}
              />
            </Col>
          ))}
        </Row>
      </section>
    );
  },
);

export default FormatsList;
