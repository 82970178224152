// ########################## [IMPORTANT LIBRARIES]
import { useMemo } from 'react';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [TYPES]
import { Order } from '@web/types/order';
import { RowDensity } from '@web/features/ship/types';

// ########################## [PAGE COMPONENTS]
import DocumentationIcon from '@web/features/ship/components/tables/blocks/DocumentationIcon';

// ########################## [HOOKS]
import useGetTabColumnSize from '@web/features/ship/hooks/tabs/useGetTabColumnSize';

const columnHelper = createColumnHelper<Order>();

export const DocumentationColumnCode = 'documentation';

export const useGetDocumentationColumn = (
  rowDensity: RowDensity | null,
  handleDocs: (order: Order) => void,
): ColumnDef<Order> => {
  const { t } = useTranslation(),
    transToShipTable = translations.ship.table,
    transToShip = transToShipTable.columns;

  const size = useGetTabColumnSize(DocumentationColumnCode, 150);

  return useMemo(
    () =>
      columnHelper.display({
        header: t(transToShip.documentation),
        id: 'documentation',
        enableSorting: false,
        minSize: 150,
        size,
        maxSize: 200,
        cell: ({ row: { original, getIsExpanded } }) =>
          documentationColumnRendererFn(
            getIsExpanded() ? RowDensity.S : rowDensity,
            handleDocs,
            original,
          ),
      }),
    [t, rowDensity, handleDocs, transToShip, size],
  );
};

const documentationColumnRendererFn = (
  rowDensity: RowDensity | null,
  handleDocs: (order: Order) => void,
  order: Order,
) => {
  return (
    // prevent propagation to row onClick handler
    <span className="w-full" onClick={e => e.stopPropagation()}>
      <DocumentationIcon
        order={order}
        compactView={rowDensity === RowDensity.S}
        handleDocs={handleDocs}
      />
    </span>
  );
};
