import { ChangeEvent, FC } from 'react';

import { IFileUploadButton } from './types';

import { IconHelper } from '@ds-web-iconhelper';
import { Button } from '@ds-web/components/atoms/ui/buttons/button/Button';
import { Color, StyleType } from '@ds-web/tokens/enums';

/**
 * A icon + text component that manage the upload of one or multiple files
 * The upload function should be passed
 *
 * @param label The text label to show
 * @param onUpload The upload function
 *
 * @author Fabio Mezzina <fabio.mezzina@shippypro.com>
 */
export const FileUploadButton: FC<IFileUploadButton> = ({
  label,
  onUpload,
}) => {
  // Handles the selected files and creates a File[] array to be managed from the onUpload function
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const fileArray: File[] = new Array<File>();

      const length = e.target.files.length;
      let i = 0;
      while (i < length) {
        fileArray.push(e.target.files[i]);
        i++;
      }

      onUpload(fileArray);
    }
  };

  const uniqueFileId = `file ${Date.now()}`;

  return (
    <div
      className="flex justify-around gap-[0.5rem] cursor-pointer text-primary"
      data-test="add-doc-text"
    >
      <Button
        color={Color.primary}
        styleType={StyleType.flat}
        className="flex !gap-2 items-center font-semibold !p-0"
        data-test="add-parcel-btn"
      >
        <label
          className="flex gap-1 cursor-pointer !py-[0.786rem] !px-[1.5rem]"
          htmlFor={uniqueFileId}
        >
          {' '}
          <IconHelper icon="IconUpload" size={18} /> {label}
        </label>
        <input
          type="file"
          name="file"
          id={uniqueFileId}
          accept=".pdf"
          hidden
          onChange={handleFileChange}
          multiple
        />
      </Button>
    </div>
  );
};
