import styled from 'styled-components';

import { IMutedTextWithDescriptionProps } from './types';

/**
 * A set of two elements:
 * - a muted label or title
 * - a description text, truncated to 2 lines
 *
 * @interface IMutedTextWithDescriptionProps
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
export const MutedTextWithDescription: React.FC<
  IMutedTextWithDescriptionProps
> = ({ text, description }) => {
  return (
    <>
      <StyledSmallMutedText>{text}</StyledSmallMutedText>
      <StyledDescriptionBox>{description}</StyledDescriptionBox>
    </>
  );
};

export const StyledSmallMutedText = styled.p`
  font-size: 0.857rem;
  letter-spacing: 0.15px;
  color: var(--shp-color-text-label);
`;

export const StyledDescriptionBox = styled.div`
  font-weight: 500;
  color: var(--shp-color-text-body);

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
`;
