// ########################## [IMPORTANT LIBRARIES]
import { useMemo } from 'react';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';
import { TFunction } from 'i18next';

// ########################## [TYPES]
import { Order } from '@web/types/order';
import { RowDensity } from '@web/features/ship/types';

// ########################## [COMPONENTS]
import TrackingCodeText from '@web/components/common-features/order/TrackingCodeText';
import {
  getTrackingStatusIcon,
  getTrackingStatusLabel,
} from '@web/hooks/orders/useGetTrackingStatusIconAndLabel';

// ########################## [HOOKS]
import useGetTabColumnSize from '@web/features/ship/hooks/tabs/useGetTabColumnSize';

const columnHelper = createColumnHelper<Order>();

export const TrackingCodeColumnCode = 'trackingCode';

/**
 * Displays the Tracking Code of the order, that opens a handy timeline modal on click
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const useGetTrackingCodeColumn = (
  rowDensity: RowDensity | null,
): ColumnDef<Order, string> => {
  const { t } = useTranslation();

  const size = useGetTabColumnSize(TrackingCodeColumnCode, 150);

  return useMemo(
    () =>
      columnHelper.accessor('shipment.tracking_code', {
        header: t(translations.ship.table.columns.trackingCode),
        id: 'trackingCode',
        enableSorting: true,
        minSize: 150,
        size,
        maxSize: 400,
        cell: ({ row: { original, getIsExpanded } }) =>
          trackingCodeColumnRenderedFn(
            t,
            getIsExpanded() ? RowDensity.S : rowDensity,
            original,
          ),
      }),
    [t, rowDensity, size],
  );
};

const trackingCodeColumnRenderedFn = (
  t: TFunction,
  rowDensity: RowDensity | null,
  order: Order,
) => {
  const trackingLabel = getTrackingStatusIcon(order.shipment?.tracking_status);
  const trackingStatus = getTrackingStatusLabel(
    t,
    order.shipment?.tracking_status,
  );

  return (
    <TrackingCodeText
      order={order}
      trackingCode={order.shipment?.tracking_code}
      trackingLabel={trackingLabel}
      trackingStatus={trackingStatus}
      showTrackingStatus={rowDensity !== RowDensity.S}
    />
  );
};
