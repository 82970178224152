// ########################## [IMPORTANT LIBRARIES]
import { useCallback, useMemo } from 'react';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [DESIGN SYSTEM]
import {
  showSecondaryToastr,
  showWarningToastr,
} from '@shippypro/design-system-web/functions';

// ########################## [TYPES]
import {
  CreateDocumentArchivePayload,
  CreateDocumentArchiveResult,
} from '@web/features/ship/types';

// ########################## [UTILS]
import { handleAPIError } from '@web/utils/@reduxjs/rtk-query';
import { base64ToZip } from '@web/utils/functions';

// ########################## [HOOKS]
import { useCreateDocumentArchiveMutation } from '@web/features/ship/api';

/**
 * Returns a `useCreateDocumentArchiveMutation` RTK Query hook with its action field renamed.
 * This hooks creates a Archive document file .zip e for selected order.
 * Depending on the result of the API call, the user will be notified and, when everything
 * has completed successfully, will be able to download the file.
 *
 * @author Fortunato De Angelis <fortunato.deangelis@shippypro.com>
 */
export const useCreateDocumentArchive = () => {
  const { t } = useTranslation(),
    trans = translations.common;

  const [mutate, { ...results }] = useCreateDocumentArchiveMutation();

  const createDocumentArchive = useCallback(
    async (payload: CreateDocumentArchivePayload) => {
      const result = await mutate(payload);
      const data = await handleAPIError<CreateDocumentArchiveResult>(result);
      /* istanbul ignore if */
      if (!data) return;

      /* istanbul ignore else */
      if (data.base64file) {
        base64ToZip(data.base64file, data.filename);
        showSecondaryToastr(t(trans.fileDownloadSuccess));
      } else {
        showWarningToastr(t(trans.errors.error), t(trans.errors.noContent));
      }
    },
    [
      mutate,
      t,
      trans.errors.error,
      trans.errors.noContent,
      trans.fileDownloadSuccess,
    ],
  );

  return useMemo(
    () => ({
      createDocumentArchive,
      ...results,
    }),
    [createDocumentArchive, results],
  );
};
