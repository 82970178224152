import { FC } from 'react';
import { ModalHeader as ReactstrapModalHeader } from 'reactstrap';
import classnames from 'classnames';

import { Color } from '@ds-web/tokens/enums';

import { IModalHeaderProps } from './types';

/**
 * A simple wrapper class for the `reactstrap: <ModalHeader />` component.
 *
 * @interface IModalHeaderProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const ModalHeader: FC<IModalHeaderProps> = ({
  className,
  color,
  ...rest
}) => (
  <ReactstrapModalHeader
    className={classnames(className, {
      [`bg-${color}`]: color && color !== Color.none,
    })}
    {...rest}
  />
);
