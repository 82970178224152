import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import { Button } from '@shippypro/design-system-web';
import { Color } from '@shippypro/design-system-web/enums';

import { formatDate } from '@web/utils/@date-fns/dates';

import type { Order } from '@web/types/order';

interface IOrderTimelineProps {
  date?: number | null;
  translation: string;
  row: Order;
  handleClick: (row: Order, e: React.MouseEvent, scroll?: string) => void;
}

const OrderTimeline: React.FC<IOrderTimelineProps> = ({
  date,
  translation,
  row,
  handleClick,
}) => {
  const { t } = useTranslation(),
    transPopovers = translations.ship.table.columns.popovers,
    transOrder = translations.order;

  return (
    <div data-test="data-table-popover">
      <div className="flex flex-col w-full">
        <p className="m-0">{t(translation)}</p>
        <p className="m-0">
          {date ? formatDate(date, 'dd/MM/yyyy') : t(transOrder.info.noDate)}
        </p>
      </div>
      <div className="flex w-full justify-end mt-1">
        <Button
          color={Color.primary}
          onClick={e => {
            handleClick(row, e, '.DrawerView-show ul.timeline');
            // this should be innocuous, it just closes the popover
            document.dispatchEvent(new Event('mousedown'));
          }}
        >
          {t(transPopovers.showTimeline)}
        </Button>
      </div>
    </div>
  );
};

export default OrderTimeline;
