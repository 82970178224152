import { BaseColor } from '@ds-web/tokens/enums/color';

/**
 * Calculates the avatar data from the full name of the user,
 * Specifically, it takes the first letter of the name and surname to make the avatar content,
 * and it takes the sum of the char codes of the full name to pick a color from the `BaseColor` enum.
 *
 * @param {string} fullName The full name of the user
 * @returns {{ content: string, color: BaseColor }} The content and color of the avatar
 */
export const getAvatarData = (fullName: string, darkBg: boolean = false) => {
  const hasSpace = fullName.indexOf(' ') !== -1;

  const [name, surname] = hasSpace
    ? fullName.split(' ')
    : (fullName + ' ').split(' ');
  const prefix = name[0] + (surname ? surname[0] : name[1]);

  const colors = Object.keys(BaseColor).filter(c =>
    darkBg ? c !== BaseColor.primary : true,
  );
  const pick =
    Array.from(fullName)
      .map(x => x.charCodeAt(0))
      .reduce((x, y) => x + y) % colors.length;
  const color = BaseColor[colors[pick]];

  return { content: prefix, color: color as BaseColor };
};
