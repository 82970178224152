import { FC, useState } from 'react';
import classnames from 'classnames';
import { Spinner } from 'reactstrap';

import { IconHelper } from '@ds-web-iconhelper';

import { IImageWithOverlayAndStatusProps } from './types';

export const ImageWithOverlayAndStatus: FC<IImageWithOverlayAndStatusProps> = ({
  img,
  alt,
  status = true,
  allowEvents = true,
  onHoverIcon,
  onClick,
  loading,
  pictureProps,
  hideOverlay,
  hideStatus = false,
}) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <picture
      className={classnames('flex-none mr-4 avatar', {
        'cursor-default': hideOverlay,
      })}
      onMouseEnter={() => setIsHovering(Boolean(onClick))}
      onMouseLeave={() => setIsHovering(false)}
      onClick={onClick}
      {...pictureProps}
    >
      <img
        src={img}
        alt={alt ?? ''}
        className="w-10 h-10 !rounded-md bg-white border"
        data-tag={allowEvents && 'allowRowEvents'}
        loading="lazy"
      />
      {(isHovering || loading) && !hideOverlay && (
        <div
          className="
              absolute
              flex justify-center items-center 
              w-full h-full
              bg-black opacity-60 
              z-5
              rounded-md
            "
          data-test="update-mrk-avatar"
        />
      )}

      {isHovering && !loading && !hideOverlay && (
        <span
          className="
              absolute
              flex justify-center items-center
              w-full h-full
              z-10
              rounded-md
            "
        >
          {onHoverIcon || (
            <IconHelper icon="IconRefresh" size={20} color="white" />
          )}
        </span>
      )}

      {loading && (
        <span
          className="
              absolute
              flex justify-center items-center
              w-full h-full
              z-20
              rounded-md
            "
        >
          <Spinner color="white" />
        </span>
      )}

      {!hideStatus && (
        <span
          className={classnames(
            '!w-[12px] !h-[12px] !right-[-2px] !bottom-[-2px] z-10',
            {
              'avatar-status-busy': !status,
              'avatar-status-online': status,
            },
          )}
        />
      )}
    </picture>
  );
};
