import { FC } from 'react';
import { PopoverBody as ReactstrapPopoverBody } from 'reactstrap';

import { IPopoverBodyProps } from './types';

/**
 * A simple wrapper class for the `reactstrap: <PopoverBody />` component.
 *
 * @interface IPopoverBodyProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const PopoverBody: FC<IPopoverBodyProps> = props => (
  <ReactstrapPopoverBody {...props} />
);
