/**
 * This function waits for an element to load, handy when trying to
 * get a pure JS element while the page is loading content asynchronously
 * using RTK Query or other asynchronous methods.
 *
 * @param selector {string} The selector to look for
 */
export function waitForElem(selector: string) {
  return new Promise(resolve => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(mutations => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}
