import {
  isDevelopEnv,
  isProductionEnv,
  isTestingEnv,
} from '@web/utils/functions/session';

import { MicroserviceNames } from './types';

/**
 * Returns the right url configuration for a specific Microservice, depending on the current environment.
 * Will error out if an unknown Microservice is passed or has not been configured yet.
 *
 * @param {MicroserviceNames} name The name of the Microservice
 * @returns {string} The URL of the Microservice
 */
export const getMicroserviceUrl = (name: MicroserviceNames) => {
  const isProduction = isProductionEnv();
  const isTesting = isTestingEnv() || isDevelopEnv();

  switch (name) {
    case MicroserviceNames.ORDERS_SERVICE:
      return isProduction
        ? 'https://order.services.shippypro.com/api/v1'
        : isTesting
          ? 'https://order.services.shp-dev.com/api/v1'
          : 'http://localhost:8000/api/v1';
    case MicroserviceNames.PRIVACY_ENGINE:
      return isProduction
        ? 'https://privacy.services.shippypro.com/api/v1'
        : isTesting
          ? 'https://privacy.services.shp-dev.com/api/v1'
          : 'http://localhost:8000/api/v1';
    case MicroserviceNames.USER_ENGINE:
      return isProduction
        ? 'https://user.services.shippypro.com/api/v1'
        : isTesting
          ? 'https://user.services.shp-dev.com/api/v1'
          : // Commented the local version as RTK Query has a bug that infinite-reloads the page
            // if the service is not reachable, so only change this when you're developing the User Engine locally!
            // > https://github.com/reduxjs/redux-toolkit/discussions/1349
            'https://user.services.shp-dev.com/api/v1'; // 'http://localhost:8000/api/v1';
    case MicroserviceNames.SEARCH_ENGINE:
      return isProduction
        ? 'https://search.services.shippypro.com/api/v1'
        : isTesting
          ? 'https://search.services.shp-dev.com/api/v1'
          : 'http://localhost:3333/api/v1';
    case MicroserviceNames.VALIDATION_ENGINE:
      return isProduction
        ? 'https://validation.services.shippypro.com/api/v1'
        : isTesting
          ? 'https://validation.services.shp-dev.com/api/v1'
          : 'http://localhost:4000/api/v1';
    case MicroserviceNames.DOCUMENTS_ENGINE:
      return isProduction
        ? 'https://document.services.shippypro.com/api/v2'
        : isTesting
          ? 'https://document.services.shp-dev.com/api/v2'
          : 'http://localhost:3333/api/v2';
    case MicroserviceNames.ANALYTICS_ENGINE:
      return isProduction
        ? 'https://analytics.services.shippypro.com/api/v1'
        : isTesting
          ? 'https://analytics.services.shp-dev.com/api/v1'
          : 'http://localhost:4000/api/v1';
    default:
      throw new Error('⚠️ Unknown/Misconfigured service name');
  }
};
