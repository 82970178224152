import { FC } from 'react';
import { PaginationLink as ReactstrapPaginationLink } from 'reactstrap';

import { IPaginationLinkProps } from './types';

/**
 * A simple wrapper class for the `reactstrap: <PaginationLink />` component.
 *
 * @interface IPaginationLinkProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const PaginationLink: FC<IPaginationLinkProps> = (
  props: IPaginationLinkProps,
) => <ReactstrapPaginationLink {...props} />;
