// ########################## [IMPORTANT LIBRARIES]
import { useContext, useMemo } from 'react';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [TYPES]
import { Order } from '@web/types/order';

// ########################## [DESIGN SYSTEM]
import { Badge } from '@shippypro/design-system-web';
import { Color } from '@shippypro/design-system-web/enums';

// ########################## [PAGE COMPONENTS]
import { BadgeWithCustomHover } from '@web/components/common-ui/tables/columns';
import TrackingDialogContext from '@web/features/tracking-dialog/contexts/TrackingDialogContext';

interface ITrackingCodeBadge {
  order: Order;
  trackingCode?: string | null;
}

/**
 * This badge:
 * - shows the tracking code when it is available
 * - shows 'pending' when the tracking code isn't available yet
 * In the tracking code is available, on click it will open the Tracking modal
 *
 * TODO: Deprecate in favour of the `TrackingCodeText` component
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 *
 * @version 1.0.0
 * @interface ITrackingCodeBadge
 * @returns {JSX.Element}
 */

const TrackingCodeBadge: React.FC<ITrackingCodeBadge> = ({
  order,
  trackingCode,
}) => {
  const { t } = useTranslation(),
    transOrderShipmentInfo = translations.order.shipmentInfo;

  const trackingCodeLengthLimit = 17;
  const cutToTrackingCodeLengthLimit = useMemo(() => {
    if (trackingCode) {
      return trackingCode.slice(0, trackingCodeLengthLimit) + '...';
    }
  }, [trackingCode]);

  // Bootstrapping the `TrackingDialog` utility to show every tracking detail
  const { openTrackingDialog } = useContext(TrackingDialogContext)!;

  return (
    <span data-test="tracking-code-container">
      {trackingCode ? (
        trackingCode.length > trackingCodeLengthLimit ? (
          <BadgeWithCustomHover
            className="tracking-code-badge"
            label={cutToTrackingCodeLengthLimit!}
            color={Color.primary}
            hasHover
            target={`tracking-code-${order?.id}`}
            iconAndLabelClassnames="font-normal hover:underline hover:cursor-pointer"
            handleOnClick={() => openTrackingDialog(order.id)}
          >
            {trackingCode}
          </BadgeWithCustomHover>
        ) : (
          <Badge
            color={Color.primary}
            light
            pill
            className="tracking-code-badge bg-light-primary font-normal text-light-purpleish hover:underline hover:cursor-pointer"
            onClick={e => {
              e.stopPropagation();
              openTrackingDialog(order.id);
            }}
          >
            {trackingCode}
          </Badge>
        )
      ) : (
        <BadgeWithCustomHover
          label={
            order.carrier_name !== 'Generic'
              ? t(transOrderShipmentInfo.trackingCodeNotAvailableYet)
              : t(transOrderShipmentInfo.trackingCodeNotAvailable)
          }
          color={Color.secondary}
          hasHover
          target={`tracking-code-${order.id}`}
          allowEvents
          icon="IconMapPin"
          iconAndLabelClassnames="font-normal"
        >
          {t(transOrderShipmentInfo.trackingCodePendingTooltip)}
        </BadgeWithCustomHover>
      )}
    </span>
  );
};

export default TrackingCodeBadge;
