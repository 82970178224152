import { createContext } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { ILabelFormatSettingsForm } from './types';

interface ILabelFormatSettingsFormContext {
  form: UseFormReturn<ILabelFormatSettingsForm>;
  selectedOrders: number[];
}

export const LabelFormatSettingsFormContext =
  createContext<ILabelFormatSettingsFormContext | null>(null);
