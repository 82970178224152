import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

import { Header, flexRender } from '@tanstack/react-table';

import Sorting from '../handlers/Sorting';

import { IReactTableProps } from '../../types';

interface IHeaderCellProps<T extends object> {
  header: Header<T, unknown>;
  index: number;

  canResize: boolean;
  isResizing: string | false;
  draggingCol: string | undefined;
  setDraggingCol: Dispatch<SetStateAction<string | undefined>>;

  className?: string;
}

/**
 * This component is the UI representation of a `react-table` header.
 * It displays the `columns` list into an actual table headers layout and manages:
 * * Full Selection (w/ checkbox);
 * * Full Expansion (w/ icon);
 * * Cell Sorting (w/ icon);
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 *
 * @returns {FC} The `React Table` header, fully rendered & managed.
 */
function TableHeaderCell<T extends object>({
  header,
  index,
  canResize,
  isResizing,
  draggingCol,
  setDraggingCol,
  className,
  ...props
}: IReactTableProps<T> & IHeaderCellProps<T>) {
  const { options, getColumn, getState } = props;
  const [highlightedColumnIndex, setHighlightedColumnIndex] = useState(-1);

  const state = getState();
  const columnResizeRef = useRef<string | false>();

  useEffect(() => {
    if (
      state.columnSizingInfo &&
      !state.columnSizingInfo?.isResizingColumn &&
      columnResizeRef.current &&
      header.column.id === columnResizeRef.current
    ) {
      props.customComponents?.onColumnResize?.(
        columnResizeRef.current,
        header.column.getIndex(),
        header.column.getSize(),
      );
    }
    columnResizeRef.current = state.columnSizingInfo?.isResizingColumn;
  }, [
    header.column,
    props.customComponents,
    state.columnSizing.isResizingColumn,
    state.columnSizingInfo,
  ]);

  return (
    <th
      key={header.id}
      className={classnames(
        'group',
        'rdt_TableCol',
        `react-table-header-column header-column--${header.id}`,
        {
          non_resizable:
            !canResize || !getColumn(header.column.id)?.getCanResize(),
        },
        className,
      )}
      {...{
        style: {
          width: header.getSize(),
        },
      }}
    >
      <div
        className={classnames(
          'flex justify-between items-center font-medium text-[color:--shp-color-text-table-header] !h-[3.25rem]',
        )}
        onMouseEnter={() => setHighlightedColumnIndex(index)}
        onMouseLeave={() => setHighlightedColumnIndex(-1)}
      >
        <div
          className={classnames('flex !gap-1 items-center !py-2 !px-2', {
            'cursor-pointer select-none': header.column.getCanSort(),
          })}
          onClick={header.column.getToggleSortingHandler()}
        >
          {flexRender(header.column.columnDef.header, header.getContext())}
          <Sorting
            {...header}
            isHighlighted={highlightedColumnIndex === index}
          />
        </div>
        {canResize &&
          (!draggingCol || draggingCol === header.column.id) &&
          (getColumn(header.column.id)?.getCanResize() ?? false) && (
            <div
              className={classnames(
                'react-table-header-column_resizer',
                options.columnResizeDirection,
                {
                  is_resizing: header.column.getIsResizing(),
                },
              )}
              {...{
                onDoubleClick: () => header.column.resetSize(),
                onMouseDown: e => {
                  setDraggingCol(header.column.id);
                  header.getResizeHandler()(e);
                },
                onTouchStart: e => {
                  setDraggingCol(header.column.id);
                  header.getResizeHandler()(e);
                },
              }}
            />
          )}
      </div>
    </th>
  );
}

export default TableHeaderCell;
