import { FC, memo } from 'react';

import { Swiper } from 'swiper/react';

import { ISwiperProps } from './types';

/**
 * A simple wrapper class for the `swiper/react: <Swiper />` component.
 *
 * @interface ISwiperProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const Carousel: FC<ISwiperProps> = memo(props => <Swiper {...props} />);
