// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@web/utils/@reduxjs/toolkit';
import { FeatureState } from './types';

// The initial state of the Home feature
export const initialState: FeatureState = {};

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {},
});

export const { actions: homeActions, reducer, name: sliceKey } = homeSlice;
