import { FC, memo } from 'react';

import { IconHelper } from '@ds-web-iconhelper';
import { Checkbox } from '@ds-web/components/atoms/form/checkbox/Checkbox';

import ListAction from './ListAction';
import { ListViewOrder, IListHeaderActionsProps } from './types';

const ListHeaderActions: FC<IListHeaderActionsProps> = memo(
  ({ config, data, ...rest }) => {
    const isSelectAll = rest.selected.length !== data.length;

    return (
      <div className="list-view-header-actions">
        <span className="list-view-header-actions-sel-ord">
          {config.selectable ? (
            <Checkbox
              className="mr-1"
              checked={rest.selected.length > 0}
              onChange={() => {
                if (isSelectAll)
                  rest.setSelected(data.map(v => Object.values(v)[0]));
                else rest.setSelected([]);
              }}
              icon={
                rest.selected.length === data.length ? (
                  <IconHelper icon="IconCheck" className="vx-icon" />
                ) : (
                  <IconHelper icon="IconMinus" className="vx-icon" />
                )
              }
            />
          ) : null}
          <span
            className={`list-view-header-actions-order ${
              rest.order === ListViewOrder.desc && ListViewOrder.desc
            }`}
          >
            <IconHelper
              icon="IconArrowUp"
              size={20}
              onClick={() =>
                rest.toggleOrder(
                  rest.order === ListViewOrder.desc
                    ? ListViewOrder.asc
                    : ListViewOrder.desc,
                )
              }
            />
          </span>
        </span>
        <span className="list-view-header-actions-cell">
          <ListAction config={config} hide />
        </span>
      </div>
    );
  },
);

export default ListHeaderActions;
