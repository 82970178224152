/**
 * Checks if a string is null, undefined, or empty.
 *
 * @param {string|null|undefined} string The string to check
 *
 * @return {boolean} The result
 */
export function isNullOrEmptyString(string?: string | null): boolean {
  return (
    (string === null || typeof string == 'undefined' || !string?.length) ?? true
  );
}
