import { FC, memo } from 'react';
import classnames from 'classnames';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations_restricted';

import {
  NoteSavingOverlay,
  EditNoteIcon,
  DeleteNoteIcon,
} from '@ds-web/components';

import { INoteBlockProps } from './types';

/**
 * This component wraps a note with actions and accept states and body
 *
 * @interface INoteBlockProps
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
export const NoteBlock: FC<INoteBlockProps> = memo(
  ({
    category,
    editable,
    isEditing,
    disabled,
    isSaving,
    bgColorClassName = '',
    onEditAction,
    onDeleteAction,
    isEditingIconComponent,
    noteCardBodyComponent,
    wFull = true,
    className,
  }) => {
    const { t } = useTranslation(),
      trans = translations.common;

    return (
      <div
        className={classnames(
          className,
          bgColorClassName,
          'flex rounded relative flex-col items-start !mb-5 overflow-auto',
          {
            'px-[8px] py-[4px]': editable,
            'px-[8px]': !editable,
            'bg-[--shp-color-bg-highlight]':
              editable && bgColorClassName === '',
            'w-full': wFull,
            'max-w-full': !wFull,
          },
        )}
      >
        <NoteSavingOverlay visible={isSaving} />
        <div className="flex w-full justify-between">
          {category && <span className="!mb-2 font-bold">{category}</span>}
          {editable && (
            <div className="flex gap-1">
              {!isEditing && (
                <>
                  {/* Edit note  */}
                  <EditNoteIcon
                    tooltipText={t(trans.tooltips.clickToEdit)}
                    action={onEditAction}
                    disabled={disabled}
                  />

                  {/* Delete note */}
                  <DeleteNoteIcon
                    action={onDeleteAction}
                    tooltipText={t(trans.tooltips.deleteNote)}
                    disabled={disabled}
                  />
                </>
              )}

              {/* Save note component */}
              {isEditing && isEditingIconComponent}
            </div>
          )}
        </div>
        {noteCardBodyComponent}
      </div>
    );
  },
);
