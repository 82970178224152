import { i18n, translations } from '@shippypro/translations_restricted';

import { showErrorToastr, showSuccessToastr } from '@ds-web/utils/functions';

/**
 * This function takes a string and copies it to the user's clipboard,
 * showing a success toastr message if it succeeds.
 *
 * @param {string} text The text to copy to the user's clipboard
 * @returns {void}
 */
export async function copyToClipboard(
  text: string,
  showToastr: boolean = true,
) {
  const t = await i18n;

  return navigator.clipboard
    .writeText(text)
    .then(() => {
      // For some reason the COPY API is very difficult to test successfully
      /* istanbul ignore next */
      showToastr && showSuccessToastr(t(translations.common.copiedToClipboard));
    })
    .catch(e => {
      // avoid uncaught exceptions thrown by copy to clipboard
      // see SUPPORT-964
      console.error(e);
      showErrorToastr(
        t(translations.common.errors.error),
        t(translations.common.errors.errorCopyToClipboard),
      );
    });
}
