import { MenuItem } from '../types';

import useGetAllMenuItems from './useGetAllMenuItems';

type ActiveTabData = {
  activeTab: MenuItem | undefined;
  father: MenuItem | undefined;
  displayActiveItem: MenuItem | undefined;
  isCheckActive: boolean;
  isCheckFather: boolean;
};

/**
 * Takes a list of **Menu items**, the active tab ID and an optional item to check
 * and returns a set of useful data on the Tab that's supposed to be active:
 * * `activeTab` => The Item object of the currently active tab;
 * * `father` => The father object of the active tab;
 * * `displayActiveItem` => The Father object with the title and icon of the selected child;
 * * `isCheckActive` => Boolean to check if the active tab is the one that was passed as an optional parameter;
 * * `isCheckFather` => Boolean to check if the father of the active tab is the one that was passed as an optional parameter;
 *
 * @param items {MenuItem[]} List of Menu items to check
 * @param id {string} ID of the active tab
 * @param check {?MenuItem} Optional item to check
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 *
 * @type {ActiveTabData}
 * @returns {ActiveTabData} The detailed data of the Active Tab
 */
const useGetActiveTabData = (
  itemData: MenuItem[] | MenuItem,
  id: string,
  check?: MenuItem,
): ActiveTabData => {
  const items = itemData instanceof Array ? itemData : [itemData];
  const allMenuItems = useGetAllMenuItems(items);
  const activeTab = allMenuItems?.find(item => item.id === id);
  const father = items?.find(
    item => item.children?.some(child => child.id === id),
  );

  return {
    activeTab,
    father,
    displayActiveItem: check
      ? {
          ...check,
          title: activeTab?.title ?? check.title,
          icon: activeTab?.icon ?? check.icon,
        }
      : activeTab,
    isCheckActive: check?.id === activeTab?.id,
    isCheckFather:
      check?.collector && activeTab ? true : check?.id === father?.id,
  };
};

export default useGetActiveTabData;
