import { FC } from 'react';

import { Progress as ReactstrapProgress } from 'reactstrap';
import classnames from 'classnames';

import { SizeWithXL } from '@ds-web/tokens/enums';

import { ILinearProgressProps } from './types';

/**
 * A simple wrapper class for the `reactstrap: <Progress />` component.
 *
 * @interface ILinearProgressProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const LinearProgress: FC<ILinearProgressProps> = ({
  size,
  className,
  ...rest
}) => {
  return (
    <ReactstrapProgress
      className={classnames(className, {
        [`progress-${size}`]: size && size !== SizeWithXL.md,
      })}
      {...rest}
    />
  );
};
