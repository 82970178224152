// ########################## [IMPORTANT LIB]
import classnames from 'classnames';

// ########################## [TRANSLATIONS]
import { translations } from '@shippypro/translations';
import { useTranslation } from 'react-i18next';

// ########################## [DESIGN SYSTEM]
import { Dot } from '@shippypro/design-system-web';
import { Color } from '@shippypro/design-system-web/enums';

// ########################## [REDUX]

// ########################## [TYPES]
import { LastLevelLink } from '@web/features/_global/types';
import { ConvertedToObjectType } from '@shippypro/translations';
import { SharedMenuItemProps } from '@web/features/_global/layout/SideMenu/types';

// ########################## [DESIGN SYSTEM]
import { IconHelper } from '@shippypro/design-system-web/iconhelper';

// ########################## [PAGE COMPONENTS]

// ########################## [UTILS]

// ########################## [HOOKS]

interface ILastLevelMenuItemProps extends SharedMenuItemProps {
  menuItem: LastLevelLink;
  icon?: string;
  ml?: boolean;
  menuTrans?: ConvertedToObjectType<unknown>;
}

/**
 * This component is a dumb component that manages the last link item of the sidebar menu
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
const LastLevelMenuItem: React.FC<ILastLevelMenuItemProps> = ({
  menuItem,
  i,
  manageLink,
  isMenuSelected,
  icon,
  ml = false,
  menuTrans = translations.sideMenu,
}) => {
  const { t } = useTranslation();

  return (
    <div
      key={i}
      className={classnames(
        'menu-link flex justify-between items-center py-[0.6rem] min-h-[52px] md:min-h-[38px] rounded-md cursor-pointer relative',
        {
          'menu-link-selected': isMenuSelected(menuItem) === 1,
          hidden: menuItem.isHidden,
          'ml-9': ml,
        },
      )}
      onClick={
        menuItem.onClick
          ? menuItem.onClick
          : menuItem.link
            ? () =>
                manageLink(
                  menuItem.link,
                  menuItem.isExternal,
                  menuItem.isCustom,
                )
            : () => {}
      }
    >
      {icon && <IconHelper icon={icon} size={8} />}
      <span
        className={classnames(
          'ml-2 text-[16px] md:text-[14px] whitespace-nowrap',
          {
            'pl-14': !icon,
          },
        )}
        id={`link-${menuItem.label}`}
        data-test={`link-${menuItem.label}`}
      >
        {t(menuTrans[menuItem.label])}
      </span>
      {menuItem.hasDot && (
        <Dot
          isPulsing={menuItem.dotProps?.isPulsing}
          color={Color.accent}
          dotClassName="!right-[-20px]"
        />
      )}
      {menuItem.isExternal && (
        <span className="ext-link mr-4">
          <IconHelper icon="IconExternalLink" size={14} />
        </span>
      )}
      {menuItem.lockComponent}
    </div>
  );
};

export default LastLevelMenuItem;
