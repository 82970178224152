import { FC } from 'react';
import { Item as ContexifyItem } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';

import { IItemProps } from './types';

/**
 * A simple wrapper class for the `contexify: <Item />` component.
 *
 * @interface IItemProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const Item: FC<IItemProps> = props => <ContexifyItem {...props} />;
