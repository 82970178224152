import { waitForElem } from '../wait-for-elem/wait-for-elem';

/**
 * This method tries to find the given element in the DOM,
 * and if it's found, it scrolls it into view.
 *
 * @param selector {string} The selector to look for
 */
export async function scrollIntoView(selector: string) {
  const elem = (await waitForElem(selector)) as HTMLElement;

  if (elem) {
    elem.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest',
    });
  }
}
