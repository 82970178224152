// ########################## [IMPORTANT LIBRARIES]
import { FC, ReactNode } from 'react';
import styled from 'styled-components';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [DESIGN SYSTEM]
import { TooltipWrapperByID } from '@shippypro/design-system-web';
import { IconHelper } from '@shippypro/design-system-web/iconhelper';
import { copyToClipboard } from '@shippypro/design-system-web/functions';

// ########################## [TYPES]
import { Address } from '@web/types/common/address';

// ########################## [UTILITIES]
import { getAddressLabel } from '@web/hooks/addresses/useGetAddressLabel';

interface ICustomerContactsRow {
  target: string;
  text: string;
  icon: ReactNode;
  options?: {
    alignIcon: string;
  };
}

const HoverableDiv = styled.div`
  cursor: pointer;
  padding: 8px;

  .hover-visible {
    opacity: 0;
  }
  &:hover .hover-visible {
    opacity: 100;
  }

  &:hover {
    background: var(--shp-color-bg-highlight);
  }
`;

/**
 * A single row used in CustomerContacts
 * Being pretty specific and having little reusable logics, I decided to keep it "private"
 * @author Fabio Fognani <fabio.fognani@shippypro.com>
 */
const CustomerContactsRow: FC<ICustomerContactsRow> = ({
  target,
  text,
  icon,
  options,
}) => {
  const { t } = useTranslation(),
    transShipTooltips = translations.ship.tooltips;

  return (
    <HoverableDiv
      className="flex justify-between rounded-md"
      onClick={() => copyToClipboard(text)}
    >
      <div className={`flex space-x-2 ${options?.alignIcon ?? 'items-center'}`}>
        <span className="text-muted">{icon}</span>
        <p className="text-gray">{text}</p>
      </div>
      <div className="hover-visible">
        <TooltipWrapperByID
          tooltipProps={{
            placement: 'top',
          }}
          target={target}
          text={t(transShipTooltips.copyToClipboard)}
        >
          <IconHelper icon="IconCopy" className="cursor-pointer outline-none" />
        </TooltipWrapperByID>
      </div>
    </HoverableDiv>
  );
};

interface ICustomerContacts {
  address: Address;
}

/**
 * Basic read-only info about a customer
 * @author Fabio Fognani <fabio.fognani@shippypro.com>
 */
const CustomerContacts: FC<ICustomerContacts> = ({ address }) => {
  return (
    <div>
      <div className="flex-col space-y-2">
        <p className="font-bold text-lg">{address.name}</p>

        <CustomerContactsRow
          icon={<IconHelper icon="IconMail" />}
          text={address.email}
          target="email"
        />
        <CustomerContactsRow
          icon={<IconHelper icon="IconPhone" />}
          text={address.phone}
          target="phone"
        />
        <CustomerContactsRow
          icon={<IconHelper icon="IconMapPin" />}
          text={getAddressLabel(address)}
          target="address"
          options={{
            alignIcon: 'items-start',
          }}
        />
      </div>
    </div>
  );
};

export default CustomerContacts;
