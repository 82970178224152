import type { User } from '@web/types/global';

export const toLDUser = (
  user: User,
  customParams:
    | Record<string | number, string | number | boolean | Date>
    | undefined,
) => {
  return {
    kind: 'user',
    key: user.id?.toString(),
    name: `${user.full_name}`,
    email: user.email,
    ...customParams,
  };
};
