import styled from 'styled-components';
import { AccordionItem } from 'reactstrap';

export const StyledAccordionItem = styled(AccordionItem)`
  .accordion-button {
    height: 65px !important;

    .form-check {
      position: absolute;
      right: 0;
      padding: 0;

      .form-check-label {
        display: none;
      }
    }
  }
`;
