import { useCallback, useRef, useState } from 'react';

import type { Item } from '@web/types/order';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import {
  UncontrolledTooltip,
  TooltipWrapperByID,
} from '@shippypro/design-system-web';
import { IconHelper } from '@shippypro/design-system-web/iconhelper';
import {
  toCurrencySymbol,
  toLocalizedPrice,
} from '@shippypro/design-system-web/functions';

import ImagePreview from './ImagePreview';

interface ISingleItemProps {
  item: Item;
  currency: string;
  divider?: boolean;
  allowMagnify?: boolean;
}

// TODO: move to design system + add documentation
const SingleItem: React.FC<ISingleItemProps> = ({
  item,
  currency,
  divider = true,
  allowMagnify,
}) => {
  const { t } = useTranslation(),
    transOrder = translations.order.info,
    transItem = translations.ship.table.items;

  const [mustShowTitleTooltip, setMustShowTitleTooltip] = useState(false);
  const titleRef = useRef<HTMLParagraphElement | null>(null);
  const onRefChange = useCallback(
    node => {
      titleRef.current = node;
      setMustShowTitleTooltip(node && node.offsetWidth < node.scrollWidth);
    },
    [setMustShowTitleTooltip],
  );

  return (
    <>
      <div className="flex w-full gap-1" data-test={`item-block-${item.id}`}>
        <div className="w-[12%]">
          {item.image_url ? (
            <ImagePreview
              items={[{ url: item.image_url, description: item.description }]}
              alt={item.sku}
              allowMagnify={allowMagnify}
              className="flex items-center h-full"
              previewClassName="w-full h-full max-w-[50px] max-h-[50px]"
            />
          ) : (
            <IconHelper
              icon="IconCameraOff"
              className="w-full h-full max-w-[50px] text-muted !p-3"
            />
          )}
        </div>
        <div className="flex flex-col items-start w-[80%]">
          <p className="w-full text-truncate !mb-2" ref={onRefChange}>
            {t(transOrder.item)}: {item.description}
          </p>
          {mustShowTitleTooltip && (
            <UncontrolledTooltip target={titleRef} trigger="hover">
              {item.description}
            </UncontrolledTooltip>
          )}
          <div className="flex justify-between w-full">
            <div className="flex flex-col items-start text-truncate w-[30%]">
              <span className="flex w-full">
                {t(transItem.sku)}:{' '}
                <TooltipWrapperByID
                  target={`single-item-sku-${item.id}`}
                  text={item.sku}
                  checkOverflow
                >
                  <p
                    className={`w-full text-truncate single-item-sku-${item.id}`}
                  >
                    {item.sku}
                  </p>
                </TooltipWrapperByID>
              </span>
              {item.ean && (
                <span className="flex w-full">
                  {t(transItem.ean)}:{' '}
                  <TooltipWrapperByID
                    target={`single-item-ean-${item.id}`}
                    text={item.ean}
                    checkOverflow
                  >
                    <p
                      className={`w-full text-truncate single-item-ean-${item.id}`}
                    >
                      {item.ean}
                    </p>
                  </TooltipWrapperByID>
                </span>
              )}
              {item.asin && (
                <span className="flex w-full">
                  {t(transItem.asin)}:{' '}
                  <TooltipWrapperByID
                    target={`single-item-asin-${item.id}`}
                    text={item.asin}
                    checkOverflow
                  >
                    <p
                      className={`w-full text-truncate single-item-asin-${item.id}`}
                    >
                      {item.asin}
                    </p>
                  </TooltipWrapperByID>
                </span>
              )}
            </div>
            <p className="text-truncate w-[30%]">
              {t(transOrder.quantity)}: <b>{item.quantity}</b>
            </p>
            <TooltipWrapperByID
              target={`single-item-price-${item.id}`}
              text={item.price?.toString()}
              checkOverflow
            >
              <p
                className={`text-truncate w-[30%] single-item-price-${item.id}`}
              >
                {t(transOrder.price)}:{' '}
                <b className={`single-item-price-${item.id}`}>
                  {toLocalizedPrice(
                    toCurrencySymbol(currency),
                    item.price ? +item.price : 0,
                  )}
                </b>
              </p>
            </TooltipWrapperByID>
          </div>
        </div>
      </div>
      {divider && <hr />}
    </>
  );
};

export default SingleItem;
