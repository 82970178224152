import { memo } from 'react';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import { IconHelper } from '@shippypro/design-system-web/iconhelper';

interface IEmptyProps {}

/**
 * **COMPONENT: Empty view**
 *
 * This view appears when there's no posts to display. It should only appear until the first
 * post with the new 'UI App' label has been created though.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 * @interface IEmptyProps
 */
const Empty: React.FC<IEmptyProps> = memo(() => {
  const { t } = useTranslation(),
    trans = translations.changelog;

  return (
    <div className="flex flex-col justify-center items-center w-full my-3 no-news">
      <div className="empty-icon text-primary">
        <IconHelper icon="IconBook2" size={64} className="text-muted !mb-4" />
      </div>
      <h2 className="empty-text text-center text-2xl font-medium !mb-2">
        {t(trans.emptyText)}
      </h2>
      <p className="empty-desc text-center text-base !mb-2">
        {t(trans.emptyDesc)}
      </p>
    </div>
  );
});

export default Empty;
