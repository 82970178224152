import { FC, memo } from 'react';

import ListHeaderCell from './ListHeaderCell';
import ListHeaderActions from './ListHeaderActions';

import { ListViewMode, IListHeaderProps } from './types';

const ListHeader: FC<IListHeaderProps> = memo(
  ({ mode, config, columns, data, ...rest }) => {
    return (
      <>
        {mode === ListViewMode.list && (
          <div className={`list-view-header ${config.selectable && 'pl5'}`}>
            {columns
              .filter(c => c.name.toUpperCase() !== 'ID' && !c.hidden)
              .map((column, i) => (
                <ListHeaderCell
                  config={config}
                  key={i}
                  first={i === 0}
                  width={column.width}
                >
                  {column.name.toUpperCase()}
                </ListHeaderCell>
              ))}
            {config.actions !== null && (
              <ListHeaderCell config={config} actions>
                {config.translations.actions.toUpperCase()}
              </ListHeaderCell>
            )}
          </div>
        )}
        {data.length > 0 && (
          <ListHeaderActions
            config={config}
            data={data}
            order={rest.order}
            toggleOrder={order => rest.toggleOrder(order)}
            selected={rest.selected}
            setSelected={rest.setSelected}
          />
        )}
      </>
    );
  },
);

export default ListHeader;
