import { FC, PropsWithChildren } from 'react';

import { HoverableDiv } from './styles';
import { IGenericHoverableItemProps } from './types';

/**
 * Item with hidden custom section that is shown on hover
 *
 * @interface IGenericHoverableItemProps
 * @author Fabio Fognani <fabio.fognani@shippypro.com>
 */
export const GenericHoverableItem: FC<
  PropsWithChildren<IGenericHoverableItemProps>
> = ({ reveals, children, actionOnClick, ...rest }) => {
  return (
    <HoverableDiv {...rest} onClick={actionOnClick}>
      {children}
      <div className="hover-visible">{reveals}</div>
    </HoverableDiv>
  );
};
