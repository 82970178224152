import { FC, memo } from 'react';

import {
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  BarChart,
  Bar,
  LabelList,
} from 'recharts';

import { toLocalizedDecimal } from '@ds-web/utils/functions/numbers/localised-decimal/localised-decimal';

import { IDoubleBarChartMetricsBodyProps } from './types';
import { MultipleMetricDataType } from '..';

/**
 * This Component renders a `Double Bar Chart` view of a metric, with two columns per data.
 *
 * @interface IDoubleBarChartMetricsBodyProps
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
export const DoubleBarChartMetricsBody: FC<IDoubleBarChartMetricsBodyProps> =
  memo(
    ({
      data,
      locale = 'en-US',
      XAxisFormatter,
      TopBarLabelFormatter,
      SecondTopBarLabelFormatter,
    }) => {
      const maxYValue = Math.max(
        ...data.map((d: MultipleMetricDataType) =>
          Math.max(d.yValueFirst, d.yValueSecond),
        ),
      );
      const yAxisDomain = [0, maxYValue + maxYValue * 0.25]; // Add 25% padding to the top of the chart

      return (
        <section
          id="metricsdoublebarchart-container"
          className="w-full flex flex-col justify-between items-start"
        >
          <div
            id="metricsdoublebarchart-graphcontainer"
            className="recharts-wrapper !mt-5"
          >
            <ResponsiveContainer width="98%" height="100%">
              <BarChart
                data={data}
                margin={{ right: 0, left: 0, top: 10, bottom: 0 }}
              >
                <CartesianGrid vertical={false} />
                <XAxis dataKey="xName" tick={XAxisFormatter} interval={0} />
                <YAxis
                  dataKey="yValue"
                  width={35}
                  domain={yAxisDomain}
                  tickFormatter={value => toLocalizedDecimal(value, 0, 0)}
                />
                <Bar
                  type="monotone"
                  dataKey="yValueFirst"
                  fill="#7349dd"
                  fillOpacity={1}
                  radius={[3, 3, 0, 0]}
                  isAnimationActive={false}
                >
                  <LabelList
                    dataKey="yValueFirst"
                    position="top"
                    content={TopBarLabelFormatter}
                  />
                </Bar>
                <Bar
                  type="monotone"
                  dataKey="yValueSecond"
                  fill="#b6a0ed"
                  fillOpacity={1}
                  radius={[3, 3, 0, 0]}
                  isAnimationActive={false}
                >
                  <LabelList
                    dataKey="yValueSecond"
                    position="top"
                    content={SecondTopBarLabelFormatter ?? TopBarLabelFormatter}
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </section>
      );
    },
  );
