import { Address } from '@web/types/common/address';

export const getAddressLabel = (address: Address): string => {
  return [
    address.name,
    address.company && `(${address.company})`,
    [address.street1, address.street2].filter(Boolean).join(' '),
    address.city,
    address.zip,
  ]
    .filter(Boolean)
    .join(' - ');
};

/**
 * This hook receives an address and returns the string for the addresses select value
 *
 * @param  address
 * @returns string
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
/* istanbul ignore next: not used as of now */
const useGetAddressLabel = (address: Address): string => {
  return getAddressLabel(address);
};

export default useGetAddressLabel;
