import { FC } from 'react';
import classnames from 'classnames';

import { AreaChart, Area, XAxis, YAxis, ResponsiveContainer } from 'recharts';

import { CardBody } from '@ds-web/components/atoms/ui/card';
import { Avatar } from '@ds-web/components/atoms/ui/avatar/Avatar';
import { Badge } from '@ds-web/components/atoms/ui/badge/Badge';
import { IconHelper } from '@ds-web-iconhelper';

import { StyledStatisticsCard } from './StatisticsCard.styles';

import { IStatisticsCardProps } from './types';

/**
 * This Component renders a Card with a background simplifed graph.
 * It has a title+desc, and a summed up metric value, with a navigation link
 * at the bottom-right of the body.
 *
 * @interface IStatisticsCardProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const StatisticsCard: FC<IStatisticsCardProps> = ({
  className = '',
  icon,
  color,
  title,
  description,
  statTitle,
  statDescription,
  stats,
  link: { text, onClick },
  ...rest
}) => {
  // Calculating the right color var for the chart gradient;
  let chartVarColor = `--bs-${color}`;

  return (
    <StyledStatisticsCard {...rest}>
      {/* ------------------------[HEADING SECTION] */}
      <CardBody
        className={classnames('stats-card-body', {
          [className]: className,
        })}
      >
        <h2 className="stats-card-title">{title}</h2>
        <p className="stats-card-description">{description}</p>
        <div className="stats-card-statcontainer">
          <Avatar
            className={classnames('stats-card-statavatar', `bg-light-${color}`)}
            icon={<IconHelper icon={icon} size={24} />}
          />
          <div className="stats-card-stattextcontainer">
            <h2 className="stats-card-stattitle">{statTitle}</h2>
            <p className="stats-card-statdescription">{statDescription}</p>
          </div>
        </div>
      </CardBody>
      {/* ------------------------[CHART SECTION] */}
      <div className="stats-card-graphcontainer recharts-wrapper">
        <ResponsiveContainer width="120%" height="95%">
          <AreaChart
            data={stats}
            margin={{ top: 0, left: -75, right: 0, bottom: 0 }}
          >
            <defs>
              <linearGradient id="colorShp" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  stopColor={`var(${chartVarColor})`}
                  stopOpacity={0.6}
                />
                <stop
                  offset="10%"
                  stopColor={`var(${chartVarColor})`}
                  stopOpacity={0.5}
                />
                <stop
                  offset="20%"
                  stopColor={`var(${chartVarColor})`}
                  stopOpacity={0.4}
                />
                <stop
                  offset="30%"
                  stopColor={`var(${chartVarColor})`}
                  stopOpacity={0.3}
                />
                <stop
                  offset="40%"
                  stopColor={`var(${chartVarColor})`}
                  stopOpacity={0.2}
                />
                <stop
                  offset="50%"
                  stopColor={`var(${chartVarColor})`}
                  stopOpacity={0.1}
                />
                <stop
                  offset="60%"
                  stopColor={`var(${chartVarColor})`}
                  stopOpacity={0.05}
                />
                <stop
                  offset="70%"
                  stopColor={`var(${chartVarColor})`}
                  stopOpacity={0.025}
                />
                <stop
                  offset="80%"
                  stopColor={`var(${chartVarColor})`}
                  stopOpacity={0.001}
                />
                <stop
                  offset="90%"
                  stopColor={`var(${chartVarColor})`}
                  stopOpacity={0}
                />
              </linearGradient>
            </defs>
            <XAxis dataKey="xName" tick={false} />
            <YAxis dataKey="yValue" tick={false} />
            <Area
              type="monotone"
              dataKey="yValue"
              stroke={`var(${chartVarColor})`}
              strokeWidth={1.5}
              fillOpacity={1}
              fill="url(#colorShp)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
      {/* ------------------------[LINK SECTION] */}
      <div className="stats-card-footer">
        {onClick ? (
          <span
            className={classnames('stats-card-footer-navlink', `text-${color}`)}
            onClick={onClick}
          >
            {text}
            <IconHelper
              className="stats-card-footer-icon"
              icon="IconCircleArrowRight"
            />
          </span>
        ) : (
          <Badge
            color={color}
            className={classnames('stats-card-footer-badge', `bg-${color}`)}
            pill
          >
            {text}
          </Badge>
        )}
      </div>
    </StyledStatisticsCard>
  );
};
