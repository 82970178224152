import { FC } from 'react';
import OrderNote, {
  IOrderNoteProps,
} from '@web/components/common-features/order/notes/OrderNote';

interface ICarrierNoteProps
  extends Omit<
    IOrderNoteProps,
    'color' | 'editable' | 'isEditing' | 'setEditing' | 'onChange' | 'disabled'
  > {}

/**
 * This component hosts the note written to the carrier, with its fixed color.
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 *
 * @version 1.0.0
 * @interface ICarrierNoteProps
 * @returns {JSX.Element}
 */

const CarrierNote: FC<ICarrierNoteProps> = props => {
  return <OrderNote editable={false} dataTest="carrier-note" {...props} />;
};

export default CarrierNote;
