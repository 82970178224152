import { useCallback, useMemo } from 'react';

import { useGetOrderTimelineMutation } from '@web/features/order/api';
import { Order } from '@web/types/order';

/**
 * Returns a `useGetOrderQuery` RTK Query hook with its data field renamed.
 * This hook fetches the order's timeline of tracking events.
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const useGetOrderTimeline = () => {
  const [fetchMore, { ...results }] = useGetOrderTimelineMutation();
  const getTimeline = useCallback(
    (order: Order) => {
      const apiPayload = {
        order_id: order.shippypro_id,
        transaction_id: order.external_id,
        marketplace_id: order.marketplace_id,
        marketplace_platform: order.marketplace_platform,
      };

      return fetchMore(apiPayload);
    },
    [fetchMore],
  );

  return useMemo(
    () => ({
      getTimeline,
      ...results,
    }),
    [getTimeline, results],
  );
};
