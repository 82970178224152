import { Order, OrderManagedResult } from '@web/types/order';

/**
 * Generates a composite key from an order object.
 * The composite key is a string that combines the transaction ID, marketplace ID, and marketplace platform of the order.
 *
 * @param order - The order object from which to generate the composite key.
 * @returns The composite key string in the format "transID={encoded transaction ID}&mktpID={encoded marketplace ID}&mktpPlatform={encoded marketplace platform}".
 */
export const getCompositeKeyFromOrder = (
  order?: Order | OrderManagedResult | null,
) => {
  if (!order) return '';

  const transID = order.external_id ?? '',
    mktpID = order.marketplace_id?.toString() ?? '',
    mktpPlatform = order.marketplace_platform ?? '';

  return `?transID=${encodeURIComponent(transID)}&mktpID=${encodeURIComponent(
    mktpID,
  )}&mktpPlatform=${encodeURIComponent(mktpPlatform)}`;
};
