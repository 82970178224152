export type Payment = {
  method: string | null;
  shipment_amountpaid: number;
  shipment_amountpaid_currency: string;
  insurance: number;
  insurance_currency: string;
  totalsales: number;
  totalsales_curr: string;
  cashondelivery: number | null;
  cashondelivery_currency: string | null;
  cashondelivery_type: CashOnDeliveryType | null;
  detailed_pricing: DetailedPricing;
  shipment_cost: number | null;
  shipment_cost_currency: string | null;
};

export enum CashOnDeliveryType {
  cash = 0,
  cashier_check = 1,
  check = 2,
  all_payment_methods = 3,
}

export type DetailedPricing = {
  zone_name: string;
  weight_range: string;
  carrier_options: DetailedPricingOption;
  other_options: DetailedPricingOption;
} | null;

export type DetailedPricingOption = {
  total: number;
  currency: string;
  items: DetailedPricingItem[];
};

export type DetailedPricingItem = {
  type: string;
  price: number;
  desc: string;
};
