import { FC } from 'react';
import { DropdownItem as ReactstrapDropdownItem } from 'reactstrap';

import { IDropdownItemProps } from './types';

/**
 * A simple wrapper class for the `reactstrap: <DropdownItem />` component.
 *
 * @interface IDropdownItemProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const DropdownItem: FC<IDropdownItemProps> = props => (
  <ReactstrapDropdownItem {...props} />
);
