import { FC } from 'react';
import { NavItem as ReactstrapNavItem } from 'reactstrap';

import { INavItemProps } from './types';

/**
 * A simple wrapper class for the `reactstrap: <NavItem />` component.
 *
 * @interface INavItemProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const NavItem: FC<INavItemProps> = props => (
  <ReactstrapNavItem {...props} />
);
