import { Order } from '@web/types/order';
import { AdditionalInfoName } from '@web/types/order/additional-info';

/**
 * **[HOOK]** This hook returns the tags of an order,
 * taken from different sources (I.E. Shopify Additional Info, etc...)
 *
 * @param {Order} order The order to get the tags from
 * @returns {string[]} The tags of the order
 */
const useGetOrderTags = (order: Order | null | undefined) => {
  return getOrderTags(order);
};

export const getOrderTags = (order: Order | null | undefined) => {
  return (
    order?.additional_info
      ?.filter(info => info.additional_info_name === AdditionalInfoName.Tags)
      .map(info => info.additional_info_value.split(','))
      .map(tag => tag.map(t => t.trim()))
      .flat() ?? []
  );
};

export default useGetOrderTags;
