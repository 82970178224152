import classnames from 'classnames';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import { ErrorCategory } from '@web/types/order/error';

import { Badge } from '@shippypro/design-system-web';
import { Color } from '@shippypro/design-system-web/enums';

interface IErrorCategoryBadge {
  target?: string;
  allowEvents?: boolean;
  iconAndLabelClassnames?: string;
  handleOnClick?: VoidFunction;
  errCategoryString?: string;
}

const ErrorCategoryBadge: React.FC<IErrorCategoryBadge> = ({
  target,
  allowEvents = false,
  iconAndLabelClassnames = '',
  handleOnClick = () => {},
  errCategoryString = ErrorCategory.unknown.toString(),
}) => {
  const { t } = useTranslation(),
    transTable = translations.ship.table;

  const errCategory =
    Object.keys(ErrorCategory).find(
      key => ErrorCategory[key] === errCategoryString,
    ) ?? ErrorCategory.unknown;

  return (
    <Badge
      color={Color.danger}
      light
      pill
      className={
        'text-light-purpleish mb-0 text-[12px] font-medium tracking-widest'
      }
      onClick={handleOnClick}
    >
      <span
        id={target}
        className={classnames(iconAndLabelClassnames)}
        data-tag={'allowRowEvents'}
      >
        {t(transTable.errors[errCategory])}
      </span>
    </Badge>
  );
};

export default ErrorCategoryBadge;
