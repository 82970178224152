import { useCallback } from 'react';

/**
 * This hook returns the operating system on which the user
 * is operating, checking the window.navigator.platform
 *
 * NOTE: navigator.platform is being deprecated, but as the new
 * navigator.userAgentData.platform is not yet supported on all browsers,
 * I still prefer to go "the old way" until it becomes supported
 * refereces -> https://developer.mozilla.org/en-US/docs/Web/API/NavigatorUAData/platform
 *
 * @author Fabio Mezzina <fabio.mezzina@shippypro.com>
 */
export const useGetOperatingSystem = () => {
  const getOperatingSystem = useCallback(() => {
    const userAgentPlatform = navigator.platform;
    if (/mac/.test(userAgentPlatform.toLowerCase())) return 'mac';
    if (/win/.test(userAgentPlatform.toLowerCase())) return 'windows';
    if (/linux/.test(userAgentPlatform.toLowerCase())) return 'linux';
    return 'unknown';
  }, []);

  return { getOperatingSystem, os: getOperatingSystem() };
};
