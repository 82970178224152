import { format } from 'date-fns';
import { enGB, es, it, de, fr } from 'date-fns/locale';

import { toDate } from '../to-date/to-date';

const locales = { enGB, es, it, de, fr };

/**
 * Formats a date to a string using the given format string.
 *
 * @param {string | Date} date The date to format
 * @param {string} formatStr The format string to use
 * @param {keyof typeof locales} locale The locale to use
 * @returns {string} The formatted date
 */
export const formatLocale = (
  date: string | Date,
  formatStr: string,
  locale: string = 'enGB',
): string =>
  format(toDate(date), formatStr, {
    locale: locales[locale],
  });
