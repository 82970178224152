import { useMemo } from 'react';

import { useMediaQuery } from 'react-responsive';

/**
 * This hook returns the boolean answers of every possible size
 * that the current display might fit in, from mobile to XL screen
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const useGetDeviceSize = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTabletMini = useMediaQuery({
    minWidth: 768,
    maxWidth: 1024,
  });
  const isTablet = useMediaQuery({
    minWidth: 768,
    maxWidth: 1200,
  });
  const isTabletHoriz = useMediaQuery({ maxWidth: 768 });
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const isHDDesktop = useMediaQuery({ minWidth: 1280 });
  const isFHDDesktop = useMediaQuery({ minWidth: 1920 });
  const isLScreen = useMediaQuery({ minWidth: 1440 });
  const isXLScreen = useMediaQuery({ minWidth: 2560 });

  return useMemo(
    () => ({
      isMobile,
      isTabletMini,
      isTablet,
      isTabletHoriz,
      isDesktop,
      isHDDesktop,
      isFHDDesktop,
      isLScreen,
      isXLScreen,
    }),
    [
      isMobile,
      isTabletMini,
      isTablet,
      isTabletHoriz,
      isDesktop,
      isHDDesktop,
      isFHDDesktop,
      isLScreen,
      isXLScreen,
    ],
  );
};
