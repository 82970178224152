import { FC, useCallback } from 'react';

import type { INavmenuItemProps } from './types';

import { NavItem } from 'reactstrap';
import classnames from 'classnames';

import { IconHelper } from '@ds-web-iconhelper';

import useGetActiveTabData from './hooks/useGetActiveTabData';

const NavmenuItem: FC<INavmenuItemProps> = ({
  item,
  activeTab,
  tabChangeHandler,
  clickHandler,
  caret,
}) => {
  const { isCheckActive, isCheckFather } = useGetActiveTabData(
    item,
    activeTab,
    item,
  );

  const onClick = useCallback(() => {
    if (item.externalUrl || item.disabled || item.children) return;

    clickHandler ? clickHandler() : tabChangeHandler(item.id);
  }, [
    clickHandler,
    item.children,
    item.disabled,
    item.externalUrl,
    item.id,
    tabChangeHandler,
  ]);

  return (
    <NavItem
      className={classnames('navmenu-item flex mx-[8px]', {
        'nav-item-active': isCheckActive || isCheckFather,
        'nav-item-highlight': item.highlighted,
        'md:ml-auto': !item.children && item.right,
        'nav-menu-disabled': item.disabled,
        'navmenu-collector': item.collector,
        wrapped: document
          .getElementById(`nav-item-${item.id}`)
          ?.classList.contains('wrapped'),
      })}
      id={`nav-item-${item.id}`}
      onClick={onClick}
    >
      <a
        className="navmenu-link"
        href={item.externalUrl}
        target="_blank"
        rel="noreferrer"
      >
        <div className="flex items-center">
          {item.icon && (
            <IconHelper icon={item.icon} size={14} className="mr-2" />
          )}
          <p className="text-lg mb-0">{item.title}</p>
          {caret && (
            <IconHelper icon="IconChevronDown" size={14} className="ml-2" />
          )}
        </div>
      </a>
    </NavItem>
  );
};

export default NavmenuItem;
