export enum MicroserviceNames {
  // Encompasses the entire domain of an order, from import/creation to just before shipping
  ORDERS_SERVICE,
  // Deals in privacy and security of user data
  PRIVACY_ENGINE,
  // Stores, edits and retrieves user configurations
  USER_ENGINE,
  // Searches for several ShippyPro Entities on a whim
  SEARCH_ENGINE,
  // Validates an order to make sure it's 100% compliant with carrier specs
  VALIDATION_ENGINE,
  // Stores, edits and retrieves documents for one or more orders
  DOCUMENTS_ENGINE,
  // Processes and Analyzes data for business intelligence and insights
  ANALYTICS_ENGINE,
}
