import { memo } from 'react';

import { InputGroupText } from 'reactstrap';

import { InputGroup } from '@ds-web/components/atoms/form/inputs/input-group/InputGroup';
import { Input } from '@ds-web/components/atoms/form/inputs/input/Input';
import { DatetimePicker } from '@ds-web/components/atoms/form/datetimepicker/DatetimePicker';
import { Button } from '@ds-web/components/atoms/ui/buttons/button/Button';
import { ButtonGroup } from '@ds-web/components/atoms/ui/buttons/button-group/ButtonGroup';

import { IconHelper } from '@ds-web-iconhelper';

import { Color, Size, StyleType } from '@ds-web/tokens/enums';

import { getFlatpickrLocaleFromI18Next } from '@ds-web/utils/functions/locales';
import { dateFormatToFlatPickr } from '@ds-web/utils/functions/dates';

import { ListViewMode, IListFiltersProps } from './types';

const ListFilters = memo(({ config, ...rest }: IListFiltersProps) => {
  rest.className = ((rest.className || '') + ' list-view-filters').trim();

  return (
    <div className={rest.className}>
      <InputGroup size={Size.md} className="input-group-merge">
        <InputGroupText>
          <IconHelper icon="IconSearch" />
        </InputGroupText>
        <Input
          placeholder={config.translations.search}
          type="text"
          className="w-40 !border-none"
          onKeyUp={k => rest.search(k.target['value'] ?? '')}
        />
      </InputGroup>
      <span className="flex justify-between">
        <DatetimePicker
          className="form-control"
          value={rest.dates}
          options={{
            mode: 'range',
            static: true,
            maxDate: new Date().setHours(23, 59, 59, 999),
            locale: getFlatpickrLocaleFromI18Next(config.language),
            dateFormat: dateFormatToFlatPickr(config.dateFormat),
          }}
          onChange={(date: Date[]) => rest.setDates(date)}
          render={({ defaultValue, value, ...props }, ref) => {
            return (
              <InputGroup size={Size.md} className="input-group-merge">
                <InputGroupText>
                  {rest.dates.length ? (
                    <IconHelper
                      icon="IconX"
                      className="cursor"
                      onClick={() => rest.setDates([])}
                    />
                  ) : (
                    <IconHelper icon="Calendar" />
                  )}
                </InputGroupText>
                <Input
                  defaultValue={defaultValue}
                  innerRef={ref}
                  {...props}
                  placeholder={config.translations.datepicker}
                  type="text"
                  className="border-0"
                  onChange={() => {}}
                />
              </InputGroup>
            );
          }}
        />
        <span className={`mr-50 list-view-filters-cond-action-buttons`}>
          {config.actions?.download && (
            <Button
              color={Color.secondary}
              styleType={StyleType.flat}
              className=" btn-icon"
              icon={<IconHelper icon="IconArrowDownCircle" size={17} />}
              disabled={!rest.selected.length}
              onClick={() =>
                config.actions?.download
                  ? config.actions?.download(rest.selected)
                  : {}
              }
            />
          )}
          {config.actions?.delete && (
            <Button
              color={Color.secondary}
              styleType={StyleType.flat}
              className=" btn-icon"
              icon={<IconHelper icon="IconTrash" size={17} />}
              disabled={!rest.selected.length}
              onClick={() =>
                config.actions?.delete
                  ? config.actions?.delete(rest.selected)
                  : {}
              }
            />
          )}
        </span>
        <span
          className={`list-view-filters-mode-buttons ${
            config.mobile ? 'hidden' : ''
          }`}
        >
          <ButtonGroup>
            <Button
              color={Color.primary}
              styleType={StyleType.outline}
              className=" btn-icon"
              icon={<IconHelper icon="IconLayoutGrid" size={14} />}
              active={rest.mode === ListViewMode.grid}
              onClick={() => rest.toggleMode(ListViewMode.grid)}
            />
            <Button
              color={Color.primary}
              styleType={StyleType.outline}
              className=" btn-icon"
              icon={<IconHelper icon="IconNotes" size={14} />}
              active={rest.mode === ListViewMode.list}
              onClick={() => rest.toggleMode(ListViewMode.list)}
            />
          </ButtonGroup>
        </span>
      </span>
    </div>
  );
});

export default ListFilters;
