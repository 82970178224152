import { css } from 'styled-components';

const MediaQueries = css`
  @media (max-width: 1020px) {
    max-width: 85% !important;
  }
`;

/**
 * This stylising wrapper customises the Paywall modal with some handy specifics
 * based on the Zeplin UI mocks: https://zpl.io/jZX0WAp
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const ModalBaseStyles = css`
  ${MediaQueries}

  // Giving a dynamic width and positioning to the entire modal
  max-width: 900px;
  max-height: 550px;
  height: 100%;
  margin: auto;
  margin-top: 5rem;

  // Adding a base radius to round the modal
  .modal-content {
    border-radius: 6px;
    height: 100%;
  }

  // Shrinking the modal header bar to 0 and leaving only the 'X' button
  .modal-header {
    height: 0;
    padding: 0;

    .btn-close {
      display: none;
    }
  }

  // Styling the body to be divided in 2 blocks: the info and the image
  .modal-body {
    padding: 0;
    border-radius: 6px;
    height: 100%;

    background-image: linear-gradient(138deg, #e5e8ea 17%, #d7dadd 83%);

    #paywall-info {
      min-height: 550px !important;
      height: 100%;

      display: flex;
      flex-direction: column;

      padding: 2rem 0 1.5rem 0;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;

      background-color: white;

      // Styling all of the text in the top info (intro, title, desc)
      #paywall-top-info {
        h3 {
          margin: 0 0 6px 0;

          font-size: 17.5px;
          font-weight: 500;
          line-height: 1.94;
          letter-spacing: 0.11px;
          color: var(--shp-color-genericui-subdued);
        }

        h1 {
          margin: 0 1px 21px 0;

          font-size: 26px;
          font-weight: bold;
          line-height: 1.31;
          color: var(--shp-color-genericui-primary);
        }

        h4 {
          margin: 21px 1px 40px 0;

          font-size: 14px;
          line-height: 1.78;
          letter-spacing: 0.11px;
          color: var(--shp-color-genericui-purpleish);
        }
      }

      // Styling the details list with its list items checkmarks
      #paywall-details ul {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        margin-bottom: 20px;

        .paywall-single-detail-block {
          display: flex;
          flex-direction: row;
          align-items: center;

          row-gap: 2.5rem;
          column-gap: 0.5rem;

          font-size: 14px;
          font-weight: 500;
          line-height: 1.36;
          letter-spacing: 0.2px;
          color: var(--shp-color-genericui-purpleish);

          .paywall-single-detail-block-check {
            align-self: flex-start;
          }

          .paywall-single-detail-block-text {
            width: 90%;
          }
        }
      }

      // Moving the CTA to the far bottom of the modal
      #paywall-cta {
        margin-top: auto;
        height: 8%;
      }
    }
  }
`;

export default ModalBaseStyles;
