import React from 'react';

/**
 * This file is used to enable the why-did-you-render package.
 * It is only imported when the NX_TRACE_RERENDERS environment variable is set to true.
 * This is done to avoid the overhead of the package when it is not needed.
 *
 * @see {@link https://www.npmjs.com/package/@welldone-software/why-did-you-render}
 */
if (process.env.NX_TRACE_RERENDERS === 'true') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    trackAllPureComponents: true,
  });
}
