// ########################## [IMPORTANT LIBRARIES]
import { FC, memo, useContext } from 'react';

// ########################## [CONTEXTS]
import PaywallContext from './contexts/PaywallContext';

// ########################## [TYPES]
import {
  PaywallDesign,
  TwoRowsOneImageLayoutPaywallInfo,
  TwoRowsOneVideoLayoutPaywallInfo,
} from './types';

// ########################## [PAGE COMPONENTS]
import { TwoRowsSingleImagePaywallModal } from './pages/two-rows-single-image-modal';
import { TwoRowsSingleVideoPaywall } from './pages/two-rows-single-video';

/**
 * **FEATURE: Global Paywall**
 *
 * *"STOP, you violated the law ✋ Pay the court a fine or serve your sentence 💸"*
 * This code is responsible for blocking the user from accessing a given feature and
 * showing a Paywall blocker modal instead.
 *
 * Said modal will contain the name+desc of the blocked feature, its capabilities
 * and a handy CDN-fetched image to entice our users to lend us more money 🤑🤑🤑
 *
 * N.B. It also has a `Page Differentiation capability`, meaning that you can pass different
 * designs to create different Paywall UI experiences!
 *
 * @link https://app.tettra.co/teams/shippypro/pages/paywall
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const Paywall: FC = memo(() => {
  const { design, info, ...rest } = useContext(PaywallContext)!;

  let PaywallPage = <div />;
  switch (design) {
    case PaywallDesign.TwoRowsSingleImage:
      PaywallPage = (
        <TwoRowsSingleImagePaywallModal
          info={info as TwoRowsOneImageLayoutPaywallInfo}
          {...rest}
        />
      );
      break;
    case PaywallDesign.TwoRowsSingleVideo:
      PaywallPage = (
        <TwoRowsSingleVideoPaywall
          info={info as TwoRowsOneVideoLayoutPaywallInfo}
          {...rest}
        />
      );
      break;
    case PaywallDesign.PureText:
      // N.B. NOT IMPLEMENTED YET > will be created when needed, for now the true default is the `Two Rows - Single Image` design!
      break;
  }

  return PaywallPage;
});
