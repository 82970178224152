import { FC } from 'react';

import { AccordionHeader as ReactstrapAccordionHeader } from 'reactstrap';

import { IAccordionHeaderProps } from './types';

/**
 * A simple wrapper class for the `reactstrap: <AccordionHeader />` component.
 *
 * @interface IAccordionHeaderProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const AccordionHeader: FC<IAccordionHeaderProps> = props => (
  <ReactstrapAccordionHeader {...props} />
);
