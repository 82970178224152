import { FC, memo } from 'react';
import { IKpiTitleDescriptionProps } from './types';

/**
 * A component hosting the kpi and graph description.
 *
 * @interface IKpiTitleDescriptionProps
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
export const KpiTitleDescription: FC<IKpiTitleDescriptionProps> = memo(
  ({ description, code, link, linkText }) => {
    return (
      <div className="max-w-[350px] flex flex-col p-1 gap-2 !text-primary">
        <span>
          {description}
          <a
            href={link}
            target="_blank"
            rel="noreferrer"
            className="ml-1 underline text-accent"
          >
            {linkText}
          </a>
        </span>
        {code && (
          <code className="text-[color:--shp-color-text-body]">{code}</code>
        )}
      </div>
    );
  },
);
