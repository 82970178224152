import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@web/utils/@reduxjs';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.order || initialState;

export const selectOrderPage = createSelector(
  [selectDomain],
  orderState => orderState,
);

export const selectMktpOrdersUpdating = createSelector(
  [selectDomain],
  orderState => orderState.mktpOrdersUpdating,
);

export const selectOrderSendingMail = createSelector(
  [selectDomain],
  orderState => orderState.sendingMail,
);

export const selectOrderLabelMailIds = createSelector(
  [selectDomain],
  orderState => orderState.labelMailIds,
);
