import { useMemo } from 'react';
import { useCheckAvailableFeatureQuery } from '@web/features/_global/api';
import { CheckAvailableFeatureQueryPayload } from '@web/features/_global/types';

/**
 * Returns RTK Query hook with renamed data field.
 * Returns if the passed feature is available for current user.
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 *
 * @param {CheckAvailableFeatureQueryPayload} feature feature code
 */
export const useCheckAvailableFeature = (
  feature: CheckAvailableFeatureQueryPayload,
) => {
  const { data, ...rest } = useCheckAvailableFeatureQuery(feature);

  return useMemo(
    () => ({
      data,
      ...rest,
    }),
    [data, rest],
  );
};
