// ########################## [IMPORTANT LIB]
import { useMemo } from 'react';
import classnames from 'classnames';

// ########################## [TRANSLATIONS]
import { translations } from '@shippypro/translations';
import { useTranslation } from 'react-i18next';

// ########################## [DESIGN SYSTEM]
import { Dot } from '@shippypro/design-system-web';
import { Color } from '@shippypro/design-system-web/enums';

// ########################## [REDUX]

// ########################## [TYPES]
import { LastLevelLink, MenuLink } from '@web/features/_global/types';
import { SharedMenuItemProps } from '@web/features/_global/layout/SideMenu/types';

// ########################## [DESIGN SYSTEM]
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from '@shippypro/design-system-web';
import { IconHelper } from '@shippypro/design-system-web/iconhelper';

// ########################## [PAGE COMPONENTS]
import LastLevelMenuItem from './LastLevelMenuItem';

// ########################## [UTILS]

// ########################## [HOOKS]
import { useGetSetupData } from '@web/features/_global/hooks/api/useGetSetupData';
import { useGetSetupStepsCompleted } from '../hooks/useGetSetupStepsCompleted';
import useLDFlag from '@web/hooks/useLDFlags';

interface IMenuItemProps extends SharedMenuItemProps {
  list: MenuLink;
  showCollapsed: boolean;
  openedAccordionIds: string[];
  toggleAccordion: (id: string) => void;
}

/**
 * This component is a dumb component that manages the first and middle link items of the sidebar menu
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 */
const MenuItem: React.FC<IMenuItemProps> = ({
  list,
  i,
  showCollapsed,
  openedAccordionIds,
  toggleAccordion,
  manageLink,
  isMenuSelected,
}) => {
  const { t } = useTranslation(),
    menuTrans = translations.sideMenu;

  const {
    data: [, setup],
  } = useGetSetupData();
  const { newExperienceFlow } = useLDFlag();

  const isHomeMenu = (link: string) => link === 'home';
  const setupIsNotCompleted = useMemo(
    () => newExperienceFlow && setup && !setup.progress.setupCompleted,
    [newExperienceFlow, setup],
  );
  const setupStepsCompleted = useGetSetupStepsCompleted();

  return (
    // SHIP-1168 TODO: this menu manages 3 levels at maximum. It should be recursive
    <div
      className={classnames('menu-category mt-2', {
        '!mb-8': !showCollapsed,
      })}
      key={i}
    >
      {list.category && (
        // Category title
        <p
          className={classnames(
            'category-label my-1 ml-[14px] text-muted text-left text-sm',
            {
              '!ml-0 text-center !text-xl': showCollapsed,
            },
          )}
        >
          {showCollapsed ? '...' : list.category.toUpperCase()}
        </p>
      )}
      <Accordion
        open={openedAccordionIds}
        toggle={toggleAccordion}
        className={classnames({
          'accordion-without-arrow': showCollapsed,
          'w-[220px]': !showCollapsed,
        })}
      >
        {list.data.map((menu, j) => {
          return (
            menu.isVisible && (
              // First level menu
              <AccordionItem
                className={classnames({
                  'accordion-without-arrow': !menu.subMenu,
                  'locked-accordion': !!menu.lockComponent,
                })}
                key={j}
              >
                <AccordionHeader
                  targetId={menu.label}
                  // manage link only if there is no 2nd level menu
                  onClick={() => {
                    if (menu.onClick) menu.onClick();
                    if (!menu.subMenu)
                      manageLink(menu.link, menu.isExternal, menu.isCustom);
                  }}
                  className={classnames(
                    'flex items-center justify-center min-h-[52px] md:min-h-[38px]',
                    {
                      'accordion-without-arrow': !menu.subMenu,
                      'locked-accordion': !!menu.lockComponent,
                      'bg-[color:--shp-color-bg-dark-highlight-alternate]':
                        isMenuSelected(menu) === 0,
                      'menu-link-selected': isMenuSelected(menu) === 1,
                      'menu-link-accent':
                        isHomeMenu(menu.link) && setupIsNotCompleted,
                    },
                  )}
                >
                  <div className="flex items-center relative w-full">
                    <IconHelper
                      icon={menu.icon}
                      size={20}
                      className={classnames('ml-[7px]', {
                        '!text-white': isMenuSelected(menu) === 1,
                      })}
                    />

                    {showCollapsed || (
                      <>
                        <span
                          className={classnames(
                            'mx-1 font-normal text-[16px] md:text-[14px]',
                            {
                              '!text-white': isMenuSelected(menu) === 1,
                            },
                          )}
                          id={`link-${menu.label}`}
                          data-test={`link-${menu.label}`}
                        >
                          {isHomeMenu(menu.link) && setupIsNotCompleted
                            ? t(menuTrans.getStarted, {
                                completed: setupStepsCompleted,
                                total: 3,
                              })
                            : t(menuTrans[menu.label])}
                        </span>
                        {menu.hasDot && (
                          <Dot
                            isPulsing={menu.dotProps?.isPulsing}
                            color={Color.accent}
                            dotClassName="!right-[-20px]"
                          />
                        )}
                        {menu.isExternal && (
                          <span className="ext-link ml-auto">
                            <IconHelper icon="IconExternalLink" size={14} />
                          </span>
                        )}
                        {menu.lockComponent}
                      </>
                    )}
                  </div>
                </AccordionHeader>
                {menu.subMenu && menu.isVisible && (
                  // Second level menu section
                  <AccordionBody
                    accordionId={menu.label}
                    className={classnames({
                      hidden: showCollapsed,
                    })}
                  >
                    <span
                      id={`link-${menu.label}-body`}
                      data-test={`link-${menu.label}-body`}
                    >
                      {menu.subMenu.map((sm, k) => {
                        if (sm.subMenu && sm.isVisible) {
                          return (
                            <Accordion
                              key={k}
                              open={openedAccordionIds}
                              toggle={toggleAccordion}
                              className={classnames({
                                'accordion-without-arrow': showCollapsed,
                              })}
                            >
                              <AccordionItem
                                className={classnames({
                                  'accordion-without-arrow': !menu.subMenu,
                                })}
                              >
                                <AccordionHeader
                                  targetId={sm.label}
                                  // manage link only if there is no 2nd level menu
                                  onClick={() => {
                                    if (sm.onClick) sm.onClick();
                                    if (!sm.subMenu)
                                      manageLink(
                                        sm.link,
                                        sm.isExternal,
                                        sm.isCustom,
                                      );
                                  }}
                                  className={classnames(
                                    'flex items-center justify-center min-h-[52px] md:min-h-[38px] accordion-header-btn-pl-0',
                                    {
                                      'accordion-without-arrow': !sm.subMenu,
                                      'bg-[color:--shp-color-bg-dark-highlight]':
                                        isMenuSelected(sm) === 0,
                                      'menu-link-selected':
                                        isMenuSelected(sm) === 1,
                                    },
                                  )}
                                >
                                  <div className="menu-link flex !px-0 items-center relative w-full">
                                    {showCollapsed || (
                                      <>
                                        <IconHelper
                                          icon="IconCircle"
                                          size={8}
                                        />
                                        <span
                                          className={classnames(
                                            'ml-2 text-[16px] md:text-[14px] text-purpleish whitespace-nowrap',
                                            {
                                              '!text-white':
                                                isMenuSelected(sm) === 1,
                                            },
                                          )}
                                          id={`link-${sm.label}`}
                                          data-test={`link-${sm.label}`}
                                        >
                                          {t(menuTrans[sm.label])}
                                        </span>
                                        {sm.isExternal && (
                                          <span className="ext-link ml-auto">
                                            <IconHelper
                                              icon="IconExternalLink"
                                              size={14}
                                            />
                                          </span>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </AccordionHeader>
                                {/*Second level menu section*/}
                                <AccordionBody
                                  accordionId={sm.label}
                                  className={classnames({
                                    hidden: showCollapsed,
                                  })}
                                >
                                  <span
                                    id={`link-${menu.label}-body`}
                                    data-test={`link-${menu.label}-body`}
                                  >
                                    {sm.subMenu.map(
                                      (lastMenuItem: LastLevelLink, j) =>
                                        lastMenuItem &&
                                        lastMenuItem.isVisible && (
                                          <LastLevelMenuItem
                                            key={j}
                                            menuItem={lastMenuItem}
                                            i={j}
                                            manageLink={manageLink}
                                            isMenuSelected={isMenuSelected}
                                            ml={true}
                                          />
                                        ),
                                    )}
                                  </span>
                                </AccordionBody>
                              </AccordionItem>
                            </Accordion>
                          );
                        } else if (!sm.subMenu && sm.isVisible) {
                          return (
                            <LastLevelMenuItem
                              key={k}
                              menuItem={sm}
                              i={k}
                              manageLink={manageLink}
                              isMenuSelected={isMenuSelected}
                            ></LastLevelMenuItem>
                          );
                        }

                        return null;
                      })}
                    </span>
                  </AccordionBody>
                )}
              </AccordionItem>
            )
          );
        })}
      </Accordion>
    </div>
  );
};

export default MenuItem;
