import { useCallback, useMemo } from 'react';
import { CustomRTKQueryOptions } from '@web/utils/@reduxjs/rtk-query';
import { useGetCarriers } from './api/useGetCarriers';
import { useGetCountries } from './api/useGetCountries';
import { useGetMarketplaces } from './api/useGetMarketplaces';
import { useGetSenderInfo } from './api/useGetSenderInfo';
import { useGetUser } from './api/useGetUser';
import { useGetPlan } from './api/useGetPlan';
import { useGetSetupData } from './api/useGetSetupData';
import { useHasActivePlan } from './api/useHasActivePlan';
import { useGetFeatureFlaggingCustomParameters } from './api/useGetFeatureFlaggingCustomParameters';

type GlobalData = {
  isReady: boolean;
  isLoading: boolean;
  fetchAll: () => void;
};

/**
 * Hook that creates & manages the Global properties
 *
 * @author Fabio Fognani <fabio.fognani@shippypro.com>
 *
 * @returns {GlobalData} The object containing every global property.
 */
const useGlobalData = (options?: CustomRTKQueryOptions): GlobalData => {
  const {
    countries,
    isLoading: isCountriesLoading,
    refetch: fetchCountries,
  } = useGetCountries(options);
  const {
    senderInfo,
    isLoading: isSenderInfoLoading,
    refetch: fetchSenderInfo,
  } = useGetSenderInfo(options);
  const {
    allMarketplaces: marketplaces,
    isLoading: isMarketplacesLoading,
    refetch: fetchMarketplaces,
  } = useGetMarketplaces(options);
  const {
    carriers,
    isLoading: isCarriersLoading,
    refetch: fetchCarriers,
  } = useGetCarriers(options);

  const {
    user,
    isLoading: isUserLoading,
    refetch: fetchUser,
  } = useGetUser(options);

  const {
    plan,
    isLoading: isPlanLoading,
    refetch: fetchPlan,
  } = useGetPlan(options);

  const {
    data: setupData,
    isLoading: isSetupDataLoading,
    refetch: fetchSetupData,
  } = useGetSetupData(options);

  const {
    data: hasActivePlanData,
    isLoading: isHasActivePlanDataLoading,
    refetch: fetchHasActivePlanData,
  } = useHasActivePlan(options);

  const {
    data: featureFlaggingCustomParameters,
    isLoading: isFeatureFlaggingCustomParametersLoading,
    refetch: fetchFeatureFlaggingCustomParameters,
  } = useGetFeatureFlaggingCustomParameters(options);

  // true iff all the necessary global data has been loaded
  const isLoading = useMemo(() => {
    return (
      isCountriesLoading ||
      isSenderInfoLoading ||
      isMarketplacesLoading ||
      isCarriersLoading ||
      isUserLoading ||
      isPlanLoading ||
      isSetupDataLoading ||
      isHasActivePlanDataLoading ||
      isFeatureFlaggingCustomParametersLoading
    );
  }, [
    isCountriesLoading,
    isSenderInfoLoading,
    isMarketplacesLoading,
    isCarriersLoading,
    isUserLoading,
    isPlanLoading,
    isSetupDataLoading,
    isHasActivePlanDataLoading,
    isFeatureFlaggingCustomParametersLoading,
  ]);

  const isReady = useMemo(() => {
    return Boolean(
      countries &&
        senderInfo &&
        marketplaces &&
        carriers &&
        user &&
        plan &&
        setupData &&
        hasActivePlanData &&
        featureFlaggingCustomParameters,
    );
  }, [
    countries,
    senderInfo,
    marketplaces,
    carriers,
    user,
    plan,
    setupData,
    hasActivePlanData,
    featureFlaggingCustomParameters,
  ]);

  const fetchAll = useCallback(() => {
    fetchCountries();
    fetchSenderInfo();
    fetchMarketplaces();
    fetchCarriers();
    fetchUser();
    fetchPlan();
    fetchSetupData();
    fetchHasActivePlanData();
    fetchFeatureFlaggingCustomParameters();
  }, [
    fetchCountries,
    fetchSenderInfo,
    fetchMarketplaces,
    fetchCarriers,
    fetchUser,
    fetchPlan,
    fetchSetupData,
    fetchHasActivePlanData,
    fetchFeatureFlaggingCustomParameters,
  ]);

  return {
    isReady,
    isLoading,
    fetchAll,
  };
};

export default useGlobalData;
