import { useGetCarriers } from '@web/features/_global/hooks/api/useGetCarriers';
import type { Carrier } from '@web/types/global';

/**
 * [HOOK] useGetCarrier returns a single carrier from your configured
 * set of carriers depending on the composite key [ID <-> Platform Name]
 *
 * @returns {Carrier | undefined} the found Carrier object
 *
 * @author [Emanuele Moricci](https://github.com/emanuele-moricci-shippypro)
 */
const useGetCarrier = (
  id: number | null | undefined,
  name: string | null | undefined,
): Carrier | undefined => {
  const { carriers } = useGetCarriers();

  return getCarrier(carriers || [], id, name);
};

export const getCarrier = (
  carriers: Carrier[],
  id: number | null | undefined,
  name: string | null | undefined,
) => {
  return carriers.find(m => m.id === id && m.name === name);
};

export default useGetCarrier;
