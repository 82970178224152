import { FC } from 'react';
import { Submenu as ContexifySubmenu } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';

import { ISubMenuProps } from './types';

/**
 * A simple wrapper class for the `contexify: <Submenu />` component.
 *
 * @interface ISubMenuProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const Submenu: FC<ISubMenuProps> = props => (
  <ContexifySubmenu {...props} />
);
