import { FC } from 'react';
import OrderNote, {
  IOrderNoteProps,
} from '@web/components/common-features/order/notes/OrderNote';

interface IMarketplaceNoteProps
  extends Omit<
    IOrderNoteProps,
    'color' | 'editable' | 'isEditing' | 'setEditing' | 'onChange' | 'disabled'
  > {}

/**
 * This component hosts the note coming from the marketplace, with its fixed color.
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 *
 * @version 1.0.0
 * @interface IMarketplaceNoteProps
 * @returns {JSX.Element}
 */

const MarketplaceNote: FC<IMarketplaceNoteProps> = props => {
  return (
    <OrderNote editable={false} dataTest="sales-channel-note" {...props} />
  );
};

export default MarketplaceNote;
