import { InputGroupText } from 'reactstrap';

import { ITableComposition, ITableFiltering } from '../../types';

import { IconHelper } from '@ds-web-iconhelper';

import { Input } from '@ds-web/components/atoms/form/inputs/input/Input';
import { InputGroup } from '@ds-web/components/atoms/form/inputs/input-group/InputGroup';

import { Size } from '@ds-web/tokens/enums';

interface IDataTableSearch {
  composition: ITableComposition;
  filtering: ITableFiltering;

  filters: any;
  handleFilter: (filters: any, data: any) => any;
  filterNumber: number;

  paginationPerPage: number;
  handlePagination: (rowNumber: number) => void;
}

const DataTableSearch = ({
  composition,
  filtering,
  ...rest
}: IDataTableSearch) => {
  return (
    <InputGroup className="input-group-merge mb-2" size={Size.md}>
      <InputGroupText>
        <IconHelper icon="IconSearch" size={14} />
      </InputGroupText>
      <Input
        className="datatable-search-input"
        value={rest.filters?.input ?? ''}
        placeholder={filtering.placeholder}
        onChange={(e: any) =>
          rest.handleFilter(
            { ...rest.filters, input: e.target.value },
            composition.data,
          )
        }
      />
    </InputGroup>
  );
};

export default DataTableSearch;
