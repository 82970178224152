import { useMemo, useRef } from 'react';

import { useVirtualizer } from '@tanstack/react-virtual';
import { Row } from '@tanstack/react-table';

export const usePrepareTableVirtualisation = <T extends unknown>(
  rows: Row<T>[],
  estimateSize?: (index: number) => number,
  overscan: number = 5,
) => {
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: index => {
      if (estimateSize) {
        return estimateSize(index);
      }

      const row = rows[index];

      return row.getIsExpanded() ? 260 : 45;
    },
    overscan,
  });

  const { getVirtualItems, getTotalSize } = rowVirtualizer;

  const paddingTop =
    getVirtualItems().length > 0 ? getVirtualItems()?.at(0)?.start ?? 0 : 0;
  const paddingBottom =
    getVirtualItems().length > 0
      ? getTotalSize() - (getVirtualItems()?.at(-1)?.end ?? 0)
      : 0;

  return useMemo(
    () => ({
      ...rowVirtualizer,
      tableContainerRef,
      totalSize: getTotalSize(),
      paddingTop,
      paddingBottom,
    }),
    [paddingBottom, paddingTop, getTotalSize, rowVirtualizer],
  );
};
