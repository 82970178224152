import { Fragment } from 'react';

const heightBars = 20,
  borderRadius = 4,
  heightButton = 38;

let padding = 15,
  heightRows = 50;

export const LineSkeleton = ({ ...rest }) => (
  <rect x="0" y={rest.y} width="100%" height="1" />
);

export const BoxSkeleton = ({ ...rest }) => {
  rest.height = rest.height || heightBars;
  rest.borderRadius = rest.borderRadius || borderRadius;
  return (
    <rect
      x={`calc(50% - ${rest.width / 2}px)`}
      y={rest.y}
      rx={rest.borderRadius}
      ry={rest.borderRadius}
      width={rest.width}
      height={rest.height}
    />
  );
};

export const CheckboxSkeleton = ({ ...rest }) => {
  padding = rest.padding || padding;
  rest.heightBars = rest.heightBars || heightBars;
  rest.borderRadius = rest.borderRadius || borderRadius;
  return (
    <rect
      x={padding}
      y={rest.y}
      rx={rest.borderRadius}
      ry={rest.borderRadius}
      width={rest.heightBars}
      height={rest.heightBars}
    />
  );
};

export const TableHeaderSkeleton = ({
  ...rest
}: {
  y: number;
  padding?: number;
  left?: number;
  right?: number;
  heightButton?: number;
  borderRadius?: number;
}) => {
  padding = rest.padding || padding;
  rest.heightButton = rest.heightButton || heightButton;
  rest.borderRadius = rest.borderRadius || borderRadius;

  return (
    <>
      {rest.left ? (
        <rect
          x={padding}
          y={rest.y}
          rx={rest.borderRadius}
          ry={rest.borderRadius}
          width={rest.left}
          height={rest.heightButton}
        />
      ) : (
        ''
      )}

      {rest.right ? (
        <rect
          x={`calc(100% - ${padding + rest.right}px)`}
          y={rest.y}
          rx={rest.borderRadius}
          ry={rest.borderRadius}
          width={rest.right}
          height={rest.heightButton}
        />
      ) : (
        ''
      )}
    </>
  );
};

export const RowSkeleton = ({
  ...rest
}: {
  y: number;
  countColumn: number;
  checkbox?: number;
  lastColumn?: number;
  padding?: number;
  heightBars?: number;
}) => {
  const firstColumnWidth = rest.checkbox ? rest.checkbox : 0,
    lastColumnWidth = rest.lastColumn ? rest.lastColumn : 0;

  !firstColumnWidth && rest.countColumn++;
  !lastColumnWidth && rest.countColumn++;

  padding = rest.padding || padding;
  rest.heightBars = rest.heightBars || heightBars;

  return (
    <>
      {rest.checkbox ? <CheckboxSkeleton y={rest.y} /> : ''}
      {new Array(rest.countColumn - 2).fill(' ').map((_, col) => {
        return (
          <rect
            x={`calc( ${firstColumnWidth + padding}px + (100% - ${
              firstColumnWidth + lastColumnWidth
            }px) / ${rest.countColumn - 2} * ${col})`}
            y={rest.y}
            rx="10"
            ry="10"
            width={`calc((100% - ${firstColumnWidth}px - ${lastColumnWidth}px) / ${
              rest.countColumn - 2
            } - ${padding * 2}px)`}
            height={rest.heightBars}
            key={col}
          />
        );
      })}

      {rest.lastColumn ? (
        <rect
          x={`calc(100% - ${lastColumnWidth - padding}px)`}
          y={rest.y}
          rx="10"
          ry="10"
          width={`${lastColumnWidth - 2 * padding}px`}
          height={rest.heightBars}
        />
      ) : (
        ''
      )}
    </>
  );
};

export const PaginationSkeleton = ({
  ...rest
}: {
  y: number;
  width: number;
  pagination?: number;
  padding?: number;
  heightBars?: number;
}) => {
  const pagination = rest.pagination ? rest.pagination : 0;

  padding = rest.padding || padding;
  rest.heightBars = rest.heightBars || heightBars;

  return (
    <>
      {pagination ? (
        <rect
          x={padding}
          y={rest.y}
          rx="10"
          ry="10"
          width={pagination}
          height={rest.heightBars}
        />
      ) : (
        ''
      )}
      <rect
        x={`calc(100% - ${padding}px - ${rest.width}px)`}
        y={rest.y}
        rx="10"
        ry="10"
        width={rest.width}
        height={rest.heightBars}
      />
    </>
  );
};

export const TableSkeleton = ({
  ...rest
}: {
  y: number;
  numberRows: number;
  countColumn: number;
  pagination: {
    width: number;
    pagination?: number;
  };
  padding?: number;
  heightBars?: number;
  heightRows?: number;
  checkbox?: number;
  lastColumn?: number;
  header?: boolean;
}) => {
  heightRows = rest.heightRows || heightRows;
  padding = rest.padding || padding;
  rest.heightBars = rest.heightBars || heightBars;

  const header = typeof rest.header === 'undefined' || rest.header;

  return (
    <>
      <LineSkeleton y={rest.y} />
      {header ? (
        <>
          <RowSkeleton {...rest} y={rest.y + 9} />
          <LineSkeleton y={(rest.y += 38)} />
        </>
      ) : null}

      {new Array(rest.numberRows).fill(' ').map((_, i) => {
        return (
          <Fragment key={i}>
            <RowSkeleton
              {...rest}
              y={rest.y + (heightRows - (rest.heightBars || heightBars)) / 2}
            />
            <LineSkeleton y={(rest.y += heightRows)} />
          </Fragment>
        );
      })}

      <PaginationSkeleton {...rest.pagination} y={rest.y + 30} />
    </>
  );
};
