import { css } from 'styled-components';

/** TODO: move to design system + add documentation
 * This is a helper class to make the dialog responsive for mobile devices.
 *
 * @example
 * import { DialogForMobile } from '@web/components/common-ui/dialogs/DialogForMobile';
 *
 * const Dialog = styled.div`
 * ${DialogForMobile}
 * `;
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const DialogForMobile = css`
  // Enabling these styles ONLY for mobile viewports
  @media (max-width: 480px) {
    // Giving the dialog a fixed full-width and full-height
    margin: 0 !important;
    padding: 0 !important;

    .modal-content {
      // Rendering the header sticky and giving it a background color
      .modal-header {
        position: sticky;
        top: 0;

        z-index: 10;

        background-color: var(--shp-color-bg);

        border-radius: 0;

        // Making the header title and the close button responsive and mobile-ready
        .modal-title {
          font-size: 1.286rem;
          font-weight: bold;
          color: var(--shp-color-genericui-purpleish);
        }

        .btn-close {
          margin: 0;
          padding: 0;

          background-size: 1rem;
          width: 1.25rem;
          height: 1.25rem;

          box-shadow: unset;
          transform: unset;
        }
      }

      .modal-body {
        padding: 1.2rem 1.4rem 0 1.4rem;

        // Making the body title responsive and mobile-ready
        .modal-body-title {
          color: var(--shp-color-genericui-purpleish);

          margin: 0 !important;

          font-size: 1.143rem;
          letter-spacing: normal;
          text-align: center;
        }
      }

      // Rendering the header sticky and giving it a background color
      .modal-footer {
        position: sticky;
        bottom: 0;

        background-color: var(--shp-color-bg);

        border-radius: 0;

        // Making the submission button full-width
        .btn {
          width: 100%;
          display: flex;
          justify-content: center;

          margin: 1.25rem 0 1.25rem 0;
          padding: 1rem 0 1rem 0;

          font-size: 1.145rem;
        }
      }
    }
  }
`;
