// ########################## [IMPORTANT LIBRARIES]
import { useEffect, useRef } from 'react';
import classnames from 'classnames';
import { FormGroup, Label, FormFeedback, InputGroupText } from 'reactstrap';
import { CountryCode } from 'libphonenumber-js';

// ########################## [FORMS]
import { Controller, FieldValues } from 'react-hook-form';
import {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  getCountryCallingCode,
  isValidPhoneNumber,
  parsePhoneNumber,
} from 'react-phone-number-input';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';

// ########################## [DESIGN SYSTEM]
import {
  HelperText,
  InputGroup,
  UncontrolledTooltip,
  WarningText,
} from '@shippypro/design-system-web';

// ########################## [PAGE COMPONENTS]
import { ControlledInputProps } from './ControlledInput';

// ########################## [UTILS]
import 'react-phone-number-input/style.css';

export function ControlledPhoneInput<FormType extends FieldValues>({
  name,
  type,
  label,
  placeholder,
  control,
  inputProps,
  inputGroupProps,
  labelProps,
  helperTextProps,
  // here we can support one or more ways to show errors: FormFeedback, tooltip...
  errorModes = ['formFeedback'],
  errorTextProps,
  errorText,
  warningText,
  warningTextProps,
  successTextProps,
  successText,
  helperText,
  className,
  disabled,
  required,
  icon,
  endIcon,
  endLabelIcon,
  dataTest,
  inputGroupLeftSideElement = null,
  preFillPrefix,
  ...rest
}: ControlledInputProps<FormType>) {
  const isRequired = required ?? inputProps?.required;

  const tooltipTargetRef = useRef<HTMLParagraphElement | null>(null);

  // Set refs to save:
  // - the country code value
  // - whether a country has just been changed
  // - the phone number without prefix when the country has been changed
  const countryRef = useRef<CountryCode>(
    inputProps?.defaultValue as CountryCode,
  );
  const countryChangeRef = useRef<Boolean>(false);
  const phoneNumberWithoutPrefixRef = useRef<String>('');

  useEffect(() => {
    countryRef.current = inputProps?.defaultValue;
  }, [inputProps?.defaultValue]);

  return (
    <div ref={tooltipTargetRef}>
      <Controller
        control={control}
        render={({
          field: { onChange, onBlur, value },
          fieldState: { error },
        }) => {
          // Removing special characters
          const format = /\(.*?\)/g;
          const valueHasSpecialCharacters = value
            ? Boolean(value.match(format))
            : false;
          if (valueHasSpecialCharacters) {
            const cleanedValue = value.replace(/[&/\\#,()$~%.'":*?<>{}]/g, '');
            if (inputGroupProps?.onChange) {
              inputGroupProps.onChange(cleanedValue);
            } else {
              onChange(cleanedValue);
            }
          }
          return (
            <FormGroup
              {...rest}
              className={classnames('!mb-0 w-full', className)}
            >
              <div className="flex space-x-1 label-icon-block">
                {label && (
                  <Label {...labelProps}>
                    {label}
                    {isRequired && <span className="ml-[2px]">*</span>}
                  </Label>
                )}
                {endLabelIcon}
              </div>
              {errorModes.includes('tooltip') && (error || errorText) && (
                <UncontrolledTooltip target={tooltipTargetRef} trigger="hover">
                  {errorText ?? error?.message}
                </UncontrolledTooltip>
              )}
              <InputGroup {...inputGroupProps}>
                {icon && <InputGroupText>{icon}</InputGroupText>}
                {inputGroupLeftSideElement}

                <PhoneInputWithCountry
                  data-test={dataTest ?? name}
                  name={name}
                  control={control}
                  className={classnames('form-control', {
                    'is-invalid': error || errorText,
                    'border border-warning': warningText,
                  })}
                  placeholder={placeholder ?? inputProps?.placeholder}
                  // NOTE: we are keeping the defaultCountry correctly set but it's not working as it should
                  country={inputProps?.defaultValue}
                  defaultCountry={
                    preFillPrefix ? inputProps?.defaultValue : undefined
                  }
                  international
                  value={value ?? ''}
                  disabled={disabled ?? inputProps?.isDisabled}
                  rules={{ required: isRequired }}
                  onBlur={
                    error || !inputProps?.onBlur ? onBlur : inputProps?.onBlur
                  }
                  onChange={e => {
                    const prevValue = value;
                    let currentValue = e;
                    // 0. Formatting currentValue
                    /** currentValue is:
                     *  - the country phone prefix if countryChangeRef.current
                     *  - the complete phone number if !countryChangeRef.current
                     *  */
                    if (countryChangeRef.current) {
                      const currentCountryPrefix =
                        '+' + getCountryCallingCode(countryRef.current);
                      const currentCountryPrefixWithPrevValue =
                        '+' +
                        getCountryCallingCode(countryRef.current) +
                        prevValue;
                      if (currentCountryPrefix === currentValue) {
                        // If country has just been changed and currentValue is just a prefix
                        // add the prefix to previous value without prefix
                        currentValue = e + phoneNumberWithoutPrefixRef.current;
                        phoneNumberWithoutPrefixRef.current = '';
                      } else if (
                        prevValue !== null &&
                        parsePhoneNumber(prevValue)
                      ) {
                        // If country has just been changed and prevValue was a valid number
                        // reset currentValue to prevValue without prefix
                        currentValue = parsePhoneNumber(prevValue)?.number;
                      } else if (
                        value !== null &&
                        parsePhoneNumber(prevValue) === undefined &&
                        isValidPhoneNumber(currentCountryPrefixWithPrevValue)
                      ) {
                        // If previous value it's 'international' (without prefix)
                        // and it is a valid phone number itself
                        // add the default country prefix to it
                        currentValue = currentCountryPrefixWithPrevValue;
                      }
                      // ref reset
                      countryChangeRef.current = false;
                    } else {
                      const oldValueWithDefCountryCode =
                        '+' +
                        getCountryCallingCode(inputProps?.defaultValue) +
                        prevValue;
                      if (
                        prevValue !== null &&
                        parsePhoneNumber(prevValue) === undefined &&
                        isValidPhoneNumber(oldValueWithDefCountryCode)
                      ) {
                        // If previous value it's 'international' (without prefix)
                        // and it is a valid phone number itself
                        // add the default country prefix to it
                        currentValue = oldValueWithDefCountryCode;
                      }
                    }
                    // 1. Format currentValue into the international format, to always have country prefix in front
                    const currentValueWithPrefix =
                      formatPhoneNumberIntl(currentValue);
                    // 2. If currentValueWithPrefix has value, save it, otherwise use the one without prefix
                    const valueToBeChanged = currentValueWithPrefix.length
                      ? currentValueWithPrefix
                      : currentValue;
                    // 3. On change action
                    if (inputGroupProps?.onChange) {
                      inputGroupProps.onChange(valueToBeChanged);
                    } else {
                      onChange(valueToBeChanged);
                    }
                  }}
                  onCountryChange={e => {
                    if (e) {
                      // If a country has been selected
                      countryRef.current = e;
                      countryChangeRef.current = true;
                      phoneNumberWithoutPrefixRef.current =
                        formatPhoneNumber(value);
                    }
                  }}
                />
                {endIcon && <InputGroupText>{endIcon}</InputGroupText>}
              </InputGroup>
              {(error || errorText) && errorModes.includes('formFeedback') && (
                <FormFeedback {...errorTextProps}>
                  {errorText ?? error?.message}
                </FormFeedback>
              )}
              {successText && (
                <FormFeedback valid {...successTextProps}>
                  {successText}
                </FormFeedback>
              )}
              {helperText && (
                <HelperText {...helperTextProps}>{helperText}</HelperText>
              )}
              {warningText && (
                <WarningText {...warningTextProps}>{warningText}</WarningText>
              )}
            </FormGroup>
          );
        }}
        name={name}
      />
    </div>
  );
}
