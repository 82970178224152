import { CashOnDeliveryType } from '@web/types/order';

export type OrderDetails = {
  description?: string | null;
  totalAmount?: string | null;
  orderNum?: string | null;
  incoterm?: string | null;
  accountNumber?: string | null;
  insurance?: number | null;
  carrierNotes?: string | null;
  currency?: string | null;
  insuranceCurrency?: string | null;
  cashOnDelivery?: number | null;
  cashOnDeliveryCurrency?: string | null;
  cashOnDeliveryType?: CashOnDeliveryType | null;
  shippingService?: string | null;
};
