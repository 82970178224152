import { FC } from 'react';

import { IListHeaderCellProps } from './types';

const ListHeaderCell: FC<IListHeaderCellProps> = ({
  config,
  first,
  width,
  ...rest
}) => {
  rest.className = (
    (rest.className || '') +
    ' list-view-header-cell ' +
    (rest.actions ? 'whitespace-nowrap relative' : 'w-full')
  ).trim();
  rest.children = rest.actions ? (
    <>
      <div className="w-20"></div>
      <div className="absolute r0">{rest.children}</div>
    </>
  ) : (
    rest.children
  );
  return (
    <span
      className={rest.className}
      //maybe there's a better way to do this
      style={width ? { width: `${width + 5}%` } : {}}
    >
      {rest.children}
    </span>
  );
};

export default ListHeaderCell;
