// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [DESIGN SYSTEM COMPONENTS]
import { Popover, PopoverBody } from '@shippypro/design-system-web';

interface IBRTPopoverProps {
  isOpen: boolean;
}

/**
 * The BRTPopover component is used to show informations about the labels format when printing
 * BRT-specific Orders, using a skeleton image as an example.
 *
 * @interface IBRTPopoverProps
 * @author [Emanuele Moricci] <emanuele.moricci@shippypro.com>
 */
const BRTPopover: React.FC<IBRTPopoverProps> = ({ isOpen }) => {
  const { t } = useTranslation(),
    trans = translations.ship.buttons.print.brt.popover;

  return (
    <Popover
      placement="left"
      target="print-btn-brt"
      isOpen={isOpen}
      data-test="print-brt-popover"
    >
      <PopoverBody className="flex flex-col w-[175px]">
        <h3 className="text-sm text-purpleish font-medium leading-7">
          {t(trans.title)}
        </h3>
        <p className="text-sm text-purpleish !mb-5">{t(trans.description)}</p>
        <img
          src="https://cdn.shippypro.com/assets/images/labels/brt-a4_8Labels.png"
          alt={t(trans.title)}
          width={100}
          height={150}
        />
      </PopoverBody>
    </Popover>
  );
};

export default BRTPopover;
