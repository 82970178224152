// ########################## [IMPORTANT LIBRARIES]
import { useMemo } from 'react';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [TYPES]
import { Order } from '@web/types/order';
import { Marketplace } from '@web/types/global';

// ########################## [PAGE COMPONENTS]
import StatusBadge from '@web/features/order/components/badges/StatusBadge';

// ########################## [HOOKS]
import { useGetMarketplaces } from '@web/features/_global/hooks/api/useGetMarketplaces';
import { getMarketplace } from '@web/hooks/marketplaces/useGetMarketplace';
import useGetTabColumnSize from '@web/features/ship/hooks/tabs/useGetTabColumnSize';

const columnHelper = createColumnHelper<Order>();

export const StatusColumnCode = 'orderStatus';

export const useGetStatusColumn = (): ColumnDef<Order, string> => {
  const { t } = useTranslation(),
    transToShipTable = translations.ship.table,
    transToShip = transToShipTable.columns;

  const { allMarketplaces: marketplaces } = useGetMarketplaces();

  const size = useGetTabColumnSize(StatusColumnCode, 150);

  return useMemo(
    () =>
      columnHelper.accessor('status', {
        header: t(transToShip.status),
        minSize: 100,
        size,
        maxSize: 150,
        id: 'status',
        enableSorting: true,
        cell: ({ row: { original: order } }) =>
          statusColumnRendererFn(order, marketplaces),
      }),
    [t, transToShip, marketplaces, size],
  );
};

const statusColumnRendererFn = (
  order: Order,
  marketplaces: Marketplace[] | undefined,
) => {
  const target = `shipped-status-${order.id}`;

  const marketplace = getMarketplace(
    marketplaces!,
    order.marketplace_id,
    order.marketplace_platform,
  );

  return (
    <StatusBadge order={order} marketplace={marketplace} target={target} />
  );
};
