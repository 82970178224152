/**
 * This function takes an array of objects and removes every duplicate based on the passed property key
 *
 * @param {T} array The array to remove the duplicates from
 * @param {string} key The key to use for the comparison
 * @returns {T} The resulting array without duplicates
 */
export const removeDuplicates = <T extends object>(
  array: T[],
  key: keyof T,
) => {
  const uniqueIds: T[keyof T][] = [];
  return array.filter(element => {
    const isDuplicate = uniqueIds.includes(element[key]);

    if (!isDuplicate) {
      uniqueIds.push(element[key]);
      return true;
    }

    return false;
  });
};

/**
 * This function takes an array and divides it into length-defined chunks,
 * resulting into an array of batched arrays.
 *
 * @param {T} array The array to batch
 * @param {?number} chunkSize The size of the chunks to divide it for. DEFAULT = 10
 * @returns {T[][]} The resulting array with the smaller chunks inside of it
 */
export const batchArrayInChunks = <T extends unknown>(
  array: T[],
  chunkSize: number = 10,
) => {
  const batchList: T[][] = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    batchList.push(chunk);
  }

  return batchList;
};

/**
 * This functions takes an array of objects and a prop name,
 * and returns an array of each object corresponding prop value
 *
 * @param {T} array The array of object to map
 * @param {string} prop The prop name
 * @returns {T[]} The resulting prop value array
 */
export const mapObjArraySingleProp = <T extends Object>(
  array: T[],
  prop: string,
) => {
  return array.map(el => el[prop]);
};

/**
 * Converts an array of strings or numbers into a query string.
 *
 * @param arr - The array of strings or numbers to be converted.
 * @param queryStringKey - The key to be used for each query string parameter.
 * @returns A query string representing the array.
 *
 * @author Marcello Aguzzi <marcello.aguzzi@shippypro.com>
 */
export function arrayToQueryString(
  arr: Array<string | number>,
  queryStringKey: string,
): string {
  let str: string[] = [];
  arr.forEach(arrValue => {
    str.push(
      encodeURIComponent(queryStringKey) + '[]=' + encodeURIComponent(arrValue),
    );
  });
  return str.join('&');
}
