import { FC } from 'react';
import classnames from 'classnames';

import { UncontrolledTooltip } from '@ds-web/components/atoms/ui/tooltip/UncontrolledTooltip';

import { ITimelineProps } from './types';

/**
 * A component that displays a list of events in chronological order.
 * Said events will have a name, a meta (usually time string) and some custom content.
 *
 * @interface ITimelineProps
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const Timeline: FC<ITimelineProps> = props => {
  const { data, className } = props;

  return (
    <ul
      className={classnames('timeline', {
        [className ?? '']: className,
      })}
    >
      {data.map((item, i) => {
        return (
          <li
            key={i}
            className={classnames('timeline-item', {
              [item.className ?? '']: className,
              '!pb-0': !item.content && !item.customContent,
            })}
          >
            <span
              className={classnames('timeline-point', {
                [`bg-${item.color}`]: item.color,
                'timeline-point-indicator': !item.icon,
              })}
            >
              {item.icon ? item.icon : null}
            </span>
            <div className="timeline-event">
              <div className="d-flex justify-content-between">
                <div>
                  <div
                    className={classnames(
                      'd-flex justify-content-between flex-sm-row flex-column',
                      {
                        'mb-sm-0 mb-1 !mr-4': item.meta,
                      },
                    )}
                  >
                    <h6 id={`item-${i}`} className="font-bold">
                      {item.title}
                    </h6>
                    <UncontrolledTooltip
                      target={`item-${i}`}
                      placement="top"
                      trigger="hover"
                    >
                      {item.tooltip}
                    </UncontrolledTooltip>
                  </div>
                  <p
                    className={classnames({
                      'mb-0': i === data.length - 1 && !item.customContent,
                    })}
                  >
                    {item.content}
                  </p>
                </div>
                {item.time ? (
                  <div
                    className={classnames(
                      'timeline-event-time w-fit d-flex flex-col text-right',
                      {
                        [item.metaClassName ?? '']: item.metaClassName,
                      },
                    )}
                  >
                    <span className="text-dark whitespace-nowrap">
                      {item.time.day}
                    </span>
                    <span>{item.time.hour}</span>
                  </div>
                ) : null}
                {item.meta ? (
                  <div
                    className={classnames('timeline-event-meta', {
                      [item.metaClassName ?? '']: item.metaClassName,
                    })}
                  >
                    {item.meta}
                  </div>
                ) : null}
              </div>
              {item.customContent ? item.customContent : null}
            </div>
          </li>
        );
      })}
    </ul>
  );
};
