import { FC, memo, useContext } from 'react';
import { Spinner } from 'reactstrap';

import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

import { Button } from '@shippypro/design-system-web';
import { IconHelper } from '@shippypro/design-system-web/iconhelper';
import { Color, StyleType } from '@shippypro/design-system-web/enums';
import { useGetDeviceSize } from '@shippypro/design-system-web/hooks';

import { useWatch } from 'react-hook-form';
import {
  LabelFormatSettingsForm,
  LabelFormatSettingsFormContext,
} from '../forms/LabelFormatSettingsForm';

import { GLSFormatCode, GLSFormatValue } from '../../types';

import { usePrintOrders } from '@web/features/ship/hooks/api/printing/usePrintOrder';
import { useGetCurrentGLSItalyConfig } from '../../hooks';

interface IPrintFooterProps {
  ids?: number[];
  glsformat?: GLSFormatValue;
  closeDialog: () => void;
}

/**
 * This component hosts a mirror of the button that would let the user print with normal labels,
 * but it also holds the info needed for the `GLSFormat` to be passed with the order ids to the API.
 *
 * @interface IPrintFooterProps
 *
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
const PrintFooter: FC<IPrintFooterProps> = memo(
  ({ ids = [], glsformat = -1, closeDialog }) => {
    const { t } = useTranslation(),
      trans = translations.ship.buttons.print.nLabels;

    // Checking if we're on a mobile viewport
    const { isMobile } = useGetDeviceSize();

    // Instantiating the form rules to save the currently-selected format as default
    const { form } = useContext(LabelFormatSettingsFormContext)!;
    const currentFormatSettings = useGetCurrentGLSItalyConfig(ids.length);
    const saveAsDefault = useWatch({
      control: form.control,
      name: 'saveAsDefault',
    });

    const glsFormatCode = Object.keys(GLSFormatCode).find(
      val => GLSFormatCode[val] === glsformat,
    );

    // Instantiating the API hook to print the orders
    const { printOrders, isLoading } = usePrintOrders();

    return (
      <section className="modal-footer flex flex-col">
        <hr className="!mb-3" />
        {isMobile && <LabelFormatSettingsForm form={form} ids={ids} />}
        <Button
          color={Color.primary}
          styleType={StyleType.filled}
          onClick={async () => {
            if (saveAsDefault && glsFormatCode) {
              localStorage.setItem(currentFormatSettings, glsFormatCode);
            }

            const result = await printOrders({ ids, glsformat });
            if (result) closeDialog();
          }}
          disabled={isLoading || glsformat === -1}
          className="flex !gap-2 self-end !mb-2"
          data-test="glsitaly-footer-printbtn"
        >
          {isLoading ? (
            <Spinner size="sm" />
          ) : (
            <IconHelper icon="IconPrinter" size={15} />
          )}
          <span>
            {t(trans, {
              count: ids.length,
            })}
          </span>
        </Button>
      </section>
    );
  },
);

export default PrintFooter;
