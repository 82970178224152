import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useLDFlags from '@web/hooks/useLDFlags';
import useHasUserCompletedOnboardingOrSetup from '@web/features/_global/hooks/useHasUserCompletedOnboardingOrSetup';
import { Spinner } from 'reactstrap';

const RedirectionCheck = () => {
  const navigate = useNavigate();
  const { newExperienceFlow } = useLDFlags();

  // Get the pre-requested destination-module (if present in the `/request-authcode` url)
  const dest_module = sessionStorage.getItem('destination_module');

  // Check if the user has completed the setup process
  const [hasCompletedOnboarding, hasCompletedSetup, isLoading] =
    useHasUserCompletedOnboardingOrSetup();

  useEffect(() => {
    if (!isLoading) {
      if (
        dest_module &&
        // avoid redirecting to onboarding pages if it was already completed
        !(dest_module === 'onboarding' && hasCompletedOnboarding)
      ) {
        // Redirect to the requested destination module if needed
        navigate(`/${dest_module}`);
      } else if (!hasCompletedOnboarding) {
        // Forcefully redirect to the `/onboarding` route if the onboarding wizard wasn't completed yet
        navigate('/onboarding');
      } else if (dest_module === 'home' && newExperienceFlow) {
        // Redirect to the `/home` route if dest_module is home and newExperienceFlow is enabled
        navigate('/home');
      } else if (!hasCompletedSetup) {
        // Forcefully redirect to the `/ship` route if the setup (connect marketplaces/carriers) wasn't completed yet
        navigate('/ship');
      } else {
        // Redirect to the `/home` route
        navigate('/home');
      }
    }
  }, [
    navigate,
    newExperienceFlow,
    dest_module,
    hasCompletedOnboarding,
    hasCompletedSetup,
    isLoading,
  ]);

  // This route will never need to show UI
  return isLoading ? (
    <div className="w-full vertical-layout flex justify-center items-center h-[95vh]">
      <Spinner />
    </div>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <></>
  );
};

export default RedirectionCheck;
