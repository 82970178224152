import { useCallback, useMemo } from 'react';
import { useTimer } from 'react-timer-hook';

type TimerData = {
  seconds?: number;
  minutes?: number;
  hours?: number;
};

/**
 * This hook returns an auto-starting timer that can be restarted with the same
 * initial values passed to it.
 *
 * @param {TimerData} timerData - The timer data (seconds, minutes, hours)
 * @author Emanuele Moricci <emanuele.moricci@shippypro.com>
 */
export const useGetRestartableTimer = (timerData: TimerData) => {
  const getTimerExpiryFromNow = useCallback(() => {
    const res = new Date();
    const { seconds, minutes, hours } = timerData;

    if (seconds) res.setSeconds(res.getSeconds() + seconds);
    if (minutes) res.setMinutes(res.getMinutes() + minutes);
    if (hours) res.setHours(res.getHours() + hours);

    return res;
  }, [timerData]);

  const { restart, ...timer } = useTimer({
    autoStart: true,
    expiryTimestamp: getTimerExpiryFromNow(),
  });

  return useMemo(
    () => ({
      ...timer,
      restart: () => restart(getTimerExpiryFromNow()),
    }),
    [getTimerExpiryFromNow, restart, timer],
  );
};
