import { Dispatch, FC, memo, SetStateAction } from 'react';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [TYPES]
import { Note } from '@web/types/order';

// ########################## [PAGE COMPONENTS]
import ShippyProNote from '../UI/notes/ShippyProNote';
import MarketplaceNote from '../UI/notes/MarketplaceNote';
import CarrierNote from '../UI/notes/CarrierNote';

interface INotesListProps {
  notes?: Note | null;
  editable?: boolean;
  isEditing?: boolean;
  setEditing?:
    | ((v: boolean) => void | Dispatch<SetStateAction<boolean>>)
    | ((v: boolean, save: boolean) => void);
  onChange?:
    | ((value: string) => Promise<unknown>)
    | ((note: string) => unknown);
  disabled?: boolean;
  localShippyProNote?: string | null;
  onSubmit?: (note: string) => void;
}

/**
 * This component lists all the notes available.
 *
 * @author Valeria Curseri <valeria.curseri@shippypro.com>
 *
 * @version 1.0.0
 * @interface INotesListProps
 * @returns {JSX.Element}
 */

const NotesList: FC<INotesListProps> = memo(
  ({
    notes,
    editable,
    isEditing,
    setEditing,
    onChange,
    disabled,
    localShippyProNote,
    onSubmit,
  }) => {
    const { t } = useTranslation(),
      transOrder = translations.order.info,
      transCommon = translations.common;

    return (
      <>
        {(localShippyProNote || notes?.shippypro || isEditing) && (
          <ShippyProNote
            category={t(transCommon.shippypro)}
            value={localShippyProNote || notes?.shippypro || ''}
            disabled={disabled}
            editable={!!editable}
            isEditing={isEditing}
            setEditing={setEditing}
            onChange={onChange}
            onSubmit={onSubmit}
          />
        )}
        {notes?.marketplace && (
          <MarketplaceNote
            category={t(transCommon.salesChannel)}
            value={notes.marketplace}
          />
        )}
        {notes?.carrier && (
          <CarrierNote
            category={t(transOrder.carrierNotes)}
            value={notes.carrier}
          />
        )}
      </>
    );
  },
);

export default NotesList;
