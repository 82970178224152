export enum StyleType {
  filled = 'filled',
  outline = 'outline',
  flat = 'flat',
}

export enum BorderType {
  default = 'default',
  round = 'round',
  square = 'square',
}

export enum Status {
  online = 'online',
  offline = 'offline',
  away = 'away',
  busy = 'busy',
}

export enum Direction {
  start = 'start',
  center = 'center',
  end = 'end',
}

export enum PaginationArrows {
  basic = 'basic',
  separated = 'separated',
  hide = 'hide',
}

export enum PaginationIcons {
  single = 'single',
  double = 'double',
  both = 'both',
}
