import { FC, useEffect, useState } from 'react';
import { useInterval } from 'react-use';
import classnames from 'classnames';

import { ILoadingPaceProps } from './types';
import { StyledPace } from './styles';
import { Color } from '@ds-web/tokens/enums';

/**
 * A custom progress bar that can be used to show a real loading state
 * while the FE fetches new data from the BE, based on activities count.
 *
 * @interface ILoadingPaceProps
 * @author Marcello Aguzzi <marcello.aguzzi@shippypro.com>
 */
export const LoadingPace: FC<ILoadingPaceProps> = ({
  totalActivitiesCount,
  pendingActivitiesCount,
  stepLength,
  completed,
  color = Color.accent,
  pulsed = true,
}) => {
  // Setting the current progress of the loading bar, expressed in a percentage value used for the width of the bar
  const [progress, setProgress] = useState(0);
  const [isInvisible, setIsInvisible] = useState(false);

  useInterval(
    () => {
      // The progress bar will run until the `completed` prop is `true` based on pending activities
      const done = totalActivitiesCount - pendingActivitiesCount;
      const progressPercentage =
        done * stepLength <= 100 ? done * stepLength : 100;
      setProgress(progressPercentage);
    },
    // If the `complete` props change, the loading bar will either stop or run to completion setting delay to null
    completed ? null : 750,
  );

  // Reset the progress bar after a while when the `complete` prop changes to `true`
  // to give the component a smooth opacity transition feel
  useEffect(() => {
    if (completed) {
      const timeout = setTimeout(() => {
        setIsInvisible(true);
        setProgress(0);
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [completed]);

  return (
    <StyledPace
      className={classnames(`loading-pace-progress-bar bg-${color}`, {
        'animate-pulse': pulsed,
        invisible: isInvisible,
      })}
      style={{
        // The loading bar will be gradually hidden when the `complete` prop is `true`
        opacity: completed ? 0 : 1,
        // Changing the width of the loading bar based on the current progress and completion status
        width: !progress ? `0%` : completed ? `100%` : `${progress}%`,
      }}
    />
  );
};
