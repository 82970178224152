import { FetchArgs } from '@reduxjs/toolkit/query/react';

export type BASE_MICROSERVICE_ALLOWED_METHODS =
  | 'GET'
  | 'POST'
  | 'PUT'
  | 'PATCH'
  | 'DELETE';

/**
 * This method scaffolds the query object for a generic microservice API request.
 * It takes a url, a REST method code and an optional body.
 * N.B. Said body is only used for non-GET requests.
 *
 * @param {string} url The url of the microservice endpoint.
 * @param {BASE_MICROSERVICE_ALLOWED_METHODS} method The REST method code.
 * @param {ArgT} [args] The body of the request.
 * @returns {FetchArgs} The query object.
 */
export function getMicroserviceQuery<ArgT>(
  url: string,
  method: BASE_MICROSERVICE_ALLOWED_METHODS,
  args?: ArgT,
): FetchArgs {
  return {
    url,
    method,
    body: method !== 'GET' ? args ?? {} : undefined,
  };
}
